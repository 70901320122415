export function quantifier(value: number, oneQuantity: string, manyQuantity: string,
    zeroText?: string): string {

    if (value === 0) {
        return zeroText || "";
    }

    return value === 1 ?
        `1 ${oneQuantity}` :
        `${value} ${manyQuantity}`;
}

export function isEmpty(str: string | null | undefined) {
    return !str || str.trim().length === 0;
}

export function formatPercentage(val: number): string {
    const wholeVal: number = val * 100;
    const formattedAmount = Number.isInteger(wholeVal) ?
        wholeVal :
        wholeVal.toFixed(2);
    return `${formattedAmount} %`;
}
export function formatCurrency(val: number): string {
    const formattedAmount = val.toFixed(2);
    return formattedAmount + "€";
}

//Checks to see if every required field was filled
export function verifyErrors<Type>(errors: Type): string | undefined {
    for (const key in errors) {
        if (Object.prototype.hasOwnProperty.call(errors, key)) {
            const element = errors[key];
            if (element) {
                return key;
            }
        }
    }
    return undefined;
}

/**
 * Waits X seconds to execute a callback
 * @param seconds number of milliseconds to wait
 * @param callback function to execute once the tim runs out
 */
export function dealyForXSeconds(seconds: number, callback: Function) {
    const timer = setTimeout(() => {
        clearTimeout(timer);
        callback();
    }, seconds);
}