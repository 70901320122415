import { CSSProperties, useContext, useEffect, useState } from "react";
import { toDatePT, toInputDate } from "../../../utils/dates";
import Student from "../../../models/Student.model";
import Card from "../../shared/Card";
import styles from "../../shared/Card.module.css";
import { FieldsEdited } from "../../../types/FieldsEdited";
import { StudentIdenticationFields } from "../forms/StudentForm";
import { MessagingContext } from "../../App";
import ConfirmCancelIcons from "../../shared/ConfirmCancelIcons";
import Loader from "../../shared/Loader";
import { api } from "../../../services/api";
import IdentificationType from "../../../interfaces/IdentificationType.interface";
import GenericCombo from "../../shared/GenericCombo";
import Nationality from "../../../interfaces/Nationality.interface";

type StudentIdentificationCardProps = {
    value: Student;
    style?: CSSProperties;
    fieldsEditedCallback: (params: FieldsEdited<StudentIdenticationFields>) => void;
    setStudent: (newStudent: Student) => void;
}

export default function StudentIdentificationCard(props: StudentIdentificationCardProps) {
    const student = props.value;

    const messaging = useContext(MessagingContext);
    const [editMode, setEditMode] = useState(false);
    const [editing, setEditing] = useState(false);
    const [nationalities, setNationalities] = useState<Nationality[] | undefined>();
    const [identificationTypes, setIdentificationTypes] = useState<IdentificationType[] | undefined>();
    const [fieldsValues, setFieldsValues] = useState<StudentIdenticationFields>({
        fiscal_number: student?.fiscalNumber?.toString() || "",
        identification_number: student?.identificationNumber || "",
        identification_date: student?.identificationDate ? toInputDate(student?.identificationDate) : "",
        identification_entity: student?.identificationEntity || "",
        identificationTypeId: student?.identificationTypeId,
        naturality: student?.naturality || "",
        nationalityId: student?.nationalityId
    });
    const [selectedIdentificationTypeName, setSelectedIdentificationTypeName] = useState(student.identificationType);
    const [selectedNationalityName, setSelectedNationalityName] = useState(student.nationality);

    useEffect(() => {
        if (editMode && !nationalities) {
            api.getCountries(nationalities => setNationalities(nationalities), err => setNationalities([]));
        }

        if (editMode && !identificationTypes) {
            api.getIdentificationTypes(identificationTypes =>
                setIdentificationTypes(identificationTypes), err => setIdentificationTypes([]));
        }
    }, [editMode, nationalities, identificationTypes]);

    function handleConfirm() {
        setEditing(true);
        props.fieldsEditedCallback({
            newValues: fieldsValues,
            success: () => {
                const newStudent = student.clone();
                newStudent.nationality = selectedNationalityName;
                newStudent.identificationType = selectedIdentificationTypeName;
                props.setStudent(newStudent);
                setEditing(false);
                setEditMode(false);
            },
            fail: err => {
                setEditing(false);
                messaging.modal.showMessage("Erro", "Erro a editar os dados do aluno: " + err.message);
            }
        });
    }

    function handleIdentificationTypeChange(identificationType: number | string | IdentificationType) {
        const newIdentificationTypeId = identificationType as number;
        setFieldsValues({ ...fieldsValues, identificationTypeId: newIdentificationTypeId });
        const newIdentificationType = identificationTypes?.find(identificationType =>
            identificationType.id === newIdentificationTypeId);
        if (newIdentificationType) {
            setSelectedIdentificationTypeName(newIdentificationType.title);
        }
    }

    function handleNationalityChange(nationality: number | string | Nationality) {
        const newNationalityId = nationality as number;
        setFieldsValues({ ...fieldsValues, nationalityId: newNationalityId });
        const newNationality = nationalities?.find(nationality => nationality.id === newNationalityId);
        if (newNationality) {
            setSelectedNationalityName(newNationality.title);
        }
    }

    return (
        <Card
            keyId={student.id.toString()}
            style={props.style || {}}
            title={
                <div>Identificação
                    {editMode ?
                        (editing ?
                            <Loader
                                outerClassName=""
                                outterStyle={{ display: "inline" }}
                                style={{ marginLeft: "10px" }}
                                size="small" /> :
                            <ConfirmCancelIcons
                                confirmHandler={handleConfirm}
                                cancelHandler={() => setEditMode(false)} />
                        ) :
                        <i
                            className="fas fa-pen action-icon"
                            style={{ margin: "5px 0 0 10px" }}
                            title="Editar"
                            onClick={() => setEditMode(true)}></i>
                    }
                </div>
            }
            includeHeader={true}
            headerStyle={{ fontSize: "1.1rem" }}
            body={
                <table className="table table-striped" >
                    <tbody>
                        <tr>
                            <td className={styles.titleField}>Tipo</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <GenericCombo
                                        value={fieldsValues.identificationTypeId}
                                        values={identificationTypes}
                                        getId={identificationType => identificationType.id}
                                        getOptionContent={identificationType => identificationType.title}
                                        selectionChanged={handleIdentificationTypeChange} /> :
                                    student.identificationType}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Número</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="identification_number"
                                        value={fieldsValues.identification_number || ""}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, identification_number: event.target.value }
                                        )} /> :
                                    student.identificationNumber}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Validade</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="fiscal_number"
                                        value={fieldsValues.identification_date || ""}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, identification_date: event.target.value }
                                        )} /> :
                                    (student.identificationDate && toDatePT(student?.identificationDate))}</td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Entidade</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="identification_entity"
                                        value={fieldsValues.identification_entity || ""}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, identification_entity: event.target.value }
                                        )} /> :
                                    student.identificationEntity}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>NIF</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="fiscal_number"
                                        value={fieldsValues.fiscal_number || ""}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, fiscal_number: event.target.value }
                                        )} /> :
                                    student.fiscalNumber}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Nacionalidade</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <GenericCombo
                                        value={fieldsValues.nationalityId}
                                        values={nationalities}
                                        getId={nationality => nationality.id}
                                        getOptionContent={nationality => nationality.title}
                                        selectionChanged={handleNationalityChange} /> :
                                    student.nationality}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Naturalidade</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="naturality"
                                        value={fieldsValues.naturality || ""}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, naturality: event.target.value }
                                        )} /> :
                                    student.naturality}
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
            width={350} />
    );
}