import { useContext } from "react";
import { MessagingContext } from "../App";
import styles from "./Education.styles.module.css";
import { api } from "../../services/api";
import { EducationArea } from "../../interfaces/EducationArea.interface";
import { InsertType } from "./EducationsList";


type EducationAreaTableProp = {
    values: EducationArea[];
    EducationAreaRemoved: (id: number, type: InsertType) => void;
    EducationAreaEdited: (EducationArea: EducationArea, type: InsertType) => void;
}

export default function EducationAreaTable(props: EducationAreaTableProp) {
    const { values, EducationAreaRemoved, EducationAreaEdited } = props;
    const messaging = useContext(MessagingContext);

    function handleRemoveEducationArea(EducationArea: EducationArea) {
        messaging.modal.showFull({
            title: "Remover ocupação",
            body: `Tem a certeza que pretende remover a Área de Educação ${EducationArea.title} ?`,
            closeName: "Cancelar",
            closeHandler: messaging.modal.close,
            actionName: "Eliminar",
            actionHandler: () => {
                messaging.modal.close();
                deleteEducationArea(EducationArea.id);
            },
            actionClassName: "btn-danger",
            showAction: true
        });
    }

    function deleteEducationArea(id: number) {
        api.deleteEducationArea(id, (e: any) => {

            if (e.status !== 204) {
                throw new Error(e);
            }

            if (EducationAreaRemoved) {
                EducationAreaRemoved(id, InsertType.educationArea);
            }
            messaging.toast.show("Área de Educação", "Área de Educação removida");
        }, (err) => {
            console.log(err);
            messaging.modal.showMessage("Erro", "Erro a remover Área de Educação");
        });
    }

    return (
        <div className={styles.splitContainer}>
            <h2>Área de educação</h2>
            <div className={styles.container}>
                {
                    values.length > 0 ?
                        values.map((row: EducationArea) =>
                            <span key={row.id} className={`${styles.tag} badge badge-secondary`}>{row.title}
                                <span className={`${styles.icon} action-icon`}
                                    onClick={() => EducationAreaEdited(row, InsertType.educationArea)}>
                                    <i className="fas fa-pen" title="Editar"></i>
                                </span>
                                <span className={`${styles.icon} action-icon`}
                                    onClick={() => handleRemoveEducationArea(row)}>
                                    <i className="fas fa-trash" title="Eliminar"></i>
                                </span>
                            </span>
                        )
                        :
                        "Não existem Áreas de Educação para a pesquisa efetuada"
                }
            </div>
        </div >
    );
}