import { CSSProperties, useContext, useEffect, useState } from "react";
import Occupation from "../../../interfaces/Occupation";
import Student from "../../../models/Student.model";
import { api } from "../../../services/api";
import { FieldsEdited } from "../../../types/FieldsEdited";
import { MessagingContext } from "../../App";
import Card from "../../shared/Card";
import styles from "../../shared/Card.module.css";
import ConfirmCancelIcons from "../../shared/ConfirmCancelIcons";
import GenericCombo from "../../shared/GenericCombo";
import Loader from "../../shared/Loader";
import { StudentOccupationFields } from "../forms/StudentForm";

type StudentOccupationCardProps = {
    value: Student;
    style?: CSSProperties;
    fieldsEditedCallback: (params: FieldsEdited<StudentOccupationFields>) => void;
    setStudent: (newStudent: Student) => void;
}

export default function StudentOccupationCard(props: StudentOccupationCardProps) {
    const student = props.value;

    const messaging = useContext(MessagingContext);
    const [editMode, setEditMode] = useState(false);
    const [occupations, setOccupations] = useState<Occupation[] | undefined>();
    const [editing, setEditing] = useState(false);
    const [fieldsValues, setFieldsValues] = useState<StudentOccupationFields>({
        occupationId: student?.occupationId
    });
    const [selectedOccupationName, setSelectedOccupationName] = useState(student?.occupation);

    useEffect(() => {
        if (editMode && !occupations) {
            api.getOccupations(occupations => setOccupations(occupations), err => setOccupations([]));
        }
    }, [editMode, occupations]);

    function handleConfirm() {
        setEditing(true);
        props.fieldsEditedCallback({
            newValues: fieldsValues,
            success: () => {
                const newStudent = student.clone();
                newStudent.occupation = selectedOccupationName;
                props.setStudent(newStudent);
                setEditing(false);
                setEditMode(false);
            },
            fail: err => {
                setEditing(false);
                messaging.modal.showMessage("Erro", "Erro a editar os dados do aluno: " + err.message);
            }
        });
    }

    function handleOccupationChange(occupation: number | string | Occupation) {
        const newOccupationId = occupation as number;
        setFieldsValues({ ...fieldsValues, occupationId: newOccupationId });
        const newOccupation = occupations?.find(occupation => occupation.id === newOccupationId);
        if (newOccupation) {
            setSelectedOccupationName(newOccupation.title);
        }
    }

    return (
        <Card
            keyId={student.id.toString()}
            style={props.style || {}}
            title={
                <div>Situação Profissional
                    {editMode ?
                        (editing ?
                            <Loader
                                outerClassName=""
                                outterStyle={{ display: "inline" }}
                                style={{ marginLeft: "10px" }}
                                size="small" /> :
                            <ConfirmCancelIcons
                                confirmHandler={handleConfirm}
                                cancelHandler={() => setEditMode(false)} />
                        ) :
                        <i
                            className="fas fa-pen action-icon"
                            style={{ margin: "5px 0 0 10px" }}
                            title="Editar"
                            onClick={() => setEditMode(true)}></i>
                    }
                </div>
            }
            includeHeader={true}
            headerStyle={{ fontSize: "1.1rem" }}
            body={
                <table className="table table-striped">
                    <tbody>
                        <tr>
                            <td className={styles.titleField}>Ocupação</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <GenericCombo
                                        value={fieldsValues.occupationId}
                                        values={occupations}
                                        getId={occupation => occupation.id}
                                        getOptionContent={occupation => occupation.title}
                                        selectionChanged={handleOccupationChange} /> :
                                    student.occupation}</td>
                        </tr>
                    </tbody>
                </table>
            }
            width={350} />
    );
}