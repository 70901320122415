import styles from '../../styles/ExtensionChanger.module.css';
import moment, { Moment } from "moment";
import { useContext, useEffect, useState } from "react";
import { TRAINING_STATUS_NEW_EXTENSION_ID } from "../../constants";
import Training from "../../interfaces/Training.interface";
import { api, getStorageInfo } from "../../services/api";
import { MessagingContext } from "../App";
import CaretToggle from "../shared/CaretToggle";


type ExtensionChangerProps = {
    training: Training;
    editing: boolean;
    setEditing: (value: boolean) => void;
    editTraining: (newTraining: Training) => void;
};

const EXTENSION_METHOD = {
    MONTH: 0,
    DATE: 1
}

export default function ExtensionChanger(props: ExtensionChangerProps) {
    const { training, editing, setEditing } = props;

    const messaging = useContext(MessagingContext);
    const [extension, setExtension] = useState(0);
    const [extensionDate, setExtensionDate] = useState("");
    const [newExpirationDate, setNewExpirationDate] = useState<Moment>(moment());
    const [newObservations, setNewObservations] = useState("");
    const [visible, setVisible] = useState(false);

    const [visibleSection, setVisibleSection] = useState(EXTENSION_METHOD.MONTH);


    function createExtensionObjs() {
        let newExtension = extension;
        let newExtensionDate: Moment = moment(training.expirationDate);

        if (extension > 0) {
            newExtensionDate = moment().add(newExtension, 'months');
        }
        if (extensionDate) {
            newExtension = 0;
            newExtensionDate = moment(extensionDate);
        }

        let finalObservations = "";
        if (newExtension > 0) {
            finalObservations = `Nova extensão: ${newExtension} meses.`;
        }
        finalObservations += `Data final: ${newExpirationDate.format("DD/MM/YYYY")} `;
        finalObservations += `Nota: ${newObservations}`;

        const editedApiInfo = {
            id: training.id,
            month_extension: newExtension,
            observations_extension: finalObservations,
            user_id: getStorageInfo().userId,
            expiration_date: newExtensionDate.format('YYYY/MM/DD')
        };
        const editedUIInfo = {
            monthExtension: newExtension,
            trainingStatusTypeId: TRAINING_STATUS_NEW_EXTENSION_ID,
            observations_extension: finalObservations,
            expiration_date: newExtensionDate.format('YYYY/MM/DD'),
            expirationDate: newExtensionDate.toDate()
        };

        return { editedApiInfo, editedUIInfo }
    }

    function applyExtension() {
        if (extension !== 0 || extensionDate !== "") {

            const { editedApiInfo, editedUIInfo } = createExtensionObjs();

            setEditing(true);
            api.editTraining(editedApiInfo, () => {
                props.editTraining({ ...training, ...editedUIInfo });
                setVisible(false);
                setEditing(false);
            }, err => {
                messaging.modal.showMessage("Erro", "Erro a aplicar extensão: " + err.message);
                setEditing(false);
            });
        }
        else {
            messaging.modal.showMessage("Erro", "Erro a aplicar extensão: ");
        }
    }

    useEffect(() => {
        let newExpirationDate: Moment = moment();

        if (visibleSection === EXTENSION_METHOD.DATE) {
            newExpirationDate = moment(extensionDate);
        } else {
            newExpirationDate = moment().add(extension, "months");
        }

        setNewExpirationDate(newExpirationDate);

    }, [extension, training.expirationDate, training.monthExtension, extensionDate, visibleSection]);


    const extensionJSX: JSX.Element = visibleSection === EXTENSION_METHOD.MONTH ?
        <>
            <input
                type="number"
                id="extensionNumber"
                className="form-control"
                onChange={event => setExtension(parseInt(event.target.value))}
            />
            <label className="form-label" htmlFor="extensionNumber">Numero de Meses</label>
        </>
        :
        <>
            <input
                type="date"
                id="extensionDate"
                className="form-control"
                onChange={event => setExtensionDate(event.target.value)}
            />
            <label className="form-label" htmlFor="extensionNumber">Data de Validade</label>
        </>;


    return (
        <div className={styles.extensionContainer}>
            <span onClick={() => setVisible(val => !val)} className="cursor-pointer">Nova extensão</span>
            <CaretToggle
                visible={visible}
                onClick={() => setVisible(val => !val)} />
            {visible && <>

                <div className={`btn - group ${styles.extensionGroup} `} role="group" aria-label="Basic radio toggle button group">
                    <input
                        onClick={() => setVisibleSection(EXTENSION_METHOD.MONTH)}
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        checked={visibleSection === EXTENSION_METHOD.MONTH}
                        id="training_extension_month" />
                    <label className="btn btn-outline-primary"
                        htmlFor="training_extension_month">Por Mês</label>

                    <input
                        onClick={() => setVisibleSection(EXTENSION_METHOD.DATE)}
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        checked={visibleSection === EXTENSION_METHOD.DATE}
                        id="training_extension_date" />
                    <label className="btn btn-outline-warning"
                        htmlFor="training_extension_date">Por Data</label>
                </div>

                <div className="row">
                    <div className="col m-2">
                        {extensionJSX}
                    </div>
                </div>
                <div className="row">
                    <div className="col m-2">
                        Termina em {newExpirationDate.format("DD/MM/YYYY")}
                    </div>
                </div>
                <div className="row">
                    <div className="col m-2">
                        <label htmlFor="obs">Observações</label>
                        <textarea
                            className="form-control"
                            id="obs"
                            rows={3}
                            value={newObservations || ""}
                            onChange={event => setNewObservations(event.target.value)}></textarea>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <button className={`btn btn - warning ${styles.extensionButton} ${editing ? "disabled" : ""} `}
                            onClick={applyExtension}>Aplicar Extensão
                            {editing && <span
                                className="spinner-border spinner-border-sm ml-10"
                                role="status"
                                aria-hidden="true"></span>}
                        </button>
                    </div>
                </div>
            </>}
        </div>
    );
}