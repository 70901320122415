import React, { CSSProperties } from "react";
import City from "../../interfaces/City.interface";
import GenericCombo from "../shared/GenericCombo";
import InvalidFeedback from "../shared/InvalidFeedback";
import { CustomerFields } from "./CustomerForm";

type CustomerBasicFieldsFormErrors = {
    name?: boolean;
    address?: boolean;
};

type CustomerBasicFieldsFormProps = {
    fieldsValues: CustomerFields;
    setFieldsValues: (val: CustomerFields) => void;
    cities: City[] | undefined;
    style?: CSSProperties;
    showErrors?: CustomerBasicFieldsFormErrors
};

export default function CustomerBasicFieldsForm(props: CustomerBasicFieldsFormProps) {
    const { fieldsValues, setFieldsValues, cities } = props;

    return (
        <div>
            <div className="row">
                <div className="col m-2">
                    <div className="form-group">
                        <label htmlFor="name">Nome</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            value={fieldsValues.name || ""}
                            onChange={event => setFieldsValues({ ...fieldsValues, name: event.target.value })}
                            required />
                        {props.showErrors && props.showErrors.name &&
                            <InvalidFeedback message="Insira um nome" />}
                    </div>
                </div>
                <div className="col m-2">
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            value={fieldsValues.email || ""}
                            onChange={event => setFieldsValues({ ...fieldsValues, email: event.target.value })} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col m-2">
                    <div className="form-group">
                        <label htmlFor="phone">Telefone</label>
                        <input
                            type="number"
                            className="form-control"
                            id="phone"
                            value={fieldsValues.phone || ""}
                            onChange={event => setFieldsValues({ ...fieldsValues, phone: event.target.value })} />
                    </div>
                </div>
                <div className="col m-2">
                    <div className="form-group">
                        <label htmlFor="phone">Contribuinte</label>
                        <input
                            type="number"
                            className="form-control"
                            id="phone"
                            value={fieldsValues.fiscal_number || ""}
                            onChange={event => setFieldsValues({ ...fieldsValues, fiscal_number: event.target.value })} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col col-md-5 m-2">
                    <div className="form-group">
                        <label htmlFor="address">Morada</label>
                        <input
                            type="text"
                            className="form-control"
                            id="address"
                            value={fieldsValues.address || ""}
                            onChange={event => setFieldsValues({ ...fieldsValues, address: event.target.value })} />
                        {props.showErrors && props.showErrors.address &&
                            <InvalidFeedback message="Insira uma morada" />}
                    </div>
                </div>
                <div className="col col-md-4 m-2">
                    <div className="form-group">
                        <label htmlFor="postal_code">Código Postal</label>
                        <input
                            type="text"
                            className="form-control"
                            id="postal_code"
                            value={fieldsValues.postal_code || ""}
                            onChange={event => setFieldsValues({ ...fieldsValues, postal_code: event.target.value })} />
                    </div>
                </div>
                <div className="col col-md m-2">
                    <div className="form-group">
                        <label>Localidade</label>
                        <GenericCombo
                            value={fieldsValues.city}
                            values={cities}
                            getId={city => city.id}
                            getOptionContent={city => city.title}
                            selectionChanged={city =>
                                setFieldsValues({ ...fieldsValues, city: city as number })
                            } />
                    </div>
                </div>
            </div>
        </div>
    );
}