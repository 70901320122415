import { Link } from "react-router-dom";
import { toDatePT } from "../../utils/dates";
import Student from "../../models/Student.model";
import CourseTag from "../tags/CourseTag";
import OfficeTag from "../tags/OfficeTag";
import ListCard from '../shared/ListCard';

type StudentProps = {
    values: Student[];
    selectHandler?: (student: Student) => void;
    notFoundText?: string;
}

export default function StudentsTable(props: StudentProps) {
    const { values, selectHandler } = props;
    return (
        <div className="container">
            {
                values ?
                    values.map((student: Student) =>
                        <ListCard
                            key={`${student.id}`}
                            body={
                                <>
                                    <div className="col">
                                        <div className="item">
                                            <Link className="link-secondary" to={"/alunos/" + student.id}>{student.name}</Link>
                                        </div>
                                        <div className="contact-email item ">
                                            <a
                                                href={`mailto:${student.email}`}
                                                target="_blank"
                                                rel="noreferrer">{student.email}</a>
                                        </div>
                                        <div className="item" >
                                            <OfficeTag office={student.officeId} />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <span>{student.phone}</span>
                                    </div>
                                    <div className="col">
                                        <span>{student.username}</span>
                                    </div>
                                    <div className="col">
                                        <span>{toDatePT(student.createdDate)}</span>
                                    </div>
                                    <div className="col">
                                        <div className="coursesContainer">
                                            {(student.courses || []).map((course, pos) =>
                                                <CourseTag name={course.title} key={pos} />)}
                                        </div>
                                    </div>
                                    {selectHandler &&
                                        <div className="col">

                                            <button
                                                className="btn btn-primary"
                                                onClick={(e: any) => {
                                                    e.preventDefault();
                                                    selectHandler(student);
                                                }}>Escolher</button>

                                        </div>
                                    }
                                </>
                            } />)
                    :
                    "Não existem alunos para a pesquisa efetuada"
            }
        </div>
    );
    // return (
    //     <table className="table table-striped">
    //         <thead>
    //             <tr>
    //                 <th scope="col">Nome</th>
    //                 <th scope="col">Telefone</th>
    //                 <th scope="col">User</th>
    //                 <th scope="col">Criado em</th>
    //                 <th scope="col">Cursos</th>
    //                 {selectHandler && <th scope="col">Ações</th>}
    //             </tr>
    //         </thead>
    //         <tbody>
    //             {values.length > 0 ?
    //                 values.map(row =>
    //                     <tr key={row.id}>
    //                         <th scope="row">
    //                             <Link to={"/alunos/" + row.id}>{row.name}</Link>
    //                             <div className="contact-email">
    //                                 <a
    //                                     href={`mailto:${row.email}`}
    //                                     target="_blank"
    //                                     rel="noreferrer">{row.email}</a>
    //                             </div>
    //                             <div>
    //                                 <OfficeTag office={row.officeId} />
    //                             </div>
    //                         </th>
    //                         <td style={{ minWidth: "115px" }}>
    //                             {row.phone ?
    //                                 <>
    //                                     <i
    //                                         className="fas fa-phone mr-5"
    //                                         title="Telefone"
    //                                         style={{ color: "#5d5d5d" }}></i>
    //                                     <span style={{ fontSize: "0.95rem" }}>{row.phone}</span>
    //                                 </>
    //                                 : ""
    //                             }
    //                         </td>
    //                         <td>{row.username}</td>
    //                         <td style={{ fontSize: "0.95rem" }}>{toDatePT(row.createdDate)}</td>
    //                         <td>{(row.courses || []).map((course, pos) =>
    //                             <CourseTag name={course.title} key={pos} />)}</td>
    //                         {selectHandler &&
    //                             <td>
    //                                 <button
    //                                     className="btn btn-primary"
    //                                     onClick={(e: any) => {
    //                                         e.preventDefault();
    //                                         selectHandler(row);
    //                                     }}>Escolher</button>
    //                             </td>
    //                         }
    //                     </tr>
    //                 ) :
    //                 <tr>
    //                     <td colSpan={5} style={{ textAlign: "center" }}>
    //                         Não existem alunos para a pesquisa efetuada</td>
    //                 </tr>
    //             }
    //         </tbody>
    //     </table>
    // );
}