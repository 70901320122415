import { courseStatusTypesList } from "../../constants";

interface CourseStatesComboProps {
    value: number | undefined;
    selectionChanged: (newVal: number) => void;
    showError?: boolean;
    includeOverall: boolean;
}
type ComboOption = {
    id: number;
    name: string;
}

export default function CourseStatesCombo(props: CourseStatesComboProps) {

    const options = [...courseStatusTypesList];
    if (props.includeOverall) {
        options.unshift({ id: 0, name: "Tudo" });
    }

    const keyPrefix = `${props.includeOverall ? 'overall' : 'new'}`;

    return (
        <>
            <select
                value={props.value}
                className="form-select"
                onChange={event => props.selectionChanged(parseInt(event.target.value))}>
                {
                    options.map((option: ComboOption) =>
                        <option key={`${keyPrefix}_state_${option.id}`} value={option.id}>{option.name}</option>
                    )
                }
            </select>
        </>
    );
}