import Product from "../interfaces/Product.interface";
import Discount from "./Discount.model";
import Student from "./Student.model";

export default class Course implements Product {
    public id: number;
    public title: string;
    public visible: boolean;
    public price: number;
    public time: number;
    public code: string;
    public info: string;
    public createdDate: Date | undefined;
    public category: string;
    public status: string;
    public expirationPeriod: number;

    public courseSubcategoryId: number | undefined;
    public courseStateId: number | undefined;

    public student: Student;
    public productNumber: number;

    constructor(data: any) {
        this.id = data.id;
        this.title = data.title;
        this.visible = data.visible;
        this.price = data.price;
        this.time = data.time
        this.code = data.code;
        this.info = data.info;
        this.expirationPeriod = data.expiration_period ?? 6;

        this.category = data.course_category ?? "";
        this.status = data.course_status_type ?? "";
        this.createdDate = data.created_date ? new Date(data.created_date) : undefined;

        this.courseSubcategoryId = data.course_category_id ?? 0;
        this.courseStateId = data.course_status_id ?? 0;

        this.student = data.student ?? undefined;
        this.productNumber = 0;
    }

    clone(): Course {
        let newCourse = new Course({});

        newCourse.id = this.id;
        newCourse.title = this.title;
        newCourse.visible = this.visible;
        newCourse.price = this.price;
        newCourse.time = this.time
        newCourse.info = this.info
        newCourse.code = this.code;
        newCourse.category = this.category;
        newCourse.status = this.status;
        newCourse.createdDate = this.createdDate;
        newCourse.courseSubcategoryId = this.courseSubcategoryId;
        newCourse.courseStateId = this.courseStateId;
        newCourse.student = this.student;
        newCourse.productNumber = this.productNumber;

        return newCourse;
    }

    getProductPosition(): number {
        return this.productNumber;
    }

    getStudentName(): string {
        return this.student.name ?? undefined;
    }

    hasStudent(): boolean {
        return this.student !== undefined;
    }

    getDiscountValue(discount: Discount | undefined): number {
        if (!discount) {
            return 0;
        }

        return discount.percentage ?
            this.getPrice() * discount.percentage :
            this.getPrice() - discount.value;
    }

    getPriceAfterDiscount(discount: Discount | undefined): number {
        if (!discount) {
            return this.getPrice();
        }
        else {
            return discount.percentage ?
                this.getPrice() - this.getPrice() * discount.percentage :
                this.getPrice() - discount.value;
        }
    }

    getUniqueId(): string {
        return `course${this.id}`;
    }

    getName(): string {
        return this.title;
    }

    getTime(): number {
        return this.time;
    }

    getPrice(): number {
        return this.price;
    }
}