type ContactForTrainingTypeComboProps = {
    value: number | undefined;
    selectionChanged: (newVal: number) => void;
    showError?: boolean;
}

export default function ContactForTrainingTypeCombo(props: ContactForTrainingTypeComboProps) {
    return (
        <>
            <select
                value={props.value}
                className="form-select"
                onChange={event => props.selectionChanged(parseInt(event.target.value))}>

                <option value={0}>Escolha o tipo formação</option>
                <option value={1}>Formação Presencial</option>
                <option value={2}>Formação Online</option>
                <option value={3}>Formação B-Learning</option>
            </select>
            {props.showError &&
                <div className="invalid-feedback" style={{ display: "block" }}>
                    Precisa de escolher o tipo de formação
                </div>
            }
        </>
    );
}