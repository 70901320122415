import moment from "moment";
import { Link } from "react-router-dom";
import Training from "../../interfaces/Training.interface";
import { daysToHumanTime, diffInDays, toDatePT } from "../../utils/dates";
import { quantifier } from "../../utils/generic";
import CourseTag from "../tags/CourseTag";
import OfficeTag from "../tags/OfficeTag";

export default function DatePassedTrainingsTable(props: { values: Training[] }) {
    const now = new Date();

    function getRealExpirationDate(training: Training): Date {
        if (training.expirationDate){
            return training.monthExtension > 0 ?
                moment(training.expirationDate).add(training.monthExtension, "months").toDate() : 
                training.expirationDate;
        }
        return now;
    }
    
    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Aluno</th>
                    <th scope="col">Curso</th>
                    <th scope="col">Terminou em</th>
                    <th scope="col">Extensão</th>
                    <th scope="col">Observações</th>
                </tr>
            </thead>
            <tbody>
                {props.values.length > 0 ?
                    props.values.map(row =>
                        <tr key={row.id}>
                            <th scope="row">
                                <Link to={`/alunos/${row.student_id}/formacoes`}>{row.student_name}</Link>
                                <div>
                                    {row.office_id && <OfficeTag office={row.office_id} />}
                                </div>
                            </th>
                            <td><CourseTag name={row.course} /></td>
                            <td style={{ fontSize: "0.95rem" }}>
                                <div>{row.expirationDate ? 
                                    toDatePT(row.expirationDate) : 
                                    "Não disponível"}
                                </div>
                                <div>{daysToHumanTime(Math.ceil(diffInDays(row.expirationDate || row.createdDate, now)))}</div>
                            </td>
                            <td>
                                {row.monthExtension > 0 && quantifier(row.monthExtension, "mês", "meses")}
                            </td>
                            <td>{row.observations_extension}</td>
                        </tr>
                    ) :
                    <tr>
                        <td colSpan={5} style={{ textAlign: "center" }}>
                            Não existem cursos com valida terminada
                        </td>
                    </tr>
                }
            </tbody>
        </table>
    );
}