import { Link } from "react-router-dom";
import Payment from "../../models/Payment.model";
import { toDatePT } from "../../utils/dates";
import { paymentMethodTypesHash } from "../../constants";
import { formatCurrency } from "../../utils/generic";
import OfficeTag from "../tags/OfficeTag";


export default function ExpiringPaymentsTable(props: { values: Payment[] }) {
    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Aluno</th>
                    <th scope="col">Método</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Descrição</th>
                    <th scope="col">Valor</th>
                    <th scope="col">Expira em</th>
                </tr>
            </thead>
            <tbody>
                {props.values.length > 0 ?
                    props.values.map((payment: Payment) =>
                        <tr key={payment.id}>
                            <th scope="row">
                                {
                                    payment.carerId ?
                                        <Link to={`/encarregado-educacao/${payment.carerId}`}>{payment.carer}</Link>
                                        :
                                        payment.studentId ?
                                            <Link to={`/alunos/${payment.studentId}/inscricoes`}>{payment.student}</Link>
                                            :
                                            payment.customerId ?
                                                <Link to={`/cliente/${payment.customerId}/inscricoes`}>{payment.customer}</Link>
                                                : "asdas"
                                }
                                <div style={{ marginTop: "4px" }}>
                                    {payment.officeId && <OfficeTag office={payment.officeId} />}
                                </div>

                            </th>
                            <td>
                                <i
                                    style={{ color: "#4c4c4c" }}
                                    className={`fa ${paymentMethodTypesHash[payment.methodId]?.icon}`}
                                    title={paymentMethodTypesHash[payment.methodId]?.name}></i>
                            </td>
                            <td>
                                {payment.status}
                            </td>
                            <td>
                                {payment.description}
                            </td>
                            <td>
                                {formatCurrency(payment.value)}
                            </td>
                            <td>
                                {
                                    payment.expirationDate < new Date() ?
                                        <span style={{
                                            backgroundColor: "#e53935",
                                            padding: "4px",
                                            borderRadius: "4px",
                                            color: "white",
                                            fontSize: "12px",
                                            fontWeight: "bold"
                                        }}>{toDatePT(payment.expirationDate)}</span>

                                        :
                                        toDatePT(payment.expirationDate)
                                }
                            </td>
                        </tr>
                    ) :
                    <tr>
                        <td colSpan={6} style={{ textAlign: "center" }}>
                            Não existem pagamentos a expirar para os filtros indicados
                        </td>
                    </tr>
                }
            </tbody>
        </table>
    );
}