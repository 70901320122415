import React, { useEffect, useState } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import ContactsList from "./contact/ContactsList";
import ContactForm from "./contact/forms/ContactForm";
import CustomerForm from "./customer/CustomerForm";
import CustomersList from "./customer/CustomersList";
import CustomerPage from "./customer/CustomerPage";
import CarerPage from "./carers/CarerPage";
import CarerList from "./carers/CarerList";
import CarerForm from "./carers/forms/CarerForm";
import Home from "./Home";
import Login from "./Login";
import NavigationBar from "./shared/NavigationBar";
import StudentForm from "./student/forms/StudentForm";
import StudentsExpiringCourses from "./student/StudentsExpiringCourses";
import StudentPage from "./student/StudentPage";
import StudentsList from "./student/StudentsList";
import { Redirect } from "react-router-dom";
import { LOCAL_STORAGE_KEY } from "../constants";
import FreeSessionsList from "./free-session/FreeSessionsList";
import FreeSessionForm from "./free-session/forms/FreeSessionForm";
import StudentDatePassedCourses from "./student/StudentDatePassedCourses";
import StudentsExpiringPayments from "./student/StudentsExpiringPayments";
import CitiesList from "./cities/CitiesList";
import TrainingList from "./training/TrainingList";
import UserList from "./user/UserList";
import CoursesList from "./courses/CoursesList";
import DiscountsList from "./discounts/DiscountsList";
import PurchasesList from "./purchase/PurchasesList";
import ChartComponent from "./charts/ChartComponent";
import PacksList from "./packs/PacksList";
import OccupationsList from "./occupations/OccupationsList";
import EducationsList from "./educations/EducationsList";

export const RedirectionContext = React.createContext({ redirectTo: (path: string) => { } });

export const Routes = {
    login: "/login",
    contacts: "/contactos",
    newContact: "/contactos/novo",
    editContact: "/contactos/:id/editar",
    students: "/alunos",
    student: "/alunos/:id",
    newStudent: "/alunos/novo",
    customers: "/clientes",
    customer: "/cliente/:id",
    newCustomer: "/clientes/novo",
    expiringCourses: "/alunos/cursos-a-expirar",
    activeDatePassedCourses: "/alunos/cursos-com-validade-terminada",
    missingPayments: "/alunos/pagamentos-em-falta",
    freeSessions: "/sessoes-gratuitas",
    newFreeSession: "/sessoes-gratuitas/nova",
    editFreeSession: "/sessoes-gratuitas/:id/editar",
    carers: "/encarregados-educacao/",
    carer: "/encarregado-educacao/:id",
    trainings: "/formacoes/",
    newCarer: "/encarregados-educacao/novo",
    editCarer: "/encarregados-educacao/:id/editar",
    cities: "/localidades/",
    occupations: "/ocupacoes/",
    educations: "/educacao/",
    users: "/utilizadores",
    courses: "/cursos",
    packs: "/pack",
    newCourses: "/cursos/novo",
    discounts: "/descontos",
    accounting: "/contabilidade",
    stats: "/estatisticas",
};

export default function MainRouter() {
    const [redirect, setRedirect] = useState<React.ReactElement | undefined>(undefined);

    const redirection = {
        redirectTo: (path: string) => {
            setRedirect(<Redirect to={path} />);
        }
    };

    useEffect(() => {
        if (redirect) {
            setRedirect(undefined);
        }
    }, [redirect]);

    const tokenString = localStorage.getItem(LOCAL_STORAGE_KEY);

    return (
        <HashRouter basename={`/`}>
            {redirect}
            {tokenString && JSON.parse(tokenString).token ?
                null :
                <Redirect to={Routes.login} />}
            <RedirectionContext.Provider value={redirection}>
                <Switch>
                    <Route exact path="/">
                        <NavigationBar />
                        <Home />
                    </Route>
                    <Route path={Routes.login}>
                        <Login />
                    </Route>
                    <Route exact path={Routes.contacts}>
                        <NavigationBar />
                        <ContactsList />
                    </Route>
                    <Route path={Routes.newContact}>
                        <NavigationBar />
                        <ContactForm />
                    </Route>
                    <Route path={Routes.editContact}>
                        <NavigationBar />
                        <ContactForm />
                    </Route>
                    <Route exact path={Routes.students}>
                        <NavigationBar />
                        <StudentsList />
                    </Route>
                    <Route exact path={Routes.newStudent}>
                        <NavigationBar />
                        <StudentForm />
                    </Route>
                    <Route exact path={Routes.expiringCourses}>
                        <NavigationBar />
                        <StudentsExpiringCourses />
                    </Route>
                    <Route exact path={Routes.activeDatePassedCourses}>
                        <NavigationBar />
                        <StudentDatePassedCourses />
                    </Route>
                    <Route exact path={Routes.missingPayments}>
                        <NavigationBar />
                        <StudentsExpiringPayments />
                    </Route>
                    <Route path={Routes.student}>
                        <NavigationBar />
                        <StudentPage />
                    </Route>
                    <Route exact path={Routes.customers}>
                        <NavigationBar />
                        <CustomersList />
                    </Route>
                    <Route exact path={Routes.newCustomer}>
                        <NavigationBar />
                        <CustomerForm />
                    </Route>
                    <Route exact path={Routes.freeSessions}>
                        <NavigationBar />
                        <FreeSessionsList />
                    </Route>
                    <Route exact path={Routes.newFreeSession}>
                        <NavigationBar />
                        <FreeSessionForm />
                    </Route>
                    <Route path={Routes.editFreeSession}>
                        <NavigationBar />
                        <FreeSessionForm />
                    </Route>
                    <Route exact path={Routes.carers}>
                        <NavigationBar />
                        <CarerList />
                    </Route>
                    <Route exact path={Routes.newCarer}>
                        <NavigationBar />
                        <CarerForm />
                    </Route>
                    <Route path={Routes.editCarer}>
                        <NavigationBar />
                        <CarerForm />
                    </Route>
                    <Route path={Routes.customer}>
                        <NavigationBar />
                        <CustomerPage />
                    </Route>
                    <Route path={Routes.carer}>
                        <NavigationBar />
                        <CarerPage />
                    </Route>
                    <Route path={Routes.trainings}>
                        <NavigationBar />
                        <TrainingList />
                    </Route>
                    <Route exact path={Routes.cities}>
                        <NavigationBar />
                        <CitiesList />
                    </Route>
                    <Route exact path={Routes.occupations}>
                        <NavigationBar />
                        <OccupationsList />
                    </Route>
                    <Route exact path={Routes.educations}>
                        <NavigationBar />
                        <EducationsList />
                    </Route>
                    <Route exact path={Routes.users}>
                        <NavigationBar />
                        <UserList />
                    </Route>
                    <Route exact path={Routes.courses}>
                        <NavigationBar />
                        <CoursesList />
                    </Route>
                    <Route exact path={Routes.packs}>
                        <NavigationBar />
                        <PacksList />
                    </Route>
                    <Route path={Routes.discounts}>
                        <NavigationBar />
                        <DiscountsList />
                    </Route>
                    <Route exact path={Routes.accounting}>
                        <NavigationBar />
                        <PurchasesList />
                    </Route>
                    <Route exact path={Routes.stats}>
                        <NavigationBar />
                        <ChartComponent />
                    </Route>

                </Switch>
            </RedirectionContext.Provider>
        </HashRouter>
    );
}