import { useState, useEffect, useRef, useContext } from 'react';
import Discount from '../../models/Discount.model';
import { api } from '../../services/api';
import AtiveToggle, { AtiveToggleOptions } from '../AtiveToggle';
import { MessagingContext } from "../App";
import SearchForm from '../shared/SearchForm';
import DiscountsFormModal from './DiscountsFormModal';
import DiscountsTable from './DiscountsTable';
import PaginationLinks from '../shared/PaginationLinks';
import { MILLISECONDS_LIST_WAIT } from '../../constants';
import { dealyForXSeconds } from '../../utils/generic';
import Course from '../../models/Course.model';
import CourseCombo from '../combos/CourseCombo';
import PackCombo from '../combos/PackCombo';
import Pack from '../../models/Pack.model';

export default function DiscountsList() {
    const messaging = useContext(MessagingContext);
    const [currentSearch, setCurrentSearch] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);

    const [discounts, setDiscounts] = useState<Discount[]>([]);
    const [shownDiscounts, setShownDiscounts] = useState<Discount[]>([]);


    const [activeOptionSelected, setActiveOptionSelected] = useState(2);
    const [discountModalToggle, setDiscountModalToggle] = useState(false);
    const [selectedDiscount, setSelectedDiscount] = useState<Discount | undefined>(undefined);

    const [currentCourse, setCurrentCourse] = useState<Course | undefined>(undefined);
    const [currentPack, setCurrentPack] = useState<Pack | undefined>(undefined);

    const searchRef = useRef<HTMLInputElement | null>(null);


    function addNewDiscount(newDiscount: Discount) {
        const oldDiscounts = discounts;
        oldDiscounts.splice(0, 0, newDiscount);
        setDiscounts(oldDiscounts)
    }

    function updateDiscount(newDiscount: Discount) {
        const oldDiscounts = discounts;
        const position = oldDiscounts.findIndex((d: Discount) => newDiscount.id === d.id);
        if (position !== -1) {
            oldDiscounts.splice(position, 1, newDiscount);
            setDiscounts(oldDiscounts)
        }
    }

    useEffect(() => {
        let sendParams: any = {
            search: currentSearch,
            page: currentPage
        }

        if (activeOptionSelected !== 2) {
            sendParams.active = true;
        }

        if (currentCourse) {
            sendParams.course_id = currentCourse.id;
        }

        if (currentPack) {
            sendParams.pack_id = currentPack.id;
        }

        api.getDiscounts(sendParams,
            data => {
                const newDiscounts: Discount[] = data.data.map((discount: any) => new Discount(discount));
                setDiscounts(newDiscounts);
                setShownDiscounts(newDiscounts);

                setHasMorePages(data.morePages);
            },
            () => setDiscounts([])
        );

        searchRef.current?.focus();
    }, [currentPage, currentSearch, activeOptionSelected, currentCourse, currentPack]);

    function handleRemoveDiscount(id: number) {
        api.deleteDiscount(id,
            data => setDiscounts(discounts.filter((discount: Discount) => discount.id !== id)),
            err => messaging.modal.showMessage("Erro", err.message));
    }

    function handleEditDiscount(id: number) {
        const discountSelected = discounts.find((discount: Discount) => discount.id === id);
        setSelectedDiscount(discountSelected);
        setDiscountModalToggle(true);
    }


    const options: AtiveToggleOptions[] = [
        { id: 2, name: "Tudo" },
        { id: 1, name: "Ativos" }
    ];

    const paginationLinksJSX =
        <PaginationLinks
            page={currentPage}
            pageChanged={newPage => setCurrentPage(newPage)}
            hasMorePages={hasMorePages}
            classes="justify-content-end" />

    return (
        <>
            {discountModalToggle && <DiscountsFormModal
                closeHandler={() => setDiscountModalToggle(false)}
                addDiscount={addNewDiscount}
                editDiscount={updateDiscount}
                discount={selectedDiscount} />}

            <div className="list-container container" style={{ position: "relative" }}>
                <button
                    className="btn btn-success new-list-button"
                    onClick={() => {
                        setSelectedDiscount(undefined);
                        setDiscountModalToggle(true)
                    }}>Novo Desconto</button>
                <h2 className="text-center m-4">Descontos</h2>
                <SearchForm
                    label="Pesquisar Descontos"
                    onChangeHandler={event => {
                        dealyForXSeconds(MILLISECONDS_LIST_WAIT, () => {
                            setCurrentSearch(event.target.value);
                            setCurrentPage(1);
                        });
                    }}
                    style={{ marginBottom: "20px" }}
                    searchRef={searchRef}
                    searchHint="Deve pesquisar por nome" />


                <div style={{ margin: "30px 0", alignItems: "center" }} className="d-flex">
                    <div>
                        <span className="lateralMargin10">Ativo:</span>
                        <AtiveToggle activeChanged={setActiveOptionSelected} options={options}
                            activeOption={activeOptionSelected} />
                    </div>
                    <div style={{ margin: "0 30px", width: "30%", alignItems: "center" }} className="d-flex">
                        <span style={{ marginRight: "10px" }}>Curso:</span>
                        <CourseCombo
                            value={currentCourse}
                            selectionChanged={(newVal: Course | undefined) => setCurrentCourse(newVal)}
                            defaultText="Curso para filtrar"
                            autoFetchCourses={true}
                        />
                    </div>
                    <div style={{ margin: "0 30px", width: "30%", alignItems: "center" }} className="d-flex">
                        <span style={{ marginRight: "10px" }}>Pack:</span>
                        <PackCombo
                            value={currentPack}
                            selectionChanged={(newVal: Pack | undefined) => setCurrentPack(newVal)}
                            defaultText="Pack para filtrar"
                            autoFetchPacks={true}
                        />
                    </div>
                </div>

                {paginationLinksJSX}
                <DiscountsTable values={shownDiscounts}
                    deleteDiscount={handleRemoveDiscount}
                    editDiscountClick={handleEditDiscount} />
                {paginationLinksJSX}


            </div>
        </>
    );
}