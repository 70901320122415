import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { TRAINING_STATUS_FINISHED_ID, trainingStatusTypes, TRAINING_STATUS_NEW_EXTENSION_ID } from "../../constants";
import Training from "../../interfaces/Training.interface";
import TrainingStatus from "../../interfaces/TrainingStatus.interface";
import TrainingStatusType from "../../interfaces/TrainingStatusType.interface";
import { api, getStorageInfo } from "../../services/api";
import { diffInDays, toDatePT, toInputDate } from "../../utils/dates";
import { quantifier } from "../../utils/generic";
import { MessagingContext } from "../App";
import Card from "../shared/Card";
import CaretToggle from "../shared/CaretToggle";
import ConfirmCancelIcons from "../shared/ConfirmCancelIcons";
import GenericCombo from "../shared/GenericCombo";
import Loader from "../shared/Loader";
import ExtensionChanger from "./ExtensionChanger";
import trainingStatusStillActive from "./TrainingUtils";

type TrainingCardProps = {
    value: Training;
    cardColor: string;
    editTraining: (newTraining: Training) => void;
};

type TrainingFields = {
    startDate: string;
    trainingStatusTypeId: number;
    trainingStatusDate: string;
    trainingStatusObservations: string;
    totalTime: number;
};

export default function TrainingCard(props: TrainingCardProps) {
    const training = props.value;

    const messaging = useContext(MessagingContext);
    const [fieldsValues, setFieldsValues] = useState<TrainingFields>({
        startDate: training.startDate ? toInputDate(training.startDate) : "",
        trainingStatusTypeId: training.trainingStatusTypeId,
        trainingStatusDate: toInputDate(new Date()),
        trainingStatusObservations: "",
        totalTime: 0
    });

    const [editMode, setEditMode] = useState(false);
    const [editing, setEditing] = useState(false);
    const [fetchedTrainingStatusTypes, setFetchedTrainingStatusTypes] = useState<TrainingStatusType[] | undefined>();
    const [statusVisible, setStatusVisible] = useState(false);


    const currentDate = new Date();
    const expired = training.expirationDate && currentDate >= training.expirationDate;
    let daysToExpire = -1;
    let finalDate = training.expirationDate || training.createdDate;

    if (training.expirationDate) {
        daysToExpire = Math.ceil(diffInDays(new Date(), finalDate));
    }

    console.log(daysToExpire);
    useEffect(() => {
        api.getTrainingStatusTypes(data => setFetchedTrainingStatusTypes(data),
            err => {
                messaging.modal.showMessage("Erro",
                    "Não foi possivel carregar os possiveis estados da formação");
                setFetchedTrainingStatusTypes([]);
            });
        // eslint-disable-next-line
    }, []);

    function handleConfirm() {
        setEditing(true);
        const editTrainingApiInfo = {
            id: training.id,
            start_date: fieldsValues.startDate,
            total_time: fieldsValues.totalTime,
            training_status:
                fieldsValues.trainingStatusTypeId !== training.trainingStatusTypeId ||
                    fieldsValues.trainingStatusObservations !== training.trainingStatusObservations ?
                    {
                        id: fieldsValues.trainingStatusTypeId,
                        date: fieldsValues.trainingStatusDate,
                        observations: fieldsValues.trainingStatusObservations,
                        user_id: getStorageInfo().userId,
                    } :
                    undefined
        };

        const newTrainingTypeName = fetchedTrainingStatusTypes?.find(type =>
            type.id === fieldsValues.trainingStatusTypeId);

        const editTrainingUIInfo = {
            startDate: fieldsValues.startDate ?
                new Date(fieldsValues.startDate) :
                undefined,
            trainingStatusTypeId: fieldsValues.trainingStatusTypeId,
            training_status: newTrainingTypeName?.title || "Erro ao obter o novo estado",
            endDate: fieldsValues.trainingStatusTypeId === TRAINING_STATUS_FINISHED_ID ?
                new Date(fieldsValues.trainingStatusDate) :
                undefined,
            totalTime: fieldsValues.trainingStatusTypeId === TRAINING_STATUS_FINISHED_ID ?
                fieldsValues.totalTime :
                0
        };

        api.editTraining(editTrainingApiInfo,
            (response: any) => {
                if (response.status === 200) {
                    const newTraining = { ...training, ...editTrainingUIInfo };
                    props.editTraining(newTraining);
                }
                setEditing(false);
                setEditMode(false);
            }, err => {
                setEditing(false);
                messaging.modal.showMessage("Erro", "Erro ao editar a formação: " + err.message);
            });
    }

    function handleFieldChange(event: any) {
        const fieldName = event.target.name;
        const fieldValue = event.target.value
        setFieldsValues({ ...fieldsValues, [fieldName]: fieldValue });
    }

    function removeTrainingStatus(removedStatus: TrainingStatus) {
        api.deleteTrainingStatus(removedStatus.id,
            data => {
                if (data.status !== 204) {
                    throw new Error(data.message);
                }
                const copyTraining = training;
                const removedTrainingIndex = training.status.findIndex((status: TrainingStatus) => status.id === removedStatus.id);
                copyTraining.status.splice(removedTrainingIndex, 1);
                props.editTraining(copyTraining);
            },
            err => err)
    }

    function handleRemoveTrainingStatus(removedStatus: TrainingStatus) {
        messaging.modal.showFull({
            title: "Remover contacto",
            body: `Tem a certeza que pretende remover o estado ${removedStatus.observations}?`,
            closeName: "Cancelar",
            closeHandler: messaging.modal.close,
            actionName: "Eliminar",
            actionHandler: () => {
                messaging.modal.close();
                removeTrainingStatus(removedStatus);
            },
            actionClassName: "btn-danger",
            showAction: true
        });
    }

    const startDateJsx = training.startDate ?
        <div>
            <i className="icon-color icon-mr5 fas fa-clock" title="Iniciada em"></i>
            Iniciada em {toDatePT(training.startDate)}
        </div> :
        <div>
            <i className="icon-color icon-mr5 fas fa-clock" title="Por iniciar"></i>
            Por iniciar
        </div>;

    return (
        <Card
            keyId={training.id.toString()}
            style={{ margin: "20px", backgroundColor: props.cardColor }}
            includeHeader={true}
            title={
                <div>{training.course}
                    {editMode ?
                        (editing ?
                            <Loader
                                outerClassName=""
                                outterStyle={{ display: "inline" }}
                                style={{ marginLeft: "10px" }}
                                size="small" /> :
                            <ConfirmCancelIcons
                                confirmHandler={handleConfirm}
                                cancelHandler={() => setEditMode(false)} />
                        ) :
                        <i
                            className="fas fa-pen action-icon"
                            style={{ margin: "5px 0 0 10px" }}
                            title="Editar"
                            onClick={() => setEditMode(true)}></i>
                    }
                </div>
            }
            body={<div>
                <div style={{ marginBottom: "10px" }}>
                    Formação: {training.training_type}
                </div>
                <div>
                    <i className="icon-color icon-mr5 fas fa-signature" title="Inscrição"></i>
                    Inscrição em {toDatePT(training.createdDate)}
                </div>

                {editMode ?
                    <>
                        Início: <input
                            type="date"
                            value={fieldsValues.startDate}
                            name="startDate"
                            onChange={handleFieldChange} />
                    </> :
                    startDateJsx
                }
                {training.expirationDate && !training.endDate &&
                    <div>
                        <i className="icon-color icon-mr5 fas fa-exclamation-triangle" title="Expira em"></i>
                        {expired ? "Expirada" : "Expira"} em {toDatePT(finalDate)}&nbsp;
                        {daysToExpire && `(${daysToExpire < 0 ? "há" : ""} ${quantifier(Math.abs(daysToExpire), "dia", "dias")})`}

                    </div>
                }
                {training.endDate &&
                    <div>
                        <div>
                            <i className="icon-color icon-mr5 fas fa-flag-checkered" title="Finalizada"></i>
                            Finalizada em {toDatePT(training.endDate)}
                        </div>
                        <div>Duração: {training.totalTime} Horas</div>
                    </div>
                }
                {training.monthExtension > 0 &&
                    <div>Extensão de <b>{training.monthExtension} meses</b></div>
                }
                {training.observations_extension &&
                    <div>Observações da extensão: {training.observations_extension}</div>}
                <div style={{ marginTop: "15px" }}>{editMode ? "Novo estado" : "Estado"}:&nbsp;
                    {editMode ?
                        <>
                            <GenericCombo
                                values={fetchedTrainingStatusTypes}
                                value={fieldsValues.trainingStatusTypeId}
                                getId={val => val.id}
                                getOptionContent={val => val.title}
                                selectionChanged={id => setFieldsValues(
                                    { ...fieldsValues, trainingStatusTypeId: id as number }
                                )} />
                            Em:
                            <input
                                type="date"
                                name="trainingStatusDate"
                                className="form-control"
                                value={fieldsValues.trainingStatusDate}
                                onChange={handleFieldChange} />
                            {fieldsValues.trainingStatusTypeId === TRAINING_STATUS_FINISHED_ID &&
                                <>
                                    Horas:
                                    <input
                                        type="number"
                                        name="totalTime"
                                        className="form-control"
                                        value={fieldsValues.totalTime}
                                        onChange={handleFieldChange} />
                                </>}
                            Observações:
                            <textarea
                                className="form-control"
                                id="obs"
                                name="trainingStatusObservations"
                                rows={3}
                                value={fieldsValues.trainingStatusObservations}
                                onChange={handleFieldChange}></textarea>
                        </> :

                        training.status ?
                            <>
                                <div className="card-sub-title" style={{ cursor: 'pointer', marginBottom: "10px", backgroundColor: "#f7f7f7" }}
                                    onClick={() => setStatusVisible(val => !val)}>
                                    {trainingStatusTypes.find((type) => type.id === fieldsValues.trainingStatusTypeId)?.name}

                                    <CaretToggle visible={statusVisible} onClick={() => setStatusVisible(val => !val)} />
                                </div>
                                {statusVisible &&
                                    training.status.map((status: TrainingStatus) =>
                                        <div style={{ fontSize: "0.9em", margin: "5px 0" }} key={status.id}>
                                            <b>{toDatePT(status.date ?? status.createdDate)}</b>: {status.trainingStatusType}  {status.observations ? `- ${status.observations}` : ""}
                                            <br />
                                            <b style={{ marginLeft: "10px" }}>Por:</b> {status.user}
                                            <span
                                                className="action-icon"
                                                style={{ marginLeft: "5px" }}
                                                onClick={() => handleRemoveTrainingStatus(status)}>
                                                <i className="fas fa-trash" title="Eliminar"></i>
                                            </span>
                                        </div>
                                    )
                                }
                            </>
                            :
                            training.training_status}
                </div>
                {training.certificateId &&
                    <>
                        <i className="fas fa-certificate"></i> Certificado: {training.certificate_status}
                    </>
                }
                {editMode && trainingStatusStillActive(training.trainingStatusTypeId) &&
                    <ExtensionChanger
                        training={training}
                        editing={editing}
                        setEditing={setEditing}
                        editTraining={(training: Training) => {
                            props.editTraining(training);
                            setFieldsValues({ ...fieldsValues, trainingStatusTypeId: TRAINING_STATUS_NEW_EXTENSION_ID });
                            setEditMode(false);
                            setEditing(false);
                        }} />}
            </ div>} />
    )
}