import { Teacher } from "../../types/Teacher";

interface TeacherComboProps {
    value: number | undefined;
    values: Teacher[];
    selectionChanged: (newVal: number) => void;
    showError?: boolean;
}

export default function TeacherCombo(props: TeacherComboProps) {
    return (
        <>
            <select
                value={props.value}
                className="form-select"
                onChange={event => props.selectionChanged(parseInt(event.target.value))}>

                <option key={0} value={0}>Escolha os Formadores que quer adicionar ao Questionário</option>
                {
                    props.values.map((value: Teacher) =>
                        <option key={value.id} value={value.id}>{value.name}</option>
                    )
                }
            </select>
            {props.showError &&
                <div className="invalid-feedback" style={{ display: "block" }}>
                    Precisa de escolher um Formador
                </div>
            }
        </>
    );
}