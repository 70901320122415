export type AtiveToggleOptions = {
    id: number;
    name: string;
};

export default function AtiveToggle(props: {
    activeChanged: (newOption: number) => void,
    activeOption: number,
    options: AtiveToggleOptions[],
}) {

    return (
        <div className="btn-group btn-group-toggle" data-toggle="buttons">

            {
                props.options.map((option: any, pos: number) => <label
                    key={option.id}
                    className={`btn btn-primary ${option.id === props.activeOption ? "active" : ""}`}>
                    <input
                        type="radio"
                        name="options"
                        id={option.id}
                        autoComplete="off"
                        onClick={() => {
                            props.activeChanged(option.id);
                        }}
                        style={{ display: "none" }} /> {option.name}
                </label>
                )
            }
        </div>
    );
}