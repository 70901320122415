import { useEffect, useState, useContext } from 'react';
import Carer from "../../models/Carer.model";
import { api } from "../../services/api";
import { MessagingContext } from "../App";
import Loader from "../shared/Loader";
import StudentCarerCard from "../student/cards/StudentCarerCard";

export default function CarerInfo(props: { carerId: number, setCarerName: (name: string) => void }) {
    const { carerId, setCarerName } = props;
    const [carer, setCarer] = useState<Carer>();
    const messaging = useContext(MessagingContext);

    useEffect(() => {
        api.getCarer(carerId,
            carerFetched => {
                setCarer(new Carer(carerFetched));
                setCarerName(carerFetched.name);
            }, err => messaging.modal.showMessage("Erro", "Erro ao obter o cliente:" + err.message));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carerId, setCarerName]);


    function handleEditCarer(editedCarer: Carer) {
        if (carer) {
            const fieldValues = {
                id: carer.id,
                name: editedCarer.name,
                email: editedCarer.email,
                phone: editedCarer.phone,
                address: editedCarer.address,
                postal_code: editedCarer.postalCode,
                city_id: editedCarer.cityId,
                fiscal_number: editedCarer.fiscalNumber,
                identification_number: editedCarer.identificationNumber,
                identification_date: editedCarer.identificationDate,
                identification_entity: editedCarer.identificationEntity,
                identification_type_id: editedCarer.identificationTypeId,
                naturality: editedCarer.naturality,
                nationality_id: editedCarer.nationalityId,
                observations: editedCarer.observations,
                birth_date: editedCarer.birthDate,
            }
            api.editCarer(fieldValues,
                () => {
                    setCarer(editedCarer);
                },
                err => {
                    messaging.modal.showMessage("Erro", "Erro a editar os dados do aluno: " + err.message);
                }
            );
        }
    }

    return (
        <div className="d-flex justify-content-center">
            {typeof carer === "undefined" ?
                <Loader /> :

                <div>
                    <StudentCarerCard
                        value={carer}
                        handleCarerChange={handleEditCarer} />
                </div>
            }
        </div>
    );
}