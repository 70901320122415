import '../../styles/ContactsTable.css';
import { api } from '../../services/api';
import { useContext, useEffect, useState } from 'react';
import StudentModal from '../student/StudentModal';
import FreeSessionModal from '../free-session/FreeSessionModal';
import Contact from '../../interfaces/Contact.interface';
import Student from '../../models/Student.model';
import { FreeSession } from '../../models/FreeSession.model';
import { MessagingContext } from '../App';
import FollowUp from '../../interfaces/FollowUp.interface';
import ContactsListItem from './ContactsListItem';
import FollowUpModalForm from '../followup/FollowUpModalForm';

type ContactTableProps = {
    contactRemoved: (id: number) => void,
    contactModified: (contact: Contact) => void,
    values: Contact[]
}

export default function ContactsTable(props: ContactTableProps) {
    const messaging = useContext(MessagingContext);
    const [processingContactId, setProcessingContactId] = useState(0);

    const [associatingSG, setAssociatingSG] = useState(false);
    const [associatingStudent, setAssociatingStudent] = useState(false);
    const [selectedContact, setSelectedContact] = useState<Contact | undefined>();
    const [selectedFollowUp, setSelectedFollowUp] = useState<FollowUp | undefined>();

    const [newFollowUpVisible, setNewFollowUpVisible] = useState(false);

    function deleteContact(id: number) {
        setProcessingContactId(id);
        api.deleteContact(id, () => {
            setProcessingContactId(0);
            if (props.contactRemoved) {
                props.contactRemoved(id);
            }
            messaging.toast.show("Contact", "Contacto removido");
        }, () => {
            setProcessingContactId(0);
            messaging.modal.showMessage("Erro", "Erro a remover contacto");
        });
    }

    function deleteFollowUp(followUp: FollowUp) {
        api.deleteFollowUp(followUp.id,
            (response) => {

                if (selectedContact && selectedContact.follow_ups) {
                    const followUps = selectedContact.follow_ups;
                    const newFollowUps = followUps.filter(f => f.id !== followUp.id);

                    const newContact = selectedContact;
                    newContact.follow_ups = newFollowUps;

                    setNewFollowUpVisible(false);
                    props.contactModified(newContact);
                    setSelectedFollowUp(undefined);

                    messaging.toast.show("Seguimento", `Seguimento ${followUp.description} removido`);
                }


            }, () => {
                setProcessingContactId(0);
                messaging.modal.showMessage("Erro", "Erro a remover Seguimento");
            });
    }

    function associateContactWithStudent(selectedStudent: Student) {
        if (selectedContact) {

            const editContact = { student_id: selectedStudent.id, id: selectedContact.id };
            api.editContact(editContact, () => {
                setAssociatingStudent(false);
                const newContact = selectedContact;
                newContact.student_id = selectedStudent.id;
                setSelectedContact(newContact);
            }, () => {
                messaging.modal.showMessage("Erro", "Erro ao editar contacto");
            });
        };
    }
    function associateContactWithFreeSession(selectedFreeSession: FreeSession) {
        if (selectedContact) {

            const editContact = { free_session_id: selectedFreeSession.id, id: selectedContact.id };
            api.editContact(editContact, () => {
                setAssociatingSG(false);
                const newContact = selectedContact;
                newContact.free_session_id = selectedFreeSession.id;
                setSelectedContact(newContact);
            }, () => {
                messaging.modal.showMessage("Erro", "Erro ao editar contacto");
            });
        };
    }

    function handleRemoveContact(contact: Contact) {
        messaging.modal.showFull({
            title: "Remover contacto",
            body: `Tem a certeza que pretende remover o contacto de ${contact.name} ?`,
            closeName: "Cancelar",
            closeHandler: messaging.modal.close,
            actionName: "Eliminar",
            actionHandler: () => {
                messaging.modal.close();
                deleteContact(contact.id);
            },
            actionClassName: "btn-danger",
            showAction: true
        });
    }

    function handleRemoveFollowUp(followUp: FollowUp) {
        messaging.modal.showFull({
            title: "Remover contacto",
            body: `Tem a certeza que pretende remover o seguimento de ${followUp.description} ?`,
            closeName: "Cancelar",
            closeHandler: () => {
                setSelectedContact(undefined);
                setSelectedFollowUp(undefined);
                messaging.modal.close();
            },
            actionName: "Eliminar",
            actionHandler: () => {
                messaging.modal.close();
                deleteFollowUp(followUp);
            },
            actionClassName: "btn-danger",
            showAction: true
        });
    }

    function handleFollowUpEdit(followUp: FollowUp) {
        setSelectedFollowUp(followUp);
        setNewFollowUpVisible(true);
    }


    function updateContactFollowUps(followUps: FollowUp[], contact: Contact) {
        const newContact = { ...contact };
        newContact.follow_ups = followUps;
        props.contactModified(newContact);
    }

    function handleAddFollowUp(followUp: FollowUp) {
        if (selectedContact && selectedContact.follow_ups) {
            const followUps = selectedContact.follow_ups;
            followUps.push(followUp);

            selectedContact.follow_ups = followUps;

            setNewFollowUpVisible(false);
            props.contactModified(selectedContact);
            setSelectedFollowUp(undefined);
            setSelectedContact(undefined);

        }
    }
    return (
        <>
            {newFollowUpVisible && selectedContact &&
                <FollowUpModalForm
                    contact={selectedContact}
                    followUpAdded={handleAddFollowUp}
                    followUpEdited={handleFollowUpEdit}
                    followUp={selectedFollowUp}
                    closeHandler={() => setNewFollowUpVisible(false)} />
            }
            {associatingSG && selectedContact &&
                <FreeSessionModal
                    title={"Associar um contacto a uma Sessão Gratuita"}
                    selectHandler={(freeSession: FreeSession) => associateContactWithFreeSession(freeSession)}
                    closeHandler={() => setAssociatingSG(false)} />
            }
            {associatingStudent && selectedContact &&
                <StudentModal
                    title={"Associar um contacto a um Aluno"}
                    selectHandler={(student: Student) => associateContactWithStudent(student)}
                    closeHandler={() => setAssociatingStudent(false)} />
            }


            {props.values.length > 0 ?
                props.values.map((row: Contact) =>
                    <ContactsListItem
                        key={row.id}
                        contact={row}
                        handleUpdateContactFollowUps={(followUps: FollowUp[]) => { updateContactFollowUps(followUps, row) }}
                        handleNewFollowUp={() => {
                            setSelectedFollowUp(undefined);
                            setNewFollowUpVisible(true);
                        }}
                        handleFollowUpEdit={handleFollowUpEdit}
                        handleFollowUpDelete={handleRemoveFollowUp}
                        handleRemoveContact={handleRemoveContact}
                        setSelectedContact={setSelectedContact}
                        setAssociatingSG={setAssociatingSG}
                        setAssociatingStudent={setAssociatingStudent} />
                ) : ""}
        </>
    );
}