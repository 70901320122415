import { CSSProperties } from "react";
import { DEFAULT_TOAST_TIMEOUT } from "../../constants";

export interface ToastProps {
    title: string;
    body: string;
    removeHandler: () => void;
    time?: number;
    style?: CSSProperties;
}

/**
 * Shows a toast message that should be removed after a specificied time
 * @param ToastProps props The valid props for this component
 * @returns The component JSX
 */
export default function Toast(props: ToastProps) {
    setTimeout(() => {
        props.removeHandler();
    }, props.time || DEFAULT_TOAST_TIMEOUT);

    const style = {
        zIndex: 5000
    };
    Object.assign(style, props.style || {});

    return (
        <div className="position-fixed bottom-0 end-0 p-3" style={style}>
            <div
                className="toast showing"
                role="alert"
                aria-live="assertive"
                aria-atomic="true">

                <div className="toast-header">
                    <strong className="me-auto">{props.title}</strong>
                    <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => props.removeHandler()}></button>
                </div>
                <div className="toast-body">{props.body}</div>
            </div>
        </div>
    );
}