import { useState, useEffect, useContext } from "react";
import { api, NotFoundError } from "../../services/api";
import Student from "../../models/Student.model";
import Carer from "../../models/Carer.model";
import StudentDataCard from "./cards/StudentDataCard";
import StudentEducationCard from "./cards/StudentEducationCard";
import StudentFormabaseCard from "./cards/StudentFormabaseCard";
import StudentIdentificationCard from "./cards/StudentIdentificationCard";
import StudentOccupationCard from "./cards/StudentOccupationCard";
import Loader from "../shared/Loader";
import { MessagingContext, PrintingContext } from "../App";
import { FieldsEdited } from "../../types/FieldsEdited";
import { mapStudentFieldsToApi } from "./forms/StudentForm";
import Customer from "../../models/Customer.model";
import PrintStudent from "./PrintStudent";

export default function StudentInfo(props: { studentId: number, setStudentName: (name: string) => void }) {
    const printing = useContext(PrintingContext);
    const messaging = useContext(MessagingContext);
    const [customer, setCustomer] = useState<Customer | undefined | null>();
    const [student, setStudent] = useState<Student | undefined>();
    const [carer, setCarer] = useState<Carer | undefined | null>();
    const { studentId, setStudentName } = props;

    useEffect(() => {
        api.getStudentInfo(studentId, studentFetched => {
            setStudent(new Student(studentFetched));
            setStudentName(studentFetched.name);
        }, err => messaging.modal.showMessage("Erro", "Erro ao obter o aluno:" + err.message));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studentId, setStudentName]);
    useEffect(() => {
        if (typeof carer === "undefined") {
            api.getStudentCarer(studentId,
                carer => setCarer(new Carer(carer)),
                err => {
                    console.log("Erro a carregar dados do encarregado de educacao: " + err.message);
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studentId, carer]);


    useEffect(() => {
        if (typeof customer === "undefined") {
            api.getLastStudentCustomer(studentId,
                customer => setCustomer(new Customer(customer)),
                err => {
                    if (err instanceof NotFoundError) {
                        setCustomer(null);
                    }
                    else {
                        messaging.modal.showMessage("Erro",
                            "Erro a carregar dados de faturação: " + err.message);
                    }
                });
        }
    }, [customer, messaging, studentId]);
    function editStudent<T>(edited: FieldsEdited<T>) {
        const studentEditData: any = Object.assign({ id: studentId }, mapStudentFieldsToApi(edited.newValues));
        console.log(studentEditData);
        api.editStudent(studentEditData,
            data => {
                if (student) {
                    setStudent(Object.assign(student, edited.newValues));
                    edited.success();
                }
            },
            err => edited.fail(err));
    }

    function printStudent() {
        if (student && customer) {
            printing.showDocument(<PrintStudent
                customer={customer}
                student={student}
                carer={carer ?? undefined}
                close={printing.close}
            />);
        }
    }

    return (
        student ?
            <>
                <i
                    style={{ float: "left", fontSize: "1.2em", opacity: student && customer ? "1" : "0" }}
                    className="fas fa-print action-icon" onClick={printStudent}></i>
                <div className="p-4" style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                    <StudentDataCard
                        value={student}
                        style={{ margin: "10px" }}
                        fieldsEditedCallback={editStudent}
                        setStudent={setStudent} />
                    <StudentIdentificationCard
                        value={student}
                        style={{ margin: "10px" }}
                        fieldsEditedCallback={editStudent}
                        setStudent={setStudent} />

                    <div>
                        <StudentFormabaseCard
                            value={student}
                            style={{ margin: "10px 10px 15px 10px" }}
                            fieldsEditedCallback={editStudent}
                            setStudent={setStudent} />
                        <StudentOccupationCard
                            value={student}
                            style={{ margin: "20px 10px" }}
                            fieldsEditedCallback={editStudent}
                            setStudent={setStudent} />
                    </div>
                    <StudentEducationCard
                        value={student}
                        style={{ margin: "20px 10px" }}
                        fieldsEditedCallback={editStudent}
                        setStudent={setStudent} />

                </div>
            </>
            :
            <Loader />
    );
}