import { useContext, useEffect, useState } from "react";
import { api, NotFoundError } from "../../services/api";
import { MessagingContext } from "../App";
import Survey from "../../models/Survey.model";
import Loader from "../shared/Loader";
import SurveyCard from "../surveys/SurveysCard";

export default function StudentSurveys(props: { studentId: number, setStudentName: (name: string) => void }) {
    const messaging = useContext(MessagingContext);
    const [surveys, setSurveys] = useState<Survey[]>();
    const [adding, setAdding] = useState(false);

    useEffect(() => {
        if (typeof surveys === "undefined") {
            api.getStudentSurveys(props.studentId,
                (resp: any) => {
                    const surveys = resp.map((val: any) => new Survey(val));
                    setSurveys(surveys);
                },
                err => {
                    if (err instanceof NotFoundError) {
                        setSurveys([]);
                    }
                    else {
                        messaging.modal.showMessage("Erro",
                            "Erro a carregar os questionarios: " + err.message);
                    }
                });
        }

    }, [surveys, messaging, props.studentId]);

    function addNewSurveyToList(survey: Survey) {
        if (surveys) {
            setSurveys([...surveys, survey]);
            setAdding(false);
        }
    }

    function removeNewSurveyToList(id: number) {
        if (surveys) {
            setSurveys(surveys.filter(survey => survey.id !== id));
        }
    }

    function updateNewSurveyToList(updatedSurvey: Survey) {
        if (surveys) {
            const foundSurveyIndex = surveys.findIndex((survey: Survey) => survey.id === updatedSurvey.id);
            if (foundSurveyIndex !== -1) {
                const newSurveys = [...surveys];
                newSurveys[foundSurveyIndex] = updatedSurvey;

                setSurveys(surveys);
            }
        }
    }

    return (
        <div className="justify-content-center">
            <div className="col text-center">
                {
                    !adding ?
                        <button
                            className="btn btn-success"
                            onClick={() => setAdding(true)}
                            style={{ marginBottom: "20px" }}>Novo Questionário</button> : ""}
            </div>
            {


                adding ?
                    <SurveyCard
                        newSurveyHandler={addNewSurveyToList}
                        studentId={props.studentId} key={0} survey={undefined} editable={true} />
                    :
                    typeof surveys === "undefined" ?
                        <Loader /> :
                        (surveys.map((survey: Survey, index: number) =>
                            <SurveyCard
                                newSurveyHandler={addNewSurveyToList}
                                editSurveyHandler={updateNewSurveyToList}
                                removeSurveyHandler={removeNewSurveyToList}
                                studentId={props.studentId}
                                bodyHidden={index !== 0 ? true : false}
                                key={survey.id}
                                survey={survey}
                            />))
            }
        </div>
    );
}