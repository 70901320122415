import { CSSProperties } from 'react';
import { noOffice, officesHash } from '../../constants';

export default function OfficeTag(props: { office: number }) {
    const office = officesHash[props.office] || noOffice;
    const DEFAULT_BACKGROUND_COLOR = "gray";
    const tagStyle: CSSProperties = {
        backgroundColor: office.color || DEFAULT_BACKGROUND_COLOR,
        padding: "4px",
        borderRadius: "4px",
        color: "white",
        fontSize: "12px",
        fontWeight: "bold"
    };

    return (
        <span style={tagStyle}>FB&nbsp;{office.name}</span>
    )
}