import Purchase from "../../models/Purchase.model";
import Student from "../../models/Student.model";
import Loader from "../shared/Loader";
import PurchaseCard from "./PurchaseCard";

interface PurchasesGridProps {
    purchases: Purchase[] | undefined,
    studentId: number,
    changedData?: (purchase: Purchase) => void,
    removedData?: (purchaseId: number) => void;
}
export default function PurchasesGrid(props: PurchasesGridProps) {
    return (
        props.purchases ?
            <div className="d-flex justify-content-center">
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {props.purchases.map(purchase =>
                        <PurchaseCard
                            key={purchase.id}
                            style={{ margin: "20px" }}
                            studentId={props.studentId}
                            purchase={purchase}
                            changedData={props.changedData}
                            removedData={props.removedData}
                        />)}</div>
            </div> :
            <Loader />
    );
}