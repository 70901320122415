import { CSSProperties } from "react";

export default function CaretToggle(props: { visible: boolean, onClick: () => void, style?: CSSProperties }) {
    return (
        <i
            className={`fa fa-caret-${props.visible ? "down" : "right"} action-icon ml-10`}
            aria-hidden="true"
            onClick={props.onClick}
            style={props.style || {}}></i>
    );
}