import React, { useContext, useEffect, useState } from "react";
import * as Constants from "../../constants";
import Training from "../../interfaces/Training.interface";
import { api, NotFoundError } from "../../services/api";
import { buildTraining } from "../../utils/objects-builders";
import { MessagingContext } from "../App";
import Loader from "../shared/Loader";
import TrainingCard from "../training/TrainingsCard";

export default function StudentTrainings(props: { studentId: number, setStudentName: (name: string) => void }) {
    const messaging = useContext(MessagingContext);
    const [trainings, setTrainings] = useState<Training[] | undefined>();
    const { studentId, setStudentName } = props;

    useEffect(() => {
        api.getStudentTrainings(studentId, trainingsFetched => {
            setTrainings(trainingsFetched.map(buildTraining));
            if (trainingsFetched.length > 0 && trainingsFetched[0].student_name) {
                setStudentName(trainingsFetched[0].student_name);
            }
        }, err => {
            if (err instanceof NotFoundError) {
                setTrainings([]);
            }
            else {
                messaging.modal.showMessage("Erro", "Erro a obter as formações do aluno: " + err);
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studentId, setStudentName]);

    function getBackgroundColor(training: Training) {
        if (training.trainingStatusTypeId === Constants.TRAINING_TYPE_FINISHED_ID) {
            return Constants.FINISHED_TRAINING_STATUS_TYPE_COLOR;
        }
        if (training.trainingStatusTypeId === Constants.TRAINING_TYPE_EXPIRED_ID) {
            return Constants.EXPIRED_TRAINING_STATUS_TYPE_COLOR;
        }
        if (training.trainingStatusTypeId === Constants.TRAINING_TYPE_NEW_ID) {
            return Constants.NEW_TRAINING_STATUS_TYPE_COLOR;
        }
        return "white";
    }

    function editTraining(training: Training) {
        if (trainings) {
            const trainingsCopy = [...trainings];
            const index = trainingsCopy.findIndex(t => t.id === training.id);
            trainingsCopy[index] = training;
            setTrainings(trainingsCopy);
        }
    }

    return (
        trainings ?
            <div className="d-flex justify-content-center">
                <div style={{ display: "flex", flexWrap: "wrap", alignItems: "baseline" }}>
                    {trainings &&
                        (trainings.length === 0 ?
                            "Este aluno não possui formações" :
                            trainings.map((training, pos) =>
                                <TrainingCard
                                    value={training}
                                    key={pos}
                                    cardColor={getBackgroundColor(training)}
                                    editTraining={editTraining} />
                            )
                        )
                    }
                </div>
            </div> :
            <Loader />
    );
}