export default class Customer {
    public id: number;
    public name: string;
    public fiscalNumber: number | undefined;
    public responsible: string;
    public address: string;
    public postalCode: string;
    public cityId: number | undefined;
    public city: string;
    public phone: string;
    public email: string;
    public observations: string;
    public createdDate: Date;
    public officeId: number;
    public office: string;
    public customerTypeId: number | undefined;
    public customerType: string;
    public carerId: number | undefined;
    public carer: string;
    public studentId: number | undefined;
    public student: string;

    constructor(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.address = data.address;
        this.postalCode = data.postal_code;
        this.email = data.email;
        this.phone = data.phone;
        this.cityId = data.city_id;
        this.city = data.city;
        this.office = data.office;
        this.fiscalNumber = data.fiscal_number;
        this.createdDate = new Date(data.created_date);
        this.officeId = data.office_id;
        this.office = data.office;
        this.observations = data.observations;
        this.responsible = data.responsible;
        this.customerTypeId = data.customer_type_id;
        this.customerType = data.customer_type;
        this.carerId = data.carer_id;
        this.carer = data.carer;
        this.studentId = data.student_id;
        this.student = data.student;
    }

    clone(): Customer {
        const newCustomer = new Customer({});
        
        newCustomer.id = this.id;
        newCustomer.name = this.name;
        newCustomer.address = this.address;
        newCustomer.postalCode = this.postalCode;
        newCustomer.email = this.email;
        newCustomer.phone = this.phone;
        newCustomer.cityId = this.cityId;
        newCustomer.city = this.city;
        newCustomer.office = this.office;
        newCustomer.fiscalNumber = this.fiscalNumber;
        newCustomer.createdDate = this.createdDate;
        newCustomer.officeId = this.officeId;
        newCustomer.office = this.office;
        newCustomer.observations = this.observations;
        newCustomer.responsible = this.responsible;
        newCustomer.customerTypeId = this.customerTypeId;
        newCustomer.customerType = this.customerType;
        newCustomer.carerId = this.carerId;
        newCustomer.carer = this.carer;
        newCustomer.studentId = this.studentId;
        newCustomer.student = this.student;

        return newCustomer;
    }
}