import moment from "moment";
import { SyntheticEvent, useContext, useEffect, useState } from "react";
import { PaymentTypes, offices } from "../../constants";
import PaymentMethod from "../../interfaces/PaymentMethod.interface";
import Product from "../../interfaces/Product.interface";
import Course from "../../models/Course.model";
import Student from "../../models/Student.model";
import Customer from "../../models/Customer.model";
import Discount from "../../models/Discount.model";
import Pack from "../../models/Pack.model";
import { api, getStorageInfo } from "../../services/api";
import { Tax } from "../../types/Tax.type";
import { toInputDate, toISODate } from "../../utils/dates";
import { MessagingContext } from "../App";
import { RedirectionContext } from "../MainRouter";
import MultipleCourseCombo from "../combos/MultipleCourseCombo";
import MultiplePackCombo from "../combos/MultiplePackCombo";
import ContactForTrainingTypeCombo from "../contact/combos/ContactForTrainingTypeCombo";
import { CustomerFields } from "../customer/CustomerForm";
import CreatePaymentsTable from "../payment/CreatePaymentsTable";
import Card from "../shared/Card";
import GenericCombo from "../shared/GenericCombo";
import MassProductsTable from "../enrollment/MassProductsTable";
import City from "../../interfaces/City.interface";
import CustomerBasicFieldsForm from "../customer/CustomerBasicFieldsForm";
import Carer from "../../models/Carer.model";

export type ProductsTotals = {
    time: number;
    price: number;
    discountPrice: number;
    total: number;
};

const emptyTotals = {
    time: 0,
    price: 0,
    discountPrice: 0,
    total: 0
};

const availableNumberOfPayments = [2, 3, 4, 5, 6];

type MassPurchaseCardProps = {
    customer: Customer | undefined,
    close: () => void,
    carer?: Carer,
}

export default function NewMassPurchaseCard(props: MassPurchaseCardProps) {
    const { customer, carer } = props;

    const redirection = useContext(RedirectionContext);
    const messaging = useContext(MessagingContext);
    const [selectedCourses, setSelectedCourses] = useState<Course[]>([]);
    const [courses, setCourses] = useState<Course[] | undefined>();
    const [customerFieldsValues, setCustomerFieldsValues] = useState<CustomerFields>(
        {
            name: "",
            address: "",
            responsible: "",
            postal_code: "",
            fiscal_number: "",
            city: undefined,
            phone: "",
            email: "",
            observations: "",
            customer_type: 3,
            office: undefined,
            carer: undefined,
            student: undefined
        }

    );
    const [totals, setTotals] = useState<ProductsTotals>(emptyTotals);
    const [selectedPaymentType, setSelectedPaymentType] = useState(1);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<number | undefined>();
    const [numberOfPayments, setNumberOfPayments] = useState(1);
    const [numberOfProducts, setNumberOfProducts] = useState(0);
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[] | undefined>();
    const [packs, setPacks] = useState<Pack[] | undefined>();
    const [selectedPacks, setSelectedPacks] = useState<Pack[]>([]);
    const [discounts, setDiscounts] = useState<Discount[] | undefined>();
    const [paymentDates, setPaymentDates] = useState<Date[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [cities, setCities] = useState<City[]>([]);
    const [productsDiscounts, setProductsDiscounts] = useState<(Discount | undefined)[]>([]);
    const [observations, setObservations] = useState("");
    const [schedule, setSchedule] = useState("");
    const [expirationPeriod, setExpirationPeriod] = useState(6);
    const [taxes, setTaxes] = useState<Tax[] | undefined>();
    const [selectedTaxId, setSelectedTaxId] = useState<number | undefined>();
    const [trainingType, setTrainingType] = useState<number | undefined>();
    const [createdDate, setCreatedDate] = useState<Date>(new Date());
    const [errors, setErrors] = useState({
        tax: false,
        paymentMethod: false,
        paymentType: false,
        trainingType: false
    });

    const [customerOfficeId, setCustomerOfficeId] = useState(0);

    useEffect(() => {
        if (customer) {
            setCustomerFieldsValues({
                name: customer.name,
                address: customer.address,
                responsible: customer.responsible,
                postal_code: customer.postalCode,
                fiscal_number: customer.fiscalNumber ? customer.fiscalNumber.toString() : "",
                city: customer.cityId,
                phone: customer.phone,
                email: customer.email,
                observations: customer.observations,
                customer_type: customer.customerTypeId,
                office: customer.officeId,
                carer: customer.carerId,
                student: customer.studentId
            });

            setCustomerOfficeId(customer.officeId);
        }
    }, [customer])

    useEffect(() => {
        if (carer) {
            setCustomerFieldsValues({
                name: carer.name,
                address: carer.address,
                responsible: "",
                postal_code: carer.postalCode,
                fiscal_number: carer.fiscalNumber ? carer.fiscalNumber.toString() : "",
                city: carer.cityId,
                phone: carer.phone,
                email: carer.email,
                observations: carer.observations,
                customer_type: undefined,
                office: carer.officeId,
                carer: carer.id,
                student: undefined,
            });
        }
    }, [carer])


    useEffect(() => {
        api.getEnrollmentComboboxesInfo(data => {
            setCourses(data.courses.map((course: any) => new Course(course)));
            setPaymentMethods(data.payment_methods);
            setPacks(data.packs.map((pack: any) => new Pack(pack)));
            setTaxes(data.taxes);
            setCities(data.cities);
            setSelectedTaxId(data.taxes[0].id);

            const discounts: Discount[] = data.discounts.map((disc: any) => new Discount(disc));
            setDiscounts(getApplicableDiscounts(discounts));
        }, err => messaging.modal.showMessage(
            "Erro", "Erro a obter cursos, packs, taxas e formas de pagamento: " + err.message));

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        updateTotals();
        // eslint-disable-next-line
    }, [selectedCourses, selectedPacks, productsDiscounts]);

    useEffect(() => {
        generateDatesToNumberOfPayments(createdDate ?? new Date());
        // eslint-disable-next-line
    }, [numberOfPayments, createdDate]);

    useEffect(() => {
        setErrors({
            tax: !selectedTaxId || selectedTaxId === 0,
            paymentMethod: !selectedPaymentMethod || selectedPaymentMethod === 0,
            paymentType: !selectedPaymentType || selectedPaymentType === 0,
            trainingType: !trainingType || trainingType === 0
        });
    }, [customerFieldsValues, selectedTaxId, selectedPaymentMethod, selectedPaymentType, trainingType]);

    function removeDiscountAtIndex(index: number) {
        const productsDiscountsCopy = [...productsDiscounts];
        productsDiscountsCopy.splice(index, 1);
        setProductsDiscounts(productsDiscountsCopy);
    }

    function generateDatesToNumberOfPayments(enrollmentDate: Date) {
        const newDates = [enrollmentDate];
        for (let i = 0; i < numberOfPayments - 1; ++i) {
            const lastDate = newDates[newDates.length - 1];
            newDates.push(moment(lastDate).add(1, 'months').toDate());
        }
        setPaymentDates(newDates);
    }

    function getApplicableDiscounts(discounts: Discount[]): Discount[] {
        const now = new Date();
        return discounts.filter(disc =>
            (disc.startDate === null || disc.startDate <= now) &&
            (disc.endDate === null || disc.endDate >= now)
        );
    }

    function changePaymentDate(newDay: string, pos: number) {
        const newDates = paymentDates.map(d => new Date(d.getTime()));
        newDates[pos] = new Date(newDay);
        setPaymentDates(newDates);
    }

    function changePaymentAmount(newAmount: string, pos: number) {
/*
        const changedPayment: PaymentObject = paymentsObject[pos];
        const newObjects = [...paymentsObject];

        changedPayment.amount = newAmount;
        newObjects[pos] = changedPayment;

        setPaymentsObjects(newObjects);*/
    }

    function changeProductDiscount(newDiscountId: number, pos: number, product: Product) {
        const newProductsDiscounts = productsDiscounts.map(
            disc => typeof disc === "undefined" ? undefined : disc.clone());

        newProductsDiscounts[pos] = product instanceof Course ?
            discounts?.find(disc => disc.id === newDiscountId) :
            (product as Pack).discounts.find(disc => disc.id === newDiscountId);
        setProductsDiscounts(newProductsDiscounts);
    }

    function updateTotals() {
        let newTotals = Object.assign({}, emptyTotals);
        for (let i = 0; i < products.length; i++) {
            const price = products[i].getPrice() || 0
            const discount = productsDiscounts[i];
            newTotals.time += products[i].getTime() || 0;
            newTotals.price += price;
            if (discount) {
                newTotals.discountPrice += parseInt(discount.getDiscountValueForProduct(products[i]));
            }
        }

        newTotals.total += newTotals.price - newTotals.discountPrice;
        setTotals(newTotals);
    }

    function addCourse(course: Course) {
        let newCourse = course.clone();
        newCourse.productNumber = numberOfProducts;

        let productNumber = numberOfProducts;
        setNumberOfProducts(++productNumber);

        setSelectedCourses([...selectedCourses, newCourse]);
        setProducts([...products, newCourse]);
        setProductsDiscounts([...productsDiscounts, undefined]);
    }

    function removeCourse(course: Course) {
        setSelectedCourses(selectedCourses.filter(c => c.getProductPosition() !== course.getProductPosition()));
        setProducts(products.filter(p => p.getProductPosition() !== course.getProductPosition()));
        const productIndex = products.findIndex((p: Product) => p.getProductPosition() === course.getProductPosition());
        removeDiscountAtIndex(productIndex);
    }

    useEffect(() => {
        console.log(products);
    }, [products])

    function addPack(pack: Pack) {
        let newPack = pack.clone();
        newPack.productNumber = numberOfProducts;

        let productNumber = numberOfProducts;
        setNumberOfProducts(++productNumber);

        setSelectedPacks([...selectedPacks, newPack]);
        setProducts([...products, newPack]);

        const discount = pack.discounts && pack.discounts[0];

        setProductsDiscounts([...productsDiscounts, discount]);
    }

    function removePack(pack: Pack) {
        setSelectedPacks(selectedPacks.filter(p => p.getProductPosition() !== pack.getProductPosition()));
        const packIndex = products.findIndex(p => p.getProductPosition() !== pack.getProductPosition());
        setProducts(products.filter(p => p.getProductPosition() !== pack.getProductPosition()));
        removeDiscountAtIndex(packIndex);
    }

    function getSinglePaymentValue() {
        return (totals.total / numberOfPayments).toFixed(2);
    }

    function handleStudentChosen(student: Student, selectedProduct: Product) {
        console.log(selectedProduct?.getUniqueId());

        if (selectedProduct instanceof Course && courses) {

            const newSelectedCourses = selectedCourses;
            const modifiedProductIndex = newSelectedCourses.findIndex((data: Course) => data.productNumber === selectedProduct.productNumber);

            if (modifiedProductIndex !== -1) {
                let obj = newSelectedCourses[modifiedProductIndex].clone();
                obj.student = student;
                newSelectedCourses.splice(modifiedProductIndex, 1, obj);

                setSelectedCourses(newSelectedCourses);
            }
        }
        else if (selectedProduct instanceof Pack && packs) {

            const newSelectedPacks = selectedPacks;
            const modifiedProductIndex = newSelectedPacks.findIndex((data: Pack) => data.getProductPosition() === selectedProduct.getProductPosition());

            if (modifiedProductIndex !== -1) {
                let obj = newSelectedPacks[modifiedProductIndex].clone();
                obj.student = student;
                newSelectedPacks.splice(modifiedProductIndex, 1, obj);

                setSelectedPacks(newSelectedPacks);
            }
        }

        setProducts([...selectedCourses, ...selectedPacks]);

    }

    function handleSubmit(event: SyntheticEvent) {
        event.preventDefault();
        console.log("test");

        const emptyProducts = products.filter((p: Product) => !p.hasStudent());

        if (errors.paymentMethod || errors.paymentType ||
            errors.tax || errors.trainingType || products.length === 0 || emptyProducts.length > 0 || !customerOfficeId) {
            messaging.modal.showMessage("Faltam dados", "Preencha os dados em falta");
            return;
        }

        const newEnrollmentInfo = {
            customer: {
                ...customerFieldsValues,
                id: customer ? customer.id : 0,
                student_id: undefined,
                office_id: customerOfficeId,
                customer_type_id: 3,
                city_id: customerFieldsValues.city ?? null,
                carer_id: props.carer ? props.carer.id : undefined,
            },
            purchase: {
                courses: selectedCourses.map((course: Course, pos: number) => Object.assign(course, {
                    discount: productsDiscounts[pos],
                    final_value: course.getPriceAfterDiscount(productsDiscounts[pos]),
                    student_id: course.hasStudent() ? course.student.id : undefined
                })),
                packs: selectedPacks.map((pack, pos) => Object.assign(
                    { ...pack, pack_id: pack.id }, {
                    discount: productsDiscounts[pos],
                    final_value: pack.getPriceAfterDiscount(productsDiscounts[pos]),
                    student_id: pack.hasStudent() ? pack.student.id : undefined
                })),
                payments: paymentDates.map((payDate, pos) =>
                ({
                    description:
                        paymentDates.length > 1 ?
                            `${pos + 1}º pagamento de ${paymentDates.length} prestações` :
                            `Pronto Pagamento.`,
                    payment_method_id: selectedPaymentMethod,
                    value: getSinglePaymentValue(),
                    expiration_date: toISODate(payDate)
                })
                ),
                tax_id: selectedTaxId,
                observations: observations,
                value: totals.total
            },
            schedule: schedule,
            user_id: getStorageInfo().userId,
            training_type_id: trainingType,
            start_date: toISODate(createdDate),
            forecast_time: totals.time,
            expiration_period: expirationPeriod
        };

        console.log(newEnrollmentInfo);

        api.addMassEnrollment(newEnrollmentInfo,
            data => {
                console.log(data);
                const message = carer ? `Nova inscrição adicionada a ${carer.name}` : "Nova inscrição adicionada!";
                const redirect = carer ? `/encarregado-educacao/${carer.id}/dados-pessoais` : "/cliente/" + data.customer + "/dados-pessoais";


                messaging.toast.show(`Nova Inscrição`, message);
                redirection.redirectTo(redirect);
            },
            err => messaging.modal.showMessage("Erro", "Erro ao efetuar a inscrição: " + err.message));

    }

    return (
        <div className="d-flex justify-content-center">
            <Card
                keyId={"purchase_form"}
                title="Nova Inscrição"
                includeHeader={true}
                closeable={true}
                closeHandler={props.close}
                width={900}
                body={
                    <>
                        <form onSubmit={handleSubmit}>
                            {
                                !customer ?
                                    <>
                                        <h5 className="card-sub-title" style={{ margin: "40px 0px 10px 0px" }}>Dados de Faturação</h5>
                                        <div className="d-flex justify-content-center" style={{ margin: "20px 0 10px 0" }}>
                                            <div className=" btn-group btn-group-toggle" data-toggle="buttons">
                                                {
                                                    offices.filter(office => "color" in office).map(office =>
                                                        <label className={`btn btn-primary ${customerOfficeId === office.id ? "active" : ""}`} >
                                                            <input type="radio" name="options" id={`${office.value}`} hidden autoComplete="off"
                                                                onChange={event => {
                                                                    if (event.target.checked) {
                                                                        setCustomerOfficeId(office.id);
                                                                    }
                                                                }} /> {office.name}
                                                        </label>
                                                    )
                                                }
                                            </div>
                                        </div>

                                        <CustomerBasicFieldsForm
                                            fieldsValues={customerFieldsValues}
                                            setFieldsValues={setCustomerFieldsValues}
                                            cities={cities} />

                                    </>
                                    : ""
                            }
                            <h5 className="card-sub-title" style={{ margin: "40px 0px 10px 0px" }}>Cursos</h5>
                            <div className="d-flex" >
                                <div>
                                    <div>Escolher cursos:</div>
                                    <MultipleCourseCombo
                                        value={selectedCourses.map(data => data)}
                                        availableCourses={courses}
                                        autoFetchCourses={false}
                                        courseAdded={addCourse}
                                        courseRemoved={removeCourse}
                                        defaultText="Adicione um curso à inscrição" />
                                </div>
                                <div style={{ marginLeft: "30px" }}>
                                    <div>Escolher packs:</div>
                                    <MultiplePackCombo
                                        value={selectedPacks}
                                        availablePacks={packs}
                                        autoFetchPacks={false}
                                        packAdded={addPack}
                                        packRemoved={removePack}
                                        defaultText="Adicione um pack à inscrição" />
                                </div>
                                <div style={{ marginLeft: "30px" }}>
                                    <div>Taxa:</div>
                                    <GenericCombo
                                        value={selectedTaxId}
                                        values={taxes}
                                        getId={tax => tax.id}
                                        getOptionContent={tax => tax.title}
                                        selectionChanged={taxId => setSelectedTaxId(taxId as number)}
                                        showError={errors.tax} />
                                </div>
                            </div>
                            <div style={{ marginTop: "15px" }}>
                                <MassProductsTable
                                    products={products}
                                    productsDiscounts={productsDiscounts}
                                    discounts={discounts}
                                    changeProductDiscount={changeProductDiscount}
                                    handleStudentChange={handleStudentChosen}
                                    totals={totals} />
                            </div>
                            <h5 className="card-sub-title" style={{ margin: "40px 0px 10px 0px" }}>Pagamentos</h5>
                            <div className="row">
                                <div className="col m-2">
                                    <label>Forma de pagamento</label>
                                    <GenericCombo
                                        value={selectedPaymentMethod}
                                        values={paymentMethods}
                                        getId={paymentMethod => paymentMethod.id}
                                        getOptionContent={paymentMethod => paymentMethod.title}
                                        selectionChanged={id => setSelectedPaymentMethod(id as number)}
                                        showError={errors.paymentMethod} />
                                </div>
                                <div className="col m-2">
                                    <label>Tipo de pagamento</label>
                                    <GenericCombo
                                        value={selectedPaymentType}
                                        values={PaymentTypes}
                                        getId={paymentType => paymentType.id}
                                        getOptionContent={paymentType => paymentType.name}
                                        selectionChanged={id => setSelectedPaymentType(id as number)}
                                        showError={errors.paymentType} />
                                </div>
                                {selectedPaymentType === 2 &&
                                    <div className="col m-2">
                                        <label>Número de pagamentos</label>
                                        <GenericCombo
                                            value={numberOfPayments}
                                            values={availableNumberOfPayments}
                                            getId={paymentType => paymentType}
                                            getOptionContent={paymentType => paymentType}
                                            selectionChanged={num => setNumberOfPayments(num as number)} />
                                    </div>
                                }
                            </div>
                            {selectedPaymentType === 2 &&
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <CreatePaymentsTable
                                                dateChangedHandler={changePaymentDate}
                                                amountChangedHandler={changePaymentAmount}
                                            />

                                        </div>
                                    </div>
                                </div>
                            }

                            <h5 className="card-sub-title" style={{ margin: "40px 0px 10px 0px" }}>Outras informações</h5>
                            <div className="row">
                                <div className="col m-2">
                                    <label>Tipo de formação</label>
                                    <ContactForTrainingTypeCombo
                                        value={trainingType}
                                        selectionChanged={newTrainingType => setTrainingType(newTrainingType)}
                                        showError={errors.trainingType} />
                                </div>
                                <div className="col m-2">
                                    <label htmlFor="obs">Data de inscrição:</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={toInputDate(createdDate)}
                                        onChange={event => setCreatedDate(new Date(event.target.value))}
                                        required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col m-2">
                                    <label htmlFor="obs">Horário:</label>
                                    <textarea
                                        className="form-control"
                                        id="obs"
                                        rows={2}
                                        value={schedule}
                                        onChange={event => setSchedule(event.target.value)}></textarea>
                                </div>
                                <div className="col m-2">
                                    <label htmlFor="expiration_period">Periodo de Validade:</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="expiration_period"
                                        value={expirationPeriod}
                                        onChange={event => setExpirationPeriod(parseInt(event.target.value))} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col m-2">
                                    <label htmlFor="obs">Observações:</label>
                                    <textarea
                                        className="form-control"
                                        id="obs"
                                        rows={2}
                                        value={observations}
                                        onChange={event => setObservations(event.target.value)}></textarea>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button
                                    className="btn btn-success mt-10"
                                    type="submit"
                                    style={{ padding: "5px 30px" }}>Inscrever</button>
                            </div>
                        </form>
                    </>
                } />
        </div>
    );
}