import Contact from "../interfaces/Contact.interface";
import Payment from "../interfaces/Training.interface";
import TrainingStatus from "../interfaces/TrainingStatus.interface";

export function buildContact(contact: any): Contact {
    const newContact = Object.assign(contact);
    newContact.date = new Date(contact.date);
    if (contact.last_follow_up_date) {
        newContact.lastFollowUpDate = new Date(contact.last_follow_up_date);
    }
    return newContact;
}

export function buildTraining(training: any): Payment {
    const newTraining = Object.assign(training);
    newTraining.startDate = training.start_date ? new Date(training.start_date) : null;
    newTraining.endDate = training.end_date ? new Date(training.end_date) : null;
    newTraining.createdDate = training.created_date ? new Date(training.created_date) : null;
    newTraining.expirationDate = training.expiration_date ? new Date(training.expiration_date) : null;
    newTraining.totalTime = parseInt(training.total_time);
    newTraining.monthExtension = parseInt(training.month_extension);
    newTraining.trainingStatusTypeId = parseInt(training.training_status_type_id);
    newTraining.trainingTypeId = parseInt(training.training_type_id);
    newTraining.certificateId = training.certificate_id ? parseInt(training.certificate_id) : null;

    newTraining.status = training.status ? training.status.map((status: TrainingStatus) => buildTrainingStatus(status)) : null;

    return newTraining;
}


export function buildTrainingStatus(trainingStatus: any): TrainingStatus {

    const newTrainingStatus = Object.assign(trainingStatus);
    newTrainingStatus.id = parseInt(trainingStatus.id);
    newTrainingStatus.createdDate = trainingStatus.created_date ? new Date(trainingStatus.created_date) : null;
    newTrainingStatus.date = trainingStatus.date ? new Date(trainingStatus.date) : null;

    newTrainingStatus.userId = parseInt(trainingStatus.user_id);
    newTrainingStatus.user = trainingStatus.user;

    newTrainingStatus.trainingStatusTypeId = parseInt(trainingStatus.training_status_type_id);
    newTrainingStatus.trainingStatusType = trainingStatus.training_status_type;
    newTrainingStatus.observations = trainingStatus.observations;

    return newTrainingStatus;
}

