import './listcard.styles.css';

type StudentListCardProp = {
    body: string | React.ReactElement,
}

export default function ListCard(props: StudentListCardProp) {

    const { body } = props;

    return (

        <div className="card">
            <div className="card-body">
                {body}
            </div>
        </div>
    );
}