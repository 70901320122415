import { CSSProperties, useState, useEffect } from "react";
import Carer from "../../../models/Carer.model";
import { toDatePT, toInputDate } from "../../../utils/dates";
import Card from "../../shared/Card";
import styles from "../../shared/Card.module.css";
import ConfirmCancelIcons from "../../shared/ConfirmCancelIcons";
import Loader from "../../shared/Loader";
import { StudentCarerFields } from "../forms/StudentForm";
import { api } from "../../../services/api";
import GenericCombo from "../../shared/GenericCombo";
import City from "../../../interfaces/City.interface";
import IdentificationType from "../../../interfaces/IdentificationType.interface";
import Nationality from "../../../interfaces/Nationality.interface";
import CityCombo from "../../combos/CityCombo";

type StudentCarerCardProps = {
    value: Carer;
    style?: CSSProperties;
    handleCarerChange: (carer: Carer) => void;
}

export default function StudentCarerCard(props: StudentCarerCardProps) {
    const carer = props.value;

    const [editMode, setEditMode] = useState(false);
    const [editing, setEditing] = useState(false);

    const [cities, setCities] = useState<City[] | undefined>(undefined);
    const [nationalities, setNationalities] = useState<Nationality[] | undefined>(undefined);
    const [identificationTypes, setIdentificationTypes] = useState<IdentificationType[] | undefined>(undefined);

    const [fieldsValues, setFieldsValues] = useState<StudentCarerFields>({
        name: carer?.name,
        email: carer?.email,
        phone: carer?.phone,
        address: carer?.address,
        postal_code: carer?.postalCode,
        city_id: carer?.cityId,
        fiscal_number: carer?.fiscalNumber,
        identification_number: carer?.identificationNumber,
        identification_date: toInputDate(carer?.identificationDate),
        identification_entity: carer?.identificationEntity,
        identification_type_id: carer?.identificationTypeId,
        naturality: carer?.naturality,
        nationality_id: carer?.nationalityId,
        observations: carer?.observations,
        birth_date: carer?.birthDate ? toInputDate(carer?.birthDate) : undefined,
    });


    useEffect(() => {
        if (editMode && (!cities || !nationalities || !identificationTypes)) {
            api.getCarerComboboxesInfo((data: any) => {
                setCities(data.cities);
                setIdentificationTypes(data.identification_types);
                setNationalities(data.countries);
            }, err => {
                setCities([]);
                setIdentificationTypes([]);
                setNationalities([]);
            });
        }
    }, [editMode, cities, nationalities, identificationTypes]);

    useEffect(() => {
        console.log(fieldsValues);
    }, [fieldsValues])

    function handleConfirm() {
        setEditing(true);

        const carerInfo = {
            id: carer.id,
            name: fieldsValues.name,
            email: fieldsValues.email,
            phone: fieldsValues.phone,
            address: fieldsValues.address,
            postal_code: fieldsValues.postal_code,
            city_id: fieldsValues.city_id,
            fiscal_number: fieldsValues.fiscal_number,
            identification_number: fieldsValues.identification_number,
            identification_date: fieldsValues.identification_date,
            identification_entity: fieldsValues.identification_entity,
            identification_type_id: fieldsValues.identification_type_id,
            naturality: fieldsValues.naturality,
            nationality_id: fieldsValues.nationality_id,
            observations: fieldsValues.observations,
            birth_date: fieldsValues.birth_date,
        }

        const updatedCarer = new Carer(carerInfo);
        props.handleCarerChange(updatedCarer);

        setEditing(false);
        setEditMode(false);
    }

    function handleIdentificationTypeChange(identificationType: number | string | IdentificationType) {
        const newIdentificationTypeId = identificationType as number;
        setFieldsValues({ ...fieldsValues, identification_type_id: newIdentificationTypeId });
    }

    function handleNationalityChange(nationality: number | string | Nationality) {
        const newNationalityId = nationality as number;
        setFieldsValues({ ...fieldsValues, nationality_id: newNationalityId });
    }


    return (
        <Card
            keyId={carer.id.toString()}
            style={props.style || {}}
            title={
                <div>Encarregado de Educação
                    {editMode ?
                        (editing ?
                            <Loader
                                outerClassName=""
                                outterStyle={{ display: "inline" }}
                                style={{ marginLeft: "10px" }}
                                size="small" /> :
                            <ConfirmCancelIcons
                                confirmHandler={handleConfirm}
                                cancelHandler={() => setEditMode(false)} />
                        ) :
                        <i
                            className="fas fa-pen action-icon"
                            style={{ margin: "5px 0 0 10px" }}
                            title="Editar"
                            onClick={() => setEditMode(true)}></i>
                    }
                </div>
            }
            includeHeader={true}
            headerStyle={{ fontSize: "1.1rem" }}
            body={
                <table className="table table-striped">
                    <tbody>
                        <tr>
                            <td className={styles.titleField}>Nome</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        value={fieldsValues.name || ""}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, name: event.target.value }
                                        )} /> :
                                    carer.name}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Morada</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="address"
                                        value={fieldsValues.address || ""}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, address: event.target.value }
                                        )} /> :
                                    carer.address}
                            </td>
                        </tr>
                        {
                            carer.birthDate ?
                                <tr>
                                    <td className={styles.titleField}>Data de Nascimento</td>
                                    <td className={styles.infoField}>
                                        {editMode ?
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="birth_date"
                                                value={fieldsValues.birth_date || ""}
                                                onChange={event => setFieldsValues(
                                                    { ...fieldsValues, birth_date: event.target.value }
                                                )} />
                                            :
                                            toDatePT(carer.birthDate)}
                                    </td>
                                </tr>
                                : null
                        }
                        <tr>
                            <td className={styles.titleField}>Código Postal</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <div className="d-flex">
                                        <div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="postal_code"
                                                value={fieldsValues.postal_code || ""}
                                                onChange={event => setFieldsValues(
                                                    { ...fieldsValues, postal_code: event.target.value }
                                                )} />
                                        </div>
                                        <div>
                                            <CityCombo
                                                value={cities?.find((c: City) => c.id === fieldsValues.city_id)}
                                                availableCities={cities}
                                                selectionChanged={(city: City | undefined) => setFieldsValues({ ...fieldsValues, city_id: city?.id })}
                                                cityInserted={((list: City[]) => setCities([...list]))} />
                                        </div>
                                    </div> :
                                    carer.postalCode + " " + (cities?.find((city: City) => city.id === carer.cityId)?.title || "")
                                }
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Telefone</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="phone"
                                        value={fieldsValues.phone || ""}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, phone: event.target.value }
                                        )} /> :
                                    carer.phone}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Email</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="email"
                                        value={fieldsValues.email || ""}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, email: event.target.value }
                                        )} /> :
                                    carer.email}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>NIF</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="fiscal_number"
                                        value={fieldsValues.fiscal_number || ""}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, fiscal_number: parseInt(event.target.value) }
                                        )} /> :
                                    carer.fiscalNumber}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Nº Identificação</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="identification_number"
                                        value={fieldsValues.identification_number || ""}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, identification_number: event.target.value }
                                        )} /> :
                                    carer.identificationNumber}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Entidade de Identificação</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="identification_entity"
                                        value={fieldsValues.identification_entity || ""}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, identification_entity: event.target.value }
                                        )} /> :
                                    carer.identificationEntity}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Data de Idenficação</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="fiscal_number"
                                        value={fieldsValues.identification_date || ""}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, identification_date: event.target.value }
                                        )} />
                                    :
                                    toDatePT(carer.identificationDate)}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Tipo de Idenficação</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <GenericCombo
                                        value={fieldsValues.identification_type_id}
                                        values={identificationTypes}
                                        getId={identificationType => identificationType.id}
                                        getOptionContent={identificationType => identificationType.title}
                                        selectionChanged={handleIdentificationTypeChange} />
                                    :
                                    identificationTypes?.find((idType: IdentificationType) => idType.id === carer.identificationTypeId)?.title
                                }
                            </td>
                        </tr>
                        {carer.nationality || editMode ?
                            <tr>
                                <td className={styles.titleField}>Nacionalidade</td>
                                <td className={styles.infoField}>
                                    {editMode ?
                                        <GenericCombo
                                            value={fieldsValues.nationality_id}
                                            values={nationalities}
                                            getId={nationality => nationality.id}
                                            getOptionContent={nationality => nationality.title}
                                            selectionChanged={handleNationalityChange} />
                                        :
                                        nationalities?.find((nat: Nationality) => nat.id === carer.nationalityId)?.title}
                                </td>
                            </tr>
                            : null
                        }
                        {carer.naturality || editMode ?
                            <tr>
                                <td className={styles.titleField}>Naturalidade</td>
                                <td className={styles.infoField}>
                                    {editMode ?
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="naturality"
                                            value={fieldsValues.naturality}
                                            onChange={event => setFieldsValues(
                                                { ...fieldsValues, naturality: event.target.value }
                                            )} /> :
                                        carer.naturality}
                                </td>
                            </tr>
                            : null
                        }
                        {carer.observations || editMode ?
                            <tr>
                                <td className={styles.titleField}>Observações</td>
                                <td className={styles.infoField}>
                                    {editMode ?
                                        <textarea
                                            name="observations"
                                            className="form-control"
                                            id="observations"
                                            value={fieldsValues.observations || ""}
                                            onChange={event => setFieldsValues(
                                                { ...fieldsValues, observations: event.target.value }
                                            )}

                                        ></textarea>
                                        :
                                        carer.observations}
                                </td>
                            </tr>
                            : null
                        }
                    </tbody>
                </table>
            }
            width={500} />
    );
}