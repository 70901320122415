import Customer from "./Customer.model";
import Enrollment from "./Enrollment";
import Payment from "./Payment.model";

export default class Purchase {
    public id: number;
    public createdDate: Date;
    public title: string;
    public value: number;
    public paymentDescription: string;
    public observations: string;
    public name: string;
    public schedule: string;
    public startDate: Date;
    public expectedTime: number;
    public studentId: number | undefined;
    public studentName: string | undefined;
    public studentUsername: string | undefined;
    public studentPassword: string | undefined;
    public tax: string | undefined;
    public office: string | undefined;
    public officeId: number | undefined;
    public enrollments: Enrollment[];
    public payments: Payment[];
    public customer: Customer;
    public trainingTypeId: number | undefined;
    public expirationPeriod: number;

    constructor(data: any) {
        this.id = data.id;
        this.createdDate = new Date(data.created_date);
        this.title = data.title;
        this.value = data.value;
        this.paymentDescription = data.payment_description;
        this.observations = data.observations;
        this.name = data.name;
        this.schedule = data.schedule;
        this.startDate = new Date(data.start_date);
        this.expectedTime = data.forecast_time;
        this.officeId = data.office_id;
        this.office = data.office;
        this.tax = data.tax;
        this.studentId = data.student_id;
        this.studentName = data.student;
        this.studentUsername = data.username;
        this.studentPassword = data.student_password;
        this.expirationPeriod = data.expiration_period;

        this.trainingTypeId = data.training_type;

        this.enrollments = data.enrollments ? data.enrollments.map((val: any) => new Enrollment(val)) : undefined;
        this.payments = data.payments ? data.payments.map((val: any) => new Payment(val)) : undefined;
        this.customer = new Customer(data.customer ?? {});
    }

    clone(): Purchase {
        const newPurchase = new Purchase({});

        newPurchase.id = this.id;
        newPurchase.createdDate = this.createdDate;
        newPurchase.title = this.title;
        newPurchase.value = this.value;
        newPurchase.paymentDescription = this.paymentDescription;
        newPurchase.observations = this.observations;
        newPurchase.name = this.name;
        newPurchase.schedule = this.schedule;
        newPurchase.startDate = this.startDate;
        newPurchase.expectedTime = this.expectedTime;
        newPurchase.studentName = this.studentName;

        newPurchase.enrollments = this.enrollments;
        newPurchase.payments = this.payments;
        newPurchase.customer = this.customer;
        newPurchase.expirationPeriod = this.expirationPeriod;
        return newPurchase;
    }

    public description(): string {
        return this.enrollments
            .map(enroll => enroll.isPack() ? enroll.pack : enroll.course)
            .join(", ");
    }

    public totalTime(): number {
        return this.enrollments.reduce((acc, curr) => acc + curr.expectedTime, 0);
    }
}