import { civilStates, genders } from "../constants";

export default class Student {
    public id: number;
    public name: string;
    public phone: string;
    public email: string;
    public address: string;
    public postalCode: string;
    public cityId: number | undefined;
    public city: string;
    public birthDate: Date;
    public username: string;
    public password: string;
    public fiscalNumber: number | undefined;
    public identificationNumber: string;
    public identificationDate: Date;
    public identificationEntity: string;
    public identificationTypeId: number | undefined;
    public identificationType: string;
    public naturality: string;
    public nationalityId: number | undefined;
    public nationality: string;
    public educationId: number | undefined;
    public education: string;
    public educationAreaId: number | undefined;
    public educationArea: string;
    public occupationId: number | undefined;
    public occupation: string;
    public gender: string;
    public createdDate: Date;
    public officeId: number;
    public office: string;
    public divulgationId: number | undefined;
    public divulgation: string;
    public observations: string;
    public carer_id: number | undefined;
    public civilState: string | undefined;
    public documentCC: boolean | undefined;
    public documentCV: boolean | undefined;
    public documentCH: boolean | undefined;
    public courses: { id: number, title: string }[];

    constructor(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.address = data.address;
        this.postalCode = data.postal_code;
        this.email = data.email;
        this.phone = data.phone;
        this.cityId = data.city_id;
        this.city = data.city;
        this.office = data.office;
        this.birthDate = new Date(data.birth_date);
        this.fiscalNumber = data.fiscal_number;
        this.identificationDate = new Date(data.identification_date);
        this.identificationNumber = data.identification_number;
        this.identificationEntity = data.identification_entity;
        this.identificationTypeId = data.identification_type_id;
        this.identificationType = data.identification_type;
        this.naturality = data.naturality;
        this.nationalityId = data.nationality_id;
        this.nationality = data.nationality;
        this.educationId = data.education_id;
        this.education = data.education;
        this.educationAreaId = data.education_area_id;
        this.educationArea = data.education_area;
        this.occupationId = data.occupation_id;
        this.occupation = data.occupation;
        this.gender = data.gender;
        this.courses = data.courses;
        this.createdDate = new Date(data.created_date);
        this.officeId = data.office_id;
        this.office = data.office;
        this.username = data.username;
        this.password = data.password;
        this.divulgation = data.divulgation;
        this.divulgationId = data.divulgation_id;
        this.observations = data.observations;
        this.civilState = data.civil_state;
        this.documentCC = data.document_cc === 1;
        this.documentCV = data.document_cv === 1;
        this.documentCH = data.document_ch === 1;
    }

    clone(): Student {
        const newStudent = new Student({});
        newStudent.id = this.id;
        newStudent.name = this.name;
        newStudent.address = this.address;
        newStudent.postalCode = this.postalCode;
        newStudent.email = this.email;
        newStudent.phone = this.phone;
        newStudent.cityId = this.cityId;
        newStudent.city = this.city;
        newStudent.office = this.office;
        newStudent.birthDate = this.birthDate;
        newStudent.fiscalNumber = this.fiscalNumber;
        newStudent.identificationDate = this.identificationDate;
        newStudent.identificationNumber = this.identificationNumber;
        newStudent.identificationEntity = this.identificationEntity;
        newStudent.identificationTypeId = this.identificationTypeId;
        newStudent.identificationType = this.identificationType;
        newStudent.naturality = this.naturality;
        newStudent.nationalityId = this.nationalityId;
        newStudent.nationality = this.nationality;
        newStudent.educationId = this.educationId;
        newStudent.education = this.education;
        newStudent.educationAreaId = this.educationAreaId;
        newStudent.educationArea = this.educationArea;
        newStudent.occupationId = this.occupationId;
        newStudent.occupation = this.occupation;
        newStudent.gender = this.gender;
        newStudent.courses = this.courses;
        newStudent.createdDate = this.createdDate;
        newStudent.officeId = this.officeId;
        newStudent.office = this.office;
        newStudent.username = this.username;
        newStudent.password = this.password;
        newStudent.divulgation = this.divulgation;
        newStudent.divulgationId = this.divulgationId;
        newStudent.observations = this.observations;
        newStudent.documentCC = this.documentCC;
        newStudent.documentCV = this.documentCV;
        newStudent.documentCH = this.documentCH;

        return newStudent;
    }

    getGenderDescription() {
        if (this.gender) {
            return genders.find(gender => gender.val === this.gender)?.name;
        }

        return "";
    }
    
    getCivilStateDescription() {
        if (this.civilState) {
            return civilStates.find(state => state.val === this.civilState)?.name;
        }

        return "";
    }
}