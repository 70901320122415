export default class Enrollment {
    public id: number;
    public finalValue: number;
    public createdDate: Date;
    public initialValue: number;
    public percentage: number;
    public expectedTime: number;
    public discountId: number;
    public discount: string;
    public courseId: number;
    public course: string;
    public packId?: number;
    public pack?: string;
    public studentId: number | undefined;
    public student: string | undefined;

    public courses?: { id: number, title: string }[];

    public constructor(data: any) {
        this.id = data.id;
        this.finalValue = data.final_value;
        this.createdDate = new Date(data.created_date);
        this.initialValue = data.initial_value;
        this.percentage = data.percentage;
        this.expectedTime = data.forecast_time;
        this.discountId = data.discount_id;
        this.discount = data.discount;
        this.courseId = data.course_id;
        this.course = data.course;
        this.pack = data.pack;
        this.packId = data.pack_id;
        this.courses = data.courses;
        this.studentId = data.student_id;
        this.student = data.student;
    }

    public hasDiscount(): boolean {
        return this.finalValue !== this.initialValue;
    }

    public discountValue(): number {
        return this.initialValue - this.finalValue;
    }

    public isPack() {
        return typeof this.packId !== "undefined" && this.packId !== null;
    }
}