import { useContext, useState } from "react";
import { CertificateFace } from "../../constants";
import Certificate from "../../interfaces/Certificate.interface";
import { PrintingContext } from "../App";
import './CertificateComponent.styles.css';
import PrintCertificate from "./PrintCertificate";

type CertificateProps = {
    certificate: Certificate;
}

export function removeEmptySpaces(html: string) {
    return html
        .replaceAll(`<p align="center">&nbsp;</p>`, "")
        .replaceAll(`<p>&nbsp;</p>`, "");
}


export default function CertificateComponent(props: CertificateProps) {
    const { certificate } = props;
    const [faceShowing, setFaceShowing] = useState<CertificateFace>(CertificateFace.Front);
    const printing = useContext(PrintingContext);

    function printCertificate() {
        if (certificate) {
            printing.showDocument(<PrintCertificate
                face={faceShowing}
                certificate={certificate}
                close={printing.close}
            />);
        }
    }

    return (
        <div id="section-to-print" className={`certificateContainer ${faceShowing === CertificateFace.Back ? "large-certificate" : ""}`} style={{
            backgroundImage: `url('${process.env.REACT_APP_IMAGES_URL}/marca_agua.jpg')`
        }}>
            <div className={`cursor-pointer rotate-icon`} onClick={() => {
                setFaceShowing(faceShowing === CertificateFace.Front ? CertificateFace.Back : CertificateFace.Front);
            }}>
                Rodar: <i className="fas fa-sync-alt"></i>
            </div>
            <i className="fas fa-print print-icon cursor-pointer" onClick={() => {
                printCertificate();
            }}></i>
            <header className="d-flex justify-content-center">
                <img src={`${process.env.REACT_APP_IMAGES_URL}/fb-pita-logo.png`} alt="" />
            </header>
            <div dangerouslySetInnerHTML={{ __html: removeEmptySpaces(faceShowing === CertificateFace.Front ? certificate.front : certificate.back) }} />
        </div>
    );
}