export default class User {
    public id: number;
    public name: string;
    public username: string;
    public password: string;
    public active: boolean;
    public officeId: number;
    public userTypeId: number;
    public userType: string;
    public picture: string;
    public lastLoginDate: Date;
    public online: boolean;
    public numTries: number;

    constructor(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.username = data.username;
        this.password = data.password;
        this.active = data.active;
        this.officeId = data.office_id;
        this.userTypeId = data.user_type_id;
        this.userType = data.user_type;
        this.picture = data.picture;
        this.lastLoginDate = data.last_login_date ? new Date(data.last_login_date) : new Date();
        this.online = data.online || false;
        this.numTries = data.num_tries || 3;
    }
}
