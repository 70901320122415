import moment from "moment";

export default function DateTime(props: { value: string | undefined, valueChanged: (newVal: string) => void }) {
    let date = "";
    let time = "";
    if (props.value){
        const [propDate, propTime] = props.value.split("T");
        date = propDate;
        time = propTime;
    }
    
    function dateChanged(event: any){
       const newDate = event.target.value;
       props.valueChanged(newDate + (time ? "T" + time : ""));
    }

    function timeChanged(event: any){
       const newTime = event.target.value;
       props.valueChanged(moment(date).format("YYYY-MM-DD")  + "T" + newTime);
    }

    return (
        <div className="row">
            <div className="col">
                <div className="form-group">
                    <label htmlFor="date">Data</label>
                    <input
                        type="date"
                        className="form-control"
                        id="date"
                        value={date}
                        onChange={dateChanged}
                        required />
                </div>
            </div>
            <div className="col">
                <div className="form-group">
                    <label htmlFor="time">Hora</label>
                    <input
                        type="time"
                        className="form-control"
                        id="time"
                        min="09:00"
                        max="21:00"
                        step="1800"
                        value={time}
                        onChange={timeChanged}
                        required />
                </div>
            </div>
        </div>
    );
}