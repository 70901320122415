import { useState, useEffect, SyntheticEvent, useContext } from "react";
import Divulgation from "../../../interfaces/Divulgation.interface";
import { Education } from "../../../interfaces/Education.interface";
import { EducationArea } from "../../../interfaces/EducationArea.interface";
import IdentificationType from "../../../interfaces/IdentificationType.interface";
import Nationality from "../../../interfaces/Nationality.interface";
import Occupation from "../../../interfaces/Occupation";
import { api, getStorageInfo } from "../../../services/api";
import ActionButton from "../../shared/ActionButton";
import StudentDataForm from "./StudentDataForm";
import StudentEducationForm from "./StudentEducationForm";
import StudentIdentificationForm from "./StudentIdentificationForm";
import StudentFormabaseForm from "./StudentFormabaseForm";
import StudentOccupationForm from "./StudentOccupationForm";
import { MessagingContext } from "../../App";
import { RedirectionContext } from "../../MainRouter";
import { useLocation } from "react-router-dom";
import Contact from "../../../interfaces/Contact.interface";
import { FreeSession } from "../../../models/FreeSession.model";
import { verifyErrors } from "../../../utils/generic";
import { officesHash } from "../../../constants";

export type StudentDataFields = {
    name: string;
    email: string;
    phone: string;
    address: string;
    postal_code: string;
    cityId: number | undefined;
    birth_date: string;
    gender: string | undefined;
    civilState: string | undefined;
    documentCC: boolean | undefined;
    documentCV: boolean | undefined;
    documentCH: boolean | undefined;
};

export type StudentCarerFields = {
    name: string;
    email: string;
    phone: string;
    address: string;
    postal_code: string;
    city_id: number | undefined;
    fiscal_number: number | undefined;
    identification_number: string;
    identification_date: string;
    identification_entity: string;
    identification_type_id: number | undefined;
    naturality: string | undefined;
    nationality_id: number | undefined;
    birth_date: string | undefined;
    observations: string;
};

export type StudentEducationFields = {
    educationId: number | undefined;
    educationAreaId: number | undefined;
};

export type StudentFormabaseFields = {
    office: number | undefined;
    divulgationId: number | undefined;
    username: string;
    password: string;
    observations: string;
};

export type StudentOccupationFields = {
    occupationId: number | undefined;
};

export type StudentIdenticationFields = {
    fiscal_number: string;
    identification_number: string;
    identification_date: string;
    identification_entity: string;
    identificationTypeId: number | undefined;
    naturality: string;
    nationalityId: number | undefined;
};

export type StudentFields =
    StudentDataFields &
    StudentIdenticationFields &
    StudentFormabaseFields &
    StudentOccupationFields &
    StudentEducationFields;

export type StudentErrors = {
    office: boolean;
    gender: boolean;
}

const emptyFields: StudentFields = {
    name: "",
    address: "",
    postal_code: "",
    cityId: undefined,
    phone: "",
    email: "",
    birth_date: "",
    fiscal_number: "",
    identification_number: "",
    identification_date: "",
    identification_entity: "",
    identificationTypeId: undefined,
    naturality: "",
    nationalityId: undefined,
    educationId: undefined,
    educationAreaId: undefined,
    occupationId: undefined,
    divulgationId: undefined,
    username: "",
    password: "",
    gender: undefined,
    observations: "",
    office: undefined,
    civilState: undefined,
    documentCC: false,
    documentCV: false,
    documentCH: false,
};

export function mapStudentFieldsToApi(obj: any): any {
    const finalObj: any = {};
    for (let key of Object.keys(obj)) {
        finalObj[mapStudentFieldToApiField(key)] = obj[key];
    }
    return finalObj;
}

export function mapStudentFieldToApiField(field: string) {
    const mappings: any = {
        "identificationTypeId": "identification_type_id",
        "educationId": "education_id",
        "educationAreaId": "education_area_id",
        "divulgationId": "divulgation_id",
        "office": "office_id",
        "occupationId": "occupation_id",
        "cityId": "city_id",
        "nationalityId": "nationality",
        "civilState": "civil_state",
        "documentCC": "document_cc",
        "documentCV": "document_cv",
        "documentCH": "document_ch",
    };
    return field in mappings ? mappings[field] : field;
}

export default function StudentForm() {
    const messaging = useContext(MessagingContext);
    const redirection = useContext(RedirectionContext);

    const [fieldsValues, setFieldsValues] = useState(emptyFields);
    const [errors, setErrors] = useState<StudentErrors>({
        office: false,
        gender: false
    });

    const [pending, setPending] = useState(false);
    const [educations, setEducations] = useState<Education[]>([]);
    const [educationAreas, setEducationAreas] = useState<EducationArea[]>([]);
    const [nationalities, setNationalities] = useState<Nationality[]>([]);
    const [divulgations, setDivulgations] = useState<Divulgation[]>([]);
    const [occupations, setOccupations] = useState<Occupation[]>([]);
    const [identificationTypes, setIdentificationTypes] = useState<IdentificationType[]>([]);
    const [contactId, setContactId] = useState<number | undefined>();
    const [freeSessionId, setFreeSessionId] = useState<number | undefined>();
    const [titleInfo, setTitleInfo] = useState("");

    useEffect(() => {
        api.getStudentComboboxesInfo((data: any) => {
            setEducations(data.educations);
            setEducationAreas(data.education_areas);
            setNationalities(data.nationalities);
            setDivulgations(data.divulgations);
            setOccupations(data.occupations);
            setIdentificationTypes(data.identification_types);
        }, err => {

        });
    }, []);

    const location = useLocation();
    useEffect(() => {
        if (location.search.length > 0) {
            const contactIdRegexResult = location.search.match(/contacto=(\d+)/);
            if (contactIdRegexResult && contactIdRegexResult[1]) {
                const id = contactIdRegexResult[1];

                api.getContacts({ id: id }, contactData => {
                    const contact = contactData as Contact;
                    setContactId(contact.id);
                    setFieldsValues(fieldsValues => ({
                        ...fieldsValues,
                        name: contact.name,
                        email: contact.email,
                        phone: contact.phone,
                        office: contact.office_id
                    }));
                    setTitleInfo("a partir do contacto de " + contact.name);
                }, err => {
                    messaging.modal.showMessage("Erro",
                        "Erro a obter informação do contacto: " + err.message);
                });
            }

            const freeSessionIdRegexResult = location.search.match(/sessao-gratuita=(\d+)/);
            if (freeSessionIdRegexResult && freeSessionIdRegexResult[1]) {
                const id = freeSessionIdRegexResult[1];

                api.getFreeSessions({ id: id }, freeSessionData => {
                    const freeSession = new FreeSession(freeSessionData);
                    setFreeSessionId(freeSession.id);

                    setFieldsValues(fieldsValues => ({
                        ...fieldsValues,
                        name: freeSession.name,
                        office: freeSession.officeId
                    }));
                    setTitleInfo("a partir da sessão gratuita de " + freeSession.name);
                }, err => {
                    messaging.modal.showMessage("Erro",
                        "Erro a obter informação da sessão gratuita: " + err.message);
                });
            }
        }
    }, [location, messaging.modal]);

    useEffect(() => {
        setErrors({
            office: fieldsValues.office === undefined,
            gender: fieldsValues.gender === undefined
        });
    }, [fieldsValues]);

    function handleSubmit(event: SyntheticEvent) {
        event.preventDefault();

        const errorFound = verifyErrors(errors as StudentErrors);
        if (errorFound) {
            messaging.toast.show("Erro", `Preencha o campo: ${errorFound}`);
            return;
        }

        const studentInfo: any = {
            name: fieldsValues.name,
            address: fieldsValues.address,
            postal_code: fieldsValues.postal_code,
            city_id: fieldsValues.cityId,
            phone: fieldsValues.phone,
            email: fieldsValues.email,
            birth_date: fieldsValues.birth_date || null,
            fiscal_number: fieldsValues.fiscal_number,
            identification_number: fieldsValues.identification_number,
            identification_date: fieldsValues.identification_date || null,
            identification_entity: fieldsValues.identification_entity,
            identification_type_id: fieldsValues.identificationTypeId,
            naturality: fieldsValues.naturality,
            nationality: fieldsValues.nationalityId,
            education_id: fieldsValues.educationId,
            education_area_id: fieldsValues.educationAreaId,
            occupation_id: fieldsValues.occupationId || null,
            divulgation_id: fieldsValues.divulgationId,
            username: fieldsValues.username,
            password: fieldsValues.password,
            gender: fieldsValues.gender,
            observations: fieldsValues.observations,
            office_id: fieldsValues.office,
            user_id: getStorageInfo().userId,
            contact_id: contactId,
            free_session_id: freeSessionId,
            civil_state: fieldsValues.civilState,
            document_cc: fieldsValues.documentCC,
            document_cv: fieldsValues.documentCV,
            document_ch: fieldsValues.documentCH,
        };


        messaging.modal.showFull({
            title: "Adicionar",
            body: `Deseja criar o novo aluno(a) automaticamente no Programa de Marcações de ${officesHash[fieldsValues.office ?? 0]?.name} ?`,
            closeName: "Não",
            closeHandler: () => {
                messaging.modal.close();
                addStudentRequest(studentInfo);
            },
            actionName: "Sim",
            actionHandler: () => {
                messaging.modal.close();
                addStudentRequest({ ...studentInfo, add_regional: true });
            },
            actionClassName: "btn-danger",
            showAction: true
        });


    }

    function addStudentRequest(studentInfo: object) {
        setPending(true);

        api.addStudent(studentInfo, id => {
            setPending(false);
            redirection.redirectTo("/alunos/" + id);
        }, () => {
            setPending(false);
            messaging.modal.showMessage("Erro", "Erro ao inserir aluno");
        });
    }

    return (
        <div className="container p-4">
            <h2 className="text-center m-4">Novo Aluno {titleInfo}</h2>
            <form onSubmit={handleSubmit}>
                <StudentDataForm
                    fieldsValues={fieldsValues}
                    setFieldsValues={setFieldsValues}
                    errors={errors} />
                <StudentIdentificationForm
                    style={{ marginTop: "40px" }}
                    fieldsValues={fieldsValues}
                    setFieldsValues={setFieldsValues}
                    nationalities={nationalities}
                    identificationTypes={identificationTypes} />
                <StudentEducationForm
                    style={{ marginTop: "40px" }}
                    fieldsValues={fieldsValues}
                    setFieldsValues={setFieldsValues}
                    educations={educations}
                    educationAreas={educationAreas} />
                <StudentOccupationForm
                    style={{ marginTop: "40px" }}
                    fieldsValues={fieldsValues}
                    setFieldsValues={setFieldsValues}
                    occupations={occupations} />
                <StudentFormabaseForm
                    style={{ marginTop: "40px" }}
                    fieldsValues={fieldsValues}
                    setFieldsValues={setFieldsValues}
                    errors={errors}
                    divulgations={divulgations} />
                <div className="row" style={{ marginTop: "30px" }}>
                    <div className="col text-center">
                        <ActionButton
                            pending={pending}
                            icon="fas fa-plus-square"
                            text="Criar" />
                    </div>
                </div>
            </form>
        </div>
    );
}