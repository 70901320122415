import { toDatePT } from "../../utils/dates";
import Customer from "../../models/Customer.model";
import { Link } from "react-router-dom";
import OfficeTag from "../tags/OfficeTag";

type CustomerProps = {
    values: Customer[];
    selectHandler?: (customer: Customer) => void;
    notFoundText?: string;
}

export default function CustomersTable(props: CustomerProps) {
    const { values, selectHandler } = props;
    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Tipo</th>
                    <th scope="col">Criado em</th>
                    {selectHandler && <th scope="col">Ações</th>}
                </tr>
            </thead>
            <tbody>
                {values.length > 0 ?
                    values.map((row: Customer) =>
                        <tr key={row.id}>
                            <th scope="row">

                                <div style={{ color: "#0d6efd" }}>
                                    <Link to={`/cliente/${row.id}`}>{row.name}</Link>
                                    
                                </div>
                                <div className="contact-email">
                                    <a
                                        href={`mailto:${row.email}`}
                                        target="_blank"
                                        rel="noreferrer">{row.email}</a>
                                </div>
                                <div><OfficeTag office={row.officeId} /></div>
                            </th>
                            <td>{row.customerType}</td>
                            <td style={{ fontSize: "0.95rem" }}>{toDatePT(row.createdDate)}</td>
                            {selectHandler &&
                                <td>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => selectHandler(row)}>Escolher</button>
                                </td>
                            }
                        </tr>
                    ) :
                    <tr>
                        <td colSpan={selectHandler ? 4 : 3} style={{ textAlign: "center" }}>
                            {props.notFoundText || "Não existem clientes para a pesquisa efetuada"}
                        </td>
                    </tr>
                }
            </tbody>
        </table>
    );
}