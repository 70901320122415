import { useState, useRef, useEffect } from 'react';
import { offices } from '../../constants';
import OfficeToggle from '../OfficeToggle';
import PaginationLinks from '../shared/PaginationLinks';
import CustomersTable from './CustomersTable';
import SearchForm from '../shared/SearchForm';
import Loader from '../shared/Loader';
import '../student/student.modal.styles.css';

import { api } from '../../services/api';
import Modal from '../shared/Modal';
import Customer from '../../models/Customer.model';

interface CustomerModalProps {
    closeHandler: () => void;
    selectHandler: (any: any) => void;
    title: string;
    values?: Customer[];
}

export default function CustomerModal(props: CustomerModalProps) {
    const [currentOffice, setCurrentOffice] = useState(offices[0]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const [currentSearch, setCurrentSearch] = useState("");
    const [customers, setCustomers] = useState<Customer[] | undefined>();

    const searchRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {

        if (props.values) {
            setHasMorePages(false);
            setCustomers(props.values);

        } else {


            const searchParams: any = {
                office: currentOffice.value,
                page: currentPage,
                customer_type_id: 3
            };

            if (currentSearch.length > 0) {
                searchParams.search = currentSearch;
            }

            api.getCustomers(searchParams,
                newCustomers => {
                    setHasMorePages(newCustomers.morePages);
                    setCustomers(newCustomers.data.map((customer: any) => new Customer(customer)));
                },
                () => setCustomers([]));

        }
        searchRef.current?.focus();
    }, [currentPage, currentSearch, currentOffice, props.values]);

    const paginationLinksJSX = <PaginationLinks
        page={currentPage}
        hasMorePages={hasMorePages}
        pageChanged={newPage => setCurrentPage(newPage)}
        classes="justify-content-end" />;

    const body =
        <div>
            <div style={{ backgroundColor: "#efefef", padding: "20px 0" }}>
                <div className="container" style={{ position: "relative" }}>
                    <SearchForm
                        label="Pesquisar Encarregados de Educação"
                        onChangeHandler={event => {
                            setCurrentSearch(event.target.value);
                            setCurrentPage(1);
                        }}
                        style={{ marginBottom: "20px" }}
                        searchRef={searchRef} />


                    <div style={{ margin: "30px 0" }}>
                        Filial: <OfficeToggle officeChanged={newOffice => setCurrentOffice(newOffice)} />
                    </div>

                    {paginationLinksJSX}
                    {
                        <>
                            {
                                (customers ?
                                    <CustomersTable values={customers} selectHandler={props.selectHandler} />
                                    : <Loader />)

                            }
                        </>
                    }
                    {paginationLinksJSX}
                </div>
            </div>
        </div>;

    return (
        <>
            <Modal
                title={props.title}
                body={body}
                closeName="Fechar"
                closeHandler={props.closeHandler}
                dialogClasses={"big"}
                style={{}}
                bodyMaxHeight={500} />
        </>
    );
}