import { useState, useEffect, useRef, useContext } from 'react';
import { MILLISECONDS_LIST_WAIT, offices } from '../../constants';
import Contact from '../../interfaces/Contact.interface';
import Office from '../../interfaces/Office.interface';
import Course from '../../models/Course.model';
import { api } from '../../services/api';
import { buildContact } from '../../utils/objects-builders';
import { RedirectionContext, Routes } from '../MainRouter';
import Loader from '../shared/Loader';
import PaginationLinks from '../shared/PaginationLinks';
import SearchForm from '../shared/SearchForm';
import ContactsTable from './ContactsTable';
import { toInputDate } from '../../utils/dates';
import ListFilters from '../shared/ListFilters';
import { dealyForXSeconds } from '../../utils/generic';


export default function ContactsList() {
    const redirection = useContext(RedirectionContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const [currentSearch, setCurrentSearch] = useState("");
    const [contacts, setContacts] = useState<Contact[] | undefined>();
    const [currentOffice, setCurrentOffice] = useState<Office>(offices[0]);
    const [currentCourses, setCurrentCourses] = useState<Course[]>([]);
    const [currentDates, setCurrentDates] = useState<Date[] | undefined>(undefined);
    const [isCalendarOpen, setCalendarOpen] = useState(false);

    const [calendarState, setCalendarState] = useState([{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    }]);

    const searchRef = useRef<HTMLInputElement | null>(null);

    function addCourse(course: Course) {
        if (currentCourses) {
            if (!currentCourses.find(c => c.id === course.id)) {
                setCurrentCourses([...currentCourses, course]);
            }
        }
    }

    function removeCourse(course: Course) {
        if (currentCourses) {
            setCurrentCourses(currentCourses.filter(c => c.id !== course.id));
        }
    }

    useEffect(() => {
        let sendParams: any = {
            search: currentSearch,
            office: currentOffice.value,
            page: currentPage
        }
        if (currentCourses.length !== 0) {
            sendParams.course = currentCourses.map((c: Course) => c.id).join(",");
        }
        if (currentDates) {
            sendParams.start_date = toInputDate(currentDates[0]);
            if (currentDates[0].getTime() !== currentDates[1].getTime()) {
                sendParams.end_date = toInputDate(currentDates[1]);
            }
        }

        api.getContacts(sendParams,
            newContacts => {
                console.log(newContacts.data);
                setHasMorePages(newContacts.morePages);
                setContacts(newContacts.data.map(buildContact));
            },
            () => setContacts([])
        );

        searchRef.current?.focus();
    }, [currentPage, currentSearch, currentOffice, currentCourses, currentDates]);


    function handleContactRemoved(contactId: number) {
        if (contacts) {
            setContacts(contacts.filter(contact => contact.id !== contactId));
        }
    }

    function handleChangedContact(contact: Contact) {
        if (contacts) {
            const pos = contacts.findIndex((c: Contact) => c.id === contact.id);

            const newContacts = contacts;
            newContacts[pos].follow_ups = contact.follow_ups;

            setContacts(newContacts);
        }
    }

    const paginationLinksJSX =
        <PaginationLinks
            page={currentPage}
            pageChanged={newPage => setCurrentPage(newPage)}
            hasMorePages={hasMorePages}
            classes="justify-content-end" />

    return (
        <div className="list-container container" style={{ position: "relative" }}>
            <button
                className="btn btn-success new-list-button"
                onClick={() => redirection.redirectTo(Routes.newContact)}>Novo Contacto</button>
            <h2 className="text-center m-4">Contactos</h2>
            <SearchForm
                label="Pesquisar Contactos"
                onChangeHandler={event => {
                    dealyForXSeconds(MILLISECONDS_LIST_WAIT, () => {
                        setCurrentSearch(event.target.value);
                        setCurrentPage(1);
                    });
                }}
                style={{ marginBottom: "20px" }}
                searchRef={searchRef}
                searchHint="Pode pesquisar por nome, email ou telefone" />


            <ListFilters
                currentCourse={currentCourses}
                currentOffice={currentOffice}
                calendarState={calendarState}
                currentDates={currentDates}
                officeChanged={newOffice => setCurrentOffice(newOffice)}
                courseAdded={addCourse}
                courseRemoved={removeCourse}
                datePickerChanged={(item: any) => {
                    setCalendarState([item.selection])
                    setCurrentDates([item.selection.startDate, item.selection.endDate])
                }}
                calendarCloseHandle={() => setCalendarOpen(false)}
                calendarOpenHandle={() => setCalendarOpen(true)}
                isCalendarOpen={isCalendarOpen}
            />

            {contacts ?
                <>
                    {paginationLinksJSX}
                    <ContactsTable values={contacts} contactRemoved={handleContactRemoved}
                        contactModified={handleChangedContact} />
                    {paginationLinksJSX}
                </> :
                <Loader />
            }
        </div>
    );
}