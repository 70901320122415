export enum ChangeType {
    NEW_FEATURE,
    BUG_FIX,
    IMPROVEMENT
}

export class VersionChange {
    constructor(
        public changeType: ChangeType,
        public description: string,
        public major: number,
        public minor: number,
        public revision: number) {
    }

    public changeTypeDescription() {
        switch (this.changeType) {
            case ChangeType.NEW_FEATURE: return "Novo";
            case ChangeType.BUG_FIX: return "Correção";
            case ChangeType.IMPROVEMENT: return "Melhoria";
        }
    }

    public version() {
        return `${this.major}.${this.minor}.${this.revision}`;
    }
}