export default function StatelessToggle<T>(props: {
    values: T[],
    value: T,
    toggleChanged: (newVal: T) => void,
    getId: (option: T) => string,
    getOptionContent: (option: T) => number | string | React.ReactElement;
    defaultOptionIndex?: number
}) {
    return (
        <div className="btn-group btn-group-toggle" data-toggle="buttons">
            {props.values.map((val, pos) =>
                <label
                    key={pos}
                    className={`btn btn-primary ${props.getId(val) === props.getId(props.value) ? "active" : ""}`}>
                    <input
                        type="radio"
                        name="options"
                        id={props.getId(val)}
                        autoComplete="off"
                        onClick={() => props.toggleChanged(val)}
                        style={{ display: "none" }} /> {props.getOptionContent(val)}
                </label>
            )}
        </div>
    );
}