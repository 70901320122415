import moment from "moment";
import React, { useState, useEffect, SyntheticEvent, useContext } from "react";
import { useParams } from "react-router";
import Contact from "../../../interfaces/Contact.interface";
import Course from "../../../models/Course.model";
import { api, getStorageInfo } from "../../../services/api";
import { toInputDateTime } from "../../../utils/dates";
import { verifyErrors } from "../../../utils/generic";
import { MessagingContext } from "../../App";
import MultipleCourseCombo from "../../combos/MultipleCourseCombo";
import OfficeCombo from "../../combos/OfficeCombo";
import { RedirectionContext, Routes } from "../../MainRouter";
import ActionButton from "../../shared/ActionButton";
import DateTime from "../../shared/DateTime";
import Loader from "../../shared/Loader";
import ContactForTrainingTypeCombo from "../combos/ContactForTrainingTypeCombo";
import ContactMethodCombo from "../combos/ContactMethodCombo";
import ContactPurposeCombo from '../combos/ContactPurposeCombo';

type ContactFields = {
    name: string;
    email: string;
    phone: string;
    date: string;
    description: string;
    contactMethod: number | undefined;
    contactPurpose: number | undefined;
    office: number | undefined;
    trainingType: number | undefined;
}

type ContactErrors = {
    contactMethod: boolean;
    contactPurpose: boolean;
    office: boolean;
}

const contactErrorsDescriptions: any = {
    contactMethod: "Forma de contacto",
    contactPurpose: "Objectivo de contacto",
    office: "Filial",
}

const emptyFields: ContactFields = {
    name: "",
    email: "",
    phone: "",
    date: toInputDateTime(moment()),
    description: "",
    contactMethod: undefined,
    contactPurpose: undefined,
    office: undefined,
    trainingType: undefined
};

export default function ContactForm() {
    const messaging = useContext(MessagingContext);
    const redirection = useContext(RedirectionContext);

    const [fieldsValues, setFieldsValues] = useState(emptyFields);
    const [errors, setErrors] = useState<ContactErrors>({
        contactMethod: false,
        contactPurpose: false,
        office: false
    });

    const [pending, setPending] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadedContact, setLoadedContact] = useState<Contact | undefined>(undefined);
    const [selectedCourses, setSelectedCourses] = useState<Course[]>([]);

    const params: { id: string } = useParams();
    const contactId = params && params.id ? parseInt(params.id) : undefined;
    const editMode = contactId !== undefined;

    if (editMode && loading === false && typeof loadedContact === "undefined") {
        setLoading(true);

        api.getContacts({ id: contactId }, contact => {
            setFieldsValues({
                name: contact.name,
                email: contact.email,
                phone: contact.phone,
                date: toInputDateTime(moment(contact.date)),
                description: contact.description,
                contactMethod: contact.contact_method_id,
                contactPurpose: contact.contact_purpose_id,
                trainingType: contact.training_type_id,
                office: contact.office_id
            });

            setSelectedCourses(contact.courses);
            setLoadedContact(contact);
            setLoading(false);
        }, err => {
            setLoadedContact(undefined);
            setLoading(false);
            messaging.modal.showMessage("Erro", "Erro no carregamento do contacto: " + err);
        });
    }

    useEffect(() => {
        console.log(fieldsValues);
        setErrors({
            contactMethod: fieldsValues.contactMethod === undefined,
            contactPurpose: fieldsValues.contactPurpose === undefined,
            office: fieldsValues.office === undefined,
        });
    }, [fieldsValues]);

    function addCourse(course: Course) {
        if (!selectedCourses.find(c => c.id === course.id)) {
            setSelectedCourses([...selectedCourses, course]);
        }
    }

    function removeCourse(course: Course) {
        setSelectedCourses(selectedCourses.filter(c => c.id !== course.id));
    }

    function handleSubmit(event: SyntheticEvent) {
        event.preventDefault();

        const errorFound = verifyErrors(errors as ContactErrors);
        if (errorFound) {
            messaging.toast.show("Erro", `Preencha o campo: ${contactErrorsDescriptions[errorFound]}`);
            return;
        }

        const contactInfo: any = {
            name: fieldsValues.name,
            email: fieldsValues.email,
            phone: fieldsValues.phone,
            date: fieldsValues.date,
            contact_method_id: fieldsValues.contactMethod,
            office_id: fieldsValues.office,
            user_id: getStorageInfo().userId,
            description: fieldsValues.description,
            contact_purpose_id: fieldsValues.contactPurpose,
            courses: (selectedCourses || []).map(course => course.id)
        };
        if (fieldsValues.trainingType && fieldsValues.trainingType > 0) {
            contactInfo.training_type_id = fieldsValues.trainingType;
        }

        setPending(true);
        if (editMode) {
            const editContact = { ...contactInfo, id: contactId };
            api.editContact(editContact, () => {
                setPending(false);
                redirection.redirectTo(Routes.contacts);
            }, () => {
                setPending(false);
                messaging.modal.showMessage("Erro", "Erro ao editar contacto");
            });
        }
        else {
            api.addContact(contactInfo, () => {
                setPending(false);
                redirection.redirectTo(Routes.contacts);
            }, () => {
                setPending(false);
                messaging.modal.showMessage("Erro", "Erro ao inserir contacto");
            });
        }
    }

    function formJSX() {
        return (
            <>
                <div className="row">
                    <div className="col m-2">
                        <div className="form-group">
                            <label htmlFor="name">Nome</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                value={fieldsValues.name || ""}
                                onChange={event => setFieldsValues({ ...fieldsValues, name: event.target.value })}
                                required />
                        </div>
                    </div>
                    <div className="col m-2">
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                value={fieldsValues.email || ""}
                                onChange={event => setFieldsValues({ ...fieldsValues, email: event.target.value })} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col m-2">
                        <div className="form-group">
                            <label htmlFor="phone">Telefone</label>
                            <input
                                type="phone"
                                className="form-control"
                                id="phone"
                                value={fieldsValues.phone || ""}
                                onChange={event => setFieldsValues({ ...fieldsValues, phone: event.target.value })} />
                        </div>
                    </div>
                    <div className="col m-2">
                        <DateTime
                            value={fieldsValues.date}
                            valueChanged={(newDate) => setFieldsValues({ ...fieldsValues, date: newDate })} />
                    </div>
                </div>
                <div className="row">
                    <div className="col m-2">
                        <div className="form-group">
                            <label>Forma</label>
                            <ContactMethodCombo
                                showError={errors.contactMethod}
                                value={fieldsValues.contactMethod}
                                selectionChanged={newMethod =>
                                    setFieldsValues({ ...fieldsValues, contactMethod: newMethod })} />
                        </div>
                    </div>
                    <div className="col m-2">
                        <div className="form-group">
                            <label>Objetivo</label>
                            <ContactPurposeCombo
                                showError={errors.contactPurpose}
                                value={fieldsValues.contactPurpose}
                                selectionChanged={newPurpose =>
                                    setFieldsValues({ ...fieldsValues, contactPurpose: newPurpose })} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col m-2">
                        <div className="form-group">
                            <label>Filial</label>
                            <OfficeCombo
                                showError={errors.office}
                                value={fieldsValues.office}
                                selectionChanged={newOffice =>
                                    setFieldsValues({ ...fieldsValues, office: newOffice })} />
                        </div>
                    </div>
                    <div className="col m-2">
                        <div className="form-group">
                            <label>Tipo de formação</label>
                            <ContactForTrainingTypeCombo
                                value={fieldsValues.trainingType}
                                selectionChanged={newTrainingType =>
                                    setFieldsValues({ ...fieldsValues, trainingType: newTrainingType })} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col m-2">
                        <div className="form-group">
                            <label >Cursos</label>
                            <MultipleCourseCombo
                                value={selectedCourses}
                                courseAdded={addCourse}
                                courseRemoved={removeCourse}
                                defaultText="Escolha um curso a adicionar" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col m-2">
                        <div className="form-group">
                            <label htmlFor="description">Descrição</label>
                            <textarea
                                className="form-control"
                                id="description"
                                rows={4}
                                value={fieldsValues.description || ""}
                                onChange={event => setFieldsValues({ ...fieldsValues, description: event.target.value })}></textarea>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ marginTop: "30px" }}>
                    <div className="col text-center">
                        <ActionButton
                            pending={pending}
                            icon={`fas ${editMode ? "fa-pen" : "fa-plus-square"}`}
                            text={editMode ? "Editar" : "Criar"} />
                    </div>
                </div>
            </>
        );
    }

    return (
        <div className="container p-4">
            <h2 className="text-center m-4">{editMode ? "Editar" : "Novo"} Contacto</h2>
            <form onSubmit={handleSubmit}>
                <div className="card p-3">
                    {loading ? <Loader /> : formJSX()}
                </div>
            </form>
        </div>
    );
}