import { useState, useRef, useEffect } from "react";
import { Education } from "../../interfaces/Education.interface";
import { api } from "../../services/api";
import Loader from "../shared/Loader";
import SearchForm from "../shared/SearchForm";
import EducationsTable from "./EducationsTable";
import EducationForm from "./EducationForm";
import EducationAreaTable from "./EducationAreaTable";
import styles from "./Education.styles.module.css";
import { EducationArea } from "../../interfaces/EducationArea.interface";


export enum InsertType {
    education = "education",
    educationArea = "educationArea"
}

export default function EducationsList() {

    const [educations, setEducations] = useState<Education[]>();
    const [displayedEducations, setDisplayEducations] = useState<Education[]>();

    const [educationAreas, setEducationAreas] = useState<EducationArea[]>();
    const [displayedEducationAreas, setDisplayEducationAreas] = useState<EducationArea[]>();

    const searchRef = useRef<HTMLInputElement | null>(null);
    const [currentSearch, setCurrentSearch] = useState("");

    const [showInsertModal, setShowInsertModal] = useState(false);
    const [editing, setEditing] = useState(false);
    const [selectedEducation, setSelectedEducation] = useState<Education | undefined>(undefined);

    const [formInsertType, setFormInsertType] = useState<InsertType>();

    useEffect(() => {
        api.getEducations(
            newEducations => {
                setEducations(newEducations);
                setDisplayEducations(newEducations);
            },
            (err) => {
                console.log(err);
            }
        )
        api.getEducationAreas(
            newEducationAreas => {
                setEducationAreas(newEducationAreas);
                setDisplayEducationAreas(newEducationAreas);
            },
            (err) => {
                console.log(err);
            }
        )
    }, [])

    useEffect(() => {
        const filteredArray = educations?.filter((edu: Education) =>
            edu.title.toLocaleLowerCase().includes(currentSearch.toLocaleLowerCase()));
        setDisplayEducations(filteredArray);
    }, [currentSearch, educations])

    useEffect(() => {
        const filteredArray = educationAreas?.filter((edu: EducationArea) =>
            edu.title.toLocaleLowerCase().includes(currentSearch.toLocaleLowerCase()));
        setDisplayEducationAreas(filteredArray);
    }, [currentSearch, educationAreas])

    function handleEducationRemoved(educationId: number, type: InsertType) {
        if (type === InsertType.education) {
            if (educations) {
                setEducations(educations.filter(education => education.id !== educationId));
            }
        } else {
            if (educationAreas) {
                setEducationAreas(educationAreas.filter(education => education.id !== educationId));
            }
        }
    }


    function sortEducations(EducationsList: Education[]) {
        return EducationsList && EducationsList.sort((a: Education, b: Education) =>
            a.title.toString().localeCompare(b.title.toString())
        )
    }

    function addEducationToList(newEducation: Education) {
        if (formInsertType === InsertType.education) {
            if (educations) {
                const newEducationsList = [...educations, newEducation];
                const sortedEducations = sortEducations(newEducationsList);
                setEducations(sortedEducations);

            }
        } else {
            if (educationAreas) {
                const newEducationsList = [...educationAreas, newEducation];
                const sortedEducations = sortEducations(newEducationsList);
                setEducationAreas(sortedEducations);
            }
        }
        setShowInsertModal(false);
    }

    function replaceEducationInList(updatedEducation: Education) {

        if (formInsertType === InsertType.education) {
            if (educations) {
                const newEducationsList = educations.map((o: Education) => o.id === updatedEducation.id ? updatedEducation : o);
                const sortedEducations = sortEducations(newEducationsList);
                setEducations(sortedEducations);

            }
        } else {
            if (educationAreas) {
                const newEducationsList = educationAreas.map((o: EducationArea) => o.id === updatedEducation.id ? updatedEducation : o);
                const sortedEducations = sortEducations(newEducationsList);
                setEducationAreas(sortedEducations);
            }
        }
        setShowInsertModal(false);
        setEditing(false);
        setSelectedEducation(undefined);
    }

    function editHandler(edu: any, type: InsertType) {
        setEditing(true);
        setFormInsertType(type);
        setSelectedEducation(edu);
        setShowInsertModal(true);
    }

    function addHandler(type: InsertType) {
        setEditing(false);
        setFormInsertType(type);
        setSelectedEducation(undefined);
        setShowInsertModal(true);
    }

    return (
        <>
            {
                showInsertModal && formInsertType !== undefined &&
                <EducationForm
                    closeHandler={() => setShowInsertModal(false)}
                    education={selectedEducation}
                    EducationAdded={addEducationToList}
                    EducationEdited={replaceEducationInList}
                    editing={editing}
                    type={formInsertType}
                />
            }

            <div className="container" style={{ position: "relative" }}>
                <div className={styles.buttonsContainer}>
                    <button
                        className="btn btn-success"
                        onClick={() => addHandler(InsertType.education)}
                    >Novo Grau Académico</button>
                    <button
                        className="btn btn-success"
                        onClick={() => addHandler(InsertType.educationArea)}
                    >Nova Área Educação</button>
                </div>
                <h2 className="text-center m-4">Educação</h2>

                <SearchForm
                    label="Pesquisar"
                    onChangeHandler={event => setCurrentSearch(event.target.value)}
                    style={{ marginBottom: "20px" }}
                    searchRef={searchRef} />

                {displayedEducations && displayedEducationAreas ?
                    <div style={{ display: "flex" }}>
                        <EducationAreaTable
                            values={displayedEducationAreas}
                            EducationAreaRemoved={handleEducationRemoved}
                            EducationAreaEdited={editHandler}
                        />

                        <EducationsTable
                            values={displayedEducations}
                            EducationRemoved={handleEducationRemoved}
                            EducationEdited={editHandler}
                        />
                    </div>
                    :
                    <Loader />
                }
            </div>
        </>
    );
}