import moment from "moment";
import { SyntheticEvent, useContext, useEffect, useState } from "react";
import Contact from "../../../interfaces/Contact.interface";
import FollowUp from "../../../interfaces/FollowUp.interface";
import Course from "../../../models/Course.model";
import { getStorageInfo } from "../../../services/api";
import { toInputDateTime } from "../../../utils/dates";
import { verifyErrors } from "../../../utils/generic";
import { MessagingContext } from "../../App";
import MultipleCourseCombo from "../../combos/MultipleCourseCombo";
import ContactForTrainingTypeCombo from "../../contact/combos/ContactForTrainingTypeCombo";
import ContactMethodCombo from "../../contact/combos/ContactMethodCombo";
import ActionButton from "../../shared/ActionButton";

interface FollowUpFormFields {
    description: string;
    date: string;
    contactMethod: number | undefined;
    trainingType: number;
}

type FollowUpFormProps = {
    pending: boolean;
    contact: Contact;
    followUp: FollowUp | undefined;
    followUpAdded: (followUp: FollowUp) => void;
    followUpModified: (followUp: FollowUp) => void;
}

const emptyFields = {
    description: "",
    date: toInputDateTime(moment()),
    contactMethod: undefined,
    trainingType: 0
};

export type FollowUpErrors = {
    contactMethod: boolean;
    description: boolean;
    trainingType: boolean;
}
const emptyErrors = {
    contactMethod: false,
    description: false,
    trainingType: false,
}

const followUpErrorsDescriptions: any = {
    contactMethod: "Método de Contacto",
    description: "Descrição",
    trainingType: "Método de Formação",
}


const defaultContactMethod: number = 1;

export default function FollowUpForm(props: FollowUpFormProps) {
    const messaging = useContext(MessagingContext);
    const { followUp } = props;

    const [fieldsValues, setFieldsValues] = useState<FollowUpFormFields>(emptyFields);

    const [selectedCourses, setSelectedCourses] = useState<Course[]>([]);
    const [errors, setErrors] = useState<FollowUpErrors>(emptyErrors);


    useEffect(() => {
        setErrors({
            description: fieldsValues.description === "",
            contactMethod: fieldsValues.contactMethod === undefined,
            trainingType: fieldsValues.trainingType === 0
        });
    }, [fieldsValues]);

    useEffect(() => {
        if (followUp) {
            setFieldsValues({
                description: followUp.description,
                date: toInputDateTime(moment(followUp.date)),
                contactMethod: followUp.contact_method_id,
                trainingType: followUp.training_type_id,
            });
            setSelectedCourses(followUp.courses || []);
        }
    }, [followUp])

    function addCourse(course: Course) {
        if (!selectedCourses.find(c => c.id === course.id)) {
            setSelectedCourses([...selectedCourses, course]);
        }
    }

    function removeCourse(course: Course) {
        setSelectedCourses(selectedCourses.filter(c => c.id !== course.id));
    }

    function handleSubmit(event: SyntheticEvent) {
        event.preventDefault();


        const errorFound = verifyErrors(errors as FollowUpErrors);
        if (errorFound) {
            messaging.toast.show("Erro", `Preencha o campo: ${followUpErrorsDescriptions[errorFound]}`);
            return;
        }

        const body: FollowUp = {
            id: props.contact.id,
            created_date: moment().toDate(),
            description: fieldsValues.description,
            contact_method_id: fieldsValues.contactMethod ?? defaultContactMethod,
            training_type_id: fieldsValues.trainingType,
            courses: (selectedCourses || []),
            date: moment(fieldsValues.date).toDate(),
            user_id: getStorageInfo().userId,
            user: getStorageInfo().userName,
        }

        if (followUp) {
            body.id = followUp.id;
            props.followUpModified(body);
        } else {
            props.followUpAdded(body);
        }

        setFieldsValues(emptyFields);
        setSelectedCourses([]);
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col m-2">
                    <div className="form-group">
                        <label>Forma</label>
                        <ContactMethodCombo
                            showError={errors.contactMethod}
                            value={fieldsValues.contactMethod}
                            selectionChanged={newMethod =>
                                setFieldsValues({ ...fieldsValues, contactMethod: newMethod })} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col m-2">
                    <div className="form-group">
                        <label>Tipo de formação</label>
                        <ContactForTrainingTypeCombo
                            value={fieldsValues.trainingType}
                            selectionChanged={newTrainingType =>
                                setFieldsValues({ ...fieldsValues, trainingType: newTrainingType })} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col m-2">
                    <div className="form-group">
                        <label htmlFor="description">Descrição</label>
                        <textarea
                            className="form-control"
                            id="description"
                            rows={2}
                            value={fieldsValues.description || ""}
                            onChange={event => setFieldsValues({ ...fieldsValues, description: event.target.value })}></textarea>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col m-2">
                    <div className="form-group">
                        <label htmlFor="date">Data e hora</label>
                        <input
                            type="datetime-local"
                            className="form-control"
                            id="date"
                            value={toInputDateTime(moment(fieldsValues.date))}
                            onChange={event => setFieldsValues({ ...fieldsValues, date: event.target.value })}
                            required />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col m-2">
                    <div className="form-group">
                        <label >Cursos</label>
                        <MultipleCourseCombo
                            value={selectedCourses}
                            courseAdded={addCourse}
                            courseRemoved={removeCourse} />
                    </div>
                </div>
            </div>
            <div className="row" style={{ marginTop: "20px" }}>
                <div className="col text-center">
                    <ActionButton
                        pending={props.pending}
                        icon="fas fa-plus-square"
                        text={`${followUp ? "Modificar" : "Adicionar"} Seguimento`} />
                </div>
            </div>
        </form>
    );
}