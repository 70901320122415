import { useState, useEffect, useContext } from "react";
import GenericCombo from "../shared/GenericCombo";
import Nationality from "../../interfaces/Nationality.interface";
import { api } from "../../services/api";
import { MessagingContext } from "../App";
import City from '../../interfaces/City.interface';
import Modal from "../shared/Modal";
import { verifyErrors } from "../../utils/generic";

type CityFormProps = {
    closeHandler: () => void;
    city: City | undefined;
    cityAdded?: (city: City) => void;
    cityEdited?: (city: City) => void;
    editing: boolean;
    pending?: boolean;
}


type CityFields = {
    title: string;
    country_id: number;
}

const emptyFields: CityFields = {
    title: "",
    country_id: 0,
};

type CityErrors = {
    title: boolean;
    countryId: boolean;
}

const cityErrorsDescriptions: any = {
    title: "Titulo",
    countryId: "País",
}


export default function CityForm(props: CityFormProps) {

    const { city, cityEdited, cityAdded, closeHandler, editing } = props;

    const [fieldsValues, setFieldsValues] = useState(emptyFields);
    const [countries, setCountries] = useState<Nationality[]>([]);

    const messaging = useContext(MessagingContext);
    const [pending, setPending] = useState(false);

    const [errors, setErrors] = useState<CityErrors>({
        title: false,
        countryId: false
    });

    useEffect(() => {
        api.getCountries(
            newCountries => setCountries(newCountries),
            err => (err)
        )
    }, [])



    useEffect(() => {
        if (city) {
            setFieldsValues({
                title: city.title,
                country_id: city.country_id,
            });
        }
    }, [city])



    function handleSubmit() {

        const errorFound = verifyErrors(errors as CityErrors);
        if (errorFound) {
            messaging.toast.show("Erro", `Preencha o campo: ${cityErrorsDescriptions[errorFound]}`);
            return;
        }

        setPending(true);

        const cityObj: any = {
            title: fieldsValues.title.trim(),
            country_id: fieldsValues.country_id,
            country: countries.find((n: Nationality) => n.id === fieldsValues.country_id)?.title
        }


        setPending(true);
        if (editing) {
            const editCity = { ...cityObj, id: city?.id };
            editCityApi(editCity);
        }
        else {
            addCityApi(cityObj);
        }
    }

    function editCityApi(cityObj: City) {
        api.editCity(cityObj, (e: any) => {
            if (e.status === 500 && e.status === 404) {
                throw new Error(e);
            }
            setPending(false);
            if (cityEdited) {
                messaging.toast.show("Sucesso", "Localidade modificada com sucesso");
                cityEdited({ ...city, ...cityObj });
            }
        }, () => {
            setPending(false);
            messaging.toast.show("Erro", "Erro ao editar a localidade");
        });
    }


    function addCityApi(cityObj: City) {
        api.addCity(cityObj,
            (id: any) => {
                cityObj.id = parseInt(id);
                if (id.status === 500 && id.status === 404) {
                    throw new Error(id);
                }
                setPending(false);

                if (cityAdded) {
                    messaging.toast.show("Sucesso", "Localidade adicionada com sucesso");
                    cityAdded(cityObj as City);
                }

            }, () => {
                setPending(false);
                messaging.toast.show("Erro", "Erro ao inserir a localidade");
            });
    }

    useEffect(() => {
        setErrors({
            title: fieldsValues.title === "",
            countryId: fieldsValues.country_id === 0
        });
    }, [fieldsValues]);

    function formJSX() {
        return (
            <>
                <div className="row">
                    <div className="col m-2">
                        <div className="form-group">
                            <label htmlFor="name">Nome</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                value={fieldsValues.title}
                                onChange={event => setFieldsValues({ ...fieldsValues, title: event.target.value })}
                                required />
                        </div>
                    </div>
                </div>
                <div>

                    <div className="col m-2">
                        <div className="form-group">
                            <label htmlFor="phone">País</label>
                            <GenericCombo
                                value={fieldsValues.country_id}
                                values={countries}
                                getId={country => country.id}
                                showError={errors.countryId}
                                getOptionContent={country => country.title}
                                selectionChanged={country =>
                                    setFieldsValues({ ...fieldsValues, country_id: country as number })} />
                        </div>
                    </div>
                </div>
                <div className="row" style={{ marginTop: "20px" }}>
                    <div className="col text-center">

                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => handleSubmit()}
                            style={{ padding: "5px 40px" }}>
                            {pending ?
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"></span>
                                : <i className="fas fa-plus-square"></i>
                            }
                            <span style={{ margin: "0px 10px" }}>{`${editing ? "Modificar" : "Adicionar"} Localidade`}</span>
                        </button>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <Modal
                title={`${editing ? "Editar" : "Nova"} Localidade`}
                body={formJSX()}
                closeName="Fechar"
                closeHandler={closeHandler}
                bodyMaxHeight={500} />
        </>
    );
}