import { VersionChange, ChangeType } from "./VersionChange";

export class AppVersion {
    public static readonly changes = [
        new VersionChange(ChangeType.IMPROVEMENT, "As extensões de curso a partir de agora podem ser definidas com uma data especifica", 1, 0, 9),
        new VersionChange(ChangeType.NEW_FEATURE, "Nova página para criar, editar ou remover graus académicos e areas de educação", 1, 0, 8),
        new VersionChange(ChangeType.NEW_FEATURE, "Nova página para criar, editar ou remover ocupações", 1, 0, 7),
        new VersionChange(ChangeType.IMPROVEMENT, "Novos campos para registar Estado Civil e Documentos entregues na ficha de inscrição do aluno", 1, 0, 6),
        new VersionChange(ChangeType.IMPROVEMENT, "Melhoria na incrição de múltiplos alunos a partir da ficha de cliente. Permite atribuir uma duração do curso para todas as formações", 1, 0, 5),
        new VersionChange(ChangeType.NEW_FEATURE, "A quantidade de meses em que cada curso é válido passa a ser editável na zona de Cursos.", 1, 0, 4),
        new VersionChange(ChangeType.NEW_FEATURE, "Feature que permite criar uma Sessão Gratuita diretamente no Programa de Marcações através dos Ninjas", 1, 0, 3),
        new VersionChange(ChangeType.BUG_FIX, "Permitido percentagens decimais nos descontos", 1, 0, 2),
        new VersionChange(ChangeType.BUG_FIX, "Correção de bug que não permitia valores decimais nos preços dos Cursos e Packs", 1, 0, 1),
        new VersionChange(ChangeType.NEW_FEATURE, "Histórico de Alterações implementado", 1, 0, 0),
    ];

    public static getLastChange() {
        return this.changes[0];
    }

    public static getVersion() {
        return this.getLastChange().version();
    }

}