import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router";
import { MILLISECONDS_LIST_WAIT, offices } from "../../constants";
import Office from "../../interfaces/Office.interface";
import Course from "../../models/Course.model";
import { FreeSession } from "../../models/FreeSession.model";
import { api } from "../../services/api";
import { toInputDate } from "../../utils/dates";
import { dealyForXSeconds } from "../../utils/generic";
import { MessagingContext } from "../App";
import { RedirectionContext, Routes } from "../MainRouter";
import ListFilters from "../shared/ListFilters";
import Loader from "../shared/Loader";
import PaginationLinks from "../shared/PaginationLinks";
import SearchForm from "../shared/SearchForm";
import FreeSessionsTable from "./FreeSessionsTable";

export default function FreeSessionsList() {
    const redirection = useContext(RedirectionContext);
    const messaging = useContext(MessagingContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const [currentSearch, setCurrentSearch] = useState("");
    const [freeSessions, setFreeSessions] = useState<FreeSession[] | undefined>();
    const [currentOffice, setCurrentOffice] = useState<Office>(offices[0]);
    const [currentCourses, setCurrentCourses] = useState<Course[]>([]);
    const [currentDates, setCurrentDates] = useState<Date[] | undefined>(undefined);
    const [isCalendarOpen, setCalendarOpen] = useState(false);
    const [calendarState, setCalendarState] = useState([{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    }]);

    const history = useHistory();

    const searchRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        let sendParams: any = {
            search: currentSearch,
            office: currentOffice.value,
            page: currentPage
        }
        if (currentCourses.length !== 0) {
            sendParams.course = currentCourses.map((c: Course) => c.id).join(",");
        }
        if (currentDates) {
            sendParams.start_date = toInputDate(currentDates[0]);
            if (currentDates[0].getTime() !== currentDates[1].getTime()) {
                sendParams.end_date = toInputDate(currentDates[1]);
            }
        }

        api.getFreeSessions(sendParams,
            newFreeSessions => {
                setHasMorePages(newFreeSessions.morePages);
                setFreeSessions(newFreeSessions.data.map((session: any) => new FreeSession(session)));
            },
            () => setFreeSessions([])
        );

        searchRef.current?.focus();
    }, [currentPage, currentSearch, currentOffice, currentCourses, currentDates]);

    function handleFreeSessionRemoved(freeSessionId: number) {

        if (freeSessionId) {
            api.deleteFreeSession(freeSessionId,
                data => {
                    console.log(data);
                    if (data.status === 403 || data.status === 500) {
                        data.text().then((msg: string) => {
                            messaging.modal.showMessage("Erro", `Erro a remover a Sessão Gratuita: ${msg}`);
                        });
                    } else {
                        setFreeSessions(freeSessions?.filter((freeSession: FreeSession) => freeSession.id !== freeSessionId));
                        messaging.toast.show("Sessão Gratuita Removida", "A Sessao Gratuita selecionada foi removida com sucesso");
                    }
                },
                err => err);
        }
    }


    function handleFreeSessionChanged(freeSession: FreeSession) {
        if (freeSessions) {
            const freeSessionsCopy = [...(freeSessions || [])];
            const index = freeSessions.findIndex(fs => fs.id === freeSession.id);
            if (index !== -1) {
                freeSessionsCopy[index] = freeSession;
            }
            setFreeSessions(freeSessionsCopy);
        }
    }

    const queryParams = new URLSearchParams(useLocation().search);
    const page = queryParams.get("pagina");
    if (page && currentPage !== parseInt(page)) {
        setCurrentPage(parseInt(page));
    }

    function handlePageChanged(newPage: number) {
        history.replace(Routes.freeSessions);
        setCurrentPage(newPage);
    }

    function addCourse(course: Course) {
        if (currentCourses) {
            if (!currentCourses.find(c => c.id === course.id)) {
                setCurrentCourses([...currentCourses, course]);
            }
        }
    }

    function removeCourse(course: Course) {
        if (currentCourses) {
            setCurrentCourses(currentCourses.filter(c => c.id !== course.id));
        }
    }

    const paginationLinksJSX =
        <PaginationLinks
            page={currentPage}
            pageChanged={handlePageChanged}
            hasMorePages={hasMorePages}
            classes="justify-content-end" />

    return (
        <div className="container" style={{ position: "relative" }}>
            <button
                className="btn btn-success new-list-button"
                onClick={() => redirection.redirectTo(Routes.newFreeSession)}>Nova Sessão Gratuita</button>
            <h2 className="text-center m-4">Sessões Gratuitas</h2>
            <SearchForm
                label="Pesquisar sessões gratuitas"
                onChangeHandler={event => {
                    dealyForXSeconds(MILLISECONDS_LIST_WAIT, () => {
                        setCurrentSearch(event.target.value);
                        setCurrentPage(1);
                    });
                }}
                style={{ marginBottom: "20px" }}
                searchRef={searchRef}
                searchHint="Pode pesquisar por nome" />

            <ListFilters
                currentCourse={currentCourses}
                currentOffice={currentOffice}
                calendarState={calendarState}
                currentDates={currentDates}
                officeChanged={newOffice => setCurrentOffice(newOffice)}
                courseAdded={addCourse}
                courseRemoved={removeCourse}
                datePickerChanged={(item: any) => {
                    setCalendarState([item.selection])
                    setCurrentDates([item.selection.startDate, item.selection.endDate])
                }}
                calendarCloseHandle={() => setCalendarOpen(false)}
                calendarOpenHandle={() => setCalendarOpen(true)}
                isCalendarOpen={isCalendarOpen}
            />

            {freeSessions ?
                <>
                    {paginationLinksJSX}
                    <FreeSessionsTable
                        values={freeSessions}
                        freeSessionRemoved={handleFreeSessionRemoved}
                        freeSessionChanged={handleFreeSessionChanged}
                        page={currentPage} />
                    {paginationLinksJSX}
                </> :
                <Loader />
            }
        </div>
    );
}