import { api } from '../../services/api';
import React, { useEffect, useState, useContext } from "react";
import { MessagingContext } from '../App';
import Loader from '../shared/Loader';
import Pack from '../../models/Pack.model';
import PackForm from '../packs/PackForm';

type PackComboProps = {
    value: Pack | undefined;
    selectionChanged: (newVal: Pack | undefined) => void;
    showError?: boolean;
    office?: number;
    defaultText?: string;
    availablePacks?: Pack[] | undefined;
    autoFetchPacks?: boolean;
}

export default function PackCombo(props: PackComboProps) {
    const messaging = useContext(MessagingContext);
    const [packs, setPacks] = useState<Pack[] | undefined>();
    const [showInsertModal, setShowInsertModal] = useState(false);

    const showPacks = props.autoFetchPacks !== false ? packs : props.availablePacks;

    useEffect(() => {
        if (props.autoFetchPacks !== false) {
            api.getPacks(
                (data: Pack[]) => setPacks(data.map((p: Pack) => new Pack(p))),
                err => messaging.modal.showMessage("Erro", "Erro a obter os cursos: " + err));
        }
    }, [props.office, props.autoFetchPacks, messaging.modal]);

    function handlePackChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const selectIntVal = parseInt(event.target.value);
        if (showPacks) {
            const selectedPack = showPacks.find(Pack => Pack.id === selectIntVal);
            props.selectionChanged(selectedPack);
        }
    }

    function addPackToList(newPack: Pack) {
        if (packs) {
            const newPacksList = [...packs, newPack];
            setPacks(newPacksList.sort((a: Pack, b:Pack) => a.title.localeCompare(b.title)));
            setShowInsertModal(false);
        }
    }



    const filteredPacksList = showPacks ?
        showPacks.map((pack: Pack) =>
            <option
                key={pack.id}
                value={pack.id}>{pack.title}</option>
        ) : [];


    return (
        <>
            {showPacks ?
                <>
                    {showInsertModal &&
                        <PackForm
                            closeHandler={() => setShowInsertModal(false)}
                            pack={undefined}
                            packAdded={addPackToList}
                            editing={false}
                        />
                    }
                    <div className="d-flex" style={{ alignItems: 'baseline' }}>
                        <button type="button"
                            style={{ fontSize: "1rem", marginRight: "5px" }}
                            onClick={() => {
                                setShowInsertModal(true);
                            }}
                            className={`btn btn-outline-success btn-rounded waves-effect`}>
                            <i className="fas fa-plus-square"></i>
                        </button>
                        <div>


                            <select
                                value={typeof props.value === "undefined" ? 0 : props.value.id}
                                className="form-select"
                                onChange={handlePackChange}>
                                <option value={0}>{props.defaultText || "Escolha o pack"}</option>
                                {
                                    filteredPacksList
                                }
                            </select>
                            {props.showError &&
                                <div className="invalid-feedback" style={{ display: "block" }}>
                                    Precisa de escolher um pack
                                </div>
                            }
                        </div>
                    </div>
                </> :
                <Loader />
            }
        </>
    );
}