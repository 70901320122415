import { useContext, useState } from "react";
import { NavLink, Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import { api } from "../../services/api";
import { MessagingContext } from "../App";
import { RedirectionContext } from "../MainRouter";
import Loader from "../shared/Loader";
import StudentContacts from "./StudentContacts";
import StudentCustomer from "./StudentCustomer";
import StudentSurveys from "./StudentSurveys";
import StudentEnrollments from "./StudentEnrollments";
import StudentFreeSessions from "./StudentFreeSessions";
import StudentInfo from './StudentInfo';
import StudentTrainings from "./StudentTrainings";
import StudentCertificates from "./StudentCertificates";
import StudentCarer from "./StudentCarer";

export default function StudentPage() {
    const messaging = useContext(MessagingContext);
    const redirection = useContext(RedirectionContext);

    const [deleting, setDeleting] = useState(false);
    const [studentName, setStudentName] = useState("");

    const { path, url } = useRouteMatch();
    const params: { id: string } = useParams();
    const studentId = parseInt(params.id);
    const tabs = [
        { name: "Dados Pessoais", link: url + "/dados-pessoais" },
        { name: "Formações", link: url + "/formacoes" },
        { name: "Inscrições", link: url + "/inscricoes" },
        { name: "Contactos", link: url + "/contactos" },
        { name: "Dados Faturação", link: url + "/dados-faturacao" },
        { name: "Questionários", link: url + "/questionarios" },
        { name: "Sessões Gratuitas", link: url + "/sessao-gratuitas" },
        { name: "Encarregado Educação", link: url + "/encarregado-educacao" },
        { name: "Certificados", link: url + "/certificados" },
    ];

    function handleRemoveStudent() {
        messaging.modal.showFull({
            title: "Eliminar aluno",
            body: "Tem a certeza que pretende eliminar este aluno ?",
            closeName: "Cancelar",
            closeHandler: messaging.modal.close,
            actionName: "Eliminar",
            actionHandler: () => {
                messaging.modal.close();
                setDeleting(true);
                api.deleteStudent(studentId, (response) => {
                    if (response.status === 204) {
                        redirection.redirectTo("/alunos");

                    } else {
                        response.text()
                            .then((data: any) => {
                                messaging.modal.showMessage("Erro", "Erro ao eliminar o aluno: " + data);
                            });
                    }
                }, err => {
                    messaging.modal.showMessage("Erro", "Erro ao eliminar o aluno: " + err);
                });
            },
            actionClassName: "btn-danger",
            showAction: true
        });
    }

    
    return (
        <>

            <div>
                <div style={{ position: "relative" }}>
                    <h2 className="text-center m-4">Aluno - {studentName}</h2>
                    <button
                        className="btn btn-danger"
                        disabled={deleting}
                        onClick={handleRemoveStudent}
                        style={{ position: "absolute", top: "5px", right: "25px" }}>
                        {
                            deleting ?
                                <Loader
                                    outterStyle={{ float: "left", marginRight: "5px" }}
                                    style={{ width: "1.5rem", height: "1.5rem" }} /> :
                                ""
                        }
                        Eliminar Aluno
                    </button>
                </div>
                <ul className="nav nav-tabs">
                    {tabs.map((tab, pos) =>
                        <li
                            key={pos}
                            className="nav-item"
                            style={{ cursor: "pointer" }}>
                            <NavLink
                                to={tab.link}
                                className="nav-link"
                                activeClassName="active">{tab.name}</NavLink>
                        </li>
                    )}
                </ul>

                <div style={{ padding: "15px" }}>
                    <Switch>
                        <Route exact path={[path, path + "/dados-pessoais"]}>
                            <StudentInfo studentId={studentId} setStudentName={setStudentName} />
                        </Route>
                        <Route path={`${path}/formacoes`}>
                            <StudentTrainings studentId={studentId} setStudentName={setStudentName} />
                        </Route>
                        <Route path={`${path}/inscricoes`}>
                            <StudentEnrollments studentId={studentId} setStudentName={setStudentName} />
                        </Route>
                        <Route path={`${path}/contactos`}>
                            <StudentContacts studentId={studentId} setStudentName={setStudentName} />
                        </Route>
                        <Route path={`${path}/dados-faturacao`}>
                            <StudentCustomer studentId={studentId} setStudentName={setStudentName} />
                        </Route>
                        <Route path={`${path}/questionarios`}>
                            <StudentSurveys studentId={studentId} setStudentName={setStudentName} />
                        </Route>
                        <Route path={`${path}/sessao-gratuitas`}>
                            <StudentFreeSessions studentId={studentId} setStudentName={setStudentName} />
                        </Route>
                        <Route path={`${path}/certificados`}>
                            <StudentCertificates studentId={studentId} setStudentName={setStudentName} />
                        </Route>
                        <Route path={`${path}/encarregado-educacao`}>
                            <StudentCarer studentId={studentId} setStudentName={setStudentName} />
                        </Route>
                    </Switch>
                </div>
            </div>
        </>
    );
}