import 'moment/locale/pt';
import { CertificateFace, PITA_LOGO  } from "../../constants";
import Certificate from "../../interfaces/Certificate.interface";
import { removeEmptySpaces } from "./CertificateComponent";
import './CertificateComponent.styles.css';

export default function PrintCertificate(props: { certificate: Certificate, face: CertificateFace, close: () => void }) {
    const { certificate, close, face } = props;

    return (
        <div id="section-to-print"
            style={{
                backgroundImage: `url('${process.env.REACT_APP_IMAGES_URL}/marca_agua.jpg')`,
                zIndex: 500,
                padding: "30px",
                overflow: "auto", 
                height: "100vh"
            }}
            className={`printable fixed-full-back`}>
            <i
                className="fas fa-print action-icon non-printable"
                onClick={() => window.print()}
                style={{ fontSize: "4rem" }}></i>
            <div className="d-flex justify-content-center">
                <img id="image-logo" src={process.env.REACT_APP_IMAGES_URL + "/" + PITA_LOGO} alt="logo" />
                <i
                    className="fas fa-times action-icon non-printable"
                    onClick={() => close()}
                    style={{ fontSize: "2rem", position: "absolute", right: "20px", top: "20px" }}></i>
            </div>

            <div dangerouslySetInnerHTML={{ __html: removeEmptySpaces(face === CertificateFace.Front ? certificate.front : certificate.back) }} />
        </div>
    );
}