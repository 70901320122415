import SurveyRating from "../shared/SurveyRating";
import TeacherRating from "../../models/TeacherRating.model";
import { classifications, qualities } from "../../constants";

type TeacherRatingCardProps = {
    handleRatingChanged: (teacherRating: TeacherRating) => void,
    teacherRating: TeacherRating;
    readOnly: boolean;
}

export default function TeacherRatingCard(props: TeacherRatingCardProps) {

    const { teacherRating, handleRatingChanged } = props;

    function handleTeacherRatingChange(objectProperty: string, value: number) {
        const cloneTeacherRating = teacherRating;

        const findClassificationId = classifications.find((c: any) => c.value === value);

        if (findClassificationId) {
            cloneTeacherRating[objectProperty as keyof TeacherRating] = findClassificationId.id;
            handleRatingChanged(cloneTeacherRating);
        }
    }

    function convertClassificationIdToValue(id: number) {
        const item = classifications.find((c: any) => c.id === id);

        return item ? item.value : id;
    }

    return (
        <div className="container teacher-content">
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col rating-teacher-name" >
                            {teacherRating.teacherName}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {qualities[0].title}
                        </div>
                        <div className="col">
                            <SurveyRating
                                key={0}
                                size={6}
                                displayRating={convertClassificationIdToValue(teacherRating.clarity)}
                                handleRatingChanged={(value: number) => handleTeacherRatingChange("clarity", value)}
                                readonly={props.readOnly}
                                margin={true}
                            />
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: "7px" }}>
                        <div className="col">
                            {qualities[1].title}
                        </div>
                        <div className="col">
                            <SurveyRating
                                key={1}
                                size={6}
                                displayRating={convertClassificationIdToValue(teacherRating.availability)}
                                handleRatingChanged={(value: number) => handleTeacherRatingChange("availability", value)}
                                readonly={props.readOnly}
                                margin={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {qualities[2].title}
                        </div>
                        <div className="col">
                            <SurveyRating
                                key={2}
                                size={6}
                                displayRating={convertClassificationIdToValue(teacherRating.proactivity)}
                                handleRatingChanged={(value: number) => handleTeacherRatingChange("proactivity", value)}
                                readonly={props.readOnly}
                                margin={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {qualities[3].title}
                        </div>
                        <div className="col">
                            <SurveyRating
                                key={3}
                                size={6}
                                displayRating={convertClassificationIdToValue(teacherRating.ability)}
                                handleRatingChanged={(value: number) => handleTeacherRatingChange("ability", value)}
                                readonly={props.readOnly}
                                margin={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}