import { useContext, useEffect, useState } from "react";
import Modal from "../shared/Modal";
import { MessagingContext } from "../App";
import { verifyErrors } from "../../utils/generic";
import { api } from "../../services/api";
import { Education } from "../../interfaces/Education.interface";
import { InsertType } from "./EducationsList";


type EducationFormProps = {
    closeHandler: () => void;
    education: Education | undefined;
    EducationAdded?: (Education: Education) => void;
    EducationEdited?: (Education: Education) => void;
    editing: boolean;
    type: InsertType;
}

type EducationFields = {
    title: string;
}

const emptyFields: EducationFields = {
    title: "",
};

type EducationErrors = {
    title: boolean;
}

const EducationErrorsDescriptions: any = {
    title: "Titulo",
}

export default function EducationForm(props: EducationFormProps) {
    const { editing, closeHandler, education, EducationAdded, EducationEdited, type } = props

    const [fieldsValues, setFieldsValues] = useState(emptyFields);
    const messaging = useContext(MessagingContext);
    const [pending, setPending] = useState(false);

    const actionDisplayText: String = type === InsertType.education ? 'Grau Académico' : 'Area da educação';

    const [errors, setErrors] = useState<EducationErrors>({
        title: false
    });

    useEffect(() => {
        if (education) {
            setFieldsValues({
                title: education.title,
            });
        }
    }, [education])

    useEffect(() => {
        setErrors({
            title: fieldsValues.title === "",
        });
    }, [fieldsValues]);

    function handleSubmit() {

        const errorFound = verifyErrors(errors as EducationErrors);
        if (errorFound) {
            messaging.toast.show("Erro", `Preencha o campo: ${EducationErrorsDescriptions[errorFound]}`);
            return;
        }

        setPending(true);
        const EducationObj: any = {
            title: fieldsValues.title.trim(),
        }

        setPending(true);
        if (editing) {
            const editEducation = { ...EducationObj, id: education?.id };

            if (type === InsertType.education) {
                editEducationApi(editEducation);
            } else {
                editEducationAreaApi(editEducation);
            }
        }
        else {
            if (type === InsertType.education) {
                addEducationApi(EducationObj);
            } else {
                addEducationAreaApi(EducationObj);
            }
        }
    }

    function editEducationApi(educationObj: Education) {
        api.editEducation(educationObj, (e: any) => {
            if (e.status === 500 && e.status === 404) {
                throw new Error(e);
            }
            setPending(false);
            if (EducationEdited) {
                messaging.toast.show("Sucesso", "Educação modificada com sucesso");
                EducationEdited({ ...education, ...educationObj });
            }
        }, (err) => {
            setPending(false);
            messaging.toast.show("Erro", "Erro ao editar a educação");
        });
    }

    function editEducationAreaApi(educationObj: Education) {
        api.editEducationArea(educationObj, (e: any) => {
            if (e.status === 500 && e.status === 404) {
                throw new Error(e);
            }
            setPending(false);
            if (EducationEdited) {
                messaging.toast.show("Sucesso", "Educação modificada com sucesso");
                EducationEdited({ ...education, ...educationObj });
            }
        }, (err) => {
            setPending(false);
            messaging.toast.show("Erro", "Erro ao editar a educação");
        });
    }
    function addEducationApi(educationObj: Education) {
        api.addEducation(educationObj,
            (id: any) => {
                educationObj.id = parseInt(id);
                if (id.status === 500 && id.status === 404) {
                    throw new Error(id);
                }
                setPending(false);

                if (EducationAdded) {
                    messaging.toast.show("Sucesso", "Educação adicionada com sucesso");
                    EducationAdded(educationObj as Education);
                }

            }, (err) => {
                setPending(false);
                messaging.toast.show("Erro", "Erro ao inserir a educação");
            });
    }

    function addEducationAreaApi(educationObj: Education) {
        api.addEducationArea(educationObj,
            (id: any) => {
                educationObj.id = parseInt(id);
                if (id.status === 500 && id.status === 404) {
                    throw new Error(id);
                }
                setPending(false);

                if (EducationAdded) {
                    messaging.toast.show("Sucesso", "Educação adicionada com sucesso");
                    EducationAdded(educationObj as Education);
                }

            }, (err) => {
                setPending(false);
                messaging.toast.show("Erro", "Erro ao inserir a educação");
            });
    }

    return (
        <>
            <Modal
                title={`${editing ? "Editar" : "Nova"} ${actionDisplayText}`}
                body={<>
                    <div className="row">
                        <div className="col m-2">
                            <div className="form-group">
                                <label htmlFor="name">Nome</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    value={fieldsValues.title}
                                    onChange={event => setFieldsValues({ ...fieldsValues, title: event.target.value })}
                                    required />
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col text-center">

                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={() => handleSubmit()}
                                style={{ padding: "5px 40px" }}>
                                {pending ?
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"></span>
                                    : <i className="fas fa-plus-square"></i>
                                }
                                <span style={{ margin: "0px 10px" }}>{`${editing ? "Modificar" : "Adicionar"} ${actionDisplayText}`}</span>
                            </button>
                        </div>
                    </div>
                </>}
                closeName="Fechar"
                closeHandler={closeHandler}
                bodyMaxHeight={500} />
        </>
    );
}