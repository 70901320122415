import React, { useContext, useEffect, useRef, useState } from "react";
import {
    CUSTOMER_TYPE_CARER_ID,
    CUSTOMER_TYPE_COMPANY_ID,
    CUSTOMER_TYPE_PRIVATE_ID,
    CUSTOMER_TYPE_STUDENT_ID
} from "../../constants";
import Customer from "../../models/Customer.model";
import Carer from "../../models/Carer.model";
import Student from "../../models/Student.model";
import { api, NotFoundError } from "../../services/api";
import { MessagingContext } from "../App";
import GenericToggle from "../GenericToggle";
import Loader from "../shared/Loader";
import SearchForm from "../shared/SearchForm";
import { CustomerFields } from "./CustomerForm";
import CustomersTable from "./CustomersTable";
import CarerTable from "../carers/CarerTable";

type CustomerType = {
    name: string;
    id: number;
    search?: string;
    typeId?: number;
};

const TOGGLE_INVOICE_DATA_ID = 1;
const TOGGLE_STUDENT_ID = 2;
const TOGGLE_COMPANY_ID = 3;
const TOGGLE_PRIVATE_CUSTOMER_ID = 4;
const TOGGLE_CARER_ID = 5;

const toggleCustomerTypes: CustomerType[] = [
    { name: "Dados de faturação", id: TOGGLE_INVOICE_DATA_ID },
    { name: "Dados do Aluno", id: TOGGLE_STUDENT_ID },
    { name: "Empresa", id: TOGGLE_COMPANY_ID, search: "Pesquise a empresa", typeId: CUSTOMER_TYPE_COMPANY_ID },
    { name: "Particular", id: TOGGLE_PRIVATE_CUSTOMER_ID, search: "Pesquise o particular", typeId: CUSTOMER_TYPE_PRIVATE_ID },
    { name: "Encarregado", id: TOGGLE_CARER_ID, search: "Pesquise o encarregado", typeId: CUSTOMER_TYPE_CARER_ID }
];

type CustomerSelectionProps = {
    studentId: number;
    setCustomerFieldsValues: (customer: CustomerFields) => void;
};

export default function CustomerSelection(props: CustomerSelectionProps) {
    const { studentId, setCustomerFieldsValues } = props;

    const messaging = useContext(MessagingContext);
    const [customerType, setCustomerType] = useState<CustomerType>(toggleCustomerTypes[0]);
    const [currentSearch, setCurrentSearch] = useState("");
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [carers, setCarers] = useState<Carer[]>([]);
    const [notFoundText, setNotFoundText] = useState("");
    const searchRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (customerType.id === TOGGLE_INVOICE_DATA_ID) {
            api.getStudentCustomer(studentId,
                customer => updateCustomerFields(new Customer(customer)),
                err => {
                    if (err instanceof NotFoundError) {
                        messaging.toast.show("Dados de faturação",
                            "Este aluno não possui dados de faturação");
                    }
                    else {
                        messaging.modal.showMessage("Erro",
                            "Erro a carregar dados de faturação: " + err.message);
                    }
                });
        }
        else if (customerType.id === TOGGLE_STUDENT_ID) {
            api.getStudentInfo(studentId, studentFetched => {
                updateCustomerFieldsFromStudent(new Student(studentFetched));
            }, err => messaging.modal.showMessage("Erro", "Erro ao obter o aluno:" + err.message));
        }

        // eslint-disable-next-line
    }, [customerType, studentId]);

    useEffect(() => {
        if (currentSearch.trim().length === 0) {
            if (customerType.id === TOGGLE_COMPANY_ID) {
                setNotFoundText("Não existem empresas para a pesquisa efetuada");
            }
            else if (customerType.id === TOGGLE_PRIVATE_CUSTOMER_ID) {
                setNotFoundText("Não existem particulares para a pesquisa efetuada");
            }
            else if (customerType.id === TOGGLE_CARER_ID) {
                setNotFoundText("Não existem encarregados de educação para a pesquisa efetuada");
            }
            setCustomers([]);
        }
        else if (customerType.search) {
            if (customerType.id === TOGGLE_CARER_ID) {
                api.getCustomers({
                    search: currentSearch,
                    pageSize: 2
                },
                    carers => setCarers(carers.data.map((carer: any) => new Carer(carer))),
                    () => setCarers([])
                );
            } else {

                api.getCustomers({
                    customer_type_id: customerType.typeId,
                    search: currentSearch,
                    pageSize: 2
                },
                    newCustomers => setCustomers(newCustomers.data.map((customer: any) => new Customer(customer))),
                    () => setCustomers([]));
            }
        }
    }, [currentSearch, customerType]);

    function updateCustomerFieldsByCarer(carer: Carer) {
        setCustomerFieldsValues({
            name: carer.name,
            fiscal_number: carer.fiscalNumber ? carer.fiscalNumber.toString() : "",
            responsible: "",
            address: carer.address,
            postal_code: carer.postalCode,
            city: carer.cityId,
            phone: carer.phone,
            email: carer.email,
            observations: carer.observations,
            customer_type: 3,
            carer: carer.id,
            student: undefined,
            office: carer.officeId
        });
    }
    
    function updateCustomerFields(customer: Customer) {
        setCustomerFieldsValues({
            name: customer.name,
            fiscal_number: customer.fiscalNumber ? customer.fiscalNumber.toString() : "",
            responsible: customer.responsible,
            address: customer.address,
            postal_code: customer.postalCode,
            city: customer.cityId,
            phone: customer.phone,
            email: customer.email,
            observations: customer.observations,
            customer_type: customer.customerTypeId,
            carer: customer.carerId,
            student: customer.studentId,
            office: customer.officeId
        });
    }

    function updateCustomerFieldsFromStudent(student: Student) {
        setCustomerFieldsValues({
            name: student.name,
            fiscal_number: student.fiscalNumber ? student.fiscalNumber.toString() : "",
            responsible: "",
            address: student.address,
            postal_code: student.postalCode,
            city: student.cityId,
            phone: student.phone,
            email: student.email,
            observations: student.observations,
            customer_type: CUSTOMER_TYPE_STUDENT_ID,
            carer: undefined,
            student: student.id,
            office: student.officeId
        });
    }

    return (
        <>
            <GenericToggle
                values={toggleCustomerTypes}
                getId={val => val.id.toString()}
                getOptionContent={val => val.name}
                toggleChanged={newType => setCustomerType(newType)}
            />

            {customerType.search &&
                <>
                    <SearchForm
                        label={customerType.search || "Pesquise"}
                        onChangeHandler={event => setCurrentSearch(event.target.value)}
                        searchRef={searchRef} />

                    {customerType.id === TOGGLE_CARER_ID ?
                        carers ?
                            <CarerTable
                                values={carers}
                                selectHandler={carer => updateCustomerFieldsByCarer(carer)}
                                notFoundText={notFoundText} /> :
                            <Loader />

                        :
                        customers ?
                            <CustomersTable
                                values={customers}
                                selectHandler={customer => updateCustomerFields(customer)}
                                notFoundText={notFoundText} /> :
                            <Loader />
                    }
                </>
            }
        </>
    );
}