import { CSSProperties, useCallback, useEffect } from "react";

export interface ModalProps {
    title: string;
    body: any;
    closeName?: string;
    closeHandler: () => void;
    showAction?: boolean;
    actionName?: string;
    actionHandler?: () => void;
    actionClassName?: string;
    style?: CSSProperties;
    dialogClasses?: string;
    bodyMaxHeight?: number;
    actionPending?: boolean;
}

const DEFAULT_MAX_BODY_HEIGHT = 350;
const DEFAULT_ACTION_NAME = "Ok";
const DEFAULT_CLOSE_NAME = "Fechar";

export default function Modal(props: ModalProps) {
    const { closeHandler } = props;

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            closeHandler();
        }

    }, [closeHandler]);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    function handleOk() {
        if (props.actionHandler) {
            props.actionHandler();
        }
    }

    const styles = props.style || {};
    const bodyStyle = {
        overflow: "auto",
        maxHeight: (props.bodyMaxHeight || DEFAULT_MAX_BODY_HEIGHT) + "px"
    };

    return (
        <div
            className="modal"
            role="dialog"
            aria-hidden="true"
            style={{ ...styles, display: "block" }}>
            <div
                className={`modal-dialog modal-dialog-centered ${props.dialogClasses || ""}`}
                role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" >{props.title}</h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style={{ border: "0", backgroundColor: "white" }}
                            onClick={props.closeHandler}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={bodyStyle}>{props.body}</div>
                    <div className="modal-footer">
                        {props.showAction &&
                            <button
                                type="button"
                                className={`btn btn-primary ${props.actionClassName || ""}`}
                                onClick={handleOk}>{props.actionName || DEFAULT_ACTION_NAME}</button>}
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={props.closeHandler}>{props.closeName || DEFAULT_CLOSE_NAME}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}