import { CSSProperties, useContext, useEffect, useState } from "react";
import { getAge, toDatePT, toInputDate } from "../../../utils/dates";
import Student from "../../../models/Student.model";
import Card from "../../shared/Card";
import styles from "../../shared/Card.module.css";
import { StudentDataFields } from "../forms/StudentForm";
import ConfirmCancelIcons from "../../shared/ConfirmCancelIcons";
import GenericCombo from "../../shared/GenericCombo";
import City from "../../../interfaces/City.interface";
import { civilStates, genders } from "../../../constants";
import { api } from "../../../services/api";
import { MessagingContext } from "../../App";
import Loader from "../../shared/Loader";
import { FieldsEdited } from "../../../types/FieldsEdited";

type StudentDataCardProps = {
    value: Student;
    style?: CSSProperties;
    fieldsEditedCallback: (params: FieldsEdited<StudentDataFields>) => void;
    setStudent: (newStudent: Student) => void;
}

export default function StudentDataCard(props: StudentDataCardProps) {
    const student = props.value;
    const messaging = useContext(MessagingContext);
    const [editMode, setEditMode] = useState(false);
    const [cities, setCities] = useState<City[] | undefined>();
    const [editing, setEditing] = useState(false);



    const [fieldsValues, setFieldsValues] = useState<StudentDataFields>({
        name: student.name || "",
        email: student.email,
        phone: student.phone,
        address: student.address,
        postal_code: student.postalCode,
        cityId: student.cityId,
        birth_date: toInputDate(student.birthDate),
        gender: student.gender,
        civilState: student?.civilState,
        documentCC: student?.documentCC,
        documentCV: student?.documentCV,
        documentCH: student?.documentCH,
    });

    const [selectedCityName, setSelectedCityName] = useState(student.city);

    useEffect(() => {
        if (editMode && !cities) {
            api.getCities(cities => setCities(cities), err => setCities([]));
        }
    }, [editMode, cities]);

    function handleConfirm() {
        setEditing(true);
        props.fieldsEditedCallback({
            newValues: fieldsValues,
            success: () => {
                const newStudent = student.clone();
                newStudent.city = selectedCityName;
                newStudent.birthDate = new Date(fieldsValues.birth_date);
                props.setStudent(newStudent);
                setEditing(false);
                setEditMode(false);
            },
            fail: err => {
                setEditing(false);
                messaging.modal.showMessage("Erro", "Erro a editar os dados do aluno: " + err.message);
            }
        });
    }



    function handleCityChange(city: number | string | City) {
        const newCityId = city as number;
        setFieldsValues({ ...fieldsValues, cityId: newCityId });
        const newCity = cities?.find(city => city.id === newCityId);
        if (newCity) {
            setSelectedCityName(newCity.title);
        }
    }

    function handleChange(event: any) {
        setFieldsValues(
            { ...fieldsValues, [event.target.id]: event.target.checked }
        )
    }

    return (
        <Card
            keyId={student.id.toString()}
            style={props.style || {}}
            title={
                <div>Pessoais
                    {editMode ?
                        (editing ?
                            <Loader
                                outerClassName=""
                                outterStyle={{ display: "inline" }}
                                style={{ marginLeft: "10px" }}
                                size="small" /> :
                            <ConfirmCancelIcons
                                confirmHandler={handleConfirm}
                                cancelHandler={() => setEditMode(false)} />
                        ) :
                        <i
                            className="fas fa-pen action-icon"
                            style={{ margin: "5px 0 0 10px" }}
                            title="Editar"
                            onClick={() => setEditMode(true)}></i>
                    }
                </div>
            }
            includeHeader={true}
            headerStyle={{ fontSize: "1.1rem" }}
            body={
                <table className="table table-striped" >
                    <tbody>
                        <tr>
                            <td className={styles.titleField}>Nome</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        value={fieldsValues.name}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, name: event.target.value }
                                        )}
                                        required /> :
                                    student.name
                                }
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Email</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        value={fieldsValues.email || ""}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, email: event.target.value }
                                        )} /> :
                                    student.email
                                }
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Telefone</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="phone"
                                        value={fieldsValues.phone || ""}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, phone: event.target.value }
                                        )} /> :
                                    student.phone
                                }
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Morada</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="address"
                                        value={fieldsValues.address || ""}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, address: event.target.value }
                                        )} /> :
                                    student.address
                                }
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Código Postal</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <div className="d-flex">
                                        <div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="postal_code"
                                                value={fieldsValues.postal_code || ""}
                                                onChange={event => setFieldsValues(
                                                    { ...fieldsValues, postal_code: event.target.value }
                                                )} />
                                        </div>
                                        <div>
                                            <GenericCombo
                                                value={fieldsValues.cityId}
                                                values={cities}
                                                getId={city => city.id}
                                                getOptionContent={city => city.title}
                                                selectionChanged={handleCityChange} />
                                        </div>
                                    </div> :
                                    student.postalCode + " " + (student.city || "")
                                }
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Data Nascimento</td>
                            <td className={styles.infoField}>{
                                editMode ?
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="date"
                                        value={fieldsValues.birth_date || ""}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, birth_date: event.target.value }
                                        )} /> :
                                    toDatePT(student.birthDate)
                            }
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Idade</td>
                            <td className={styles.infoField}>
                                {getAge(editMode ? new Date(fieldsValues.birth_date) : student.birthDate)} anos
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Sexo</td>
                            <td className={styles.infoField}>{
                                editMode ?
                                    <GenericCombo
                                        value={fieldsValues.gender}
                                        values={genders}
                                        getId={gender => gender.val}
                                        getOptionContent={gender => gender.name}
                                        selectionChanged={newGender => setFieldsValues(
                                            { ...fieldsValues, gender: newGender as string }
                                        )} /> :
                                    student.getGenderDescription()}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Estado Civil</td>
                            <td className={styles.infoField}>{
                                editMode ?
                                    <GenericCombo
                                        value={fieldsValues.civilState}
                                        values={civilStates}
                                        getId={state => state.val}
                                        getOptionContent={state => state.name}
                                        selectionChanged={newState => setFieldsValues(
                                            { ...fieldsValues, civilState: newState as string }
                                        )} /> :
                                    student.getCivilStateDescription()}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Documentos</td>
                            <td className={styles.infoField}>
                                <div className={styles.deliveredDocumentsContainer}>
                                    <input type="checkbox" className="btn-check" id="documentCC"
                                        onChange={handleChange}
                                        checked={fieldsValues.documentCC}
                                        disabled={!editMode} />
                                    <label className="btn btn-outline-secondary" htmlFor="documentCC">Documento de Identificação</label>

                                    <input type="checkbox" className="btn-check" id="documentCV"
                                        onChange={handleChange}
                                        checked={fieldsValues.documentCV}
                                        disabled={!editMode} />
                                    <label className="btn btn-outline-secondary" htmlFor="documentCV">Curriculo</label>

                                    <input type="checkbox" className="btn-check" id="documentCH"
                                        onChange={handleChange}
                                        checked={fieldsValues.documentCH}
                                        disabled={!editMode} />
                                    <label className="btn btn-outline-secondary" htmlFor="documentCH">Certificado de Habilitações</label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
            width={500} />
    );
}