import { useState, useEffect, useRef, useContext } from 'react';
import { MILLISECONDS_LIST_WAIT } from '../../constants';
import Pack from '../../models/Pack.model';
import { api } from '../../services/api';
import { dealyForXSeconds } from '../../utils/generic';
import { MessagingContext } from '../App';
import AtiveToggle, { AtiveToggleOptions } from '../AtiveToggle';
import SearchForm from '../shared/SearchForm';
import PackForm from './PackForm';
import PacksTable from './PackTable';

const toggleAllOption = 0;

export default function PacksList() {

    const messaging = useContext(MessagingContext);

    const [currentPage, setCurrentPage] = useState(1);
    const [currentSearch, setCurrentSearch] = useState("");
    const [visibleStateOption, setVisibleStateOption] = useState(1);
    const [courseCategoryOption, setCourseCategoryOption] = useState(0);

    const [packs, setPacks] = useState<Pack[]>([]);
    const [selectedPack, setSelectedPack] = useState<Pack | undefined>(undefined);

    const [editing, setEditing] = useState(false);
    const [showInsertModal, setShowInsertModal] = useState(false);

    const searchRef = useRef<HTMLInputElement | null>(null);


    useEffect(() => {

        let sendParams: any = {
            search: currentSearch,
            page: currentPage,
        }

        if (visibleStateOption !== toggleAllOption) {
            sendParams.visible = visibleStateOption;
        }
        if (courseCategoryOption !== toggleAllOption) {
            sendParams.course_category = courseCategoryOption;
        }

        if (courseCategoryOption !== toggleAllOption) {
            sendParams.course_category = courseCategoryOption;
        }

        api.getPacksWithFilters(sendParams,
            newPacks => {
                setPacks(newPacks.map((pack: any) => new Pack(pack)));
            },
            () => setPacks([])
        );

        searchRef.current?.focus();
    }, [currentPage, currentSearch, visibleStateOption, courseCategoryOption]);


    function addPackToList(newPack: Pack) {
        if (packs) {
            const newCoursesList = [...packs, newPack];
            setPacks(newCoursesList.sort((a: Pack, b: Pack) => a.title.localeCompare(b.title)));
            setShowInsertModal(false);
        }
    }

    function removePackInList(id: number) {
        if (packs) {
            setPacks(packs.filter((c: Pack) => c.id !== id));
        }
    }

    function deletePackApi(id: number) {

        api.deletePack(id,
            (response) => {
                if (response.status === 204) {
                    removePackInList(id);
                    setSelectedPack(undefined);
                } else {
                    messaging.toast.show("Erro", "Não foi possivel remover este Pack");
                }
            },
            err => {
                messaging.toast.show("Erro", "Erro a remover o pack! " + err);
            })
    }
    function replacePackInList(updatedPack: Pack) {
        if (packs) {
            const newCourses = packs.map((c: Pack) => c.id === updatedPack.id ? updatedPack : c);
            setPacks(newCourses);
            setShowInsertModal(false);
            setEditing(false);
            setSelectedPack(undefined);
        }
    }

    const activeOptions: AtiveToggleOptions[] = [
        { id: 0, name: "Tudo" },
        { id: 1, name: "Ativo" },
        { id: 2, name: "Inativo" },
    ];

    const options: AtiveToggleOptions[] = [
        { id: 0, name: "Tudo" },
        { id: 1, name: "Utilizadores" },
        { id: 2, name: "Utilizadores Expert" },
        { id: 3, name: "Web Creation" },
        { id: 4, name: "Programação" },
        { id: 5, name: "Grafismo" },
    ];

    return (
        <>
            {
                showInsertModal &&
                <PackForm
                    closeHandler={() => setShowInsertModal(false)}
                    pack={selectedPack}
                    packAdded={addPackToList}
                    packEdited={replacePackInList}
                    editing={editing}
                />
            }

            <div className="list-container container" style={{ position: "relative" }}>
                <button
                    className="btn btn-success new-list-button"
                    onClick={() => {
                        setEditing(false);
                        setSelectedPack(undefined);
                        setShowInsertModal(true);
                    }
                    }>Novo Pack</button>
                <h2 className="text-center m-4">Packs de cursos</h2>
                <SearchForm
                    label="Pesquisar por packs"
                    onChangeHandler={event => {
                        dealyForXSeconds(MILLISECONDS_LIST_WAIT, () => {
                            setCurrentSearch(event.target.value);
                            setCurrentPage(1);
                        });
                    }}
                    style={{ marginBottom: "20px" }}
                    searchRef={searchRef}
                    searchHint="Pode pesquisar por nome" />

                <div className="d-flex filterContainer">
                    <div>
                        <span className="lateralMargin10">Visivel:</span>
                        <AtiveToggle activeChanged={setVisibleStateOption} options={activeOptions}
                            activeOption={visibleStateOption} />
                    </div>
                    <div>
                        <span className="lateralMargin10">Categoria:</span>
                        <AtiveToggle activeChanged={setCourseCategoryOption} options={options}
                            activeOption={courseCategoryOption} />
                    </div>
                </div>
                <PacksTable
                    values={packs}
                    handleEditPack={(c: Pack) => {
                        setSelectedPack(c);
                        setShowInsertModal(true);
                        setEditing(true);
                    }}
                    handleDeletePack={deletePackApi}
                />

            </div>
        </>
    );
}