import { useState, useEffect, useContext, SyntheticEvent } from "react";
import { CUSTOMER_TYPE_COMPANY_ID, CUSTOMER_TYPE_PRIVATE_ID } from "../../constants";
import City from "../../interfaces/City.interface";
import CustomerType from "../../interfaces/CustomerType.interface";
import { api, getStorageInfo } from "../../services/api";
import { verifyErrors } from "../../utils/generic";
import { MessagingContext } from "../App";
import CityCombo from "../combos/CityCombo";
import OfficeCombo from "../combos/OfficeCombo";
import { RedirectionContext, Routes } from "../MainRouter";
import ActionButton from "../shared/ActionButton";
import GenericCombo from "../shared/GenericCombo";

export type CustomerFields = {
    name: string;
    fiscal_number: string;
    responsible: string;
    address: string;
    postal_code: string;
    city: number | undefined;
    phone: string;
    email: string;
    observations: string;
    customer_type: number | undefined;
    carer: number | undefined;
    student: number | undefined;
    office: number | undefined;
}

export type CustomerErrors = {
    office: boolean;
    customer_type: boolean;
    city: boolean;
}

const customerErrorsDescriptions: any = {
    city: "Localidade",
    customer_type: "Tipo de Cliente",
    office: "Filial",
}


export const emptyCustomerFields: CustomerFields = {
    name: "",
    fiscal_number: "",
    responsible: "",
    address: "",
    postal_code: "",
    city: undefined,
    phone: "",
    email: "",
    observations: "",
    customer_type: undefined,
    carer: undefined,
    student: undefined,
    office: undefined
};

export default function CustomerForm() {
    const messaging = useContext(MessagingContext);
    const redirection = useContext(RedirectionContext);

    const [fieldsValues, setFieldsValues] = useState(emptyCustomerFields);
    const [errors, setErrors] = useState<CustomerErrors>({
        office: false,
        customer_type: false,
        city: false
    });
    const [pending, setPending] = useState(false);

    const [cities, setCities] = useState<City[] | undefined>(undefined);
    const [customerTypes, setCustomerTypes] = useState<CustomerType[]>([]);

    useEffect(() => {
        api.getCustomerComboboxesInfo((data: any) => {
            setCities(data.cities);
            setCustomerTypes(data.customer_types.filter((type: CustomerType) =>
                type.id === CUSTOMER_TYPE_PRIVATE_ID || type.id === CUSTOMER_TYPE_COMPANY_ID));
        }, err => {

        });
    }, []);

    useEffect(() => {
        setErrors({
            office: fieldsValues.office === undefined,
            customer_type: fieldsValues.customer_type === undefined,
            city: fieldsValues.city === undefined,
        });
    }, [fieldsValues]);

    function handleSubmit(event: SyntheticEvent) {
        event.preventDefault();

        const errorFound = verifyErrors(errors as CustomerErrors);
        if (errorFound) {
            messaging.toast.show("Erro", `Preencha o campo: ${customerErrorsDescriptions[errorFound]}`);
            return;
        }

        const customerInfo = {
            name: fieldsValues.name,
            fiscal_number: fieldsValues.fiscal_number,
            responsible: fieldsValues.responsible,
            address: fieldsValues.address,
            postal_code: fieldsValues.postal_code,
            city_id: fieldsValues.city,
            phone: fieldsValues.phone,
            email: fieldsValues.email,
            observations: fieldsValues.observations,
            customer_type_id: fieldsValues.customer_type,
            carer: fieldsValues.carer,
            student: fieldsValues.student,
            office_id: fieldsValues.office,
            user_id: getStorageInfo().userId,
        };

        setPending(true);

        api.addCustomer(customerInfo, id => {
            setPending(false);
            messaging.toast.show("Clientes", "Cliente adicionado");
            redirection.redirectTo(Routes.customers);
        }, () => {
            setPending(false);
            messaging.modal.showMessage("Erro", "Erro na criação do cliente");
        });
    }

    return (
        <div className="container p-4">
            <h2 className="text-center m-4">Novo Cliente</h2>
            <form onSubmit={handleSubmit}>
                <div className="card p-3">
                    <h4 className="m-2">Dados</h4>
                    <div className="row">
                        <div className="col col-md-5 m-2">
                            <div className="form-group">
                                <label htmlFor="name">Nome</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    value={fieldsValues.name || ""}
                                    onChange={event => setFieldsValues({ ...fieldsValues, name: event.target.value })}
                                    required />
                            </div>
                        </div>
                        <div className="col col-md-4 m-2">
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    value={fieldsValues.email || ""}
                                    onChange={event => setFieldsValues({ ...fieldsValues, email: event.target.value })} />
                            </div>
                        </div>
                        <div className="col col-md m-2">
                            <div className="form-group">
                                <label htmlFor="phone">Telefone</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="phone"
                                    value={fieldsValues.phone || ""}
                                    onChange={event => setFieldsValues({ ...fieldsValues, phone: event.target.value })} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-md-5 m-2">
                            <div className="form-group">
                                <label htmlFor="address">Morada</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="address"
                                    value={fieldsValues.address || ""}
                                    onChange={event => setFieldsValues({ ...fieldsValues, address: event.target.value })} />
                            </div>
                        </div>
                        <div className="col col-md-4 m-2">
                            <div className="form-group">
                                <label htmlFor="postal_code">Código Postal</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="postal_code"
                                    value={fieldsValues.postal_code || ""}
                                    onChange={event => setFieldsValues({ ...fieldsValues, postal_code: event.target.value })} />
                            </div>
                        </div>
                        <div className="col col-md m-2">
                            <div className="form-group">
                                <label>Localidade</label>
                                <CityCombo
                                    value={cities?.find((c: City) => c.id === fieldsValues.city)}
                                    availableCities={cities}
                                    selectionChanged={(city: City | undefined) => setFieldsValues({ ...fieldsValues, city: city?.id })}
                                    cityInserted={((list: City[]) => setCities([...list]))}
                                    showError={errors.city}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col m-2">
                            <div className="form-group">
                                <label htmlFor="fiscal_number">NIF</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="fiscal_number"
                                    value={fieldsValues.fiscal_number || ""}
                                    onChange={event => setFieldsValues({ ...fieldsValues, fiscal_number: event.target.value })} />
                            </div>
                        </div>
                        <div className="col m-2">
                            <div className="form-group">
                                <label>Responsável</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="postal_code"
                                    value={fieldsValues.responsible || ""}
                                    onChange={event => setFieldsValues({ ...fieldsValues, responsible: event.target.value })} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col m-2">
                            <label>Filial</label>
                            <OfficeCombo
                                showError={errors.office}
                                value={fieldsValues.office}
                                selectionChanged={newOffice =>
                                    setFieldsValues({ ...fieldsValues, office: newOffice })} />
                        </div>
                        <div className="col m-2">
                            <div className="form-group">
                                <label>Tipo de Cliente</label>
                                <GenericCombo
                                    value={fieldsValues.customer_type}
                                    showError={errors.customer_type}
                                    values={customerTypes}
                                    getId={customerType => customerType.id}
                                    getOptionContent={customerType => customerType.title}
                                    selectionChanged={customerType =>
                                        setFieldsValues({ ...fieldsValues, customer_type: customerType as number })
                                    } />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col m-2">
                            <label htmlFor="obs">Observações</label>
                            <textarea
                                className="form-control"
                                id="obs"
                                rows={3}
                                value={fieldsValues.observations || ""}
                                onChange={event => setFieldsValues({ ...fieldsValues, observations: event.target.value })}></textarea>
                        </div>
                    </div>
                </div>

                <div className="row" style={{ marginTop: "30px" }}>
                    <div className="col text-center">
                        <ActionButton
                            pending={pending}
                            icon="fas fa-plus-square"
                            text="Criar" />
                    </div>
                </div>
            </form>
        </div>
    );
}