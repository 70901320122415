export default class TeacherRating {

    [key: string]: string | number;
    public clarity: number;
    public availability: number;
    public proactivity: number;
    public ability: number;
    public teacherName: string;
    public teacherPhoto: string;
    public teacherId: number;


    constructor(data: any) {

        this.clarity = data.clarity || 0;
        this.availability = data.availability || 0;
        this.proactivity = data.proActivity || 0;
        this.ability = data.ability || 0;

        this.teacherName = data.teacherName;
        this.teacherPhoto = data.teacherPhoto;
        this.teacherId = data.teacherId;
    }
}