import { CSSProperties, useContext, useEffect, useState } from "react";
import Customer from "../../models/Customer.model";
import City from "../../interfaces/City.interface";
import CustomerType from "../../interfaces/CustomerType.interface";
import Card from "../shared/Card";
import { CUSTOMER_TYPE_COMPANY_ID, CUSTOMER_TYPE_PRIVATE_ID } from "../../constants";
import styles from "../shared/Card.module.css";
import { CustomerFields, emptyCustomerFields, CustomerErrors } from './CustomerForm';
import GenericCombo from "../shared/GenericCombo";
import { api } from "../../services/api";
import OfficeTag from "../tags/OfficeTag";
import ConfirmCancelIcons from "../shared/ConfirmCancelIcons";
import Loader from "../shared/Loader";
import OfficeCombo from "../combos/OfficeCombo";
import { Link } from "react-router-dom";
import { MessagingContext } from "../App";
import { verifyErrors } from "../../utils/generic";

type CustomerCardProps = {
    customer: Customer;
    style?: CSSProperties;
    showResponsible?: boolean;
    handleChange: (newCustomer: Customer) => void;
}



export default function CustomerCard(props: CustomerCardProps) {
    const { customer, style, handleChange } = props;

    const messaging = useContext(MessagingContext);

    const [editMode, setEditMode] = useState(false);
    const [editing, setEditing] = useState(false);

    const [fieldsValues, setFieldsValues] = useState<CustomerFields>(emptyCustomerFields);

    const [loading, setLoading] = useState(false);

    const [cities, setCities] = useState<City[] | undefined>(undefined);
    const [customerTypes, setCustomerTypes] = useState<CustomerType[]>([]);

    const [errors, setErrors] = useState<CustomerErrors>({
        office: false,
        customer_type: false,
        city: false
    });

    useEffect(() => {
        setErrors({
            office: fieldsValues.office === 0,
            customer_type: fieldsValues.customer_type === 0,
            city: fieldsValues.city === 0
        });
    }, [fieldsValues]);

    useEffect(() => {
        api.getCustomerComboboxesInfo((data: any) => {
            setCities(data.cities);
            setCustomerTypes(data.customer_types.filter((type: CustomerType) =>
                type.id === CUSTOMER_TYPE_PRIVATE_ID || type.id === CUSTOMER_TYPE_COMPANY_ID));
        }, err => {
            setCities([]);
            setCustomerTypes([]);

        });

        if (editMode && loading === false) {
            setLoading(true);

            setFieldsValues({
                name: customer.name,
                address: customer.address,
                city: customer.cityId,
                email: customer.email,
                phone: customer.phone,
                fiscal_number: customer.fiscalNumber?.toString() ?? "",
                observations: customer.observations,
                postal_code: customer.postalCode,
                responsible: customer.responsible,
                customer_type: customer.customerTypeId,
                carer: customer.carerId,
                student: customer.studentId,
                office: customer.officeId,
            });

            setLoading(false);

        }

    }, [editMode, customer.address, customer.carerId, customer.cityId, customer.customerTypeId, customer.email, customer.fiscalNumber, customer.name, customer.observations, customer.officeId, customer.phone, customer.postalCode, customer.responsible, customer.studentId, loading]);



    function updateCustomerObject() {
        let clone = customer.clone();


        clone.name = fieldsValues.name;
        clone.fiscalNumber = fieldsValues.fiscal_number as unknown as number;
        clone.responsible = fieldsValues.responsible;
        clone.address = fieldsValues.address;
        clone.postalCode = fieldsValues.postal_code;
        clone.cityId = fieldsValues.city;
        clone.phone = fieldsValues.phone;
        clone.email = fieldsValues.email;
        clone.observations = fieldsValues.observations;
        clone.customerTypeId = fieldsValues.customer_type;
        clone.officeId = fieldsValues.office ?? customer.officeId;

        handleChange(clone);

    }

    function handleConfirm() {
        setEditing(true);

        const errorFound = verifyErrors(errors as CustomerErrors);
        if (errorFound) {
            messaging.toast.show("Erro", `Preencha o campo: ${errorFound}`);
            return;
        }

        const customerInfo = {
            id: customer.id,
            name: fieldsValues.name,
            fiscal_number: fieldsValues.fiscal_number,
            responsible: fieldsValues.responsible,
            address: fieldsValues.address,
            postal_code: fieldsValues.postal_code,
            city_id: fieldsValues.city,
            phone: fieldsValues.phone,
            email: fieldsValues.email,
            observations: fieldsValues.observations,
            customer_type_id: fieldsValues.customer_type,
            office_id: fieldsValues.office
        };

        api.editCustomer(customerInfo,
            data => {
                updateCustomerObject();
                messaging.toast.show("Clientes", "Cliente editado");
                setEditing(false);
                setEditMode(false);

            }, err => {
                setEditing(false);
                messaging.modal.showMessage("Erro", "Erro a editar os dados do aluno: " + err.message);
            });
    }

    return (
        <Card
            style={style || {}}
            keyId={customer.id.toString()}
            title={
                <div>Cliente
                    {editMode ?
                        (editing ?
                            <Loader
                                outerClassName=""
                                outterStyle={{ display: "inline" }}
                                style={{ marginLeft: "10px" }}
                                size="small" /> :
                            <ConfirmCancelIcons
                                confirmHandler={handleConfirm}
                                cancelHandler={() => setEditMode(false)} />
                        ) :
                        <i
                            className="fas fa-pen action-icon"
                            style={{ margin: "5px 0 0 10px" }}
                            title="Editar"
                            onClick={() => setEditMode(true)}></i>
                    }

                </div>
            }
            includeHeader={true}
            headerStyle={{ fontSize: "1.1rem" }}
            body={
                <table className="table table-striped" >
                    <tbody>
                        <tr>
                            <td className={styles.titleField}>Nome</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        value={fieldsValues.name || ""}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, name: event.target.value }
                                        )} /> :
                                    customer.name}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Morada</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="address"
                                        value={fieldsValues.address || ""}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, address: event.target.value }
                                        )} /> :
                                    customer.address}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Código Postal</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="postal_code"
                                        value={fieldsValues.postal_code || ""}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, postal_code: event.target.value }
                                        )} /> :
                                    (customer.postalCode)}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Email</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        value={fieldsValues.email || ""}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, email: event.target.value }
                                        )} /> :
                                    (customer.email)}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Telefone</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="phone"
                                        value={fieldsValues.phone || ""}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, phone: event.target.value }
                                        )} /> :
                                    (customer.phone)}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>NIF</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="fiscal_number"
                                        value={fieldsValues.fiscal_number || ""}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, fiscal_number: event.target.value }
                                        )} /> :
                                    (customer.fiscalNumber)}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Localidade</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <GenericCombo
                                        value={fieldsValues.city}
                                        values={cities}
                                        getId={city => city.id}
                                        getOptionContent={city => city.title}
                                        selectionChanged={city =>
                                            setFieldsValues({ ...fieldsValues, city: city as number })
                                        } /> :
                                    customer.city}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Tipo de Cliente</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <GenericCombo
                                        value={fieldsValues.customer_type}
                                        values={customerTypes}
                                        getId={customerType => customerType.id}
                                        getOptionContent={customerType => customerType.title}
                                        selectionChanged={customerType =>
                                            setFieldsValues({ ...fieldsValues, customer_type: customerType as number })
                                        } /> :
                                    customer.customerType}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Observações</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="observations"
                                        value={fieldsValues.observations || ""}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, observations: event.target.value }
                                        )} /> :
                                    (customer.observations)}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Filial</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <OfficeCombo
                                        showError={errors.office}
                                        value={fieldsValues.office}
                                        selectionChanged={newOffice =>
                                            setFieldsValues({ ...fieldsValues, office: newOffice })} /> :
                                    <OfficeTag office={customer.officeId} />}
                            </td>
                        </tr>
                        {customer.studentId ? <tr>
                            <td className={styles.titleField}>Aluno</td>
                            <td className={styles.infoField}>
                                <Link to={`/alunos/${customer.studentId}/`}>{customer.student}</Link>
                            </td>
                        </tr>
                            :
                            null
                        }
                        {customer.carerId ? <tr>
                            <td className={styles.titleField}>Encarregado de Educação</td>
                            <td className={styles.infoField}>
                                <Link to={`/encarregado-educacao/${customer.carerId}/`}>{customer.carer}</Link>
                            </td>
                        </tr>
                            :
                            null
                        }

                    </tbody>
                </table>
            }
            width={550} />
    );
}