import NumberTag from "../tags/NumberTag";

type ListTotalComponentProps = {
    totalValue: number;
    totalPerPage?: number;
    hidePageTotal: boolean;
}

export default function ListTotalComponent(props: ListTotalComponentProps) {

    const { totalValue: total, totalPerPage, hidePageTotal } = props;

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'Eur'
    });

    return (
        <div >
            <dl className="row" style={{ fontSize: 20 }}>
                <dt style={{ fontWeight: 200 }} className="col-sm-2">Total desta página:</dt>
                <dd style={{ fontWeight: 200 }} className="col-sm-9"><NumberTag name={`${formatter.format(total)}`} /></dd>

                {
                    hidePageTotal && totalPerPage ?
                        <>
                            <dt style={{ fontWeight: 200, marginTop: 14 }} className="col-sm-2">Total:</dt>
                            <dd style={{ fontWeight: 200, marginTop: 14 }} className="col-sm-9"><NumberTag name={`${formatter.format(totalPerPage)}`} /></dd>
                        </>
                        : undefined
                }
            </dl>
        </div>

    );
}