import { Moment } from "moment";
import { quantifier } from "./generic";

const DAYS_PER_WEEK = 7;
const DAYS_PER_MONTH = 30.42;
const DAYS_PER_YEAR = 365.25;

export function toInputDate(date: Date) {
    return toISODate(date);
}

export function toInputDateTime(moment: Moment){
    return moment.format("YYYY-MM-DDTHH:mm");
}

export function toDatePT(date: Date) {
    return date.toLocaleDateString("pt-PT");
}

export function toTimePT(date: Date) {
    return date.toLocaleTimeString("pt-PT");
}

export function toDateTimePT(date: Date){
    return toDatePT(date) + " " + toTimePT(date);
}

export function getAge(birthDate: Date) {
    return Math.floor((new Date().getTime() - new Date(birthDate).getTime()) / 3.15576e+10);
}

export function diffInDays(startDate: Date, endDate: Date): number {
    return (endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24;
}

export function daysToHumanTime(days: number): string {
    if (days < DAYS_PER_WEEK) {
        return quantifier(days, "dia", "dias");
    }
    else if (days < DAYS_PER_MONTH) {
        const weeks = Math.floor(days / DAYS_PER_WEEK);
        const daysLeft = days % DAYS_PER_WEEK;
        return quantifier(weeks, "semana", "semanas") +
            (daysLeft > 0 ? " e " + quantifier(daysLeft, "dia", "dias") : "");
    }
    else if (days < DAYS_PER_YEAR) {
        const months = Math.floor(days / DAYS_PER_MONTH);
        const weeks = Math.floor((days % DAYS_PER_MONTH) / DAYS_PER_WEEK);
        return quantifier(months, "mês", "meses") +
            (weeks > 0 ? " e " + quantifier(weeks, "semana", "semanas") : "");
    }
    else {
        const years = Math.floor(days / DAYS_PER_YEAR);
        const months = Math.floor((days % DAYS_PER_YEAR) / DAYS_PER_MONTH);

        return quantifier(years, "ano", "anos") +
            (months > 0 ? " e " + quantifier(months, "mês", "meses") : "");
    }
}

export function toISODate(date: Date) {
    return date.getFullYear() + '-' +
        ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
        ('0' + date.getDate()).slice(-2);
}
