import { contactPurposes } from '../../../constants';

interface ContactPurposeComboProps {
    value: number | undefined;
    selectionChanged: (newVal: number) => void;
    showError?: boolean;
}

export default function ContactPurposeCombo(props: ContactPurposeComboProps) {
    return (
        <>
            <select
                value={props.value}
                className="form-select"
                onChange={event => props.selectionChanged(parseInt(event.target.value))}>

                <option value={0}>Escolha o objetivo</option>
                {contactPurposes.map(purpose =>
                    <option value={purpose.id} key={purpose.id}>{purpose.name}</option>
                )}
            </select>
            {props.showError &&
                <div className="invalid-feedback" style={{ display: "block" }}>
                    Precisa de escolher um objetivo de contacto
                </div>
            }
        </>
    );
}