import { useContext, useEffect, useState } from "react";
import Survey from "../../models/Survey.model";
import Card from "../shared/Card";
import { toInputDate, toInputDateTime } from "../../utils/dates";
import SurveyRating from "../shared/SurveyRating";
import './survey.styles.css';
import ConfirmCancelIcons from "../shared/ConfirmCancelIcons";
import { api, getStorageInfo } from "../../services/api";
import { Teacher } from "../../types/Teacher";
import TeacherRating from "../../models/TeacherRating.model";
import TeacherRatingCard from "../shared/TeacherRatingCard";
import YesOrNoButtons from "../shared/YesOrNoButtons";
import moment from "moment";
import { MessagingContext } from "../App";
import TeacherCombo from "../combos/TeacherCombo";
import SurveyTrainingsSelectionModal from "./SurveyTrainingsSelectionModal";
import Training from "../../models/Training.model";
import CourseTag from "../tags/CourseTag";
import { classifications } from "../../constants";
import Loader from "../shared/Loader";
import { verifyErrors } from "../../utils/generic";


type SurveyFieldValues = {
    [key: string]: any;
    classification_general: number,
    classification_method: number,
    aspects_method: string,
    classification_manual: number,
    aspects_manual: string,
    classification_service: number,
    aspects_service: string,
    classification_facilities: number,
    aspects_facilities: string,
    classification_platform: number,
    aspects_platform: string,
    comments: string,
    met_expectations: number,
    why_expectations: string,
    permission: number,
    aspects_teachers: string,
    overall_rating: number,
    survey_date: string,
    classification_teacher_overall: number,
    excerpt: string,
    teacherRatings: TeacherRating[] | undefined
}

const emptyFields: SurveyFieldValues = {
    classification_general: 0,
    classification_method: 0,
    aspects_method: "",
    classification_manual: 0,
    aspects_manual: "",
    classification_service: 0,
    aspects_service: "",
    classification_facilities: 0,
    aspects_facilities: "",
    classification_platform: 0,
    aspects_platform: "",
    comments: "",
    met_expectations: 1,
    why_expectations: "",
    permission: 1,
    aspects_teachers: "",
    overall_rating: 0,
    survey_date: toInputDateTime(moment()),
    classification_teacher_overall: 0,
    excerpt: "",
    teacherRatings: undefined
}

type SurveyErrors = {
    classificationMethod: boolean;
    classificationManual: boolean;
    classificationService: boolean;
    classificationFacilities: boolean;
}

type SurveyCardProps = {
    survey: Survey | undefined;
    studentId: number;
    editable?: boolean;
    bodyHidden?: boolean;
    newSurveyHandler: (survey: Survey) => void;
    removeSurveyHandler?: (id: number) => void;
    editSurveyHandler?: (updatedSurvey: Survey) => void;
}


export default function SurveyCard(props: SurveyCardProps) {
    const messaging = useContext(MessagingContext);
    const { survey, editable, bodyHidden } = props;

    const [hideBody, setHideBody] = useState(bodyHidden || false);
    const [editing, setEditing] = useState(false);
    const [editMode, setEditMode] = useState(editable || false);

    const [fieldsValues, setFieldsValues] = useState<SurveyFieldValues>(emptyFields);
    const [teachers, setTeachers] = useState<Teacher[] | undefined>(undefined);


    const [showTrainingModal, setShowTrainingModal] = useState(false);

    const [shownTeachers, setShownTeachers] = useState<TeacherRating[]>([]);

    const [surveyTrainings, setSurveyTrainings] = useState<Training[]>([]);

    const [errors, setErrors] = useState<SurveyErrors>({
        classificationMethod: false,
        classificationManual: false,
        classificationService: false,
        classificationFacilities: false,
    });

    useEffect(() => {
        setErrors({
            classificationMethod: fieldsValues.classification_method === 0,
            classificationManual: fieldsValues.classification_manual === 0,
            classificationService: fieldsValues.classification_service === 0,
            classificationFacilities: fieldsValues.classification_facilities === 0,
        });
    }, [fieldsValues]);


    useEffect(() => {
        if (!teachers) {
            api.getTeachers(
                (teachers: any) => {
                    setTeachers(teachers);
                    const ratings = teachers.map((t: Teacher) => new TeacherRating({
                        teacherName: t.name,
                        teacherId: t.id
                    }));
                    setFieldsValues({ ...fieldsValues, teacherRatings: ratings });
                },
                (err) => { console.log(err) }
            );
        }
    }, [editMode, teachers, fieldsValues])

    useEffect(() => {
        if (survey) {
            setFieldsValues({
                classification_general: survey.classificationGeneralId,
                classification_method: survey.classificationMethodId,
                aspects_method: survey.aspectsMethod,
                classification_manual: survey.classificationManualId,
                aspects_manual: survey.aspectsManual,
                classification_service: survey.classificationServiceId,
                aspects_service: survey.aspectsService,
                classification_facilities: survey.classificationFacilitiesId,
                aspects_facilities: survey.aspectsFacilities,
                classification_platform: survey.classificationPlatformId,
                aspects_platform: survey.aspectsPlatform,
                comments: survey.comments,
                met_expectations: survey.metExpectations ? 1 : 0,
                why_expectations: survey.whyExpectations,
                permission: survey.permission ? 1 : 0,
                aspects_teachers: survey.aspectsTeachers,
                overall_rating: survey.overallRating,
                survey_date: toInputDate(survey.surveyDate),
                classification_teacher_overall: survey.classificationTeacherOverallId,
                excerpt: survey.excerpt,
                teacherRatings: survey.teachersRatings
            });

            setShownTeachers(survey.teachersRatings);
            // setSurveyTrainings(survey);
        }
    }, [survey])

    function verifiyTeacherErrors() {

        const fields = ["clarity", "availability", "proactivity", "ability"];
        if (shownTeachers && shownTeachers.length > 0) {
            for (const teacher of shownTeachers) {
                for (const field of fields) {
                    if (teacher[field] === 0) {
                        messaging.toast.show("Erro", `Campos por preencher em: ${teacher.teacherName}`);
                        return false;
                    }
                }
            }
            return true;
        } else {
            messaging.toast.show("Erro", `Tem de escolher pelo menos 1 Formador!`);
            return false;
        }

    }


    function verifySurveyTrainings() {
        if (surveyTrainings.length > 0) {
            return true;
        } else {
            messaging.toast.show("Erro", `Tem de associar pelo menos 1 Curso!`);
            return false;
        }
    }

    function handleNewSurveyAction() {

        const errorMessages: any = {
            classificationMethod: "Metodologia",
            classificationManual: "Manuais",
            classificationService: "Serviços administrativos",
            classificationFacilities: "Instalações"
        }

        const errorFound: any = verifyErrors(errors as SurveyErrors);
        if (errorFound) {
            messaging.toast.show("Erro", `Preencha o campo: ${errorMessages[errorFound]}`);
            return;
        }
        setEditing(true);

        if (verifiyTeacherErrors() && verifySurveyTrainings()) {


            const dataObj: any =
            {
                ...fieldsValues,
                teacherRatings: shownTeachers,
                user_id: getStorageInfo().userId,
                student_id: props.studentId,
                trainings: surveyTrainings.map((t: Training) => t.id).join(','),
            }


            if (editMode) {
                if (survey) {
                    dataObj.id = survey.id;
                    api.editSurvey(dataObj,
                        data => {
                            setEditing(false);
                            actionCallBack(data);
                        },
                        err => console.log(err)
                    );
                } else {
                    api.addSurvey(dataObj,
                        data => {
                            setEditing(false);
                            actionCallBack(data);
                        },
                        err => console.log(err)
                    );
                }
            }

        }
    }

    function actionCallBack(data: any) {
        fieldsValues.id = JSON.parse(data).surveyId;
        const newSurvey = new Survey(fieldsValues);
        newSurvey.teachersRatings = shownTeachers;
        newSurvey.createdDate = new Date();
        newSurvey.courseName = surveyTrainings.map((t: Training) => t.courseName).join(', ');
        props.newSurveyHandler(newSurvey);
    }

    function handleRemoveSurvey(survey: Survey) {
        messaging.modal.showFull({
            title: "Remover Questionário",
            body: `Tem a certeza que pretende remover o questionario de ${survey.courseName}?`,
            closeName: "Cancelar",
            closeHandler: messaging.modal.close,
            actionName: "Eliminar",
            actionHandler: () => {
                messaging.modal.close();
                deleteSurvey(survey.id);
            },
            actionClassName: "btn-danger",
            showAction: true
        });
    }

    function deleteSurvey(id: number) {

        if (survey) {
            api.deleteSurvey(id,
                response => {
                    if (response.status !== 204) {
                        response.text()
                            .then((message: any) => messaging.modal.showMessage("Erro", message));
                    }
                    if (props.removeSurveyHandler) {
                        props.removeSurveyHandler(id);

                        messaging.toast.show("Aviso", "Questionário removido com sucesso!");
                    }
                },
                err => {
                    messaging.toast.show("Erro", "Erro a remover o questionário! " + err);
                });

        }
    }

    function handleTeacherRatingsChange(data: TeacherRating) {

        if (fieldsValues.teacherRatings) {

            const changedTeacherRatingIndex = fieldsValues.teacherRatings.findIndex((teacher: TeacherRating) => teacher.teacherId === data.teacherId);

            if (changedTeacherRatingIndex !== -1) {
                const newFieldValues = fieldsValues;
                newFieldValues.teacherRatings && newFieldValues.teacherRatings.splice(changedTeacherRatingIndex, 1, new TeacherRating(data));

                setFieldsValues(newFieldValues);
            }
        }

    }

    function convertClassificationIdToValue(id: number) {
        const item = classifications.find((c: any) => c.id === id);

        return item ? item.value : id;
    }

    function convertClassificationValueToId(value: number) {
        const item = classifications.find((c: any) => c.value === value);

        return item ? item.id : value;
    }

    function addNewTeacherRating(teacherId: number) {

        if (!shownTeachers.find((t: TeacherRating) => t.teacherId === teacherId)) {
            if (teachers) {

                const teacher = teachers.find((teacher: Teacher) => teacher.id === teacherId)

                if (teacher) {
                    const newTeacherRating = new TeacherRating({ ...teacher, teacherId: teacher.id, teacherName: teacher.name });
                    setShownTeachers([...shownTeachers, newTeacherRating]);
                }
            }
        }
    }

    function handleStarRatingChange(value: number, field: string) {

        const newFieldValues = { ...fieldsValues };
        newFieldValues[field] = convertClassificationValueToId(value);

        setFieldsValues(newFieldValues);
    }

    return (
        <>
            {
                showTrainingModal && <SurveyTrainingsSelectionModal
                    studentId={props.studentId}
                    closeHandler={() => setShowTrainingModal(false)}
                    selectHandler={(trainings: Training[]) => {
                        setSurveyTrainings(trainings);
                        setShowTrainingModal(false);
                    }} />
            }

            <div className="d-flex justify-content-center survey-card" >
                <Card
                    keyId={survey ? survey.id.toString() : "0"}
                    title={<>
                        <span style={{ cursor: "pointer" }} onClick={() => setHideBody(!hideBody)}>Questionário</span>
                        {
                            survey ?
                                editMode ?
                                    (editing ?
                                        <Loader
                                            outerClassName=""
                                            outterStyle={{ display: "inline" }}
                                            style={{ marginLeft: "10px" }}
                                            size="small" /> :
                                        <ConfirmCancelIcons
                                            confirmHandler={() => ""}
                                            cancelHandler={() => setEditMode(false)} />
                                    ) :
                                    <>
                                        <i
                                            className="fas fa-pen action-icon"
                                            style={{ margin: "5px 0 0 10px" }}
                                            title="Editar"
                                            onClick={() => setEditMode(true)}></i>
                                        <i className="fas fa-trash action-icon ml-10" onClick={() => handleRemoveSurvey(survey)} title="Eliminar"></i>
                                    </>
                                :
                                ""
                        }
                    </>}
                    includeHeader={true}
                    editable={true}
                    editHandler={() => ("")}
                    hasCaret={true}
                    caretHandler={() => setHideBody(!hideBody)}
                    width={1500}
                    body={
                        <>
                            <div className="survey-container card-sub-title">
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}>
                                    <div style={{ display: 'flex', width: '90%' }}>
                                        <div className="d-flex" style={{ width: '80%', alignItems: 'center' }}>
                                            {
                                                editMode ?
                                                    <input type="button" className="btn btn-dark" value="Escolher Cursos" onClick={() => setShowTrainingModal(true)} />
                                                    :
                                                    <h5 >{survey && survey.courseName}</h5>

                                            }
                                            {surveyTrainings.map((training: Training) => <CourseTag title={training.courseName} name={training.courseName} />)}
                                        </div>
                                    </div>
                                    <div className="d-flex" style={{
                                        width: "50%",
                                        justifyContent: "flex-end"
                                    }}>
                                        <span style={{ marginRight: "10px" }}>Avaliação geral</span>
                                        <SurveyRating
                                            size={6}
                                            displayRating={fieldsValues.overall_rating}
                                            handleRatingChanged={(n: number) => setFieldsValues({ ...fieldsValues, "overall_rating": n })}
                                            readonly={!editMode}
                                            margin={false}
                                        />
                                    </div>
                                </div>
                                {
                                    survey ? <span style={{ display: "block" }}>Submetido em {toInputDate(survey.createdDate)}</span> : ""
                                }
                            </div>
                            {
                                hideBody ?
                                    "" :
                                    <>
                                        <div className="survey-container">
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="">Formação</label>
                                                    <SurveyRating
                                                        size={6}
                                                        displayRating={convertClassificationIdToValue(fieldsValues.classification_general)}
                                                        handleRatingChanged={(n: number) => handleStarRatingChange(n, "classification_general")}
                                                        readonly={!editMode}
                                                        margin={false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="survey-container">
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="">Manuais</label>
                                                    <SurveyRating
                                                        size={6}
                                                        displayRating={convertClassificationIdToValue(fieldsValues.classification_manual)}
                                                        handleRatingChanged={(n: number) => handleStarRatingChange(n, "classification_manual")}
                                                        readonly={!editMode}
                                                        margin={true}
                                                    />
                                                    {fieldsValues.aspects_manual || editMode ? <textarea
                                                        className="form-control"
                                                        id="aspects_manual"
                                                        readOnly={!editMode}
                                                        onChange={(e) => setFieldsValues({ ...fieldsValues, aspects_manual: e.target.value })}
                                                        rows={4}
                                                        value={fieldsValues.aspects_manual}></textarea> : ""}
                                                </div>
                                                <div className="col">
                                                    <label htmlFor="">Instalações</label>
                                                    <SurveyRating
                                                        size={6}
                                                        displayRating={convertClassificationIdToValue(fieldsValues.classification_facilities)}
                                                        handleRatingChanged={(n: number) => handleStarRatingChange(n, "classification_facilities")}
                                                        readonly={!editMode}
                                                        margin={true}
                                                    />
                                                    {fieldsValues.aspects_facilities || editMode ? <textarea
                                                        className="form-control"
                                                        id="aspects_facilities"
                                                        readOnly={!editMode}
                                                        onChange={(e) => setFieldsValues({ ...fieldsValues, aspects_facilities: e.target.value })}
                                                        rows={4}
                                                        value={fieldsValues.aspects_facilities}></textarea> : ""}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="survey-container">
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="">Metodologia</label>
                                                    <SurveyRating
                                                        size={6}
                                                        displayRating={convertClassificationIdToValue(fieldsValues.classification_method)}
                                                        handleRatingChanged={(n: number) => handleStarRatingChange(n, "classification_method")}
                                                        readonly={!editMode}
                                                        margin={true}
                                                    />
                                                    {fieldsValues.aspects_method || editMode ? <textarea
                                                        className="form-control"
                                                        id="aspects_method"
                                                        readOnly={!editMode}
                                                        onChange={(e) => setFieldsValues({ ...fieldsValues, aspects_method: e.target.value })}
                                                        rows={4}
                                                        value={fieldsValues.aspects_method}></textarea> : ""}

                                                </div>
                                                <div className="col">
                                                    <label htmlFor="">Serviços administrativos</label>
                                                    <SurveyRating
                                                        size={6}
                                                        displayRating={convertClassificationIdToValue(fieldsValues.classification_service)}
                                                        handleRatingChanged={(n: number) => handleStarRatingChange(n, "classification_service")}
                                                        readonly={!editMode}
                                                        margin={true}
                                                    />
                                                    {fieldsValues.aspects_service || editMode ? <textarea
                                                        className="form-control"
                                                        id="aspects_service"
                                                        rows={4}
                                                        onChange={(e) => setFieldsValues({ ...fieldsValues, aspects_service: e.target.value })}
                                                        readOnly={!editMode}
                                                        value={fieldsValues.aspects_service}></textarea> : ""}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="survey-container">
                                            <div className="row">
                                                <div className="col">
                                                    <label htmlFor="">Formadores</label>
                                                    <SurveyRating
                                                        size={6}
                                                        displayRating={convertClassificationIdToValue(fieldsValues.classification_teacher_overall)}
                                                        handleRatingChanged={(n: number) => handleStarRatingChange(n, "classification_teacher_overall")}
                                                        readonly={!editMode}
                                                        margin={true}
                                                    />
                                                    {fieldsValues.aspects_teachers || editMode ? <textarea
                                                        className="form-control"
                                                        id="aspects_teachers"
                                                        readOnly={!editMode}
                                                        rows={4}
                                                        onChange={(e) => setFieldsValues({ ...fieldsValues, aspects_teachers: e.target.value })}
                                                        value={fieldsValues.aspects_teachers}></textarea> : ""}
                                                </div>
                                                {
                                                    (survey && survey.classificationPlatformId) || editMode ?

                                                        <div className="col">
                                                            <label htmlFor="">Plataforma</label>
                                                            <SurveyRating
                                                                size={6}
                                                                displayRating={convertClassificationIdToValue(fieldsValues.classification_platform)}
                                                                handleRatingChanged={(n: number) => handleStarRatingChange(n, "classification_platform")}
                                                                readonly={!editMode}
                                                                margin={true}
                                                            />
                                                            {fieldsValues.aspects_platform || editMode ? <textarea
                                                                className="form-control"
                                                                id="aspects_platform"
                                                                readOnly={!editMode}
                                                                rows={4}
                                                                onChange={(e) => setFieldsValues({ ...fieldsValues, aspects_platform: e.target.value })}
                                                                value={fieldsValues.aspects_platform}></textarea> : ""}
                                                        </div>
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                        {
                                            editMode ? <div>
                                                <div className="survey-container card-sub-title">
                                                    <div style={{ width: '100%' }}>

                                                        {
                                                            teachers &&
                                                            <TeacherCombo
                                                                value={undefined}
                                                                values={teachers}
                                                                selectionChanged={addNewTeacherRating}

                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </div> : ""
                                        }
                                        <div className="survey-container teachers-container">
                                            {
                                                props.survey ?

                                                    props.survey.teachersRatings.map((teacher: TeacherRating) =>
                                                        <TeacherRatingCard
                                                            key={teacher.teacherId}
                                                            handleRatingChanged={handleTeacherRatingsChange}
                                                            teacherRating={teacher}
                                                            readOnly={!editMode}
                                                        />
                                                    )

                                                    :
                                                    <>
                                                        {shownTeachers.map((teacher: TeacherRating) =>
                                                            <TeacherRatingCard
                                                                key={teacher.teacherId}
                                                                handleRatingChanged={handleTeacherRatingsChange}
                                                                teacherRating={teacher}
                                                                readOnly={!editMode}
                                                            />
                                                        )}
                                                    </>


                                            }
                                        </div>

                                        <div className="survey-container">
                                            <div className="row" style={{ marginTop: "30px" }}>
                                                <div className="col">
                                                    <label htmlFor="">Correspondeu às expectativas</label>
                                                    <YesOrNoButtons
                                                        checked={fieldsValues.met_expectations === 1}
                                                        inputId="metExpectations"
                                                        inputName="metExpectations"
                                                        disable={editMode}
                                                        handleOnChange={(v: boolean) => setFieldsValues({ ...fieldsValues, met_expectations: v ? 1 : 0 })}
                                                    />
                                                    {
                                                        fieldsValues.why_expectations || editMode ? <textarea
                                                            className="form-control"
                                                            readOnly={!editMode}
                                                            id="why_expectations"
                                                            onChange={(e) => setFieldsValues({ ...fieldsValues, why_expectations: e.target.value })}
                                                            rows={3}
                                                            value={fieldsValues.why_expectations}></textarea> : ""}
                                                </div>

                                            </div>
                                            <div className="row" style={{ marginTop: "30px" }}>
                                                <div className="col">
                                                    <label htmlFor="">Autoriza que excertos da avaliação sejam usados na internet pela Formabase</label>

                                                    <YesOrNoButtons
                                                        checked={fieldsValues.permission === 1}
                                                        inputId="permission"
                                                        inputName="permission"
                                                        disable={editMode}
                                                        handleOnChange={(v: boolean) => setFieldsValues({ ...fieldsValues, permission: v ? 1 : 0 })}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row" style={{ marginTop: "30px" }}>
                                                <div className="col">

                                                    {
                                                        fieldsValues.comments || editMode ?
                                                            <>
                                                                <label htmlFor="">Sugestões</label>
                                                                <div>
                                                                    <textarea
                                                                        className="form-control"
                                                                        id="comments"
                                                                        onChange={(e) => setFieldsValues({ ...fieldsValues, comments: e.target.value })}
                                                                        readOnly={!editMode}
                                                                        rows={3}
                                                                        value={fieldsValues.comments}></textarea>
                                                                </div>
                                                            </> : ""
                                                    }
                                                </div>
                                            </div>

                                            <div className="row" style={{ marginTop: "30px" }}>
                                                {
                                                    editMode &&
                                                    <div className="d-flex justify-content-center">
                                                        <button
                                                            type="button"
                                                            onClick={() => handleNewSurveyAction()}
                                                            className="btn btn-success">
                                                            {survey ? "Concluir Edição" : `Concluir`}
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </>
                            }
                        </>
                    } />
            </div >
        </>
    );
}