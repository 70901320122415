import { noTrainingStatusTypes, trainingStatusTypes } from '../../constants';
import CourseTag from '../tags/CourseTag';
import '../../styles/ContactsTable.css';
import { toDatePT } from "../../utils/dates";
import Training from '../../models/Training.model';

type TrainingSelectableTableProps = {
    values: Training[];
    selectHandler: (training: Training) => void;
}

export default function TrainingSelectableTable(props: TrainingSelectableTableProps) {
    const { values } = props;

    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Curso</th>
                    <th scope="col">Data de formação</th>
                    <th scope="col">Questionário</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Escolher</th>
                </tr>
            </thead>
            <tbody>

                {values.length > 0 ?
                    values.map((row: Training) =>

                        <tr key={row.id}>
                            <td>
                                <CourseTag
                                    style={{ marginBottom: "10px" }}
                                    name={row.courseName}
                                    key={row.courseId} />
                            </td>
                            <td>

                                {row.startDate ?
                                    <div>
                                        <b>Começou</b>: <span>{toDatePT(row.startDate)}</span>
                                    </div>
                                    :
                                    undefined
                                }
                                {row.endDate ?
                                    <div>
                                        <b>Terminou</b>: <span>{toDatePT(row.endDate)}</span>
                                    </div>
                                    :
                                    undefined
                                }
                            </td>
                            <td align="center">
                                {
                                    row.surveyId ?
                                        <i className="action-icon fas fa-poll" title="Existe Questionário"></i>
                                        :
                                        <i style={{ cursor: 'not-allowed' }} className="action-icon fas fa-ban" title="Sem Questionário"></i>
                                }
                            </td>
                            <td align="center">
                                <i
                                    className={(trainingStatusTypes[row.trainingStatusTypeId - 1] || noTrainingStatusTypes).icon}
                                    style={{ marginRight: "5px", color: "#607d8b" }}
                                    title={(trainingStatusTypes[row.trainingStatusTypeId - 1])?.name}></i>
                            </td>
                            <td align="center">
                                <input type="checkbox" name="trainingCheckbox" id="trainingCheckbox" onClick={() => props.selectHandler(row)} />
                            </td>
                        </tr>
                    ) : ""}

            </tbody>
        </table>
    );
}