import { useEffect, useState, useContext } from "react";
import { MessagingContext } from "../App";
import { api } from "../../services/api";
import { FreeSession } from "../../models/FreeSession.model";
import FreeSessionCard from '../free-session/cards/FreeSession.Card';
import FreeSessionModal from '../free-session/FreeSessionModal';

export default function StudentFreeSessions(props: { studentId: number, setStudentName: (name: string) => void }) {
    const messaging = useContext(MessagingContext);
    const [freeSessions, setFreeSessions] = useState<FreeSession[] | undefined>();

    const [associatingSG, setAssociatingSG] = useState(false);
    useEffect(() => {
        if (typeof freeSessions === "undefined") {
            api.getFreeSessions(
                {
                    student_id: props.studentId
                },
                newFreeSessions => {
                    setFreeSessions(newFreeSessions.map((session: any) => new FreeSession(session)));
                },
                () => setFreeSessions([])
            );
        }
    }, [freeSessions, props.studentId]);


    function associateContactWithFreeSession(selectedFreeSession: FreeSession) {

        const editStudent = { id: selectedFreeSession.id, student_id: props.studentId };
        api.editFreeSession(editStudent, () => {
            setAssociatingSG(false);
            if (freeSessions) {
                setFreeSessions([...freeSessions, selectedFreeSession]);
            }
        }, () => {
            messaging.modal.showMessage("Erro", "Erro ao editar o aluno");
        });
    }
    function handleFreeSessionChanged(freeSession: FreeSession) {
        if (freeSessions) {
            const freeSessionsCopy = [...(freeSessions || [])];
            const index = freeSessions.findIndex(fs => fs.id === freeSession.id);
            if (index !== -1) {
                freeSessionsCopy[index] = freeSession;
            }
            setFreeSessions(freeSessionsCopy);
        }
    }

    function handleFreeSessionRemoved(freeSessionId: number) {

        if (freeSessionId) {
            api.deleteFreeSession(freeSessionId,
                data => {
                    console.log(data);
                    if (data.status === 403 || data.status === 500) {
                        messaging.modal.showMessage("Erro", `Erro a remover a Sessão Gratuita: ${data.statusText}`);
                    } else {
                        setFreeSessions(freeSessions?.filter((freeSession: FreeSession) => freeSession.id !== freeSessionId));
                        messaging.toast.show("Sessão Gratuita Removida", "A Sessao Gratuita selecionada foi removida com sucesso");
                    }
                },
                err => err);
        }
    }


    return (

        <>
            {associatingSG &&
                <FreeSessionModal
                    title={"Associar um contacto a uma Sessão Gratuita"}
                    selectHandler={associateContactWithFreeSession}
                    closeHandler={() => setAssociatingSG(false)} />
            }
            <button
                className="btn btn-success"
                onClick={() => setAssociatingSG(true)}
            >
                Associar a Sessão Gratuita
            </button>
            <div className="d-flex justify-content-center">
                <div style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
                    {freeSessions && (freeSessions.length === 0 ?
                        "Este aluno não possui SG's" :
                        freeSessions.map((session: any) =>
                            <FreeSessionCard
                                deleteFreeSession={handleFreeSessionRemoved}
                                freeSessionChanged={handleFreeSessionChanged}
                                key={session.id}
                                value={session}
                                style={{ margin: "0 10px" }}
                            />)
                    )
                    }
                </div>
            </div>
        </>
    );
}