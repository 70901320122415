import React, { useState, useEffect, useRef, useContext } from 'react';
import { MILLISECONDS_LIST_WAIT, offices } from '../../constants';
import { api } from '../../services/api';
import Student from '../../models/Student.model';
import OfficeToggle from '../OfficeToggle';
import PaginationLinks from '../shared/PaginationLinks';
import StudentsTable from './StudentsTable';
import Loader from '../shared/Loader';
import { RedirectionContext, Routes } from '../MainRouter';
import SearchForm from '../shared/SearchForm';
import CourseCombo from '../combos/CourseCombo';
import Course from '../../models/Course.model';
import { dealyForXSeconds } from '../../utils/generic';

export default function StudentsList() {
    const redirection = useContext(RedirectionContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const [currentSearch, setCurrentSearch] = useState("");
    const [students, setStudents] = useState<Student[] | undefined>();
    const [currentOffice, setCurrentOffice] = useState(offices[0]);
    const [currentCourse, setCurrentCourse] = useState<Course | undefined>(undefined);

    const searchRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        const searchParams: any = {
            office: currentOffice.value,
            page: currentPage
        };
        if (currentSearch.trim() !== "") {
            searchParams.search = currentSearch;
        }
        if (currentCourse) {
            searchParams.course = currentCourse.id;
        }

        api.getStudents(searchParams,
            newStudents => {
                setHasMorePages(newStudents.morePages);
                setStudents(newStudents.data.map((student: any) => new Student(student)));
            },
            () => setStudents([]));

        searchRef.current?.focus();
    }, [currentPage, currentSearch, currentOffice, currentCourse]);

    const paginationLinksJSX = <PaginationLinks
        page={currentPage}
        hasMorePages={hasMorePages}
        pageChanged={newPage => setCurrentPage(newPage)}
        classes="justify-content-end" />;

    return (
        <div className="list-container container" style={{ position: "relative" }}>
            <button
                className="btn btn-success new-list-button"
                onClick={() => redirection.redirectTo(Routes.newStudent)}>Novo Aluno</button>
            <h2 className="text-center m-4">Alunos</h2>
            <SearchForm
                label="Pesquisar alunos"
                onChangeHandler={event => {
                    dealyForXSeconds(MILLISECONDS_LIST_WAIT, () => {
                        setCurrentSearch(event.target.value);
                        setCurrentPage(1);
                    });
                }}
                style={{ marginBottom: "20px" }}
                searchRef={searchRef} />

            <div style={{ margin: "30px 0", alignItems: "center" }} className="d-flex">
                <div>
                    <span style={{ marginRight: "10px" }}>Filial:</span>
                    <OfficeToggle officeChanged={newOffice => setCurrentOffice(newOffice)} />
                </div>
                <div style={{ margin: "0 30px", width: "30%", alignItems: "center" }} className="d-flex">
                    <span style={{ marginRight: "10px" }}>Curso:</span>
                    <CourseCombo
                        value={currentCourse}
                        selectionChanged={(newVal: Course | undefined) => setCurrentCourse(newVal)}
                        office={currentOffice.id}
                        defaultText="Curso para filtrar"
                        autoFetchCourses={true}
                    />
                </div>
            </div>

            {students ?
                <>
                    {paginationLinksJSX}
                    <StudentsTable values={students} />
                    {paginationLinksJSX}
                </> :
                <Loader />
            }
        </div>
    );
}
