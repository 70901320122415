export const LOCAL_STORAGE_KEY = 'fbNinjas';
export const statusCodes = {
    OK: 200,
    UNAUTHORIZED: 401,
    NOT_FOUND: 404
};

export const PORTUGAL_COUNTRY_ID = 177;

export const offices = [
    { name: "Tudo", value: "all", id: 0 },
    { name: "Lisboa", value: "lisboa", id: 1, color: "#ff8244" },
    { name: "Porto", value: "porto", id: 2, color: "#44b6ff" }
];
export const noOffice = { name: "Filial não encontrada", value: "none", id: -1, color: "red" };
export const officesHash = [undefined, offices[1], offices[2]];

export const DEFAULT_TOAST_TIMEOUT = 4000; //m

export const surveyTeacherQualities = [
    { id: 1, quality: "Clareza e objectividade no esclarecimento de dúvidas", classification: 0 },
    { id: 2, quality: "Disponibilidade e simpatia", classification: 0 },
    { id: 3, quality: "Proatividade", classification: 0 },
    { id: 4, quality: "Domínio das matérias abordadas", classification: 0 }
]

export const trainingStatusTypes = [
    { name: "Nova", id: 1, icon: "fas fa-plus-square" },
    { name: "Em espera", id: 2, icon: "far fa-hourglass" },
    { name: "A decorrer", id: 3, icon: "fas fa-running" },
    { name: "Concluída", id: 4, icon: "fas fa-check-circle" },
    { name: "Questionário preenchido", id: 5, icon: "fas fa-clipboard-check" },
    { name: "Certificado preparado", id: 6, icon: "fas fa-medal" },
    { name: "Certificado entregue", id: 7, icon: "fas fa-envelope" },
    { name: "Expirada", id: 8, icon: "fas fa-flag" },
    { name: "Cancelada", id: 9, icon: "fas fa-ban" },
    { name: "Expiração notificada", id: 10, icon: "fas fa-bell" },
    { name: "Inativo", id: 11, icon: "fas fa-gavel" },
    { name: "Nova Extensão", id: 12, icon: "NULL" }
];
export const noTrainingStatusTypes = { name: "Tipo não encontrado", id: -1, icon: "" };

export const userTypesList = [
    { name: "Formador", id: 1 },
    { name: "Frontoffice", id: 2 },
    { name: "Administrador", id: 3 },
    { name: "Frontoffice +", id: 4 },
    { name: "Antigo", id: 5 }
];

export const userTypesHash = [undefined, ...userTypesList];
export const noUserType = { name: "Tipo de utilizador não encontrado", id: -1, icon: "" };


export const courseStatusTypesList = [
    { name: "Novo", id: 1 },
    { name: "Activo", id: 2 },
    { name: "Em actualização", id: 3 },
    { name: "Em desenvolvimento", id: 4 },
    { name: "Desactivado", id: 5 }
];
export const courseStatusTypesHash = [undefined, ...courseStatusTypesList];

export const courseCategoriesList = [
    { name: "Utilizadores", id: 1 },
    { name: "Utilizadores Expert", id: 2 },
    { name: "Web Creation", id: 3 },
    { name: "Programação", id: 4 },
    { name: "Grafismo", id: 5 }
];
export const courseCategoriesHash = [undefined, ...courseCategoriesList];


export const contactMethods = [
    { name: "WebSite", id: 1, icon: "fas fa-globe" },
    { name: "Telefone", id: 2, icon: "fas fa-phone" },
    { name: "Email", id: 3, icon: "fas fa-envelope" },
    { name: "Pessoalmente", id: 4, icon: "fas fa-user" },
    { name: "Facebook", id: 5, icon: "fab fa-facebook" },
    { name: "EducaEdu", id: 6, icon: "" },
    { name: "Chat", id: 7, icon: "fas fa-comments" },
];

export const noContactMethod = { name: "Método não encontrado", id: -1, icon: "" };
export const contactMethodsHash = [undefined, ...contactMethods];

export const contactPurposes = [
    { name: "Pedido de Informação", id: 1 },
    { name: "Marcação de Sessão Gratuita", id: 2 },
    { name: "Inscrição", id: 3 },
    { name: "Contacto Geral", id: 4 },
    { name: "Aluno", id: 5 },
];
export const noContactPurpose = { name: "Objetivo não encontrado", id: -1 };
export const contactPurposesHash = [undefined, ...contactPurposes];

export const FINISHED_TRAINING_STATUS_TYPE_COLOR = "#e5ffc9";
export const TRAINING_TYPE_FINISHED_ID = 4;
export const EXPIRED_TRAINING_STATUS_TYPE_COLOR = "#ffe0e3";
export const TRAINING_TYPE_EXPIRED_ID = 8;
export const NEW_TRAINING_STATUS_TYPE_COLOR = "#ebf5ff";
export const TRAINING_TYPE_NEW_ID = 1;

export const genders = [
    { name: "Masculino", val: "m" },
    { name: "Feminino", val: "f" }
];

export const civilStates = [
    { name: "Solteiro", val: "s" },
    { name: "Casado", val: "c" },
    { name: "Divorciado", val: "d" },
    { name: "Viúvo", val: "v" }
];

export const CUSTOMER_TYPE_STUDENT_ID = 1;
export const CUSTOMER_TYPE_COMPANY_ID = 2;
export const CUSTOMER_TYPE_CARER_ID = 3;
export const CUSTOMER_TYPE_PRIVATE_ID = 4;

export const toggleClientTypes = [
    { name: "Tudo", id: 0, search: CUSTOMER_TYPE_COMPANY_ID + "," + CUSTOMER_TYPE_PRIVATE_ID },
    { name: "Empresa", id: CUSTOMER_TYPE_COMPANY_ID, search: CUSTOMER_TYPE_COMPANY_ID },
    { name: "Particular", id: CUSTOMER_TYPE_PRIVATE_ID, search: CUSTOMER_TYPE_PRIVATE_ID }
];

export const toggleClientTypesHash = [
    toggleClientTypes[0],
    undefined,
    toggleClientTypes[1],
    undefined,
    toggleClientTypes[2]
];

export const DAYS_PER_WEEK = 7;

export type PaymentType = { name: string, id: number };
export const PaymentTypes: PaymentType[] = [
    { name: "Totalidade", id: 1 },
    { name: "Faseado", id: 2 }
];

export const trainingTypes = [
    { name: "Presencial", id: 1, icon: "fas fa-chalkboard-teacher" },
    { name: "Online", id: 2, icon: "fas fa-globe" },
    { name: "Bi-Learning", id: 3, icon: "fas fa-house-user" }
]
export const noTrainingType = { name: "Tipo não encontrado", id: -1, icon: "fas fa-clock" };

export const trainingTypesHash = [undefined, ...trainingTypes];

export const TrainingTypesObject = {
    PRESENTIAL: 1,
    ONLINE: 2,
    BI_LEARNING: 3,
}
export const ContactModalType = {
    FREE_SESSION: 1,
    STUDENTS: 2,
}
export type YesNoToggleOption = {
    id: number;
    val: boolean;
    name: string;
};

export const yesToggle = { id: 1, val: true, name: "Sim" };
export const noToggle = { id: 2, val: false, name: "Não" };

export const yesNoToggle: YesNoToggleOption[] = [yesToggle, noToggle];

export const TRAINING_STATUS_NEW_ID = 1;
export const TRAINING_STATUS_ON_GOING_ID = 3;
export const TRAINING_STATUS_EXPIRED_ID = 8;
export const TRAINING_STATUS_FINISHED_ID = 4;
export const TRAINING_STATUS_NEW_EXTENSION_ID = 12;

export const paymentMethodTypes = [
    { id: 1, name: "Multibanco", icon: "fa-credit-card" },
    { id: 2, name: "Transferência", icon: "fa-university" },
    { id: 3, name: "Cheque", icon: "fa-money-check" },
    { id: 4, name: "Numerário", icon: "fa-money-bill" }
];
export const paymentMethodTypesHash = [undefined, ...paymentMethodTypes];

export const PITA_LOGO = "fb-pita-logo.png";
export const EMPOWER_LOGO = "newlogo.png";

export const qualities = [
    { id: 1, title: "Clareza e objectividade no esclarecimento de dúvidas" },
    { id: 2, title: "Disponibilidade e simpatia" },
    { id: 3, title: "Proatividade" },
    { id: 4, title: "Domínio das matérias abordadas" },
];

export const classifications = [
    { id: 6, value: 6, title: "Excelente" },
    { id: 1, value: 5, title: "Muito Bom" },
    { id: 2, value: 4, title: "Bom" },
    { id: 3, value: 3, title: "Razoável" },
    { id: 4, value: 2, title: "Mau" },
    { id: 5, value: 1, title: "Muito Mau" },
];

export const MILLISECONDS_LIST_WAIT = 500;

export enum CertificateFace {
    Front,
    Back,
  }