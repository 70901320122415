import Enrollment from "../../models/Enrollment";
import { formatCurrency, formatPercentage } from "../../utils/generic";
import { Link } from 'react-router-dom';

export default function EnrollmentsTable(props: { enrollments: Enrollment[], showStudent?: boolean }) {
    const { enrollments, showStudent } = props;
    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    {showStudent ? <th scope="col">Nome</th> : null}
                    <th scope="col">Curso/Pack</th>
                    <th scope="col">Horas</th>
                    <th scope="col">Preço</th>
                    <th scope="col">Desconto</th>
                    <th scope="col">Valor Final</th>
                </tr>
            </thead>
            <tbody>
                {enrollments.map((enroll: Enrollment) =>
                    <tr key={enroll.id}>
                        {showStudent && enroll.studentId ? <td><Link to={`/alunos/${enroll.studentId}`}>{enroll.student}</Link></td> : null}
                        <td>{enroll.isPack() ? enroll.pack : enroll.course}</td>
                        <td>{enroll.expectedTime}h</td>
                        <td>{enroll.hasDiscount() ? (formatCurrency(enroll.initialValue)) : null}</td>
                        <td>{enroll.hasDiscount() ?
                            <><div>{formatPercentage(enroll.percentage)}</div>
                                <span style={{ fontSize: "0.85rem" }}>{enroll.discount}</span>
                            </>
                            : null}
                        </td>
                        <td>{enroll.finalValue ? formatCurrency(enroll.finalValue) : formatCurrency(enroll.initialValue * (1 - enroll.percentage))}</td>
                    </tr>)}
            </tbody>
        </table>
    );
}