import { useContext } from 'react';
import Contact from '../../interfaces/Contact.interface';
import FollowUp from '../../interfaces/FollowUp.interface';
import { api } from '../../services/api';
import Modal from '../shared/Modal';
import FollowUpForm from './forms/FollowUpForm';
import { MessagingContext } from '../App';

interface FollowUpModalProps {
    contact: Contact;
    closeHandler: () => void;
    followUp: FollowUp | undefined;
    followUpAdded?: (followUp: FollowUp) => void;
    followUpEdited?: (followUp: FollowUp) => void;
}



export default function FollowUpModalForm(props: FollowUpModalProps) {

    const { followUp, closeHandler, followUpAdded, followUpEdited, contact } = props;

    const messaging = useContext(MessagingContext);

    function editFollowUp(newFollowUp: FollowUp) {
        api.editFollowUp(newFollowUp, response => {

            if (followUp && response.status === 200) {

                Object.assign(followUp, newFollowUp);

                if (followUpEdited) {
                    followUpEdited(followUp as FollowUp);
                }

                messaging.toast.showFull({
                    title: "Seguimento",
                    body: "Seguimento modificado",
                    style: { zIndex: 2000 },
                    removeHandler: messaging.toast.close
                });

                closeHandler();
            } else {
                throw new Error(`Erro no pedido! Status: ${response.status}`);
            }
        },
            err => messaging.modal.showMessage("Erro", "Erro a modificar seguimento: " + err.message))
    }

    function addFollowUp(newFollowUp: FollowUp) {


        api.addFollowUp(newFollowUp, addedFollowUp => {
            if (followUpAdded) {
                followUpAdded(addedFollowUp as FollowUp);
            }

            messaging.toast.showFull({
                title: "Seguimento",
                body: "Seguimento adicionado",
                style: { zIndex: 2000 },
                removeHandler: messaging.toast.close
            });
        },
            err => messaging.modal.showMessage("Erro", "Erro a adicionar seguimento: " + err.message))

    }

    const body =
        <div>
            <h6>Seguimentos</h6>
            <div style={{ maxHeight: "500px" }}>
                <FollowUpForm
                    followUp={followUp}
                    pending={false}
                    contact={contact}
                    followUpAdded={newFollowUp => addFollowUp(newFollowUp)}
                    followUpModified={newFollowUp => editFollowUp(newFollowUp)} />
            </div>
        </div>;

    return (
        <>
            <Modal
                title={`Seguimento para o contacto de ${contact.name}`}
                body={body}
                closeName="Fechar"
                closeHandler={closeHandler}
                bodyMaxHeight={500} />
        </>
    );
}