export class FreeSession {
    public id: number;
    public name: string;
    public description: string;
    public username: string;
    public createdDate: Date;
    public sessionDate: Date;
    public course: string;
    public courseId: number;
    public studentId: number;
    public student: string;
    public attended?: boolean;
    public userStartId: number;
    public userStart: string;
    public userEndId: number;
    public userEnd: string;
    public interested?: boolean;
    public conclusion?: string;
    public conclusionDate?: Date;
    public teacher: string;
    public officeId: number;
    public office: string;
    public trainingType: string;
    public trainingTypeId: number;

    constructor(data: any) {
        this.name = data.name;
        this.id = data.id;
        this.username = data.username;
        this.createdDate = new Date(data.created_date);
        this.sessionDate = new Date(data.session_date);
        this.course = data.course;
        this.courseId = data.course_id;
        this.studentId = data.student_id;
        this.student = data.student;
        this.attended = data.attended;
        this.userStartId = data.user_start_id;
        this.userStart = data.user_start;
        this.userEndId = data.user_end_id;
        this.userEnd = data.user_end;
        this.interested = data.interested;
        this.conclusion = data.conclusion;
        this.conclusionDate = data.conclusion_date ? new Date(data.conclusion_date) : undefined;
        this.teacher = data.teacher;
        this.office = data.office;
        this.officeId = data.office_id;
        this.trainingType = data.training_type;
        this.trainingTypeId = data.training_type_id;
        this.description = data.description || undefined;
    }

    clone() : FreeSession {
        const cloned = new FreeSession({});
        cloned.name = this.name;
        cloned.id = this.id;
        cloned.username = this.username;
        cloned.sessionDate = this.sessionDate;
        cloned.course = this.course;
        cloned.courseId = this.courseId;
        cloned.studentId = this.studentId;
        cloned.student = this.student;
        cloned.attended = this.attended;
        cloned.userStartId = this.userStartId;
        cloned.userStart = this.userStart;
        cloned.userEndId = this.userEndId;
        cloned.userEnd = this.userEnd;
        cloned.interested = this.interested;
        cloned.conclusion = this.conclusion;
        cloned.conclusionDate = this.conclusionDate;
        cloned.teacher = this.teacher;
        cloned.office = this.office;
        cloned.officeId = this.officeId;
        cloned.trainingType = this.trainingType;
        cloned.trainingTypeId = this.trainingTypeId;
        return cloned;
    }
}