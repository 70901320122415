import { useState, useEffect, useContext } from "react";
import { paymentMethodTypesHash } from "../../constants";
import Payment from "../../models/Payment.model";
import GenericCombo from "../shared/GenericCombo";
import { toDatePT, toInputDate } from "../../utils/dates";
import { api } from "../../services/api";
import PaymentStatusType from '../../interfaces/PaymentStatusType.interface';
import PaymentMethod from "../../interfaces/PaymentMethod.interface";
import { MessagingContext } from "../App";

interface PaymentTableProps {
    values: Payment[],
    editMode?: boolean;
    updatePaymentData: (e: PaymentFields[]) => void;
}

export type PaymentFields = {
    id?: number;
    description: string;
    statusTypeId: number;
    status: string;
    methodId: number;
    value: number;
    expirationDate: string;
    observations: string;

}

type PaymentErrors = {
    paymentStatusTypeId: boolean;
    paymentMethodId: boolean;
    value: boolean;
}

export const emptyPaymentFields: PaymentFields = {
    description: "",
    statusTypeId: 0,
    status: "",
    methodId: 0,
    value: 0,
    expirationDate: "",
    observations: "",
};

const paymentErrorsDescriptions: any = {
    paymentStatusTypeId: "Estado de Pagamento",
    paymentMethodId: "Método de Pagamento",
    value: "Valor da Prestação",
}

export default function PaymentsTable(props: PaymentTableProps) {
    const { values, editMode, updatePaymentData } = props;

    const messaging = useContext(MessagingContext);

    const [paymentStatusTypes, setPaymentStatusTypes] = useState<PaymentStatusType[]>([]);
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

    const [errors, setErrors] = useState<PaymentErrors[]>([]);

    const [fieldValues, setFieldValues] = useState<PaymentFields[]>([]);

    if (values && fieldValues.length === 0) {
        const fieldValues = values.map((payment: Payment) => ({
            id: payment.id,
            description: payment.description,
            statusTypeId: payment.statusTypeId,
            methodId: payment.methodId,
            value: payment.value,
            expirationDate: toInputDate(payment.expirationDate),
            status: payment.status,
            observations: payment.observations ? payment.observations : "",
        }));
        setFieldValues(fieldValues);
    }

    function handlePaymentTypeChange(id: number, index: number) {

        const paymentType = paymentStatusTypes.find(type => type.id === id);

        let cloneArray = JSON.parse(JSON.stringify(fieldValues));
        cloneArray.map((row: PaymentFields) => Object.assign(row, { expirationDate: new Date(row.expirationDate) }));

        cloneArray[index]["statusTypeId"] = id;
        if (paymentType) {
            cloneArray[index]["status"] = paymentType.title;
        }

        setFieldValues(cloneArray);
        updatePaymentData(cloneArray);
    }


    function validateErrors() {

        for (let index = 0; index < errors.length; index++) {
            const fieldValueError: PaymentErrors = errors[index];

            for (const key in fieldValueError) {

                if (Object.prototype.hasOwnProperty.call(fieldValueError, key)) {
                    const element = fieldValueError[key as keyof PaymentErrors];
                    const paymentNumber = index + 1;
                    if (element) {
                        messaging.toast.show("Erro", `Preencha o campo: ${paymentErrorsDescriptions[key]} da Prestação nº ${paymentNumber} `);
                        return;
                    }
                }
            }

        }
    }

    useEffect(() => {

        const errors: PaymentErrors[] = [];
        for (const paymentFieldValues of fieldValues) {
            errors.push({
                paymentStatusTypeId: paymentFieldValues.statusTypeId === 0,
                paymentMethodId: paymentFieldValues.methodId === 0,
                value: paymentFieldValues.value === 0,
            })
        }

        setErrors(errors);

        return () => { validateErrors(); }
    }, [fieldValues])

    useEffect(() => {

        api.getPaymentStatusTypes(data => {
            setPaymentStatusTypes(data);
        }, err => {
            setPaymentStatusTypes([]);
        });
        api.getPaymentMethods(data => {
            setPaymentMethods(data);
        }, err => {
            setPaymentMethods([]);
        });

    }, [editMode])

    function handleChange(fieldName: string, value: any, paymentIndex: number) {

        let cloneArray = JSON.parse(JSON.stringify(fieldValues));
        cloneArray.map((row: PaymentFields) => Object.assign(row, { expirationDate: new Date(row.expirationDate) }));

        cloneArray[paymentIndex][fieldName] = value;

        setFieldValues(cloneArray);
        updatePaymentData(cloneArray);
    }

    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Fatura</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Forma</th>
                    <th scope="col">Valor</th>
                    <th scope="col">Descrição</th>
                    <th scope="col">Vence a</th>
                </tr>
            </thead>
            <tbody>
                {fieldValues.length > 0 && values.map((payment: Payment, index: number) => {
                    const currentFieldValue = fieldValues[index];
                    const styles = editMode ? { height: "50px" } : {};
                    return (
                        <tr key={payment.id} {...styles}>
                            <td style={{ textAlign: "center" }}>
                                {editMode ?
                                    <textarea
                                        style={styles}
                                        name="observations"
                                        className="form-control"
                                        value={currentFieldValue.observations}
                                        onChange={(e) => handleChange("observations", (e.target.value), index)} >
                                    </textarea>
                                    :
                                    !payment.observations ? <i title="Sem Fatura" className="icon-color icon-mr5 fas fa-file-invoice"></i> : payment.observations
                                }
                            </td>
                            <td>
                                {editMode ?
                                    <GenericCombo
                                        name="statusTypeId"
                                        value={currentFieldValue?.statusTypeId}
                                        style={styles}
                                        values={paymentStatusTypes}
                                        getId={paymentTypeId => paymentTypeId.id}
                                        getOptionContent={paymentTypeId => paymentTypeId.title}
                                        selectionChanged={(a) => handlePaymentTypeChange(a as number, index)} />
                                    :
                                    payment.status
                                }
                            </td>
                            <td>
                                {editMode ?
                                    <GenericCombo
                                        name="methodId"
                                        style={styles}
                                        value={currentFieldValue?.methodId}
                                        values={paymentMethods}
                                        getId={paymentMethod => paymentMethod.id}
                                        getOptionContent={paymentMethod => paymentMethod.title}
                                        selectionChanged={(id: any) => {
                                            handleChange("methodId", id, index)
                                        }} />
                                    :
                                    <i
                                        style={{ color: "#4c4c4c" }}
                                        className={`fa ${paymentMethodTypesHash[payment.methodId]?.icon}`}
                                        title={paymentMethodTypesHash[payment.methodId]?.name}></i>
                                }

                            </td>
                            <td>{editMode ?
                                <textarea
                                    name="value"
                                    className="form-control"
                                    style={styles}
                                    value={currentFieldValue.value}
                                    onChange={(e) => handleChange("value", (e.target.value), index)} >
                                </textarea>

                                :
                                payment.value}
                            </td>
                            <td>{editMode ?
                                <textarea
                                    name="description"
                                    className="form-control"
                                    style={styles}
                                    value={currentFieldValue.description}
                                    onChange={(e) => handleChange("description", (e.target.value), index)} >
                                </textarea>

                                :
                                payment.description}
                            </td>
                            <td>
                                {editMode ?

                                    <input
                                        type="date"
                                        name="expirationDate"
                                        style={styles}
                                        className="form-control"
                                        value={currentFieldValue ? toInputDate(new Date(currentFieldValue.expirationDate)) : ""}
                                        onChange={(e) => handleChange("expirationDate", new Date(e.target.value), index)} />
                                    :
                                    toDatePT(payment.expirationDate)}
                            </td>
                        </tr>);
                })}
            </tbody>
        </table>
    );
}