import { useEffect, useRef, useState } from "react";
import SearchForm from "../shared/SearchForm";
import { api } from '../../services/api';
import Occupation from "../../interfaces/Occupation";
import Loader from "../shared/Loader";
import OccupationsTable from "./OccupationsTable";
import OccupationForm from "./OccupationForm";

export default function OccupationsList() {

    const [occupations, setOccupations] = useState<Occupation[]>();
    const [displayedOccupations, setDisplayOccupations] = useState<Occupation[]>();
    const searchRef = useRef<HTMLInputElement | null>(null);
    const [currentSearch, setCurrentSearch] = useState("");

    const [showInsertModal, setShowInsertModal] = useState(false);
    const [editing, setEditing] = useState(false);
    const [selectedOccupation, setSelectedOccupation] = useState<Occupation | undefined>(undefined);

    useEffect(() => {
        api.getOccupations(
            newOccupations => {
                setOccupations(newOccupations);
                setDisplayOccupations(newOccupations);
            },
            (err) => {
                console.log(err);
            }
        )
    }, [])

    useEffect(() => {
        const filteredArray = occupations?.filter((occu: Occupation) =>
            occu.title.toLocaleLowerCase().includes(currentSearch.toLocaleLowerCase()));
        setDisplayOccupations(filteredArray);
    }, [currentSearch, occupations])

    function handleOccupationRemoved(occupationId: number) {
        if (occupations) {
            setOccupations(occupations.filter(occupation => occupation.id !== occupationId));
        }
    }


    function sortOccupations(occupationsList: Occupation[]) {
        return occupationsList && occupationsList.sort((a: Occupation, b: Occupation) =>
            a.title.toString().localeCompare(b.title.toString())
        )
    }

    function addOccupationToList(newOccupation: Occupation) {
        if (occupations) {
            console.log(newOccupation);
            const newOccupationsList = [...occupations, newOccupation];
            const sortedOccupations = sortOccupations(newOccupationsList);
            setOccupations(sortedOccupations);
            setShowInsertModal(false);
        }
    }

    function replaceOccupationInList(updatedOccupation: Occupation) {
        if (occupations) {
            const newOccupationsList = occupations.map((o: Occupation) => o.id === updatedOccupation.id ? updatedOccupation : o);
            const sortedOccupations = sortOccupations(newOccupationsList);
            setOccupations(sortedOccupations);
            setShowInsertModal(false);
            setEditing(false);
            setSelectedOccupation(undefined);
        }
    }

    function handleNewOccupationClick() {
        setShowInsertModal(true);
        setEditing(false);
        setSelectedOccupation(undefined);
    }

    return (
        <>

            {
                showInsertModal &&
                <OccupationForm
                    closeHandler={() => setShowInsertModal(false)}
                    occupation={selectedOccupation}
                    occupationAdded={addOccupationToList}
                    occupationEdited={replaceOccupationInList}
                    editing={editing}
                />
            }

            <div className="container" style={{ position: "relative" }}>
                <button
                    className="btn btn-success new-list-button"
                    onClick={() => handleNewOccupationClick()}>Nova Ocupação</button>
                <h2 className="text-center m-4">Ocupações</h2>

                <SearchForm
                    label="Pesquisar Ocupações"
                    onChangeHandler={event => setCurrentSearch(event.target.value)}
                    style={{ marginBottom: "20px" }}
                    searchRef={searchRef} />

                {displayedOccupations ?
                    <>
                        <OccupationsTable
                            values={displayedOccupations}
                            occupationRemoved={handleOccupationRemoved}
                            occupationEdited={(occ: Occupation) => {
                                setSelectedOccupation(occ);
                                setEditing(true);
                                setShowInsertModal(true);
                            }}
                        />
                    </>
                    :
                    <Loader />
                }
            </div>
        </>
    );
}