import { CSSProperties } from "react";
import { Link } from "react-router-dom";

export default function StudentTag(props: { style?: object, id: number, name: string }) {
    let studentTagStyle: CSSProperties = {
        backgroundColor: "#1292a7",
        padding: "6px",
        borderRadius: "6px",
        color: "white",
        fontSize: "12px",
        fontWeight: "bold",
        margin: "0px 8px",
        whiteSpace: "nowrap",

    };

    if (props.style) {
        Object.assign(studentTagStyle, props.style);
    }

    return (
        <div style={{ marginBottom: "10px" }}>
            <Link className="action-icon" to={`/alunos/${props.id}`}>
                <span style={studentTagStyle}>{props.name}</span>
            </Link>
        </div>
    )
}