import React, { CSSProperties } from "react";
import Occupation from "../../../interfaces/Occupation";
import GenericCombo from "../../shared/GenericCombo";
import { StudentFields } from "./StudentForm";

type StudentOccupationFormProps = {
    fieldsValues: StudentFields;
    setFieldsValues: (val: StudentFields) => void;
    occupations: Occupation[],
    style?: CSSProperties
};

export default function StudentOccupationForm(props: StudentOccupationFormProps) {
    const { fieldsValues, setFieldsValues, occupations, style } = props;

    return (
        <div className="card p-3" style={style}>
            <h4 className="m-2">Situação profissional</h4>
            <div className="row">
                <div className="col col-md-6 m-2">
                    <div className="form-group">
                        <label>Estado</label>
                        <GenericCombo
                            value={fieldsValues.occupationId}
                            values={occupations}
                            getId={occupation => occupation.id}
                            getOptionContent={occupation => occupation.title}
                            selectionChanged={occupationId =>
                                setFieldsValues({ ...fieldsValues, occupationId: occupationId as number })} />
                    </div>
                </div>
            </div>
        </div>
    );
}