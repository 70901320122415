import '../../styles/ContactsTable.css';
import { toDatePT } from "../../utils/dates";
import OfficeTag from '../tags/OfficeTag';
import User from '../../models/User.model';
import CheckOrCrossIcon from '../shared/CheckOrCrossIcon';
import { useContext } from 'react';
import { MessagingContext } from '../App';

type UserTableProps = {
    values: User[],
    handleEditUser: (user: User) => void,
    handleDeleteUser: (userId: number) => void,
}

export default function UserTable(props: UserTableProps) {
    const { values, handleDeleteUser } = props;

    const messaging = useContext(MessagingContext);

    function handleRemoveContact(user: User) {
        messaging.modal.showFull({
            title: "Remover contacto",
            body: `Tem a certeza que pretende remover o utilizador de ${user.name} ?`,
            closeName: "Cancelar",
            closeHandler: messaging.modal.close,
            actionName: "Eliminar",
            actionHandler: () => {
                messaging.modal.close();
                handleDeleteUser(user.id);
            },
            actionClassName: "btn-danger",
            showAction: true
        });
    }

    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Último Login</th>
                    <th scope="col">Nome Utilizador</th>
                    <th scope="col">Ativo</th>
                    <th scope="col">Tipo Utilizador</th>
                    <th scope="col">Imagem</th>
                    <th style={{ width: "170px" }} scope="col">Ações</th>
                </tr>
            </thead>
            <tbody>

                {values.length > 0 ?
                    values.map((row: User) =>
                        <tr key={row.id}>
                            <td>
                                <div>{row.name}</div>
                                <OfficeTag office={row.officeId} />
                            </td>
                            <td>{toDatePT(new Date(row.lastLoginDate))}</td>
                            <td>{row.username}</td>
                            <td>{<CheckOrCrossIcon value={row.active} />}
                            </td>
                            <td>{row.userType}</td>
                            <td>
                                {
                                    !row.picture ?
                                        undefined :
                                        <>
                                            <input type="file" className="form-control" id="customFile" />
                                        </>
                                }
                            </td>
                            <td>
                                <div>
                                    <button type="button"
                                        style={{ fontSize: "0.8rem", width: "100%", marginTop: "5px" }}
                                        onClick={() => props.handleEditUser(row)}
                                        className={`btn btn-outline-success btn-rounded waves-effect`}>
                                        <i style={{ marginRight: "5px" }} className="fas fa-pen" aria-hidden="true"></i>
                                        <span>Editar</span>
                                    </button>
                                    <button type="button"
                                        style={{ fontSize: "0.8rem", width: "100%", marginTop: "5px", }}
                                        onClick={() => handleRemoveContact(row)}
                                        className={`btn btn-outline-danger btn-rounded waves-effect`}>
                                        <i style={{ marginRight: "5px" }} className="fas fa-trash" aria-hidden="true"></i>
                                        <span>Remover</span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    )
                    :
                    undefined
                }

            </tbody>
        </table>
    );
}