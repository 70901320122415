import { useContext } from "react";
import Product from "../../interfaces/Product.interface";
import Course from "../../models/Course.model";
import Discount from "../../models/Discount.model";
import Pack from "../../models/Pack.model";
import { MessagingContext } from "../App";
import { ProductsTotals } from "../purchase/NewPurchaseCard";
import { formatCurrency } from "../../utils/generic";
import DiscountCombo from "../combos/DiscountCombo";

type ProductsTableProps = {
    products: Product[];
    productsDiscounts: (Discount | undefined)[];
    discounts: Discount[] | undefined;
    changeProductDiscount: (discount: Discount, pos: number, product: Product) => void;
    newDiscountAdded: (newDiscount: Discount) => void;
    newDiscountEdited: (newDiscount: Discount, productPosition: number, product: Product) => void;
    totals: ProductsTotals;
};

export default function ProductsTable(props: ProductsTableProps) {
    const messaging = useContext(MessagingContext);
    const { products, productsDiscounts, discounts, changeProductDiscount, totals } = props;

    function filterDiscountsToCourse(discounts: Discount[] | undefined, product: Product): Discount[] {
        if (product instanceof Pack) {
            return (product as Pack).discounts;
        }
        else if (product instanceof Course) {
            const course = product as Course;
            if (discounts) {
                return discounts.filter(discount =>
                    discount.courses.map(c => c.id).includes(course.id)
                )
            }
            return [];
        }

        messaging.toast.show("Erro", "Erro a filtrar descontos para o produto");
        return [];
    }



    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Curso</th>
                    <th scope="col">Horas</th>
                    <th scope="col">Preço</th>
                    <th scope="col">Desconto</th>
                    <th scope="col">Total</th>
                </tr>
            </thead>
            <tbody>
                {products.map((product, pos) =>
                    <tr key={product.getUniqueId()}>
                        <td>{product.getName()}</td>
                        <td>{product.getTime()}h</td>
                        <td>{product.getPrice()}€</td>
                        <td>
                            <DiscountCombo
                                discount={productsDiscounts[pos]}
                                product={product}
                                selectionChanged={discount =>
                                    changeProductDiscount(discount, pos, product)
                                }
                                discountAddedList={props.newDiscountAdded}
                                discountChanged={discount =>
                                    props.newDiscountEdited(discount, pos, product)
                                }

                                autoFetchDiscounts={false}
                                availableDiscounts={filterDiscountsToCourse(discounts, product)}
                            />
                        </td>
                        <td>{formatCurrency(product.getPrice() - product.getDiscountValue(productsDiscounts[pos]))}</td>
                    </tr>
                )}
                <tr style={{ fontWeight: "bold" }}>
                    <td>Total</td>
                    <td>{totals.time}h</td>
                    <td>{totals.price}€</td>
                    <td>{formatCurrency(totals.discountPrice)}</td>
                    <td>{formatCurrency(totals.total)}</td>
                </tr>
            </tbody>
        </table>
    );
}