import { CSSProperties } from "react";

type spinnerSize = "small" | "medium" | "large";

type LoaderProps = {
    className?: string;
    style?: CSSProperties;
    outterStyle?: CSSProperties;
    outerClassName?: string;
    size?: spinnerSize;
}

export default function Loader(props: LoaderProps) {
    const targetSize = props.size || "medium";
    const classes = typeof props.outerClassName === "undefined" ?
        "d-flex justify-content-center" :
        props.outerClassName;

    function getSizeObj(size: spinnerSize): CSSProperties {
        if (size === "small") {
            return { width: "1.2rem", height: "1.2rem" };
        }
        else if (size === "medium") {
            return { width: "2rem", height: "2rem" };
        }
        else if (size === "large") {
            return { width: "3rem", height: "3rem" };
        }
        return {};
    }

    const spinnerStyle = Object.assign(getSizeObj(targetSize), props.style);

    return (
        <div className={classes} style={props.outterStyle || {}}>
            <div
                className={"spinner-border " + props.className || ""}
                role="status"
                style={spinnerStyle}>
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
}