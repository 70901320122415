import { contactMethodsHash, contactPurposesHash, noContactMethod, noContactPurpose } from '../../constants';
import CourseTag from '../tags/CourseTag';
import '../../styles/ContactsTable.css';
import { Link } from "react-router-dom";
import Loader from '../shared/Loader';
import { useEffect, useState } from 'react';
import { toDatePT, toTimePT } from "../../utils/dates";
import Contact from '../../interfaces/Contact.interface';
import OfficeTag from '../tags/OfficeTag';
import FollowUp from '../../interfaces/FollowUp.interface';
import ListCard from '../shared/ListCard';
import FollowUpItem from '../followup/FollowUpItem';
import { api } from '../../services/api';

type ContactListItemProp = {
    contact: Contact,
    handleUpdateContactFollowUps: (followUp: FollowUp[]) => void,
    handleRemoveContact: (contact: Contact) => void,
    handleNewFollowUp: () => void,
    handleFollowUpEdit: (followUp: FollowUp) => void,
    handleFollowUpDelete: (followUp: FollowUp) => void,
    setSelectedContact: (contact: Contact) => void,
    setAssociatingSG: (boolean: boolean) => void,
    setAssociatingStudent: (boolean: boolean) => void,
}


export default function ContactsListItem(props: ContactListItemProp) {
    const { contact, handleRemoveContact, handleNewFollowUp, setAssociatingSG, setAssociatingStudent, setSelectedContact, handleUpdateContactFollowUps } = props;

    const [loading, setLoading] = useState<Boolean>(false);

    const [followUpsVisible, setFollowUpsVisible] = useState(false);

    useEffect(() => {

        if (followUpsVisible) {
            setLoading(true);
            api.getContactFollowUp(contact.id,
                (data) => {
                    handleUpdateContactFollowUps(data as FollowUp[]);
                    setLoading(false);
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                });
        }

    }, [followUpsVisible, handleUpdateContactFollowUps, contact.id])

    return (
        <ListCard
            key={`${contact.id}`}
            body={

                <div style={{ width: '100%' }}>
                    <div className="d-flex" >
                        <div className="col">
                            <div>
                                <i
                                    className={(contactMethodsHash[contact.contact_method_id] || noContactMethod).icon}
                                    style={{ marginRight: "5px", color: "#607d8b" }}
                                    title={(contactMethodsHash[contact.contact_method_id] || noContactMethod).name}></i>
                                {contact.student_id ?
                                    <Link to={`/alunos/${contact.student_id}/contactos`}>{contact.student_name}</Link>
                                    :
                                    contact.name
                                }
                            </div>
                            <div className="contact-created-date">{toDatePT(new Date(contact.created_date || contact.date))}</div>
                            <div className="contact-email">
                                <a
                                    href={`mailto:${contact.email}`}
                                    target="_blank"
                                    rel="noreferrer">{contact.email}</a>
                            </div>
                            <div className="contact-phone">{contact.phone}</div>
                            <OfficeTag office={contact.office_id} />
                        </div>
                        <div className="col">
                            <div>{contact.lastFollowUpDate ?
                                toDatePT(contact.lastFollowUpDate) :
                                toDatePT(contact.date)}</div>
                            <div>{contact.lastFollowUpDate ?
                                toTimePT(contact.lastFollowUpDate) :
                                toTimePT(contact.date)}</div>
                        </div>
                        <div className="col">
                            <div style={{ fontWeight: "bold", fontSize: "0.95rem" }}>
                                {(contactPurposesHash[contact.contact_purpose_id] || noContactPurpose).name}
                            </div>
                            <div style={{ fontSize: "0.95rem", width: "300px", textAlign: "start" }}>{contact.description}</div>
                        </div>
                        <div className="col">
                            <div className="coursesContainer" style={{ margin: "0 40px" }} >
                                {contact.courses && contact.courses.map(course =>
                                    <CourseTag
                                        style={{ marginBottom: "10px" }}
                                        name={course.title}
                                        key={course.title} />
                                )}
                            </div>
                        </div>
                        <div className="col">


                            <div>
                                <div className="action-icons-container divider">
                                    <Link
                                        className="action-icon"
                                        to={`/contactos/${contact.id}/editar`}>
                                        <button type="button"
                                            onClick={() => {
                                                setSelectedContact(contact);
                                                handleNewFollowUp();
                                            }}
                                            className={`btn btn-outline-secondary btn-rounded waves-effect list_item_button`}>
                                            <i className="fas fa-pen" title="Editar"></i>
                                            <span>Editar</span>
                                        </button>
                                    </Link>
                                    <span
                                        className="action-icon"
                                        onClick={() => handleRemoveContact(contact)}>
                                        <button type="button"
                                            onClick={() => {
                                                setSelectedContact(contact);
                                                handleNewFollowUp();
                                            }}
                                            className={`btn btn-outline-secondary btn-rounded waves-effect list_item_button`}>
                                            <i className="fas fa-trash" title="Eliminar"></i>
                                            <span>Eliminar</span>
                                        </button>
                                    </span>
                                </div>
                                <div className="container">
                                    <div className="row">
                                        {contact.free_session_id ? "" :
                                            <div className="col-sm">
                                                <Link
                                                    className="action-icon"
                                                    to={`/sessoes-gratuitas/nova?contacto=${contact.id}`}>
                                                    <button type="button"
                                                        className={`btn btn-outline-success btn-rounded waves-effect link`}>
                                                        <i className="fas fa-calendar-plus" aria-hidden="true"></i>
                                                        <span>Marcar SG</span>
                                                    </button>
                                                </Link>
                                            </div>
                                        }
                                        {contact.student_id ? "" :
                                            <div className="col-sm">
                                                <Link
                                                    className="action-icon"
                                                    to={`/alunos/novo?contacto=${contact.id}`}>
                                                    <button type="button"
                                                        className={`btn btn-outline-success btn-rounded waves-effect link`}>
                                                        <i className="fas fa-user-plus" aria-hidden="true"></i>
                                                        <span>Inscrever</span>
                                                    </button>
                                                </Link>
                                            </div>
                                        }
                                        <div className="col-sm">
                                            <button type="button"
                                                onClick={() => {
                                                    setSelectedContact(contact);
                                                    handleNewFollowUp();
                                                }}
                                                className={`btn btn-outline-success btn-rounded waves-effect link full`}>
                                                <i className="fas fa-reply" aria-hidden="true"></i>
                                                <span>Novo Seguimento</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row">
                                        {!contact.free_session_id ?
                                            <div className="col-sm">
                                                <button type="button"
                                                    className="btn icon-button-small btn-outline-primary link"
                                                    onClick={() => {
                                                        setAssociatingSG(true);
                                                        setSelectedContact(contact);
                                                    }}>
                                                    Associar a SG
                                                </button>

                                            </div>
                                            : ""
                                        }
                                        {!contact.student_id ?
                                            <div className="col-sm">
                                                <button type="button"
                                                    className="btn btn-outline-primary link"
                                                    onClick={() => {
                                                        setAssociatingStudent(true);
                                                        setSelectedContact(contact);
                                                    }}>
                                                    Associar Aluno
                                                </button>
                                            </div>

                                            : ""
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {

                        contact.follow_ups && contact.follow_ups.length > 0 ?
                            <div className="expandable followup_container">
                                <i
                                    className={`fas bounce followup_icon fa-angle-double-${followUpsVisible ? "up" : "down"}`}
                                    onClick={() => setFollowUpsVisible(!followUpsVisible)}>
                                </i>

                                {loading && <Loader />}

                                {followUpsVisible ? contact.follow_ups.map((followUp: FollowUp) =>
                                    <FollowUpItem followUp={followUp}
                                        handleFollowUpEdit={
                                            (followUp: FollowUp) => {
                                                setSelectedContact(contact);
                                                props.handleFollowUpEdit(followUp);
                                            }
                                        }
                                        handleFollowUpDelete={
                                            (followUp: FollowUp) => {
                                                setSelectedContact(contact);
                                                props.handleFollowUpDelete(followUp);
                                            }
                                        }
                                    />
                                )
                                    : ""
                                }
                            </div>
                            : ""
                    }
                </div>
            } />

    );
}