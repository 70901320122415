import React, { CSSProperties } from "react";
import Divulgation from "../../../interfaces/Divulgation.interface";
import OfficeCombo from "../../combos/OfficeCombo";
import GenericCombo from "../../shared/GenericCombo";
import { StudentErrors, StudentFields } from "./StudentForm";

type StudentFormabaseFormProps = {
    fieldsValues: StudentFields;
    setFieldsValues: (val: StudentFields) => void;
    divulgations: Divulgation[];
    errors: StudentErrors;
    style?: CSSProperties;
};

export default function StudentFormabaseForm(props: StudentFormabaseFormProps) {
    const { fieldsValues, setFieldsValues, divulgations, errors, style } = props;

    return (
        <div className="card p-3" style={style}>
            <h4 className="m-2">Formabase</h4>
            <div className="row">
                <div className="col m-2">
                    <label>Filial</label>
                    <OfficeCombo
                        showError={errors.office}
                        value={fieldsValues.office}
                        selectionChanged={newOffice =>
                            setFieldsValues({ ...fieldsValues, office: newOffice })} />
                </div>
                <div className="col m-2">
                    <div className="form-group">
                        <label>Divulgação</label>
                        <GenericCombo
                            value={fieldsValues.divulgationId}
                            values={divulgations}
                            getId={divulgation => divulgation.id}
                            getOptionContent={divulgation => divulgation.title}
                            selectionChanged={newDivulgation =>
                                setFieldsValues({ ...fieldsValues, divulgationId: newDivulgation as number })} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col m-2">
                    <div className="form-group">
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            className="form-control"
                            id="username"
                            value={fieldsValues.username || ""}
                            onChange={event => setFieldsValues({ ...fieldsValues, username: event.target.value })} />
                    </div>
                </div>
                <div className="col m-2">
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="text"
                            className="form-control"
                            id="username"
                            value={fieldsValues.password || ""}
                            onChange={event => setFieldsValues({ ...fieldsValues, password: event.target.value })} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col m-2">
                    <label htmlFor="obs">Observações</label>
                    <textarea
                        className="form-control"
                        id="obs"
                        rows={3}
                        value={fieldsValues.observations || ""}
                        onChange={event => setFieldsValues({ ...fieldsValues, observations: event.target.value })}></textarea>
                </div>
            </div>
        </div>
    );

}