import React, { useContext, useEffect, useState } from "react";
import Purchase from "../../models/Purchase.model";
import { api } from "../../services/api";
import { MessagingContext } from "../App";
import NewPurchaseCard from "../purchase/NewPurchaseCard";
import PurchasesGrid from "../purchase/PurchasesGrid";

export default function StudentsEnrollments(props: { studentId: number, setStudentName: (name: string) => void }) {
    const messaging = useContext(MessagingContext);
    const [addingNew, setAddingNew] = useState(false);
    const [purchases, setPurchases] = useState<Purchase[] | undefined>();

    useEffect(() => {
        api.getPurchases({ student_id: props.studentId }, (resp: any) => {
            const purchases = resp.map((val: any) => new Purchase(val));
            purchases.sort((p1: Purchase, p2: Purchase) => p2.startDate.getTime() - p1.startDate.getTime());
            setPurchases(purchases);
        }, err => messaging.modal.showMessage("Erro",
            "Erro ao carregar inscrições do aluno: " + err.message));
        //eslint-disable-next-line
    }, [props.studentId]);

    function updatePurchase(newPurchase: Purchase) {
        if (purchases) {
            const newPurchases = purchases;

            const edited: any = newPurchases.find((purchase: Purchase) => purchase.id === newPurchase.id);

            Object.assign(edited, newPurchase);

            setPurchases(newPurchases);
        }
    }

    function deletePurchase(purchaseId: number) {
        if (purchases) {

            const newPurchases = purchases.filter((purchase: Purchase) => purchase.id !== purchaseId);

            setPurchases(newPurchases);
        }
    }

    return (
        <div>
            <button
                className="btn btn-success"
                onClick={() => setAddingNew(true)}
                style={{ marginBottom: "20px" }}>Nova Inscrição</button>
            {addingNew &&
                <NewPurchaseCard
                    studentId={props.studentId}
                    close={() => setAddingNew(false)} />}
            <PurchasesGrid studentId={props.studentId} purchases={purchases} changedData={updatePurchase} removedData={deletePurchase} />
        </div>
    );
}