import { contactMethods } from "../../../constants";

interface ContactMethodComboProps {
    value: number | undefined;
    selectionChanged: (newVal: number) => void;
    showError?: boolean;
}

export default function ContactMethodCombo(props: ContactMethodComboProps) {
    return (
        <>
            <select
                value={props.value}
                className="form-select"
                onChange={event => props.selectionChanged(parseInt(event.target.value))}>

                <option value={0}>Escolha a forma de contacto</option>
                {contactMethods.map(method =>
                    <option value={method.id} key={method.id}>{method.name}</option>)}
            </select>
            {props.showError &&
                <div className="invalid-feedback" style={{ display: "block" }}>
                    Precisa de escolher uma forma de contacto
                </div>
            }
        </>
    );
}