import { useState, useRef, useEffect } from "react";
import { DAYS_PER_WEEK, offices } from "../../constants";
import Payment from "../../models/Payment.model";
import { api } from "../../services/api";
import GenericToggle from "../GenericToggle";
import OfficeToggle from "../OfficeToggle";
import ExpiringPaymentsTable from "../payment/ExpiringPaymentsTable";
import ListTotalComponent from "../shared/ListTotalComponent";
import Loader from "../shared/Loader";
import PaginationLinks from "../shared/PaginationLinks";

export const expiringOptions = [
    { name: "Expirou", id: 0, search: { expired: true } },
    { name: "1 semana", id: 1, search: { expiring_days: 1 * DAYS_PER_WEEK } },
    { name: "2 semanas", id: 2, search: { expiring_days: 2 * DAYS_PER_WEEK } },
    { name: "3 semanas", id: 3, search: { expiring_days: 3 * DAYS_PER_WEEK } },
    { name: "1 mês", id: 4, search: { expiring_months: 1 } },
    { name: "2 meses", id: 5, search: { expiring_months: 2 } },
    { name: "3 meses", id: 6, search: { expiring_months: 3 } },
];

export default function StudentsExpiringPayments() {
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const [payments, setPayments] = useState<Payment[] | undefined>();
    const [currentOffice, setCurrentOffice] = useState(offices[0]);
    const [currentExpiringOption, setCurrentExpiringOption] = useState(0);
    const [paymentsTotal, setPaymentsTotal] = useState<number>(0);

    const searchRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        const params = { office: currentOffice.value, page: currentPage, };
        Object.assign(params, expiringOptions[currentExpiringOption].search);
        api.getExpiringPayments(params,
            newPayments => {
                const sortedPayments: Payment[] = newPayments.data.map((payment: any) => new Payment(payment));

                setPayments(sortedPayments);
                setHasMorePages(newPayments.morePages);

                const total = sortedPayments.reduce((total: number, payment: Payment) => total + payment.value, 0)
                setPaymentsTotal(total);
            },
            () => setPayments([]));

        searchRef.current?.focus();
    }, [currentPage, currentOffice, currentExpiringOption]);

    const paginationLinksJSX = <PaginationLinks
        page={currentPage}
        hasMorePages={hasMorePages}
        pageChanged={newPage => setCurrentPage(newPage)}
        classes="justify-content-end" />;

    return (
        <div className="container">
            <h2 className="text-center m-4">Pagamentos em Falta</h2>

            <div style={{ display: "flex" }}>
                <div style={{ margin: "30px 0" }}>
                    Filial: <OfficeToggle officeChanged={newOffice => setCurrentOffice(newOffice)} />
                </div>

                <div style={{ margin: "30px 40px" }}>
                    Expira em: <GenericToggle
                        values={expiringOptions}
                        toggleChanged={newFilter => setCurrentExpiringOption(newFilter.id)}
                        getId={option => option.id.toString()}
                        getOptionContent={option => option.name}
                        defaultOptionIndex={currentExpiringOption}
                    />
                </div>
            </div>

            {payments ?
                <>
                    {paginationLinksJSX}
                    <ExpiringPaymentsTable values={payments} />
                    {paginationLinksJSX}

                    <ListTotalComponent
                        totalValue={paymentsTotal}
                        hidePageTotal={true}
                    />
                </> :
                <Loader />
            }
        </div>
    );
}