import { api } from '../../services/api';
import React, { useEffect, useState, useContext } from "react";
import { MessagingContext } from '../App';
import Loader from '../shared/Loader';
import City from '../../interfaces/City.interface';
import CityForm from '../cities/CityForm';

type CityComboProps = {
    value: City | undefined;
    selectionChanged: (newVal: City | undefined) => void;
    cityInserted?: (newList: City[]) => void;
    showError?: boolean;
    defaultText?: string;
    autoFetchCities?: boolean;
    availableCities?: City[] | undefined;
}

export default function CityCombo(props: CityComboProps) {
    const messaging = useContext(MessagingContext);
    const [cities, setCities] = useState<City[] | undefined>();
    const [showInsertModal, setShowInsertModal] = useState(false);
    const showCities = props.autoFetchCities !== false ? cities : props.availableCities;

    useEffect(() => {
        if (props.autoFetchCities !== false) {
            api.getCities(
                (data: any) => setCities(data as City[]),
                err => messaging.modal.showMessage("Erro", "Erro a obter as localidades: " + err));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    function handleCityChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const selectIntVal = parseInt(event.target.value);
        if (showCities) {
            const selectedCourse = showCities.find(city => city.id === selectIntVal);
            props.selectionChanged(selectedCourse);
        }
    }

    function addCityToList(newCity: City) {
        if (showCities) {
            const newCitiesList = [...showCities, newCity as City];
            const sortedList = newCitiesList.sort((a: City, b: City) => a.title.localeCompare(b.title));
            if (props.autoFetchCities) {
                setCities(sortedList);
            } else if (props.cityInserted) {
                props.cityInserted(sortedList);
            }
            setShowInsertModal(false);
        }
    }

    return (
        <>
            {showCities ?
                <>
                    {showInsertModal &&
                        <CityForm
                            closeHandler={() => setShowInsertModal(false)}
                            city={undefined}
                            cityAdded={addCityToList}
                            editing={false}
                        />}
                    <div className="d-flex" style={{ alignItems: 'baseline' }}>
                        <button type="button"
                            style={{ fontSize: "1rem", marginRight: "5px" }}
                            onClick={() => {
                                setShowInsertModal(true);
                            }}
                            className={`btn btn-outline-success btn-rounded waves-effect`}>
                            <i className="fas fa-plus-square"></i>
                        </button>
                        <div>
                            <select
                                value={typeof props.value === "undefined" ? 0 : props.value.id}
                                className="form-select"
                                onChange={handleCityChange}>
                                <option value={0}>{props.defaultText || "Escolha a localidade"}</option>
                                {
                                    showCities
                                        .map((city: City, pos: number) =>
                                            <option
                                                key={`${city.id}-${pos}`}
                                                value={city.id}>{city.title}</option>
                                        )}
                            </select>
                            {props.showError &&
                                <div className="invalid-feedback" style={{ display: "block" }}>
                                    Precisa de escolher uma localidade
                                </div>
                            }
                        </div>
                    </div>
                </>
                :
                <Loader />
            }
        </>
    );
}