import React, { useState, useEffect, useRef } from 'react';
import { MILLISECONDS_LIST_WAIT, offices } from '../../constants';
import { buildContact } from '../../utils/objects-builders';
import { api } from '../../services/api';
import StudentsTable from '../student/StudentsTable';
import OfficeToggle from '../OfficeToggle';
import PaginationLinks from '../shared/PaginationLinks';
import ContactsTable from '../contact/ContactsTable';
import FreeSessionsTable from '../free-session/FreeSessionsTable';
import Contact from "../../interfaces/Contact.interface";
import SearchForm from '../shared/SearchForm';
import Loader from '../shared/Loader';
import Student from "../../models/Student.model";
import { FreeSession } from '../../models/FreeSession.model';
import { dealyForXSeconds } from '../../utils/generic';

export default function MultiSearchList() {
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const [currentSearch, setCurrentSearch] = useState("");
    const [freeSessions, setFreeSessions] = useState<FreeSession[] | undefined>();
    const [students, setStudents] = useState<Student[] | undefined>();
    const [contacts, setContacts] = useState<Contact[] | undefined>();

    const [currentOffice, setCurrentOffice] = useState(offices[0]);

    const searchRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        const searchParams: any = {
            office: currentOffice.value,
            page: currentPage,
            pageSize: 5
        };
        if (currentSearch.trim() !== "") {
            searchParams.search = currentSearch;
        }

        api.getMultiSearch(searchParams,
            newInfo => {
                setFreeSessions(newInfo.free_session.data.map((freeSession: any) => new FreeSession(freeSession)));
                setContacts(newInfo.contact.data.map(buildContact));
                setStudents(newInfo.student.data.map((student: any) => new Student(student)));
                setHasMorePages(newInfo.free_session.morePages || newInfo.contact.morePages || newInfo.student.morePages);
            },
            () => {
                setFreeSessions([]);
                setStudents([]);
                setContacts([]);
            });
    }, [currentPage, currentSearch, currentOffice]);

    function handleContactRemoved(contactId: number) {
        if (contacts) {
            setContacts(contacts.filter(contact => contact.id !== contactId));
        }
    }

    function handleChangedContact(contact: Contact) {
        if (contacts) {
            const pos = contacts.findIndex((c: Contact) => c.id === contact.id);
            const newContacts = contacts.splice(pos, 1, contact);

            setContacts(newContacts);
        }
    }

    function handleFreeSessionRemoved(freeSessionId: number) {
        if (freeSessions) {
            setFreeSessions(freeSessions.filter(freeSession => freeSession.id !== freeSessionId));
        }
    }

    function handleFreeSessionChanged(freeSession: FreeSession) {
        if (freeSessions) {
            const freeSessionsCopy = [...(freeSessions || [])];
            const index = freeSessions.findIndex(fs => fs.id === freeSession.id);
            if (index !== -1) {
                freeSessionsCopy[index] = freeSession;
            }
            setFreeSessions(freeSessionsCopy);
        }
    }

    const paginationLinksJSX = <PaginationLinks
        page={currentPage}
        hasMorePages={hasMorePages}
        pageChanged={newPage => setCurrentPage(newPage)}
        classes="justify-content-end" />;

    const titleMargin = "60";
    return (
        <div className="multiSearchContainer">
            <div style={{ margin: "30px 0" }}>
                Filial: <OfficeToggle officeChanged={newOffice => setCurrentOffice(newOffice)} />
            </div>

            <SearchForm
                label="Pesquisar alunos"
                onChangeHandler={event => {
                    dealyForXSeconds(MILLISECONDS_LIST_WAIT, () => {
                        setCurrentSearch(event.target.value);
                        setCurrentPage(1);
                    });
                }}
                style={{ marginBottom: "20px" }}
                searchRef={searchRef} />

            <h5>Alunos</h5>

            {paginationLinksJSX}

            {students ?
                <div className="list-container container">
                    <StudentsTable values={students} />
                </div>
                :
                <Loader />
            }
            <h5 style={{ marginTop: `${titleMargin}px` }}>Contactos</h5>
            {
                contacts ?
                    <div className="list-container container">
                        <ContactsTable values={contacts} contactRemoved={handleContactRemoved} contactModified={handleChangedContact} />
                    </div>
                    :
                    <Loader />
            }
            <h5 style={{ marginTop: `${titleMargin}px` }}>Sessões Gratuitas</h5>
            {
                freeSessions ?
                    <div className="container">

                        <FreeSessionsTable
                            values={freeSessions}
                            freeSessionRemoved={handleFreeSessionRemoved}
                            freeSessionChanged={handleFreeSessionChanged}
                            page={currentPage} />
                    </div>
                    :
                    <Loader />
            }

            {paginationLinksJSX}
        </div >
    );
}