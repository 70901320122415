import moment from "moment";
import { EMPOWER_LOGO } from "../../constants";
import Purchase from "../../models/Purchase.model";
import { toDatePT } from "../../utils/dates";
import styles from "../shared/Print.module.css";
import PrintInfoTable from "../shared/PrintInfoTable";
import 'moment/locale/pt';
import Student from "../../models/Student.model";
import Customer from "../../models/Customer.model";

export default function PrintPurchase(props: { purchase: Purchase, student: Student, close: () => void }) {
    const { purchase, student, close } = props;
    const customer = purchase.customer;

    const now = moment();
    now.locale("pt");

    let studentInfo1: any = [
        [["Nome", student.name]],
        [["Cidade", student.city], ["Data de nascimento", toDatePT(student.birthDate)]],
        [["Curso(s)", purchase.description()]],
        [["Nacionalidade", student.nationality], ["Naturalidade", student.naturality]],
        [["Morada", student.address], ["Email", student.email]],
        [["Localidade", `${student.city}`], ["Código Postal", `${student.postalCode}`]],
        [["Telemóvel", student.phone]],
        [["N.º identificação (CC, BI, etc.)", student.identificationNumber], ["Validade", toDatePT(student.identificationDate)]],
        [["Nome de Utilizador", student.username], ["Password", student.password]],
        [["Nº Contribuinte", student.fiscalNumber], ["Estado Civil", student.civilState]],
        [["Escolaridade (completa)", student.education], ["Profissão/função", student.occupation]]
    ];

    let customerData: any = [
        [["Que dados quer usar para faturação?", student.fiscalNumber === customer.fiscalNumber ? " IGUAIS AOS DADOS PESSOAIS" : "EMPRESA"]],
        [["Nome (completo)", student.fiscalNumber === customer.fiscalNumber ? "" : customer.name]],
        [["N.º contribuinte", student.fiscalNumber === customer.fiscalNumber ? "" : customer.fiscalNumber], ["Morada", student.fiscalNumber === customer.fiscalNumber ? "" : customer.address]],
        [["Código postal", student.fiscalNumber === customer.fiscalNumber ? "" : customer.postalCode], ["Localidade", student.fiscalNumber === customer.fiscalNumber ? "" : customer.city]],
        [["Telefone (faturação)", student.fiscalNumber === customer.fiscalNumber ? "" : customer.phone], ["Email (faturação)", student.fiscalNumber === customer.fiscalNumber ? "" : customer.email]],
    ];
    const count = [student.documentCC, student.documentCH, student.documentCV].filter(doc => doc === true).length;
    let documentsData: any = [
        [["Documentos entregues", count]]
    ];

    let purchaseInfo: any = [
        [["Nome de utilizador", purchase.studentUsername ?? ""], ["Password", purchase.studentPassword ?? ""],],
    ];
    let paymentsInfo: any = [
        [["Valor Final", purchase.value + "€"], ["Forma de Pagamento", purchase.payments[0].method]],
        [["IBAN", "PT50 0033 0000 4539 2256 0840 5 BCP"]],
    ];

    return (
        <div
            style={{ backgroundColor: "white", zIndex: 500, padding: "30px", overflow: "auto" }}
            className="printable fixed-full-back">
            <i
                className="fas fa-print action-icon non-printable"
                onClick={() => window.print()}
                style={{ fontSize: "4rem" }}></i>
            <div className="d-flex justify-content-center">
                <img style={{ width: '30%' }} src={process.env.REACT_APP_IMAGES_URL + "/" + EMPOWER_LOGO} alt="logo" />
                <i
                    className="fas fa-times action-icon non-printable"
                    onClick={() => close()}
                    style={{ fontSize: "2rem", position: "absolute", right: "20px", top: "20px" }}></i>
            </div>
            <h2 className="mt-20">Ficha de Inscrição</h2>

            <div className={styles.printInfoContainer}>
                <PrintInfoTable data={studentInfo1} />
            </div>
            <div className={styles.tableHeaderContainer}>
                <span className={styles.tableHeader}>Dados de Inscrição</span>
            </div>
            <div className={styles.printInfoContainer}>
                <PrintInfoTable data={purchaseInfo} />
            </div>
            <div className={styles.tableHeaderContainer}>
                <span className={styles.tableHeader}>Dados para faturação</span>
            </div>
            <div className={styles.printInfoContainer}>
                <PrintInfoTable data={customerData} />
            </div>
            <div className={styles.tableHeaderContainer}>
                <span className={styles.tableHeader}>Documentos</span>
            </div>
            <div className={styles.printInfoContainer}>
                <PrintInfoTable data={documentsData} />
            </div>
            <div className={styles.tableHeaderContainer}>
                <span className={styles.tableHeader}>Pagamento</span>
            </div>
            <div>
                <PrintInfoTable data={paymentsInfo} />
            </div>
            <div className={styles.tableHeaderContainer}>
                <span className={styles.tableHeader}>Condições</span>
            </div>

            <div className={styles.printInfoContainer}>
                <PrintInfoTable data={[
                    [["Data", ""]],
                    [["Assinatura da/o candidata/o", ""]]
                ]} />
            </div>
            <br />
            <div>
                <div><i className="fas fa-solid action-icon fa-check"></i> Aceito ceder os meus dados de acordo com a <a href="https://crm.empower.pt/privacidade-protecao-dados/">política de proteção de dados</a>.</div>
                <div><i className="fas fa-solid action-icon fa-check"></i> Autorizo que os meus dados pessoais sejam divulgados com o objetivo de auscultação pelo Sistema de Certificação, Fundo Social Europeu e Entidade Formadora.</div>
            </div>
            <br />

            <div className={styles.printInfoContainer}>
                <PrintInfoTable data={[
                    [["Data", ""]],
                    [["Assinatura do/a coordenador/a", ""]]
                ]} />
            </div>


            <footer>
                <div className="mt-20 bold">Empower - Formabase</div>
                <div><span className="bold">Lisboa</span> - Av. de Berna, 11, 1.º, 1050-035 Lisboa / Tel: 935 108 234</div>
                <div>geral@formabase.com / www.formabase.com</div>
            </footer>
        </div >
    );
}