import { useState, useRef, useEffect } from 'react';
import { offices } from '../../constants';
import OfficeToggle from '../OfficeToggle';
import PaginationLinks from '../shared/PaginationLinks';
import StudentsTable from './StudentsTable';
import Student from '../../models/Student.model';
import SearchForm from '../shared/SearchForm';
import '../contact/contact.modal.styles.css';
import Loader from '../shared/Loader';
import { api } from '../../services/api';
import Modal from '../shared/Modal';

interface StudentModalProps {
    closeHandler: () => void;
    selectHandler: (any: any) => void;
    title: string;
}

export default function StudentModal(props: StudentModalProps) {
    const [currentOffice, setCurrentOffice] = useState(offices[0]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const [currentSearch, setCurrentSearch] = useState("");
    const [students, setStudents] = useState<Student[] | undefined>();
    const searchRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        const searchParams: any = {
            office: currentOffice.value,
            page: currentPage,
            pageSize: 5
        };
        if (currentSearch.trim() !== "") {
            searchParams.search = currentSearch;
        }

        api.getStudents(searchParams,
            newStudents => {
                setHasMorePages(newStudents.morePages);
                setStudents(newStudents.data.map((student: any) => new Student(student)));
            },
            () => setStudents([]));

        searchRef.current?.focus();
    }, [currentPage, currentSearch, currentOffice]);

    const paginationLinksJSX = <PaginationLinks
        page={currentPage}
        hasMorePages={hasMorePages}
        pageChanged={newPage => setCurrentPage(newPage)}
        classes="justify-content-end" />;

    const body =
        <div>
            <div style={{ backgroundColor: "#efefef", padding: "20px 0" }}>
                <div className="container list-container" style={{ position: "relative" }}>
                    <SearchForm
                        label="Pesquisar alunos"
                        onChangeHandler={event => {
                            setCurrentSearch(event.target.value);
                            setCurrentPage(1);
                        }}
                        style={{ marginBottom: "20px" }}
                        searchRef={searchRef} />


                    <div style={{ margin: "30px 0" }}>
                        Filial: <OfficeToggle officeChanged={newOffice => setCurrentOffice(newOffice)} />
                    </div>

                    {paginationLinksJSX}
                    {
                        <>
                            {
                                (students ?
                                    <StudentsTable values={students} selectHandler={props.selectHandler} />
                                    : <Loader />)
                            }
                        </>
                    }
                    {paginationLinksJSX}
                </div>
            </div>
        </div>;

    return (
        <>
            <Modal
                title={props.title}
                body={body}
                closeName="Fechar"
                closeHandler={props.closeHandler}
                dialogClasses={"big"}
                style={{}}
                bodyMaxHeight={500} />
        </>
    );
}