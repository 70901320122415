interface OfficeComboProps {
    value: number | undefined;
    selectionChanged: (newVal: number) => void;
    showError?: boolean;
}

export default function OfficeCombo(props: OfficeComboProps) {
    return (
        <>
            <select
                value={props.value}
                className="form-select"
                onChange={event => props.selectionChanged(parseInt(event.target.value))}>

                <option value={0}>Escolha a filial</option>
                <option value={1}>Lisboa</option>
                <option value={2}>Porto</option>
            </select>
            {props.showError &&
                <div className="invalid-feedback" style={{ display: "block" }}>
                    Precisa de escolher a filial
                </div>
            }
        </>
    );
}