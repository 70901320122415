import Modal from '../../shared/Modal';
import StudentForm from "../forms/StudentForm";


type NewStudentModalProps = {
    title: string;
    closeHandler: () => void;
}

export default function NewStudentModal(props: NewStudentModalProps) {
    const { title, closeHandler } = props;

    return (

        <Modal
            title={title}
            body={<StudentForm />}
            closeName="Fechar"
            closeHandler={closeHandler}
            dialogClasses={"big"}
            style={{}}
            bodyMaxHeight={500} />
    );
}