import { useContext, useEffect, useState } from "react";
import Carer from "../../models/Carer.model";
import { api } from "../../services/api";
import { MessagingContext } from "../App";
import CarerModal from "../carers/CarerModal";
import StudentCarerCard from "./cards/StudentCarerCard";

type StudentCarerProps = {
    studentId: number,
    setStudentName: (name: string) => void
}


export default function StudentCarer(props: StudentCarerProps) {
    const { studentId } = props;
    const messaging = useContext(MessagingContext);
    const [associatingStudent, setAssociatingStudent] = useState(false);

    const [carer, setCarer] = useState<Carer | undefined>();

    function handleEditCarer(editedCarer: Carer) {
        if (carer) {
            const fieldValues = {
                id: carer.id,
                name: editedCarer.name,
                email: editedCarer.email,
                phone: editedCarer.phone,
                address: editedCarer.address,
                postal_code: editedCarer.postalCode,
                city_id: editedCarer.cityId,
                fiscal_number: editedCarer.fiscalNumber,
                identification_number: editedCarer.identificationNumber,
                identification_date: editedCarer.identificationDate,
                identification_entity: editedCarer.identificationEntity,
                identification_type_id: editedCarer.identificationTypeId,
                naturality: editedCarer.naturality,
                nationality_id: editedCarer.nationalityId,
                observations: editedCarer.observations,
                birth_date: editedCarer.birthDate,
            }
            api.editCarer(fieldValues,
                () => {
                    setCarer(editedCarer);
                },
                err => {
                    messaging.modal.showMessage("Erro", "Erro a editar os dados do aluno: " + err.message);
                }
            );
        }
    }

    useEffect(() => {
        if (typeof carer === "undefined") {
            api.getStudentCarer(studentId,
                carer => {
                    setCarer(new Carer(carer))
                },
                err => {
                    console.log(err);
                    setCarer(undefined);
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studentId, carer]);
    function associateContactWithStudent(carer: Carer) {
        const studentInfo = {
            id: studentId,
            carer_id: carer.id
        }
        api.editStudent(studentInfo,
            () => {
                setAssociatingStudent(false)
            },
            (err) => {
                messaging.modal.showMessage("Erro", "Erro ao editar a formação: " + err.message)
            });
    }

    return (
        <>
            <button
                className="btn btn-success"
                onClick={() => setAssociatingStudent(true)}
            >
                Associar a Encarregado de Educação
            </button>
            <div className="d-flex justify-content-center">
                {associatingStudent &&
                    <CarerModal
                        selectHandler={(carer: Carer) => associateContactWithStudent(carer)}
                        title={"Associar um Aluno a um Encarregado de Educação"}
                        closeHandler={() => setAssociatingStudent(false)} />
                }
                <div style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
                    {!carer ?
                        "Este aluno não possui Encarregado de Educação" :
                        <StudentCarerCard
                            value={carer}
                            style={{ margin: "20px 10px" }}
                            handleCarerChange={handleEditCarer} />
                    }
                </div>
            </div>
        </>
    );
}