import { qualities } from '../constants';
import TeacherRating from './TeacherRating.model';

export default class Survey {

    public id: number;
    public student: string;
    public user: string;
    public courseName: string;
    public classificationGeneralId: number;
    public classificationMethodId: number;
    public classificationManualId: number;
    public classificationServiceId: number;
    public classificationFacilitiesId: number;
    public classificationPlatformId: number;
    public classificationTeacherOverallId: number;
    public aspectsMethod: string;
    public aspectsManual: string;
    public aspectsService: string;
    public aspectsFacilities: string;
    public aspectsTeachers: string;
    public aspectsPlatform: string;
    public comments: string;
    public metExpectations: boolean;
    public whyExpectations: string;
    public permission: boolean;
    public createdDate: Date;
    public overallRating: number;
    public surveyDate: Date;
    public statusId: number;
    public excerpt: string;
    public surveyStatusTypeId: number;
    public surveyStatusType: string;
    public observations: string;
    public officeId: string;
    public office: string;
    public teachersRatings: TeacherRating[];

    constructor(data: any) {
        this.id = data.id;
        this.student = data.student;
        this.user = data.user;
        this.courseName = data.course_name;
        this.classificationGeneralId = data.classification_general_id || data.classification_general;
        this.classificationMethodId = data.classification_method_id || data.classification_method;
        this.classificationManualId = data.classification_manual_id || data.classification_manual;
        this.classificationServiceId = data.classification_service_id || data.classification_service;
        this.classificationFacilitiesId = data.classification_facilities_id || data.classification_facilities;
        this.classificationTeacherOverallId = data.classification_teacher_overall_id || data.classification_teacher_overall;
        this.classificationPlatformId = data.classification_platform_id || data.classification_platform;
        this.aspectsMethod = data.aspects_method;
        this.aspectsManual = data.aspects_manual;
        this.aspectsService = data.aspects_service;
        this.aspectsFacilities = data.aspects_facilities;
        this.aspectsTeachers = data.aspects_teachers;
        this.aspectsPlatform = data.aspects_platform;
        this.comments = data.comments;
        this.metExpectations = data.met_expectations;
        this.whyExpectations = data.why_expectations;
        this.permission = data.permission;
        this.createdDate = new Date(data.created_date);
        this.overallRating = data.overall_rating;
        this.surveyDate = new Date(data.survey_date);
        this.statusId = data.status_id;
        this.excerpt = data.excerpt;
        this.surveyStatusTypeId = data.survey_status_type_id;
        this.surveyStatusType = data.survey_status_type;
        this.observations = data.observations;
        this.officeId = data.office_id;
        this.office = data.office;

        this.teachersRatings = [];

        for (const key in data.teachers) {
            if (Object.prototype.hasOwnProperty.call(data.teachers, key)) {
                const teacher = data.teachers[key];

                if (teacher && teacher.length > 0) {


                    let clarity, availability, proActivity, ability = 0;

                    for (const quality of teacher) {

                        qualities.find((q: any) => q.id === quality.quality_id);

                        switch (quality.quality_id) {
                            case 1:
                                clarity = quality.classification_id;
                                break;
                            case 2:
                                availability = quality.classification_id;
                                break;
                            case 3:
                                proActivity = quality.classification_id;
                                break;
                            case 4:
                                ability = quality.classification_id;
                                break;
                        }
                    }



                    const { user, user_id } = teacher[0];


                    const newTeacherCard = new TeacherRating({
                        clarity: clarity,
                        availability: availability,
                        proActivity: proActivity,
                        ability: ability,
                        teacherName: user,
                        teacherPhoto: "",
                        teacherId: user_id,
                    });

                    this.teachersRatings.push(newTeacherCard);
                }
            }
        }
    }
}