import { useState, useEffect } from 'react';
import City from '../../interfaces/City.interface';
import Customer from "../../models/Customer.model";
import { api } from '../../services/api';
import styles from "../shared/Card.module.css";
import GenericCombo from '../shared/GenericCombo';


type CustomerDataTableProps = {
    customer: Customer,
    showResponsible: boolean,
    editMode: boolean,
    updateCustomerData?: (e: PurchaseCustomerFields) => void
}

export type PurchaseCustomerFields = {
    name: string;
    email: string;
    phone: string;
    address: string;
    postal_code: string;
    city_id: number | undefined;
    fiscal_number: number;
    observations: string;
}

export function CustomerDataTable(props: CustomerDataTableProps) {
    const { customer, showResponsible, editMode, updateCustomerData } = props;
    const [cities, setCities] = useState<City[] | undefined>(undefined);

    const [customerFieldsValues, setCustomerFieldsValues] = useState<PurchaseCustomerFields>();

    useEffect(() => {
        if (editMode) {
            setCustomerFieldsValues({
                name: customer.name,
                email: customer.email,
                phone: customer.phone,
                address: customer.address,
                postal_code: customer.postalCode,
                city_id: customer.cityId,
                fiscal_number: customer.fiscalNumber ?? 0,
                observations: customer.observations,
            })
            api.getCities((data: any) => {
                setCities(data);
            }, err => {

            });
        }

    }, [editMode, customer.address, customer.cityId, customer.email, customer.fiscalNumber, customer.name, customer.observations, customer.phone, customer.postalCode])





    useEffect(() => {
        if (updateCustomerData && customerFieldsValues) {
            updateCustomerData(customerFieldsValues);
        }
    }, [updateCustomerData, customerFieldsValues])



    return (
        <table className="table table-striped" >
            <tbody>
                <tr>
                    <td className={styles.titleField}>Nome</td>
                    <td className={styles.infoField}>
                        {editMode && customerFieldsValues
                            ?
                            <input
                                type="text"
                                name="name"
                                className="form-control"
                                value={customerFieldsValues.name}
                                onChange={(e) => setCustomerFieldsValues({ ...customerFieldsValues, "name": e.target.value })} />
                            :
                            customer && customer.name}

                    </td>
                </tr>
                <tr>
                    <td className={styles.titleField}>Email</td>
                    <td className={styles.infoField}>
                        {editMode && customerFieldsValues
                            ?
                            <input
                                type="text"
                                name="email"
                                className="form-control"
                                value={customerFieldsValues.email}
                                onChange={(e) => setCustomerFieldsValues({ ...customerFieldsValues, "email": e.target.value })} />
                            :
                            customer && customer.email}

                    </td>
                </tr>
                <tr>
                    <td className={styles.titleField}>Telefone</td>
                    <td className={styles.infoField}>
                        {editMode && customerFieldsValues
                            ?
                            <input
                                type="number"
                                name="phone"
                                className="form-control"
                                value={customerFieldsValues.phone}
                                onChange={(e) => setCustomerFieldsValues({ ...customerFieldsValues, "phone": e.target.value })} />
                            :
                            customer && customer.phone}

                    </td>
                </tr>
                <tr>
                    <td className={styles.titleField}>Morada</td>
                    <td className={styles.infoField}>
                        {editMode && customerFieldsValues
                            ?
                            <input
                                type="text"
                                name="address"
                                className="form-control"
                                value={customerFieldsValues.address}
                                onChange={(e) => setCustomerFieldsValues({ ...customerFieldsValues, "address": e.target.value })} />
                            :
                            customer && customer.address}

                    </td>
                </tr>
                <tr>
                    <td className={styles.titleField}>Código Postal</td>
                    <td className={styles.infoField}>
                        {editMode && customerFieldsValues
                            ?
                            <div className="d-flex">
                                <input
                                    type="text"
                                    name="postalCode"
                                    className="form-control"
                                    value={customerFieldsValues.postal_code}
                                    onChange={(e) => setCustomerFieldsValues({ ...customerFieldsValues, "postal_code": e.target.value })} />
                                <GenericCombo
                                    value={customerFieldsValues.city_id}
                                    values={cities}
                                    getId={(city: City) => city.id}
                                    getOptionContent={(city: City) => city.title}
                                    selectionChanged={(city: any) =>
                                        setCustomerFieldsValues({ ...customerFieldsValues, city_id: city as number })
                                    } />
                            </div>
                            :
                            `${customer && customer.postalCode} ${customer && customer.city}`}

                    </td>
                </tr>
                {showResponsible &&
                    <tr>
                        <td className={styles.titleField}>Responsável</td>
                        <td className={styles.infoField}>{customer && customer.responsible}</td>
                    </tr>
                }
                <tr>
                    <td className={styles.titleField}>NIF</td>
                    <td className={styles.infoField}>
                        {editMode && customerFieldsValues
                            ?
                            <input
                                type="number"
                                name="fiscal_number"
                                className="form-control"
                                value={customerFieldsValues.fiscal_number}
                                onChange={(e) => setCustomerFieldsValues({ ...customerFieldsValues, "fiscal_number": parseInt(e.target.value) })} />
                            :
                            customer && customer.fiscalNumber}

                    </td>
                </tr>
                <tr>
                    <td className={styles.titleField}>Observações</td>
                    <td className={styles.infoField}>
                        {editMode && customerFieldsValues
                            ?
                            <input
                                type="text"
                                name="observations"
                                className="form-control"
                                value={customerFieldsValues.observations}
                                onChange={(e) => setCustomerFieldsValues({ ...customerFieldsValues, "observations": e.target.value })} />
                            :
                            customer && customer.observations}

                    </td>
                </tr>
            </tbody>
        </table>
    );
}