import { SyntheticEvent, useState, useContext } from 'react';
import { api } from '../services/api';
import '../styles/Login.css';
import { MessagingContext } from './App';
import { RedirectionContext } from './MainRouter';

export default function Login() {
    const messaging = useContext(MessagingContext);
    const redirection = useContext(RedirectionContext);

    const [formData, setFormData] = useState({ user: '', password: '' });

    const [contador, AlterarContador] = useState(0);
    const [nome, AlterarNome] = useState("");



    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        setFormData({ ...formData, [event.target.name]: event.target.value });
    }

    function handleSubmit(event: SyntheticEvent) {
        event.preventDefault();
        api.login(formData.user, formData.password,
            () => redirection.redirectTo("/"),
            () => messaging.modal.showMessage("Erro", "Dados de login errados"),
            () => messaging.modal.showMessage("Erro", "Erro de conexão ou do servidor")
        );
    }

    return (
        <div>
            <h1 className="over-title">FB Ninjas</h1>
            <div className="centered-screen" onSubmit={handleSubmit}>
                <main>
                    <form>
                        <h1 className="h3 mb-3 fw-normal text-center">Inicie sessão</h1>

                        <div className="form-floating">
                            <input
                                type="text"
                                className="form-control"
                                id="user"
                                name="user"
                                onChange={handleChange} />
                            <label>Utilizador</label>
                        </div>
                        <div className="form-floating">
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                name="password"
                                onChange={handleChange} />
                            <label>Password</label>
                        </div>
                        <button
                            className="mt-3 w-100 btn btn-lg btn-primary"
                            type="submit">Iniciar</button>
                    </form>
                </main>
            </div>
        </div>
    );
}