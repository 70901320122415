import { CSSProperties, useContext, useState } from 'react';
import { Link } from "react-router-dom";
import { getStorageInfo } from '../../services/api';
import { LOCAL_STORAGE_KEY } from '../../constants';
import { RedirectionContext, Routes } from '../MainRouter';
import { AppVersion } from '../../models/AppVersion';
import { MessagingContext } from '../App';
import { VersionChange } from '../../models/VersionChange';

const closedDropDowns = {
    contacts: false,
    students: false,
    customers: false,
    trainings: false,
    carers: false,
    user: false,
    users: false,
    courses: false,
    accounting: false,
    extras: false,
};

const versionStyles: CSSProperties = {
    fontSize: "9px",
    position: "relative",
    top: "-10px",
    right: "10px",
    cursor: "pointer",
    zIndex: 200
}

export default function NavigationBar() {
    const redirection = useContext(RedirectionContext);
    const messaging = useContext(MessagingContext);
    const [openDropdowns, setOpenDropdowns] = useState(closedDropDowns);

    function closeAllDropdowns() {
        setOpenDropdowns(closedDropDowns);
    }

    function logout() {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
        redirection.redirectTo("/login");
    }

    function showChangelog() {
        const changelog = AppVersion.changes.map((change: VersionChange) =>
            <div>{change.version()} [{change.changeTypeDescription()}] - {change.description}</div>
        );

        messaging.modal.showFull({
            title: "Histórico de Alterações",
            body: changelog,
            closeName: "Fechar",
            closeHandler: messaging.modal.close
        });
    }

    const storageInfo = getStorageInfo();
    const showName = storageInfo && storageInfo.userName;
    const env = process.env.REACT_APP_ENV !== "prod" ?
        `${(process.env.REACT_APP_ENV || "No env found").toUpperCase()}` : undefined;

    return (
        <nav className="navbar navbar-expand-lg navbar-light" style={{ backgroundColor: "#f3f3f3" }}>
            <div className="container-fluid">
                <Link className="navbar-brand" to="/" onClick={closeAllDropdowns}>
                    FBNinjas <strong>{env}</strong>
                </Link>
                <div id="version" style={versionStyles}>
                    <span id="version" onClick={showChangelog} title={`${AppVersion.getLastChange().description}`} >{AppVersion.getVersion()}</span>
                </div>
                <div className="collapse navbar-collapse">
                    <ul className="navbar-nav">
                        <li className="nav-item dropdown">
                            <span
                                className="nav-link dropdown-toggle"
                                role="button"
                                aria-expanded="false"
                                onClick={() => setOpenDropdowns({ ...closedDropDowns, contacts: !openDropdowns.contacts })}>Contactos</span>
                            <ul className={`dropdown-menu ${openDropdowns.contacts ? "show" : ""}`}>
                                <li>
                                    <Link
                                        to={Routes.contacts}
                                        className="dropdown-item"
                                        onClick={closeAllDropdowns}>Lista</Link>
                                </li>
                                <li>
                                    <Link
                                        to={Routes.freeSessions}
                                        className="dropdown-item"
                                        onClick={closeAllDropdowns}>Sessões Gratuitas</Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ul className="navbar-nav">
                        <li className="nav-item dropdown">
                            <span
                                className="nav-link dropdown-toggle"
                                role="button"
                                aria-expanded="false"
                                onClick={() => setOpenDropdowns({ ...closedDropDowns, students: !openDropdowns.students })}>Alunos</span>
                            <ul className={`dropdown-menu ${openDropdowns.students ? "show" : ""}`}>
                                <li>
                                    <Link
                                        to={Routes.students}
                                        className="dropdown-item"
                                        onClick={closeAllDropdowns}>Lista</Link>
                                </li>
                                <li>
                                    <Link
                                        to={Routes.missingPayments}
                                        className="dropdown-item"
                                        onClick={closeAllDropdowns}>Pagamentos em falta</Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ul className="navbar-nav">
                        <li className="nav-item dropdown">
                            <span
                                className="nav-link dropdown-toggle"
                                role="button"
                                aria-expanded="false"
                                onClick={() => setOpenDropdowns({ ...closedDropDowns, customers: !openDropdowns.customers })}>Clientes</span>
                            <ul className={`dropdown-menu ${openDropdowns.customers ? "show" : ""}`}>
                                <li>
                                    <Link
                                        to={Routes.customers}
                                        className="dropdown-item"
                                        onClick={closeAllDropdowns}>Lista</Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ul className="navbar-nav">
                        <li className="nav-item dropdown">
                            <span
                                className="nav-link dropdown-toggle"
                                role="button"
                                aria-expanded="false"
                                onClick={() => setOpenDropdowns({ ...closedDropDowns, trainings: !openDropdowns.trainings })}>Formações</span>
                            <ul className={`dropdown-menu ${openDropdowns.trainings ? "show" : ""}`}>
                                <li>
                                    <Link
                                        to={Routes.trainings}
                                        className="dropdown-item"
                                        onClick={closeAllDropdowns}>Lista</Link>
                                </li>
                                <li>
                                    <Link
                                        to={Routes.expiringCourses}
                                        className="dropdown-item"
                                        onClick={closeAllDropdowns}>Formações a expirar</Link>
                                </li>
                                <li>
                                    <Link
                                        to={Routes.activeDatePassedCourses}
                                        className="dropdown-item"
                                        onClick={closeAllDropdowns}>Formações com validade terminada</Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ul className="navbar-nav">
                        <li className="nav-item dropdown">
                            <span
                                className="nav-link dropdown-toggle"
                                role="button"
                                aria-expanded="false"
                                onClick={() => setOpenDropdowns({ ...closedDropDowns, carers: !openDropdowns.carers })}>Encarregados</span>
                            <ul className={`dropdown-menu ${openDropdowns.carers ? "show" : ""}`}>
                                <li>
                                    <Link
                                        to={Routes.carers}
                                        className="dropdown-item"
                                        onClick={closeAllDropdowns}>Lista</Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ul className="navbar-nav">
                        <li className="nav-item dropdown">
                            <span
                                className="nav-link dropdown-toggle"
                                role="button"
                                aria-expanded="false"
                                onClick={() => setOpenDropdowns({ ...closedDropDowns, users: !openDropdowns.users })}>Utilizadores</span>
                            <ul className={`dropdown-menu ${openDropdowns.users ? "show" : ""}`}>
                                <li>
                                    <Link
                                        to={Routes.users}
                                        className="dropdown-item"
                                        onClick={closeAllDropdowns}>Lista</Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <ul className="navbar-nav">
                        <li className="nav-item dropdown">
                            <span
                                className="nav-link dropdown-toggle"
                                role="button"
                                aria-expanded="false"
                                onClick={() => setOpenDropdowns({ ...closedDropDowns, courses: !openDropdowns.courses })}>Produtos</span>
                            <ul className={`dropdown-menu ${openDropdowns.courses ? "show" : ""}`}>
                                <li>
                                    <Link
                                        to={Routes.courses}
                                        className="dropdown-item"
                                        onClick={closeAllDropdowns}>Cursos</Link>
                                </li>
                                <li>
                                    <Link
                                        to={Routes.packs}
                                        className="dropdown-item"
                                        onClick={closeAllDropdowns}>Pacotes</Link>
                                </li>
                                <li>
                                    <Link
                                        to={Routes.discounts}
                                        className="dropdown-item"
                                        onClick={closeAllDropdowns}>Descontos</Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    {storageInfo && storageInfo.privileges === 2 ? <ul className="navbar-nav">
                        <li className="nav-item dropdown">
                            <span
                                className="nav-link dropdown-toggle"
                                role="button"
                                aria-expanded="false"
                                onClick={() => setOpenDropdowns({ ...closedDropDowns, accounting: !openDropdowns.accounting })}>Contabilidade</span>
                            <ul className={`dropdown-menu ${openDropdowns.accounting ? "show" : ""}`}>
                                <li>
                                    <Link
                                        to={Routes.accounting}
                                        className="dropdown-item"
                                        onClick={closeAllDropdowns}>Inscrições</Link>
                                </li>
                                <li>
                                    <Link
                                        to={Routes.stats}
                                        className="dropdown-item"
                                        onClick={closeAllDropdowns}>Estatisticas</Link>
                                </li>
                            </ul>
                        </li>
                    </ul> : ""}
                    <ul className="navbar-nav">
                        <li className="nav-item dropdown">
                            <span
                                className="nav-link dropdown-toggle"
                                role="button"
                                aria-expanded="false"
                                onClick={() => setOpenDropdowns({ ...closedDropDowns, extras: !openDropdowns.extras })}>Extras</span>
                            <ul className={`dropdown-menu ${openDropdowns.extras ? "show" : ""}`}>
                                <li>
                                    <Link
                                        to={Routes.cities}
                                        className="dropdown-item"
                                        onClick={closeAllDropdowns}>Localidades</Link>
                                </li>
                                <li>
                                    <Link
                                        to={Routes.occupations}
                                        className="dropdown-item"
                                        onClick={closeAllDropdowns}>Ocupações</Link>
                                </li>
                                <li>
                                    <Link
                                        to={Routes.educations}
                                        className="dropdown-item"
                                        onClick={closeAllDropdowns}>Educação</Link>
                                </li>
                            </ul>
                        </li>
                    </ul>

                </div>
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown">
                        <span
                            className="nav-link dropdown-toggle"
                            role="button"
                            aria-expanded="false"
                            onClick={() => setOpenDropdowns({ ...closedDropDowns, user: !openDropdowns.user })}>
                            <i className="fas fa-user-circle"></i>
                        </span>
                        <ul
                            className={`dropdown-menu dropdown-menu-right ${openDropdowns.user ? "show" : ""}`}
                            style={{ right: "0", left: "auto" }}>
                            <li>
                                <span className="dropdown-item">{showName}</span>
                            </li>
                            <li>
                                <span
                                    className="dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={logout}>
                                    <i className="fas fa-power-off p-1"></i> Terminar Sessão
                                </span>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>
    );
}