import { CSSProperties, ReactElement } from "react";
import Loader from "./Loader";

export default function GenericCombo<T>(props: {
    value: string | number | undefined,
    values: T[] | undefined,
    getId: (val: T) => string | number
    getOptionContent: (val: T) => string | number | ReactElement,
    selectionChanged: (val: T | number | string) => void,
    showError?: boolean,
    name?: string,
    errorDescription?: string,
    style?: CSSProperties,
    defaultText?: string
}) {
    function handleChange(event: any) {
        const val = event.target.value;
        const typedVal = isNaN(parseInt(val)) ? val : parseInt(val);
        props.selectionChanged(typedVal);
    }

    return (
        <>
            { props.values ?
                <>
                    <select
                        style={props.style || {}}
                        name={props.name || ""}
                        value={props.value}
                        className="form-select"
                        onChange={handleChange}>

                        <option value={0}>{props.defaultText || "Escolha a opção"}</option>
                        {(props.values || []).map(val =>
                            <option value={props.getId(val)} key={props.getId(val)}>
                                {props.getOptionContent(val)}
                            </option>
                        )}
                    </select>
                    {props.showError &&
                        <div className="invalid-feedback" style={{ display: "block" }}>
                            {props.errorDescription || "Precisa de escolher uma opção válida"}
                        </div>
                    }
                </> :
                <Loader />}
        </>
    );
}