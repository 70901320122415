import { useContext } from "react";
import { MessagingContext } from "../App";
import styles from "./Education.styles.module.css";
import { api } from "../../services/api";
import { Education } from "../../interfaces/Education.interface";
import { InsertType } from "./EducationsList";


type EducationTableProp = {
    values: Education[];
    EducationRemoved: (id: number, type: InsertType) => void;
    EducationEdited: (Education: Education, type: InsertType) => void;
}

export default function EducationsTable(props: EducationTableProp) {
    const { values, EducationRemoved, EducationEdited } = props;
    const messaging = useContext(MessagingContext);

    function handleRemoveEducation(Education: Education) {
        messaging.modal.showFull({
            title: "Remover Gráu Académico",
            body: `Tem a certeza que pretende remover o Gráu Académico: ${Education.title} ?`,
            closeName: "Cancelar",
            closeHandler: messaging.modal.close,
            actionName: "Eliminar",
            actionHandler: () => {
                messaging.modal.close();
                deleteEducation(Education.id);
            },
            actionClassName: "btn-danger",
            showAction: true
        });
    }

    function deleteEducation(id: number) {
        api.deleteEducation(id, (e: any) => {

            if (e.status !== 204) {
                throw new Error(e);
            }

            if (EducationRemoved) {
                EducationRemoved(id, InsertType.education);
            }
            messaging.toast.show("Gráu Académico", "Gráu Académico removida");
        }, (err) => {
            console.log(err);
            messaging.modal.showMessage("Erro", "Erro a remover Gráu Académico");
        });
    }

    return (
        <div className={styles.splitContainer}>
            <h2>Grau Académico</h2>
            <div className={styles.container}>
                {
                    values.length > 0 ?
                        values.map((row: Education) =>
                            <span key={row.id} className={`${styles.tag} badge badge-secondary`}>{row.title}
                                <span className={`${styles.icon} action-icon`}
                                    onClick={() => EducationEdited(row, InsertType.education)}>
                                    <i className="fas fa-pen" title="Editar"></i>
                                </span>
                                <span className={`${styles.icon} action-icon`}
                                    onClick={() => handleRemoveEducation(row)}>
                                    <i className="fas fa-trash" title="Eliminar"></i>
                                </span>
                            </span>
                        )
                        :
                        "Não existem Gráus Académicos para a pesquisa efetuada"
                }
            </div>
        </div >
    );
}