import { CSSProperties, useContext, useEffect, useState } from "react";
import { Education } from "../../../interfaces/Education.interface";
import { EducationArea } from "../../../interfaces/EducationArea.interface";
import Student from "../../../models/Student.model";
import { api } from "../../../services/api";
import { FieldsEdited } from "../../../types/FieldsEdited";
import { MessagingContext } from "../../App";
import Card from "../../shared/Card";
import styles from "../../shared/Card.module.css";
import ConfirmCancelIcons from "../../shared/ConfirmCancelIcons";
import Loader from "../../shared/Loader";
import GenericCombo from "../../shared/GenericCombo";
import { StudentEducationFields } from "../forms/StudentForm";

type StudentEducationCardProps = {
    value: Student;
    style?: CSSProperties;
    fieldsEditedCallback: (params: FieldsEdited<StudentEducationFields>) => void;
    setStudent: (newStudent: Student) => void;
}

export default function StudentEducationCard(props: StudentEducationCardProps) {
    const student = props.value;
    const messaging = useContext(MessagingContext);
    const [editMode, setEditMode] = useState(false);
    const [editing, setEditing] = useState(false);
    const [fieldsValues, setFieldsValues] = useState<StudentEducationFields>({
        educationId: student.educationId,
        educationAreaId: student.educationAreaId
    });
    const [educations, setEducations] = useState<Education[] | undefined>();
    const [educationAreas, setEducationAreas] = useState<EducationArea[] | undefined>();
    const [selectedEducationName, setSelectedEducationName] = useState(student.education);
    const [selectedEducationAreaName, setSelectedEducationAreaName] = useState(student.educationArea);

    useEffect(() => {
        if (editMode && !educations) {
            api.getEducations(educations => setEducations(educations), err => setEducations([]));
        }
        if (editMode && !educationAreas) {
            api.getEducationAreas(educationAreas => setEducationAreas(educationAreas),
                err => setEducationAreas([]));
        }
    }, [editMode, educations, educationAreas]);

    function handleConfirm() {
        setEditing(true);
        props.fieldsEditedCallback({
            newValues: fieldsValues,
            success: () => {
                const newStudent = student.clone();
                newStudent.education = selectedEducationName;
                newStudent.educationArea = selectedEducationAreaName;
                props.setStudent(newStudent);
                setEditing(false);
                setEditMode(false);
            },
            fail: err => {
                setEditing(false);
                messaging.modal.showMessage("Erro", "Erro a editar os dados do aluno: " + err.message);
            }
        });
    }

    function handleEducationChange(education: number | string | Education) {
        const newEducationId = education as number;
        setFieldsValues({ ...fieldsValues, educationId: newEducationId });
        const newEducation = educations?.find(education => education.id === newEducationId);
        if (newEducation) {
            setSelectedEducationName(newEducation.title);
        }
    }

    function handleEducationAreaChange(educationArea: number | string | Education) {
        const newEducationAreaId = educationArea as number;
        setFieldsValues({ ...fieldsValues, educationAreaId: newEducationAreaId });
        const newEducationArea = educationAreas?.find(educationArea => educationArea.id === newEducationAreaId);
        if (newEducationArea) {
            setSelectedEducationAreaName(newEducationArea.title);
        }
    }

    return (
        <Card
            keyId={student.id.toString()}
            style={props.style || {}}
            title={
                <div>Educação
                    {editMode ?
                        (editing ?
                            <Loader
                                outerClassName=""
                                outterStyle={{ display: "inline" }}
                                style={{ marginLeft: "10px" }}
                                size="small" /> :
                            <ConfirmCancelIcons
                                confirmHandler={handleConfirm}
                                cancelHandler={() => setEditMode(false)} />
                        ) :
                        <i
                            className="fas fa-pen action-icon"
                            style={{ margin: "5px 0 0 10px" }}
                            title="Editar"
                            onClick={() => setEditMode(true)}></i>
                    }
                </div>
            }
            includeHeader={true}
            headerStyle={{ fontSize: "1.1rem" }}
            body={
                <table className="table table-striped">
                    <tbody>
                        <tr>
                            <td className={styles.titleField}>Grau Académico</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <GenericCombo
                                        value={fieldsValues.educationId}
                                        values={educations}
                                        getId={education => education.id}
                                        getOptionContent={education => education.title}
                                        selectionChanged={handleEducationChange} /> :
                                    student.education}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Área</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <GenericCombo
                                        value={fieldsValues.educationAreaId}
                                        values={educationAreas}
                                        getId={educationArea => educationArea.id}
                                        getOptionContent={educationArea => educationArea.title}
                                        selectionChanged={handleEducationAreaChange} /> :
                                    student.educationArea}
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
            width={300} />
    );
}