import { useContext, useEffect, useState } from "react";
import Occupation from "../../interfaces/Occupation";
import Modal from "../shared/Modal";
import { MessagingContext } from "../App";
import { verifyErrors } from "../../utils/generic";
import { api } from "../../services/api";


type OccupatinFormProps = {
    closeHandler: () => void;
    occupation: Occupation | undefined;
    occupationAdded?: (occupation: Occupation) => void;
    occupationEdited?: (occupation: Occupation) => void;
    editing: boolean;
}

type OccupationFields = {
    title: string;
}

const emptyFields: OccupationFields = {
    title: "",
};

type OccupationErrors = {
    title: boolean;
}

const occupationErrorsDescriptions: any = {
    title: "Titulo",
}

export default function OccupationForm(props: OccupatinFormProps) {
    const { editing, closeHandler, occupation, occupationAdded, occupationEdited } = props

    const [fieldsValues, setFieldsValues] = useState(emptyFields);
    const messaging = useContext(MessagingContext);
    const [pending, setPending] = useState(false);

    const [errors, setErrors] = useState<OccupationErrors>({
        title: false
    });

    useEffect(() => {
        setErrors({
            title: fieldsValues.title === ""
        });
    }, [fieldsValues])

    useEffect(() => {
        if (occupation) {
            setFieldsValues({
                title: occupation.title,
            });
        }
    }, [occupation])

    function handleSubmit() {

        const errorFound = verifyErrors(errors as OccupationErrors);
        if (errorFound) {
            messaging.toast.show("Erro", `Preencha o campo: ${occupationErrorsDescriptions[errorFound]}`);
            return;
        }

        setPending(true);
        const occupationObj: any = {
            title: fieldsValues.title.trim(),
        }

        setPending(true);
        if (editing) {
            const editOccupation = { ...occupationObj, id: occupation?.id };
            editOccupationApi(editOccupation);
        }
        else {
            addOccupationApi(occupationObj);
        }
    }

    function editOccupationApi(occupationObj: Occupation) {
        api.editOccupation(occupationObj, (e: any) => {
            if (e.status === 500 && e.status === 404) {
                throw new Error(e);
            }
            setPending(false);
            if (occupationEdited) {
                messaging.toast.show("Sucesso", "Ocupação modificada com sucesso");
                occupationEdited({ ...occupation, ...occupationObj });
            }
        }, (err) => {
            setPending(false);
            messaging.toast.show("Erro", "Erro ao editar a ocupação");
        });
    }


    function addOccupationApi(occupationObj: Occupation) {
        api.addOccupation(occupationObj,
            (id: any) => {
                occupationObj.id = parseInt(id);
                if (id.status === 500 && id.status === 404) {
                    throw new Error(id);
                }
                setPending(false);

                if (occupationAdded) {
                    messaging.toast.show("Sucesso", "Ocupação adicionada com sucesso");
                    occupationAdded(occupationObj as Occupation);
                }

            }, (err) => {
                setPending(false);
                messaging.toast.show("Erro", "Erro ao inserir a Ocupação");
            });
    }

    return (
        <>
            <Modal
                title={`${editing ? "Editar" : "Nova"} Ocupação`}
                body={<>
                    <div className="row">
                        <div className="col m-2">
                            <div className="form-group">
                                <label htmlFor="name">Nome</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    value={fieldsValues.title}
                                    onChange={event => setFieldsValues({ ...fieldsValues, title: event.target.value })}
                                    required />
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col text-center">

                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={() => handleSubmit()}
                                style={{ padding: "5px 40px" }}>
                                {pending ?
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"></span>
                                    : <i className="fas fa-plus-square"></i>
                                }
                                <span style={{ margin: "0px 10px" }}>{`${editing ? "Modificar" : "Adicionar"} Ocupação`}</span>
                            </button>
                        </div>
                    </div>
                </>}
                closeName="Fechar"
                closeHandler={closeHandler}
                bodyMaxHeight={500} />
        </>
    );
}