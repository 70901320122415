import { useState, useEffect, useRef, useContext } from 'react';
import { MILLISECONDS_LIST_WAIT } from '../../constants';
import Course from '../../models/Course.model';
import { api } from '../../services/api';
import { dealyForXSeconds } from '../../utils/generic';
import { MessagingContext } from '../App';
import AtiveToggle, { AtiveToggleOptions } from '../AtiveToggle';
import SearchForm from '../shared/SearchForm';
import CourseForm from './CourseForm';
import CoursesTable from './CoursesTable';
import CourseStatesCombo from '../combos/CourseStatesCombo';
import CourseCategoryCombo from '../combos/CourseCategoryCombo';

const OVERALL_VISIBLE_OPTION: Number = 2;
const OVERALL_STATUS_OPTION: Number = 0;
const OVERALL_CATEGORY_OPTION: Number = 0;

export default function CoursesList() {

    const messaging = useContext(MessagingContext);

    const [currentPage, setCurrentPage] = useState(1);
    const [currentSearch, setCurrentSearch] = useState("");
    const [currentActive, setCurrentActive] = useState(1);
    const [currentActiveState, setCurrentActiveState] = useState(0);
    const [currentCourseCategoryState, setCurrentCourseCategoryState] = useState(0);

    const [courses, setCourses] = useState<Course[]>([]);
    const [selectedCourse, setSelectedCourse] = useState<Course | undefined>(undefined);

    const [editing, setEditing] = useState(false);
    const [showInsertModal, setShowInsertModal] = useState(false);

    const searchRef = useRef<HTMLInputElement | null>(null);


    useEffect(() => {

        let sendParams: any = {
            search: currentSearch,
            page: currentPage
        }

        if (currentActive !== OVERALL_VISIBLE_OPTION) {
            sendParams.visible = currentActive;
        }
        if (currentActiveState !== OVERALL_STATUS_OPTION) {
            sendParams.status_type = currentActiveState;
        }
        if (currentCourseCategoryState !== OVERALL_CATEGORY_OPTION) {
            sendParams.course_category = currentCourseCategoryState;
        }

        api.getCoursesWithFilters(sendParams,
            newCourses => {
                setCourses(newCourses.map((course: any) => new Course(course)));
            },
            () => setCourses([])
        );

        searchRef.current?.focus();
    }, [currentPage, currentSearch, currentActive, currentActiveState, currentCourseCategoryState]);


    function addCourseToList(newCourse: Course) {
        if (courses) {
            const newCoursesList = [...courses, newCourse];
            setCourses(newCoursesList.sort((a: Course, b: Course) => a.title.localeCompare(b.title)));
            setShowInsertModal(false);
        }
    }

    function removeCourseInList(id: number) {
        if (courses) {
            setCourses(courses.filter((c: Course) => c.id !== id));
        }
    }

    function deleteCourseApi(id: number) {
        api.deleteCourse(id,
            (response) => {
                if (response.status === 204) {
                    removeCourseInList(id);
                    setSelectedCourse(undefined);
                }
            },
            err => {
                messaging.toast.show("Erro", "Erro a remover o curso! " + err);
            })
    }
    function replaceCourseInList(updatedCourse: Course) {
        if (courses) {
            const newCourses = courses.map((c: Course) => c.id === updatedCourse.id ? updatedCourse : c);
            setCourses(newCourses);
            setShowInsertModal(false);
            setEditing(false);
            setSelectedCourse(undefined);
        }
    }

    const options: AtiveToggleOptions[] = [
        { id: 2, name: "Tudo" },
        { id: 1, name: "Ativo" },
        { id: 0, name: "Inativo" },
    ];

    return (
        <>
            {
                showInsertModal &&
                <CourseForm
                    closeHandler={() => setShowInsertModal(false)}
                    course={selectedCourse}
                    courseAdded={addCourseToList}
                    courseEdited={replaceCourseInList}
                    editing={editing}
                />
            }

            <div className="list-container container" style={{ position: "relative" }}>
                <button
                    className="btn btn-success new-list-button"
                    onClick={() => {
                        setEditing(false);
                        setSelectedCourse(undefined);
                        setShowInsertModal(true);
                    }
                    }>Novo Curso</button>
                <h2 className="text-center m-4">Cursos</h2>
                <SearchForm
                    label="Pesquisar Cursos"
                    onChangeHandler={event => {
                        dealyForXSeconds(MILLISECONDS_LIST_WAIT, () => {
                            setCurrentSearch(event.target.value);
                            setCurrentPage(1);
                        });
                    }}
                    style={{ marginBottom: "20px" }}
                    searchRef={searchRef}
                    searchHint="Pode pesquisar por nome" />

                <div className="d-flex filterContainer">
                    <div>
                        <span className="lateralMargin10">Visivel:</span>
                        <AtiveToggle activeChanged={setCurrentActive} options={options}
                            activeOption={currentActive} />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <span className="lateralMargin10">Estado:</span>
                        <CourseStatesCombo
                            includeOverall={true}
                            value={currentActiveState}
                            selectionChanged={setCurrentActiveState}
                        />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <span className="lateralMargin10">Categoria:</span>
                        <CourseCategoryCombo
                            includeOverall={true}
                            value={currentCourseCategoryState}
                            selectionChanged={setCurrentCourseCategoryState}
                        />
                    </div>
                </div>
                <CoursesTable
                    values={courses}
                    handleEditCourse={(c: Course) => {
                        setSelectedCourse(c);
                        setShowInsertModal(true);
                        setEditing(true);
                    }}
                    handleDeleteCourse={deleteCourseApi}
                />

            </div>
        </>
    );
}