import { CSSProperties } from "react";

type SearchFormProps = {
    label: string;
    onChangeHandler: (event: any) => void;
    searchRef?: React.MutableRefObject<HTMLInputElement | null>;
    searchHint?: string;
    style?: CSSProperties
};

export default function SearchForm(props: SearchFormProps) {
    return (
        <form style={props.style} onSubmit={event => event.preventDefault()}>
            <div className="form-group">
                <label>{props.label}</label>
                <input
                    type="text"
                    className="form-control"
                    id="search"
                    onChange={props.onChangeHandler}
                    ref={props.searchRef} />
                <small className="form-text text-muted">
                    {props.searchHint || "Pode pesquisar por qualquer campo"}
                </small>
            </div>
        </form>
    );
}