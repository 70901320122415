import Rating from "react-rating";
interface RatingProps {
    size: number;
    displayRating: number;
    handleRatingChanged?: (value: number) => void;
    readonly: boolean;
    margin?: boolean;
}

export default function SurveyRating(props: RatingProps) {

    return (
        <div className={`${props.margin ? 'rating' : ''}`} style={{ fontSize: "1.3em" }}>
            <Rating
                stop={props.size}
                emptySymbol={'far fa-star'}
                fullSymbol={`fa fa-star full-star`}
                placeholderSymbol={`fa fa-star full-star`}
                onChange={props.handleRatingChanged}
                readonly={props.readonly}
                placeholderRating={props.displayRating}
            />
        </div>
    );
}