import React, { useState, useRef, useEffect, useContext } from "react";
import { MILLISECONDS_LIST_WAIT, offices, toggleClientTypes, toggleClientTypesHash } from "../../constants";
import { api } from "../../services/api";
import Customer from "../../models/Customer.model";
import OfficeToggle from "../OfficeToggle";
import PaginationLinks from "../shared/PaginationLinks";
import CustomersTable from "./CustomersTable";
import GenericToggle from "../GenericToggle";
import Loader from "../shared/Loader";
import { RedirectionContext, Routes } from "../MainRouter";
import SearchForm from "../shared/SearchForm";
import { dealyForXSeconds } from "../../utils/generic";

const customerTypes = toggleClientTypes;

export default function CustomersList() {
    const redirection = useContext(RedirectionContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const [currentSearch, setCurrentSearch] = useState("");
    const [customers, setCustomers] = useState<Customer[] | undefined>();
    const [currentOffice, setCurrentOffice] = useState(offices[0]);
    const [currentCustomerType, setCurrentCustomerType] = useState<number>(0);

    const searchRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        const searchParams: any = {
            office: currentOffice.value,
            page: currentPage,
            customer_type_id: toggleClientTypesHash[currentCustomerType]?.search
        };

        if (currentSearch.length > 0) {
            searchParams.search = currentSearch;
        }

        api.getCustomers(searchParams,
            newCustomers => {
                setHasMorePages(newCustomers.morePages);
                setCustomers(newCustomers.data.map((customer: any) => new Customer(customer)));
            },
            () => setCustomers([]));

        searchRef.current?.focus();
    }, [currentPage, currentSearch, currentOffice, currentCustomerType]);

    const paginationLinksJSX = <PaginationLinks
        page={currentPage}
        hasMorePages={hasMorePages}
        pageChanged={newPage => setCurrentPage(newPage)}
        classes="justify-content-end" />;

    return (
        <div className="container" style={{ position: "relative" }}>
            <button
                className="btn btn-success new-list-button"
                onClick={() => redirection.redirectTo(Routes.newCustomer)}>Novo Cliente</button>
            <h2 className="text-center m-4">Clientes</h2>

            <SearchForm
                label="Pesquisar clientes"
                onChangeHandler={event => {
                    dealyForXSeconds(MILLISECONDS_LIST_WAIT, () => {
                        setCurrentSearch(event.target.value);
                        setCurrentPage(1);
                    });
                }}
                style={{ marginBottom: "20px" }}
                searchRef={searchRef} />

            <div style={{ display: "flex" }}>
                <div style={{ margin: "30px 0" }}>
                    Filial: <OfficeToggle officeChanged={newOffice => setCurrentOffice(newOffice)} />
                </div>
                <div style={{ margin: "30px 40px" }}>
                    Tipo de Cliente: <GenericToggle
                        values={customerTypes}
                        toggleChanged={newCustomerType => setCurrentCustomerType(newCustomerType.id)}
                        getId={customerType => customerType.id.toString()}
                        getOptionContent={customerType => customerType.name} />
                </div>
            </div>

            {customers ?
                <>
                    {paginationLinksJSX}
                    <CustomersTable values={customers} />
                    {paginationLinksJSX}
                </>
                : <Loader />
            }
        </div>
    );
}
