import CustomRadioButton from './CustomRadioButton';

type YesOrNoButtonsProps = {
    checked: boolean,
    inputId: string,
    inputName: string,
    disable: boolean,
    handleOnChange: (value: boolean) => void,
}

export default function YesOrNoButtons(props: YesOrNoButtonsProps) {

    const { disable, checked, inputName, handleOnChange } = props;

    return (
        <div className="checkboxs-container">
            <CustomRadioButton
                displayText="Sim"
                disable={!disable}
                checked={checked}
                handleOnChange={(v: boolean) => handleOnChange(true)}
                inputId={inputName}
                inputName={inputName}
            />
            <CustomRadioButton
                displayText="Não"
                disable={!disable}
                checked={!checked}
                handleOnChange={(v: boolean) => handleOnChange(false)}
                inputId={`no${inputName}`}
                inputName={inputName}
            />
        </div>
    );
}