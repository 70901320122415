import { CSSProperties } from "react";
import { Education } from "../../../interfaces/Education.interface";
import { EducationArea } from "../../../interfaces/EducationArea.interface";
import GenericCombo from "../../shared/GenericCombo";

import { StudentFields } from "./StudentForm";

type StudentEducationFormProps = {
    fieldsValues: StudentFields;
    setFieldsValues: (val: StudentFields) => void;
    educations: Education[],
    educationAreas: EducationArea[],
    style?: CSSProperties
};

export default function StudentEducationForm(props: StudentEducationFormProps) {
    const { fieldsValues, setFieldsValues, educations, educationAreas, style } = props;

    return (
        <div className="card p-3" style={style}>
            <h4 className="m-2">Educação</h4>
            <div className="row">
                <div className="col m-2">
                    <div className="form-group">
                        <label>Grau Académico</label>
                        <GenericCombo
                            value={fieldsValues.educationId}
                            values={educations}
                            getId={education => education.id}
                            getOptionContent={education => education.title}
                            selectionChanged={educationId =>
                                setFieldsValues({ ...fieldsValues, educationId: educationId as number })} />
                    </div>
                </div>
                <div className="col m-2">
                    <div className="form-group">
                        <label>Área</label>
                        <GenericCombo
                            value={fieldsValues.educationAreaId}
                            values={educationAreas}
                            getId={educationArea => educationArea.id}
                            getOptionContent={educationArea => educationArea.title}
                            selectionChanged={educationAreaId =>
                                setFieldsValues({ ...fieldsValues, educationAreaId: educationAreaId as number })} />
                    </div>
                </div>
            </div>
        </div>
    );
}