import { trainingStatusTypes, trainingTypes } from '../../constants';
import CourseTag from '../tags/CourseTag';
import '../../styles/ContactsTable.css';
import { Link } from "react-router-dom";
import { toDatePT } from "../../utils/dates";
import OfficeTag from '../tags/OfficeTag';
import Training from '../../models/Training.model';

type TrainingTableProps = {
    values: Training[]
}

export default function TrainingTable(props: TrainingTableProps) {
    const { values } = props;

    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Horário</th>
                    <th scope="col">Expira</th>
                    <th scope="col">Curso</th>
                    <th scope="col">Observações</th>
                    <th scope="col">Questionário</th>
                    <th scope="col">Certificado</th>
                    <th scope="col">Estado</th>
                </tr>
            </thead>
            <tbody>

                {values.length > 0 ?
                    values.map((row: Training) =>

                        <tr>
                            <td>
                                <div>
                                    <i
                                        className={(trainingTypes[row.trainingTypeId - 1]).icon}
                                        style={{ marginRight: "5px", color: "#607d8b" }}
                                        title={(trainingTypes[row.trainingTypeId - 1])?.name}></i>
                                    {row.studentId ?
                                        <Link to={`/alunos/${row.studentId}/formacoes`}>{row.studentName}</Link>
                                        :
                                        row.studentName
                                    }
                                </div>
                                <div className="contact-created-date">{toDatePT(new Date(row.createdDate))}</div>
                                <OfficeTag office={row.officeId} />

                            </td>
                            <td>

                                {row.startDate ?
                                    <div>
                                        <b>Começou</b>: <span>{toDatePT(row.startDate)}</span>
                                    </div>
                                    :
                                    undefined
                                }
                                {row.endDate ?
                                    <div>
                                        <b>Terminou</b>: <span>{toDatePT(row.endDate)}</span>
                                    </div>
                                    :
                                    undefined
                                }
                            </td>
                            <td>
                                <span>{toDatePT(row.extensionExpirationDate)} {row.monthExtension > 0 ? `(${row.monthExtension} Meses de extensão)` : ""}</span>
                            </td>
                            <td>
                                <CourseTag
                                    style={{ marginBottom: "10px" }}
                                    name={row.courseName}
                                    key={row.courseId} />
                            </td>
                            <td>
                                {row.trainingStatusObservations}
                            </td>
                            <td>
                                {
                                    row.surveyId ?
                                        <Link
                                            className="action-icon"
                                            style={{ marginRight: "5px" }}
                                            to={`/alunos/${row.studentId}/questionarios/`}>
                                            <i className="fas fa-poll" title="Mostrar Questionário"></i>

                                        </Link>
                                        :
                                        <i style={{ cursor: 'not-allowed' }} className="action-icon fas fa-ban" title="Sem Questionário"></i>
                                }
                            </td>
                            <td>
                                {
                                    row.certificateId ?
                                        <Link
                                            className="action-icon"
                                            style={{ marginRight: "5px" }}
                                            to={`/alunos/${row.studentId}/questionarios/`}>
                                            <i className="fas fa-certificate" title="Mostrar Certificado"></i>

                                        </Link>
                                        :
                                        <i style={{ cursor: 'not-allowed' }} className="action-icon fas fa-ban" title="Sem Certificado"></i>
                                }
                            </td>
                            <td>
                                <i
                                    className={(trainingStatusTypes[row.trainingTypeId - 1]).icon}
                                    style={{ marginRight: "5px", color: "#607d8b" }}
                                    title={(trainingStatusTypes[row.trainingTypeId - 1])?.name}></i>
                            </td>
                        </tr>
                    ) : ""}

            </tbody>
        </table>
    );
}