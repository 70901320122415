import '../../styles/ContactsTable.css';
import { toDatePT } from "../../utils/dates";
import OfficeTag from '../tags/OfficeTag';
import Purchase from '../../models/Purchase.model';
import { Link } from 'react-router-dom';
import { formatCurrency } from '../../utils/generic';
import CourseTag from '../tags/CourseTag';
import Enrollment from '../../models/Enrollment';
import { noTrainingType, trainingTypesHash } from '../../constants';


export default function PurchasesTable(props: { values: Purchase[] }) {
    const { values } = props;

    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Aluno</th>
                    <th scope="col">Data da inscrição</th>
                    <th scope="col">Username</th>
                    <th scope="col">Valor</th>
                    <th scope="col">Taxa</th>
                    <th style={{ width: "150px" }} scope="col">Formação</th>
                    <th scope="col">Cursos</th>
                    <th scope="col">Observações</th>
                </tr>
            </thead>
            <tbody>

                {values.length > 0 ?
                    values.map((row: Purchase) =>
                        <tr>
                            <td>
                                <div>
                                    <Link to={`/alunos/${row.studentId}/inscricoes`}>{row.studentName}</Link>

                                </div>
                                {row.officeId ? <OfficeTag office={row.officeId} /> : undefined}
                            </td>
                            <td>
                                {toDatePT(new Date(row.createdDate))}
                            </td>
                            <td>
                                {row.studentUsername}
                            </td>
                            <td>
                                {formatCurrency(row.value)}
                            </td>
                            <td>
                                {row.tax}
                            </td>
                            <td>
                                {row.trainingTypeId &&
                                    <>
                                        <i className={`fa ${(trainingTypesHash[row.trainingTypeId] || noTrainingType).icon}`}></i>
                                        <span style={{ marginLeft: "10px" }}>
                                            {(trainingTypesHash[row.trainingTypeId] || noTrainingType).name}
                                        </span>
                                    </>
                                }
                            </td>
                            <td>
                                <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}>

                                    {row.enrollments && row.enrollments.map((enrollment: Enrollment) => <CourseTag name={enrollment.course} title={enrollment.course} />)}
                                </div>

                            </td>
                            <td style={{ maxWidth: "250px" }}>
                                {row.observations}
                            </td>
                        </tr>
                    )
                    :
                    ""
                }
            </tbody>
        </table>
    );
}