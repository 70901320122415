import { useState } from "react";
import CourseCombo from './CourseCombo';
import CourseTag from '../tags/CourseTag';
import Course from "../../models/Course.model";

interface MultipleCourseComboProps {
    value: Course[];
    courseAdded: (course: Course) => void;
    courseRemoved: (course: Course) => void;
    defaultText?: string;
    availableCourses?: Course[] | undefined;
    autoFetchCourses?: boolean;
    width?: string;
    style?: object;

}

export default function MultipleCourseCombo(props: MultipleCourseComboProps) {
    const [selectedCourse, setSelectedCourse] = useState<Course | undefined>(undefined);

    function courseSelected(course: Course | undefined) {
        setSelectedCourse(course);

        if (course) {
            props.courseAdded(course);
            setSelectedCourse(undefined);
        }
    }

    function removeCourse(course: Course) {
        props.courseRemoved(course);
        setSelectedCourse(undefined);
    }

    const styles = props.style || { marginTop: "10px", width: `${props.width ?? "100%"} `, flexWrap: "wrap" };

    return (
        <>
            <div className="flex">
                <div>
                    <CourseCombo
                        value={selectedCourse}
                        availableCourses={props.availableCourses}
                        autoFetchCourses={props.autoFetchCourses}
                        selectionChanged={newCourse => courseSelected(newCourse)}
                        defaultText={props.defaultText} />
                </div>
            </div>
            <div className="flex" style={styles}>
                {(props.value || []).map((course: Course, pos: number) =>
                    <div style={{ marginRight: "15px" }} key={`${course.id}-${pos}`}>
                        <CourseTag title={course.student && course.hasStudent() ? course.getStudentName() : course.title} name={course.title} />
                        <i
                            className="action-icon fas fa-trash"
                            title={`${course.student && course.hasStudent() ? `Eliminar - ${course.getStudentName()}` : "Eliminar"}`}
                            onClick={() => removeCourse(course)}></i>
                    </div>
                )}
            </div>
        </>
    );
}