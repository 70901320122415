import React, { useState, useRef, useEffect } from 'react';
import { offices } from '../../constants';
import OfficeToggle from '../OfficeToggle';
import PaginationLinks from '../shared/PaginationLinks';
import CarerTable from './CarerTable';
import Carer from '../../models/Carer.model';
import SearchForm from '../shared/SearchForm';
import Loader from '../shared/Loader';
import '../student/student.modal.styles.css';

import { api } from '../../services/api';
import Modal from '../shared/Modal';

interface StudentModalProps {
    closeHandler: () => void;
    selectHandler: (any: any) => void;
    title: string;
}

export default function CarerModal(props: StudentModalProps) {
    const [currentOffice, setCurrentOffice] = useState(offices[0]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const [currentSearch, setCurrentSearch] = useState("");
    const [carers, setCarers] = useState<Carer[] | undefined>();

    const searchRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        const searchParams: any = {
            office: currentOffice.value,
            page: currentPage,
            pageSize: 5
        };
        if (currentSearch.trim() !== "") {
            searchParams.search = currentSearch;
        }
        api.getCarers(searchParams,
            newCarers => {
                setHasMorePages(newCarers.morePages);
                setCarers(newCarers.data.map((carer: any) => new Carer(carer)));
            },
            () => setCarers([]));

        searchRef.current?.focus();
    }, [currentPage, currentSearch, currentOffice]);

    const paginationLinksJSX = <PaginationLinks
        page={currentPage}
        hasMorePages={hasMorePages}
        pageChanged={newPage => setCurrentPage(newPage)}
        classes="justify-content-end" />;

    const body =
        <div>
            <div style={{ backgroundColor: "#efefef", padding: "20px 0" }}>
                <div className="container" style={{ position: "relative" }}>
                    <SearchForm
                        label="Pesquisar Encarregados de Educação"
                        onChangeHandler={event => {
                            setCurrentSearch(event.target.value);
                            setCurrentPage(1);
                        }}
                        style={{ marginBottom: "20px" }}
                        searchRef={searchRef} />


                    <div style={{ margin: "30px 0" }}>
                        Filial: <OfficeToggle officeChanged={newOffice => setCurrentOffice(newOffice)} />
                    </div>

                    {paginationLinksJSX}
                    {
                        <>
                            {
                                (carers ?
                                    <CarerTable values={carers} selectHandler={props.selectHandler} />
                                    : <Loader />)

                            }
                        </>
                    }
                    {paginationLinksJSX}
                </div>
            </div>
        </div>;

    return (
        <>
            <Modal
                title={props.title}
                body={body}
                closeName="Fechar"
                closeHandler={props.closeHandler}
                dialogClasses={"big"}
                style={{}}
                bodyMaxHeight={500} />
        </>
    );
}