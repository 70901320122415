import { useState, useEffect, useContext } from 'react';
import styles from '../../styles/ExtensionChanger.module.css';
import Course from '../../models/Course.model';
import Discount from '../../models/Discount.model';
import Pack from '../../models/Pack.model';
import { api } from '../../services/api';
import { toInputDate } from '../../utils/dates';
import { verifyErrors } from '../../utils/generic';
import { MessagingContext } from '../App';
import MultipleCourseCombo from '../combos/MultipleCourseCombo';
import MultiplePackCombo from '../combos/MultiplePackCombo';
import Modal from '../shared/Modal';

type DiscountsFormModalProps = {
    closeHandler: () => void,
    addDiscount: (newDiscount: Discount) => void,
    editDiscount: (modifiedDiscount: Discount) => void,
    discount?: Discount
}

type DiscountFieldValues = {
    id?: number,
    title: string,
    percentage: number,
    value: number,
    start_date: string,
    end_date: string,
    quality?: number,
    courses: Course[],
    packs: Pack[]
}

type DiscountErrors = {
    title: boolean;
    startDate: boolean;
    coursesOrPacks: boolean;
}

const discountErrorsDescriptions: any = {
    title: "Titulo",
    startDate: "Data de Começo",
    coursesOrPacks: "Cursos ou Packs",
}

const DISCOUNT_VALUE_METHOD = {
    PERCENTAGE: 0,
    PRICE: 1
}

export default function DiscountsFormModal(props: DiscountsFormModalProps) {

    const { discount } = props;

    const messaging = useContext(MessagingContext);

    const [courses, setCourses] = useState<Course[]>([]);
    const [selectedCourses, setSelectedCourses] = useState<Course[]>([]);
    const [packs, setPacks] = useState<Pack[]>([]);
    const [selectedPacks, setSelectedPacks] = useState<Pack[]>([]);
    const [pending, setPending] = useState<Boolean>(false);
    const editing = discount !== undefined;
    const [visibleSection, setVisibleSection] = useState(DISCOUNT_VALUE_METHOD.PERCENTAGE);


    const [errors, setErrors] = useState<DiscountErrors>({
        title: false,
        startDate: false,
        coursesOrPacks: false
    });

    const [fieldsValues, setFieldsValues] = useState<DiscountFieldValues>({
        title: "",
        percentage: 0,
        value: 0,
        start_date: toInputDate(new Date()),
        end_date: "",
        quality: 0,
        courses: [],
        packs: []
    });

    useEffect(() => {
        if (discount && courses && courses.length > 0 && packs.length > 0) {
            const discountCourses: any = discount?.courses?.map((c: any) => courses.find((course: Course) => c.id === course.id));
            const discountPacks: any = discount?.packs?.map((p: any) => packs.find((pack: Pack) => p.id === pack.id));

            setFieldsValues({
                title: discount.title,
                percentage: discount.percentage * 100,
                value: discount.value,
                start_date: discount.startDate ? toInputDate(discount.startDate) : "",
                end_date: discount.endDate ? toInputDate(discount.endDate) : "",
                courses: discountCourses ? discountCourses : [],
                packs: discountPacks ? discountPacks : [],
            })
            setSelectedCourses(discountCourses ? discountCourses : []);
            setSelectedPacks(discountPacks ? discountPacks : []);
        }
    }, [discount, courses, packs])

    useEffect(() => {
        setErrors({
            title: fieldsValues.title === "",
            startDate: fieldsValues.start_date === "",
            coursesOrPacks: selectedCourses.length === 0 && selectedPacks.length === 0
        });
    }, [fieldsValues, selectedCourses.length, selectedPacks.length]);

    useEffect(() => {
        if (courses.length === 0) {
            api.getCourses(
                (data: any) => {
                    setCourses(data.map((c: any) => new Course(c)))
                },
                err => messaging.modal.showMessage("Erro", "Erro a obter os cursos: " + err));
        }

        if (packs.length === 0) {
            api.getPacks(
                (data: any) => setPacks(data.map((c: any) => new Pack(c))),
                err => messaging.modal.showMessage("Erro", "Erro a obter os packs: " + err));
        }
    }, [messaging.modal, courses.length, packs.length, selectedCourses.length, selectedPacks.length]);

    function addCourse(course: Course) {
        if (!selectedCourses.find(c => c.id === course.id)) {
            const newCourses = [...selectedCourses, course];
            setSelectedCourses(newCourses);
            setFieldsValues({ ...fieldsValues, courses: newCourses });
        }
    }
    function addPack(pack: Pack) {
        if (!selectedPacks.find(p => p.id === pack.id)) {
            const newPacks = [...selectedPacks, pack];
            setSelectedPacks(newPacks);
            setFieldsValues({ ...fieldsValues, packs: newPacks });
        }
    }
    function removeCourse(course: Course) {
        setSelectedCourses(selectedCourses.filter(c => c.id !== course.id));
    }
    function removePack(pack: Pack) {
        setSelectedPacks(selectedPacks.filter(p => p.id !== pack.id));
    }

    function getDifferentArrayObjectsByIds(array1: any[], array2: any[]) {
        const newCourses = [];

        for (const course of array1) {
            const foundObj = array2.find((c: any) => c.id === course.id);
            if (!foundObj) {
                newCourses.push(course);
            }
        }

        return newCourses;
    }

    function checkFormErrors() {
        const errorFound = verifyErrors(errors as DiscountErrors);
        if (errorFound) {
            messaging.toast.show("Erro", `Preencha o campo: ${discountErrorsDescriptions[errorFound]}`);
            return false;
        }

        if (fieldsValues.percentage === 0 && fieldsValues.value === 0) {
            messaging.toast.show("Erro", `Tem de atribuir obrigatoriamente uma Percentagem ou Valor Final para o desconto.`);
            return false;
        }

        return true;
    }

    function createSubmitDataObject() {
        const bodyParams: any = {
            title: fieldsValues.title,
            percentage: fieldsValues.percentage / 100,
            value: fieldsValues.value,
            start_date: fieldsValues.start_date,
        }

        if (fieldsValues.end_date !== "") {
            bodyParams.end_date = fieldsValues.end_date;
        }

        if (editing && discount) {
            bodyParams.id = discount.id;

            const newCourses = getDifferentArrayObjectsByIds(selectedCourses, discount.courses);
            const newPacks = getDifferentArrayObjectsByIds(selectedPacks, discount.packs);

            if (newCourses.length > 0) {
                bodyParams.courses = newCourses.map((c: Course) => c.id).join(", ");
            }
            if (newPacks.length > 0) {
                bodyParams.packs = newPacks.map((p: Pack) => p.id).join(", ");
            }
        } else {

            if (selectedPacks.length > 0) {
                bodyParams.packs = selectedPacks.map((pack: Pack) => pack.id).join(", ");
            }
            if (selectedCourses.length > 0) {
                bodyParams.courses = selectedCourses.map((course: Course) => course.id).join(", ");
            }

        }

        return bodyParams;
    }

    function handleSubmit() {
        setPending(true);

        if (!checkFormErrors()) {
            setPending(false);
            return;
        }

        const bodyParams: DiscountFieldValues = createSubmitDataObject();


        if (editing && discount) {
            api.editDiscount(bodyParams, (data: any) => {

                const updatedDiscount: Discount = discount.clone();

                updatedDiscount.title = fieldsValues.title;
                updatedDiscount.percentage = fieldsValues.percentage / 100;
                updatedDiscount.value = fieldsValues.value;
                updatedDiscount.startDate = new Date(fieldsValues.start_date);
                updatedDiscount.endDate = fieldsValues.end_date ? new Date(fieldsValues.end_date) : null;
                updatedDiscount.courses = selectedCourses.map((c: Course) => { return { id: c.id, title: c.title } });
                updatedDiscount.packs = selectedPacks.map((p: Pack) => { return { id: p.id, title: p.title } });

                props.editDiscount(updatedDiscount);
                messaging.toast.show("Novo", `Desconto ${discount.title} editado`);
                setPending(false);
                props.closeHandler();
            },
                err => err)
        } else {
            api.addDiscount(bodyParams,
                (data: any) => {
                    messaging.toast.show("Novo", "Novo Desconto adicionado à lista");

                    bodyParams.courses = selectedCourses;
                    bodyParams.packs = selectedPacks;
                    bodyParams.id = parseInt(data);

                    props.addDiscount(new Discount(bodyParams));
                    setPending(false);
                    props.closeHandler();
                },
                err => err)
        }
    }

    const extensionJSX: JSX.Element = visibleSection === DISCOUNT_VALUE_METHOD.PERCENTAGE ?
        <>
            <input
                type="number"
                min="0"
                max="100"
                id="discountPercentage"
                className="form-control"
                value={fieldsValues.percentage}
                onChange={event => setFieldsValues({ ...fieldsValues, percentage: parseFloat(event.target.value) })}

            />
            <label className="form-label" htmlFor="extensionNumber">Percentagem</label>
        </>
        :
        <>
            <input
                type="number"
                id="discountNumber"
                className="form-control"
                value={fieldsValues.value}
                onChange={event => setFieldsValues({ ...fieldsValues, value: parseFloat(event.target.value) })}

            />
            <label className="form-label" htmlFor="extensionNumber">Preço Final</label>
        </>;

    const body =
        <div>
            <div style={{ maxHeight: "500px" }}>
                <div className="row">
                    <div className="col">
                        <label htmlFor="discountTitle">Titulo do Desconto</label>
                        <input type="text"
                            className="form-control"
                            id="discountTitle"
                            value={fieldsValues.title}
                            onChange={event => setFieldsValues({ ...fieldsValues, title: event.target.value })}
                            aria-describedby="discountTitleHelp" placeholder="Insira o titulo do Desconto" />
                        <small id="discountTitleHelp" className="form-text text-muted">O nome insirado será utilizado para identificar o desconto.</small>
                    </div>
                </div>
                <div className={`btn-group ${styles.extensionGroup}`} style={{ marginTop: "15px" }} role="group" aria-label="Basic radio toggle button group">
                    <input
                        onClick={() => setVisibleSection(DISCOUNT_VALUE_METHOD.PERCENTAGE)}
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        checked={visibleSection === DISCOUNT_VALUE_METHOD.PERCENTAGE}
                        id="training_extension_month" />
                    <label className="btn btn-outline-primary"
                        htmlFor="training_extension_month">Por Percentagem</label>

                    <input
                        onClick={() => setVisibleSection(DISCOUNT_VALUE_METHOD.PRICE)}
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        checked={visibleSection === DISCOUNT_VALUE_METHOD.PRICE}
                        id="training_extension_date" />
                    <label className="btn btn-outline-warning"
                        htmlFor="training_extension_date">Por Valor</label>
                </div>
                <div className="row" style={{ marginTop: "15px" }}>
                    <div className="col m-2">
                        {extensionJSX}
                    </div>
                </div>
                <div className="row" style={{ marginTop: "15px" }}>
                    <div className="col">

                        <label htmlFor="discountStarDate">Data de Começo</label>
                        <input type="date"
                            className="form-control"
                            id="discountStarDate"
                            value={fieldsValues.start_date}
                            onChange={event => setFieldsValues({ ...fieldsValues, start_date: event.target.value })} />
                    </div>
                    <div className="col">

                        <label htmlFor="discountEndDate">Data de Fim</label>
                        <input type="date"
                            className="form-control"
                            id="discountEndDate"
                            value={fieldsValues.end_date}
                            onChange={event => setFieldsValues({ ...fieldsValues, end_date: event.target.value })} />
                        <small id="discountEndDateHelp" className="form-text text-muted">Opcional.</small>
                    </div>
                </div>

                <div className="row" style={{ marginTop: "15px" }}>
                    <div className="form-group col-md-6">
                        <div>Escolher cursos:</div>
                        <MultipleCourseCombo
                            value={selectedCourses}
                            availableCourses={courses}
                            autoFetchCourses={false}
                            courseAdded={addCourse}
                            courseRemoved={removeCourse}
                            defaultText="Adicione um curso à inscrição" />
                    </div>
                    <div className="form-group col-md-6">
                        <div>Escolher packs:</div>
                        <MultiplePackCombo
                            value={selectedPacks}
                            availablePacks={packs}
                            autoFetchPacks={false}
                            packAdded={addPack}
                            packRemoved={removePack}
                            defaultText="Adicione um pack à inscrição" />
                    </div>
                </div>
                <div className="d-flex" style={{ justifyContent: 'center', marginTop: "20px", paddingBottom: "20px" }}>
                    <div className="col text-center">
                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => handleSubmit()}
                            style={{ padding: "5px 40px" }}>
                            {pending ?
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"></span>
                                :
                                <i className="fas fa-plus-square"></i>
                            }
                            <span style={{ margin: "0px 10px" }}>{`${editing ? "Modificar" : "Adicionar"} Desconto`}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div >;

    return (
        <Modal
            title={`Novo Desconto de curso(s)`}
            body={body}
            closeName="Fechar"
            closeHandler={props.closeHandler}
            style={{}}
            bodyMaxHeight={500} />
    );
}