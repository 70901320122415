import { ModalProps } from "../components/shared/Modal"
import { ToastProps } from "../components/shared/Toast"

export default interface Messaging {
    toast: {
        show: (title: string, body: string) => void,
        showFull: (toastProps: ToastProps) => void,
        close: () => void
    };
    modal: {
        showMessage: (title: string, body: string) => void,
        showFull: (modalProps: ModalProps) => void,
        close: () => void
    }
}

export const emptyMessaging = {
    toast: {
        show: (title: string, body: string) => { },
        showFull: (toastProps: ToastProps) => { },
        close: () => { }
    },
    modal: {
        showMessage: (title: string, body: string) => { },
        showFull: (modalProps: ModalProps) => { },
        close: () => { }
    }
};