export default function ActionButton(props: { pending: boolean, icon?: string, text: string }) {
    const icon = props.icon && <i className={props.icon}></i>;

    return (
        <button
            type="submit"
            className="btn btn-success"
            disabled={props.pending}
            style={{ padding: "5px 40px" }}>
            { props.pending ?
                <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"></span>
                : icon
            }
            <span style={{ margin: "0px 10px" }}>{props.text}</span>
        </button>
    );
}