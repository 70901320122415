import '../../styles/ContactsTable.css';
import { useContext } from 'react';
import City from '../../interfaces/City.interface';
import { MessagingContext } from '../App';
import { api } from '../../services/api';


type CityTableProp = {
    values: City[];
    cityRemoved: (id: number) => void;
    cityEdited: (city: City) => void;
}

export default function CitiesTable(props: CityTableProp) {
    const { values, cityRemoved } = props;

    const messaging = useContext(MessagingContext);

    function deleteCity(id: number) {
        api.deleteCity(id, (e: any) => {

            if (e.status !== 204) {
                throw new Error(e);
            }

            if (cityRemoved) {
                cityRemoved(id);
            }
            messaging.toast.show("Localidade", "Localidade removida");
        }, () => {
            messaging.modal.showMessage("Erro", "Erro a remover Localidade");
        });
    }

    function handleRemoveCity(city: City) {
        messaging.modal.showFull({
            title: "Remover localidade",
            body: `Tem a certeza que pretende remover a localidade ${city.title} ?`,
            closeName: "Cancelar",
            closeHandler: messaging.modal.close,
            actionName: "Eliminar",
            actionHandler: () => {
                messaging.modal.close();
                deleteCity(city.id);
            },
            actionClassName: "btn-danger",
            showAction: true
        });
    }

    return (
        <>
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th scope="col">Nome</th>
                        <th scope="col">País</th>
                        <th scope="col" style={{ maxWidth: "10px", textAlign: "center" }}>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {values.length > 0 ?
                        values.map((row: City) =>
                            <tr key={row.id}>
                                <td>
                                    <div>
                                        {row.title}
                                    </div>
                                </td>
                                <td>
                                    <div>{row.country}</div>
                                </td>
                                <td style={{ width: "180px" }}>
                                    <div className="d-flex"
                                        style={{
                                            flexDirection: "column",
                                            alignContent: "stretch",
                                            justifyContent: 'space-evenly'
                                        }}>
                                        <button type="button"
                                            style={{ fontSize: "0.8rem", width: "100%", marginTop: "5px" }}
                                            onClick={() => props.cityEdited(row)}
                                            className="btn btn-outline-primary">
                                            Editar
                                        </button>
                                        <button type="button"
                                            style={{ fontSize: "0.8rem", width: "100%", marginTop: "5px" }}
                                            className="btn btn-outline-danger"
                                            onClick={() => handleRemoveCity(row)}>
                                            Remover
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ) :
                        <tr>
                            <td colSpan={6} style={{ textAlign: "center" }}>
                                Não existem localidades para a pesquisa efetuada
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </>
    );
}