import StudentTag from "../interfaces/StudentTagInterface.interface";

export default class Carer {
    public id: number;
    public name: string;
    public fiscalNumber: number | undefined;
    public address: string;
    public postalCode: string;
    public cityId: number | undefined;
    public city: string;
    public phone: string;
    public email: string;
    public naturality: string | undefined;
    public nationality: string | undefined;
    public nationalityId: number | undefined;
    public birthDate: Date | undefined;
    public observations: string;
    public createdDate: Date;
    public officeId: number;
    public office: string;
    public identificationNumber: string;
    public identificationDate: Date;
    public identificationEntity: string;
    public identificationTypeId: number | undefined;
    public identificationType: string;

    public students: StudentTag[];

    constructor(data: any) {
        this.id = data.id;
        this.name = data.name;
        this.address = data.address;
        this.postalCode = data.postal_code;
        this.email = data.email;
        this.phone = data.phone;
        this.cityId = data.city_id;
        this.city = data.city;
        this.office = data.office;
        this.fiscalNumber = data.fiscal_number;
        this.createdDate = new Date(data.created_date);
        this.officeId = data.office_id;
        this.office = data.office;
        this.observations = data.observations;
        this.naturality = data.naturality;
        this.nationality = data.nationality;
        this.nationalityId = data.nationality_id;
        this.birthDate = data.birth_date ? new Date(data.birth_date) : undefined;
        this.students = data.students ? data.students.map((student: any) => ({ id: student.id, name: student.name } as StudentTag)) : [];
        this.identificationDate = new Date(data.identification_date);
        this.identificationNumber = data.identification_number;
        this.identificationEntity = data.identification_entity;
        this.identificationTypeId = data.identification_type_id;
        this.identificationType = data.identification_type;
    }

    clone(): Carer {
        const newCarer = new Carer({});
        newCarer.id = this.id;
        newCarer.name = this.name;
        newCarer.address = this.address;
        newCarer.postalCode = this.postalCode;
        newCarer.email = this.email;
        newCarer.phone = this.phone;
        newCarer.cityId = this.cityId;
        newCarer.city = this.city;
        newCarer.office = this.office;
        newCarer.fiscalNumber = this.fiscalNumber;
        newCarer.createdDate = this.createdDate;
        newCarer.officeId = this.officeId;
        newCarer.office = this.office;
        newCarer.observations = this.observations;
        newCarer.naturality = this.naturality;
        newCarer.nationality = this.nationality;
        newCarer.nationalityId = this.nationalityId;
        newCarer.birthDate = this.birthDate;
        newCarer.students = this.students;
        newCarer.identificationDate = this.identificationDate;
        newCarer.identificationNumber = this.identificationNumber;
        newCarer.identificationEntity = this.identificationEntity;
        newCarer.identificationTypeId = this.identificationTypeId;
        newCarer.identificationType = this.identificationType;
        return newCarer;
    }

}