import CourseTag from '../tags/CourseTag';
import '../../styles/ContactsTable.css';
import Pack from '../../models/Pack.model';
import CheckOrCrossIcon from '../shared/CheckOrCrossIcon';
import { useContext } from 'react';
import { MessagingContext } from '../App';

type PackTableProps = {
    values: Pack[],
    handleEditPack: (c: Pack) => void;
    handleDeletePack: (id: number) => void;
}

export default function PacksTable(props: PackTableProps) {
    const messaging = useContext(MessagingContext);

    function handleRemovePackClick(pack: Pack) {
        messaging.modal.showFull({
            title: "Remover pack",
            body: `Tem a certeza que pretende remover o pack de ${pack.title} ?`,
            closeName: "Cancelar",
            closeHandler: messaging.modal.close,
            actionName: "Eliminar",
            actionHandler: () => {
                messaging.modal.close();
                props.handleDeletePack(pack.id);
            },
            actionClassName: "btn-danger",
            showAction: true
        });
    }

    return (
        <>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Nome</th>
                        <th scope="col">Carga hóraria</th>
                        <th scope="col">Preço</th>
                        <th scope="col">Visivel</th>
                        <th scope="col">Categoria</th>
                        <th scope="col">Estado</th>
                        <th scope="col">Cursos</th>
                        <th scope="col">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {props.values.length > 0 ?
                        props.values.map((row: Pack) =>
                            <tr key={row.getUniqueId()}>
                                <td><b>{row.getName()}</b></td>
                                <td>{row.getTime()}h</td>
                                <td>{row.getPrice()}€</td>
                                <td>
                                    {row.visible !== undefined ? <CheckOrCrossIcon value={row.visible} /> : undefined}
                                </td>
                                <td>
                                    {<CourseTag name={row.category} title={row.category} style={{ backgroundColor: "#e4ad07" }} />}
                                </td>
                                <td>{row.status}</td>
                                <td style={{ width: "220px" }}>{row.getCoursesTagContainer()}</td>
                                <td style={{ width: "190px" }}>

                                    <div className="d-flex"
                                        style={{
                                            flexDirection: "column",
                                            alignContent: "stretch",
                                            justifyContent: 'space-evenly'
                                        }}>
                                        <button type="button"
                                            style={{ fontSize: "0.8rem", width: "100%", marginTop: "5px" }}
                                            onClick={() => props.handleEditPack(row)}
                                            className="btn btn-outline-primary">
                                            Editar
                                        </button>
                                        <button type="button"
                                            style={{ fontSize: "0.8rem", width: "100%", marginTop: "5px" }}
                                            className="btn btn-outline-danger"
                                            onClick={() => handleRemovePackClick(row)}>
                                            Remover
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ) : undefined}
                </tbody>
            </table>
        </>
    );
}