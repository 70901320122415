import moment from "moment";
import { useState, useEffect, SyntheticEvent, useContext } from "react";
import { useParams } from "react-router";
import { api } from "../../../services/api";
import { toInputDateTime, toInputDate } from "../../../utils/dates";
import { MessagingContext } from "../../App";
import { RedirectionContext, Routes } from "../../MainRouter";
import ActionButton from "../../shared/ActionButton";
import GenericCombo from "../../shared/GenericCombo";
import Carer from '../../../models/Carer.model';
import Loader from "../../shared/Loader";
import IdentificationType from "../../../interfaces/IdentificationType.interface";
import City from "../../../interfaces/City.interface";
import Country from "../../../interfaces/CustomerType.interface";
import { PORTUGAL_COUNTRY_ID } from "../../../constants";
import { verifyErrors } from "../../../utils/generic";
import CityCombo from "../../combos/CityCombo";

type CarerFields = {
    name: string;
    address: string;
    postal_code: string;
    city_id: number | undefined;
    email: string;
    phone: string;
    birth_date: string | undefined;
    naturality: string | undefined;
    nationality: string | undefined;
    nationality_id: number | undefined;
    fiscal_number: string;
    identification_number: string | undefined;
    identification_date: string;
    identification_entity: string | undefined;
    identification_type_id: number | undefined;
    observations: string;
    created_date: string;
}

type CarerErrors = {
    name: boolean;
    city_id: boolean;
    identification_type_id: boolean;
}

const carerErrorsDescriptions: any = {
    name: "Nome",
    city_id: "Localidade",
    identification_type_id: "Tipo de identificação",
}

const emptyFields: CarerFields = {
    name: "",
    address: "",
    postal_code: "",
    city_id: undefined,
    email: "",
    phone: "",
    fiscal_number: "",
    identification_number: undefined,
    identification_date: "",
    identification_entity: undefined,
    identification_type_id: undefined,
    birth_date: undefined,
    naturality: undefined,
    nationality: undefined,
    nationality_id: undefined,
    observations: "",
    created_date: toInputDateTime(moment()),
};

export default function ContactForm() {
    const messaging = useContext(MessagingContext);
    const redirection = useContext(RedirectionContext);

    const [fieldsValues, setFieldsValues] = useState(emptyFields);

    const [cities, setCities] = useState<City[]>([]);
    const [countries, setCountries] = useState<Country[] | undefined>(undefined);
    const [identificationTypes, setIdentificationTypes] = useState<IdentificationType[] | undefined>(undefined);

    const [errors, setErrors] = useState<CarerErrors>({
        name: false,
        city_id: false,
        identification_type_id: false,
    });

    const [pending, setPending] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadedCarer, setLoadedCarer] = useState<Carer | undefined>(undefined);

    const params: { id: string } = useParams();
    const carerId = params && params.id ? parseInt(params.id) : undefined;
    const editMode = carerId !== undefined;

    useEffect(() => {
        api.getCarerComboboxesInfo((data: any) => {
            setCities(data.cities);
            setIdentificationTypes(data.identification_types);
            setCountries(data.countries);
        }, err => {
            setCities([]);
            setIdentificationTypes([]);
            setCountries([]);
        });
    }, []);

    if (editMode && loading === false && typeof loadedCarer === "undefined") {
        setLoading(true);

        api.getCarer(carerId, (carer) => {

            setFieldsValues({
                name: carer.name,
                address: carer.address,
                postal_code: carer.postal_code,
                city_id: carer.city_id,
                email: carer.email,
                phone: carer.phone,
                fiscal_number: carer.fiscal_number ?? 0,
                birth_date: carer.birth_date,
                naturality: carer.naturality,
                nationality: carer.nationality,
                nationality_id: carer.nationality_id,
                identification_number: carer.identification_number,
                identification_date: carer.identification_date ?? "",
                identification_entity: carer.identification_entity,
                identification_type_id: carer.identification_type_id,
                observations: carer.observations,
                created_date: toInputDateTime(moment(carer.created_date))
            });
            setLoadedCarer(new Carer(carer));
            setLoading(false);
        }, err => {
            setLoadedCarer(undefined);
            setLoading(false);
            messaging.modal.showMessage("Erro", "Erro no carregamento do contacto: " + err);
        });
    }

    useEffect(() => {
        setErrors({
            name: fieldsValues.name === "",
            city_id: fieldsValues.city_id === undefined,
            identification_type_id: fieldsValues.identification_type_id === undefined,
        });
    }, [fieldsValues]);

    function handleSubmit(event: SyntheticEvent) {
        event.preventDefault();

        const errorFound = verifyErrors(errors as CarerErrors);
        if (errorFound) {
            messaging.toast.show("Erro", `Preencha o campo: ${carerErrorsDescriptions[errorFound]}`);
            return;
        }

        const carerInfo: any = {
            name: fieldsValues.name,
            address: fieldsValues.address,
            postal_code: fieldsValues.postal_code,
            city_id: fieldsValues.city_id,
            email: fieldsValues.email,
            phone: fieldsValues.phone,
            birth_date: fieldsValues.birth_date,
            naturality: fieldsValues.naturality,
            nationality_id: fieldsValues.nationality_id,
            fiscal_number: fieldsValues.fiscal_number,
            identification_number: fieldsValues.identification_number,
            identification_date: fieldsValues.identification_date,
            identification_entity: fieldsValues.identification_entity,
            identification_type_id: fieldsValues.identification_type_id,
            observations: fieldsValues.observations,
            created_date: fieldsValues.created_date,
        };

        setPending(true);
        if (editMode) {
            const editCarer = { ...carerInfo, id: carerId };
            api.editCarer(editCarer, () => {
                setPending(false);
                redirection.redirectTo(Routes.carers);
            }, () => {
                setPending(false);
                messaging.modal.showMessage("Erro", "Erro ao editar Encarregado de educação");
            });
        }
        else {
            api.addCarer(carerInfo, () => {
                setPending(false);
                redirection.redirectTo(Routes.carers);
            }, () => {
                setPending(false);
                messaging.modal.showMessage("Erro", "Erro ao inserir encarregado de educação");
            });
        }

        console.log(carerInfo);
    }

    function formJSX() {
        return (
            <>
                <div className="row">
                    <div className="col m-2">
                        <div className="form-group">
                            <label htmlFor="name">Nome</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                value={fieldsValues.name || ""}
                                onChange={event => setFieldsValues({ ...fieldsValues, name: event.target.value })}
                                required />
                        </div>
                    </div>
                    <div className="col m-2">
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                value={fieldsValues.email || ""}
                                onChange={event => setFieldsValues({ ...fieldsValues, email: event.target.value })} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col m-2">
                        <div className="form-group">
                            <label htmlFor="phone">Telefone</label>
                            <input
                                type="phone"
                                className="form-control"
                                id="phone"
                                value={fieldsValues.phone || ""}
                                onChange={event => setFieldsValues({ ...fieldsValues, phone: event.target.value })} />
                        </div>
                    </div>
                    <div className="col m-2">
                        <div className="form-group">
                            <label htmlFor="phone">Morada</label>
                            <input
                                type="text"
                                className="form-control"
                                id="address"
                                value={fieldsValues.address || ""}
                                onChange={event => setFieldsValues({ ...fieldsValues, address: event.target.value })} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col m-2">
                        <div className="form-group">
                            <label htmlFor="phone">Código Postal</label>
                            <input
                                type="text"
                                className="form-control"
                                id="postal_code"
                                value={fieldsValues.postal_code || ""}
                                onChange={event => setFieldsValues({ ...fieldsValues, postal_code: event.target.value })} />
                        </div>
                    </div>
                    <div className="col m-2">
                        <div className="form-group">
                            <label htmlFor="phone">Localidade</label>
                            <CityCombo
                                value={cities?.find((c: City) => c.id === fieldsValues.city_id)}
                                availableCities={cities}
                                selectionChanged={(city: City | undefined) => setFieldsValues({ ...fieldsValues, city_id: city?.id })}
                                cityInserted={((list: City[]) => setCities([...list]))}
                                showError={errors.city_id}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col m-2">
                        <div className="form-group">
                            <label htmlFor="phone">Naturalidade</label>
                            <input
                                type="text"
                                className="form-control"
                                id="naturality"
                                value={fieldsValues.naturality || ""}
                                onChange={event => setFieldsValues({ ...fieldsValues, naturality: event.target.value })} />
                        </div>
                    </div>
                    <div className="col m-2">
                        <div className="form-group">
                            <label htmlFor="nationality">Nacionalidade</label>
                            <GenericCombo
                                showError={errors.identification_type_id}
                                value={fieldsValues.nationality_id ?? PORTUGAL_COUNTRY_ID}
                                values={countries}
                                getId={country => country.id}
                                getOptionContent={country => country.title}
                                selectionChanged={country =>
                                    setFieldsValues({ ...fieldsValues, nationality_id: country as number })} />
                        </div>
                    </div>
                    <div className="col m-2">
                        <div className="form-group">
                            <label htmlFor="identification_date">Data de Nascimento</label>
                            <input
                                type="date"
                                className="form-control"
                                id="birth_date"
                                value={fieldsValues.birth_date ? toInputDate(new Date(fieldsValues.birth_date)) : ""}
                                onChange={event => setFieldsValues({ ...fieldsValues, birth_date: event.target.value })} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col m-2">
                        <div className="form-group">
                            <label>Tipo de identificação</label>
                            <GenericCombo
                                value={fieldsValues.identification_type_id}
                                values={identificationTypes}
                                getId={identificationType => identificationType.id}
                                getOptionContent={identificationType => identificationType.title}
                                selectionChanged={identificationType =>
                                    setFieldsValues({ ...fieldsValues, identification_type_id: identificationType as number })} />
                        </div>
                    </div>
                    <div className="col m-2">
                        <div className="form-group">
                            <label htmlFor="postal_code">Número de identificação</label>
                            <input
                                type="text"
                                className="form-control"
                                id="identification_number"
                                value={fieldsValues.identification_number || ""}
                                onChange={event => setFieldsValues({ ...fieldsValues, identification_number: event.target.value })} />
                        </div>
                    </div>
                    <div className="col m-2">
                        <div className="form-group">
                            <label htmlFor="identification_date">Data de validade</label>
                            <input
                                type="date"
                                className="form-control"
                                id="identification_date"
                                value={toInputDate(new Date(fieldsValues.identification_date))}
                                onChange={event => setFieldsValues({ ...fieldsValues, identification_date: event.target.value })} />
                        </div>
                    </div>
                    <div className="col m-2">
                        <div className="form-group">
                            <label htmlFor="identification_entity">Entidade</label>
                            <input
                                type="text"
                                className="form-control"
                                id="identification_entity"
                                value={fieldsValues.identification_entity || ""}
                                onChange={event => setFieldsValues({ ...fieldsValues, identification_entity: event.target.value })} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col m-2">
                        <div className="form-group">
                            <label htmlFor="fiscal_number">NIF</label>
                            <input
                                type="text"
                                className="form-control"
                                id="fiscal_number"
                                value={fieldsValues.fiscal_number || ""}
                                onChange={event => setFieldsValues({ ...fieldsValues, fiscal_number: event.target.value })} />
                        </div>
                    </div>
                </div>
                <div className="row" style={{ marginTop: "30px" }}>
                    <div className="col text-center">
                        <ActionButton
                            pending={pending}
                            icon={`fas ${editMode ? "fa-pen" : "fa-plus-square"}`}
                            text={editMode ? "Editar" : "Criar"} />
                    </div>
                </div>
            </>
        );
    }

    return (
        <div className="container p-4">
            <h2 className="text-center m-4">{editMode ? "Editar" : "Novo"} Encarregado de Educação</h2>
            <form onSubmit={handleSubmit}>
                <div className="card p-3">
                    {loading ? <Loader /> : formJSX()}
                </div>
            </form>
        </div>
    );
}