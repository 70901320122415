import { useContext } from "react";
import '../../styles/ContactsTable.css';
import Carer from '../../models/Carer.model';
import { toDatePT } from '../../utils/dates';
import { Link } from "react-router-dom";
import { api } from "../../services/api";
import { MessagingContext } from '../App';
import StudentTagInterface from "../../interfaces/StudentTagInterface.interface";
import StudentTag from "../tags/StudentTag";

type CarerProps = {
    values: Carer[];
    selectHandler?: (carer: Carer) => void;
    notFoundText?: string;
    carerRemoved?: (id: number) => void;
    carerChanged?: (carer: Carer) => void;
}

export default function CarerTable(props: CarerProps) {
    const messaging = useContext(MessagingContext);
    const { values, selectHandler, carerRemoved } = props;

    function deleteCarer(id: number) {
        api.deleteCarer(id, () => {
            if (carerRemoved) {
                carerRemoved(id);
            }
            messaging.toast.show("Encarregado de Educação", "Encarregado de Educação removido");
        }, () => {
            messaging.modal.showMessage("Erro", "Erro a remover Encarregado de Educação");
        });
    }

    function handleRemoveCarer(carer: Carer) {
        messaging.modal.showFull({
            title: "Remover encarregado de educação",
            body: `Tem a certeza que pretende remover o encarregado de educação de ${carer.name} ?`,
            closeName: "Cancelar",
            closeHandler: messaging.modal.close,
            actionName: "Eliminar",
            actionHandler: () => {
                messaging.modal.close();
                deleteCarer(carer.id);
            },
            actionClassName: "btn-danger",
            showAction: true
        });
    }

    return (
        <>
            <table className="table table-striped table-hover">
                <thead>
                    <tr>
                        <th scope="col">Nome</th>
                        <th scope="col">Morada</th>
                        <th scope="col">Email</th>
                        <th scope="col">Localidade</th>
                        <th scope="col">Observações</th>
                        <th scope="col">Alunos associados</th>
                        <th scope="col">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {values.length > 0 ?
                        values.map((row: Carer) =>
                            <tr key={row.id}>
                                <td style={{ minWidth: "200px" }}>
                                    <div>
                                        <Link to={`/encarregado-educacao/${row.id}`}>{row.name}</Link>

                                    </div>
                                    <div className="contact-created-date">{ }</div>

                                    {row.birthDate ? <div className="contact-phone">{toDatePT(row.birthDate)}</div> : ""}
                                    <div className="contact-phone">{row.phone}</div>
                                </td>
                                <td style={{ minWidth: "150px" }}>
                                    {row.address}
                                </td>
                                <td style={{ minWidth: "150px" }}>
                                    <a
                                        href={`mailto:${row.email}`}
                                        target="_blank"
                                        rel="noreferrer">{row.email}</a>
                                </td>
                                <td style={{ minWidth: "150px" }}>
                                    {row.city}
                                </td>
                                <td style={{ minWidth: "150px" }}>
                                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                                        {row.observations}
                                    </div>
                                </td>
                                <td style={{ minWidth: "150px" }}>
                                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                                        {row.students ? row.students.map((student: StudentTagInterface) => <StudentTag key={student.id} id={student.id} name={student.name} />) : ""}
                                    </div>
                                </td>
                                {selectHandler ?
                                    <td>
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => selectHandler(row)}>Escolher</button>
                                    </td> :
                                    <td style={{ minWidth: "100px" }}>

                                        <Link
                                            className="action-icon"
                                            to={`/encarregados-educacao/${row.id}/editar/`}
                                            style={{ marginRight: "5px" }}>
                                            <i className="fas fa-pen" title="Editar"></i>
                                        </Link>
                                        {carerRemoved ?
                                            <span
                                                className="action-icon"
                                                style={{ marginRight: "5px" }}
                                                onClick={() => handleRemoveCarer(row)}>
                                                <i className="fas fa-trash" title="Eliminar"></i>
                                            </span>
                                            :
                                            ""
                                        }
                                    </td>
                                }
                            </tr>
                        ) :
                        <tr>
                            <td colSpan={6} style={{ textAlign: "center" }}>
                                {props.notFoundText || "Não existem clientes para a pesquisa efetuada"}
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </>
    );
}