import React, { useState, useRef, useEffect } from "react";
import { offices } from "../../constants";
import Training from "../../interfaces/Training.interface";
import { api } from "../../services/api";
import { buildTraining } from "../../utils/objects-builders";
import OfficeToggle from "../OfficeToggle";
import Loader from "../shared/Loader";
import PaginationLinks from "../shared/PaginationLinks";
import DatePassedTrainingsTable from "../training/DatePassedTrainingsTable";

export default function StudentDatePassedCourses() {
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const [trainings, setTrainings] = useState<Training[] | undefined>();
    const [currentOffice, setCurrentOffice] = useState(offices[0]);

    const searchRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        const params = { office: currentOffice.value, page: currentPage, expired: true };
        api.getExpiringTrainings(params,
            newTrainings => {
                setTrainings(newTrainings.data.map(buildTraining));
                setHasMorePages(newTrainings.morePages);
            },
            () => setTrainings([]));

        searchRef.current?.focus();
    }, [currentPage, currentOffice]);

    const paginationLinksJSX = <PaginationLinks
        page={currentPage}
        hasMorePages={hasMorePages}
        pageChanged={newPage => setCurrentPage(newPage)}
        classes="justify-content-end" />;

    return (
        <div className="container">
            <h2 className="text-center m-4">Alunos com cursos ativos e validade terminada</h2>

            <div style={{ display: "flex" }}>
                <div style={{ margin: "30px 0" }}>
                    Filial: <OfficeToggle officeChanged={newOffice => setCurrentOffice(newOffice)} />
                </div>
            </div>

            { trainings ?
                <>
                    {paginationLinksJSX}
                    <DatePassedTrainingsTable values={trainings} />
                    {paginationLinksJSX}
                </> :
                <Loader />
            }
        </div>
    );
}