import { useContext } from "react";
import { RedirectionContext, Routes } from "./MainRouter";
import MultiSearchList from "./multi-search/MultiSearchList";

export default function Home() {
    const redirection = useContext(RedirectionContext);

    function newContact() {
        redirection.redirectTo(Routes.newContact);
    }

    function newFreeSession() {
        redirection.redirectTo(Routes.newFreeSession);
    }

    function newStudent() {
        redirection.redirectTo(Routes.newStudent);
    }

    function newCustomer() {
        redirection.redirectTo(Routes.newCustomer);
    }

    function newCarer() {
        redirection.redirectTo(Routes.newCarer);
    }

    const buttonStyles = { width: "25%", margin: "0 6px" };
    return (
        <div className="container" style={{ width: "90vw" }}>
            <h2 className="text-center m-4">Ninjas</h2>
            <div >
                <div style={{ width: "100%" }}>
                    <h5>Criar</h5>
                    <div className="actionButtons" style={{
                        display: "flex",
                        justifyContent: "space-evenly"
                    }}>
                        <div style={buttonStyles}>
                            <button
                                style={{ width: "100%" }}
                                className="btn btn-success"
                                onClick={newContact}>Novo Contacto</button>
                        </div>
                        <div style={buttonStyles}>
                            <button
                                style={{ width: "100%" }}
                                className="btn btn-success"
                                onClick={newFreeSession}>Nova Sessão Gratuita</button>
                        </div>
                        <div style={buttonStyles}>
                            <button
                                style={{ width: "100%" }}
                                className="btn btn-success"
                                onClick={newStudent}>Novo Aluno</button>
                        </div>
                        <div style={buttonStyles}>
                            <button
                                style={{ width: "100%" }}
                                className="btn btn-success"
                                onClick={newCustomer}>Novo Cliente</button>
                        </div>
                        <div style={buttonStyles}>
                            <button
                                style={{ width: "100%" }}
                                className="btn btn-success"
                                onClick={newCarer}>Novo Encarregado</button>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "30px" }}>
                    <h5>Consultar</h5>
                    <div>
                        <MultiSearchList />
                    </div>
                </div>
            </div>
        </div >
    );
}