import moment from "moment";
import { daysToHumanTime } from "../../utils/dates";

export default function ExpirationInfo(props: { expirationDate: Date | undefined, extension: number }) {
    const { expirationDate, extension } = props;
    const finalDate = extension === 0 || !expirationDate ?
        moment(expirationDate) :
        moment(expirationDate).add(extension, 'months');

    const now = moment();

    let expireDays = -1;
    if (finalDate) {
        expireDays = Math.ceil(finalDate.diff(now, 'days', true));
    }
    return (
        <>
            {finalDate && finalDate.format("DD/MM/YYYY")}&nbsp;
            ({expireDays > 0 ? daysToHumanTime(expireDays) : "Expirou"})
        </>
    );
}