import { useState } from "react";
import { civilStates, genders } from "../../../constants";
import City from "../../../interfaces/City.interface";
import CityCombo from "../../combos/CityCombo";
import GenericCombo from "../../shared/GenericCombo";
import styles from "../../shared/Card.module.css";
import { StudentErrors, StudentFields } from "./StudentForm";

type StudentDataFormProps = {
    fieldsValues: StudentFields;
    setFieldsValues: (val: StudentFields) => void;
    errors: StudentErrors;
};

export default function StudentDataForm(props: StudentDataFormProps) {
    const { fieldsValues, setFieldsValues, errors } = props;

    const [selectedCity, setSelectedCity] = useState<City | undefined>(undefined);


    function handleChange(event: any) {
        setFieldsValues(
            { ...fieldsValues, [event.target.id]: event.target.checked }
        )
    }

    return (
        <div className="card p-3">
            <h4 className="m-2">Dados pessoais</h4>
            <div className="row">
                <div className="col col-md-5 m-2">
                    <div className="form-group">
                        <label htmlFor="name">Nome</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            value={fieldsValues.name || ""}
                            onChange={event => setFieldsValues({ ...fieldsValues, name: event.target.value })}
                            required />
                    </div>
                </div>
                <div className="col col-md-4 m-2">
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            value={fieldsValues.email || ""}
                            onChange={event => setFieldsValues({ ...fieldsValues, email: event.target.value })} />
                    </div>
                </div>
                <div className="col col-md m-2">
                    <div className="form-group">
                        <label htmlFor="phone">Telefone</label>
                        <input
                            type="number"
                            className="form-control"
                            id="phone"
                            value={fieldsValues.phone || ""}
                            onChange={event => setFieldsValues({ ...fieldsValues, phone: event.target.value })} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col col-md-5 m-2">
                    <div className="form-group">
                        <label htmlFor="address">Morada</label>
                        <input
                            type="text"
                            className="form-control"
                            id="address"
                            value={fieldsValues.address || ""}
                            onChange={event => setFieldsValues({ ...fieldsValues, address: event.target.value })} />
                    </div>
                </div>
                <div className="col col-md-4 m-2">
                    <div className="form-group">
                        <label htmlFor="postal_code">Código Postal</label>
                        <input
                            type="text"
                            className="form-control"
                            id="postal_code"
                            value={fieldsValues.postal_code || ""}
                            onChange={event => setFieldsValues({ ...fieldsValues, postal_code: event.target.value })} />
                    </div>
                </div>
                <div className="col col-md m-2">
                    <div className="form-group">
                        <label>Localidade</label>
                        <CityCombo
                            value={selectedCity}
                            selectionChanged={(city: City | undefined) => {
                                setSelectedCity(city);
                                setFieldsValues({ ...fieldsValues, cityId: city?.id })
                            }}
                            autoFetchCities={true}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col m-2">
                    <div className="form-group">
                        <label htmlFor="address">Sexo</label>
                        <GenericCombo
                            value={undefined}
                            values={genders}
                            getId={gender => gender.val}
                            getOptionContent={gender => gender.name}
                            selectionChanged={newGender => setFieldsValues({ ...fieldsValues, gender: newGender as string })}
                            showError={errors.gender} />
                    </div>
                </div>
                <div className="col m-2">
                    <div className="form-group">
                        <label htmlFor="date">Data de nascimento</label>
                        <input
                            type="date"
                            className="form-control"
                            id="date"
                            value={fieldsValues.birth_date || ""}
                            onChange={event => setFieldsValues({ ...fieldsValues, birth_date: event.target.value })} />
                    </div>
                </div>
                <div className="col m-2">
                    <div className="form-group">
                        <label htmlFor="date">Estado Civil</label>
                        <GenericCombo
                            value={fieldsValues.civilState ?? ""}
                            values={civilStates}
                            getId={state => state.val}
                            getOptionContent={state => state.name}
                            selectionChanged={newState => setFieldsValues(
                                { ...fieldsValues, civilState: newState as string }
                            )} />
                    </div>
                </div>
                <div className="col m-2">
                    <div className="form-group">
                        <label htmlFor="date">Documentos Entregues</label>
                        <div className={styles.deliveredDocumentsContainer}>
                            <input type="checkbox" className="btn-check" id="documentCC"
                                onChange={handleChange}
                                checked={fieldsValues.documentCC} />
                            <label className="btn btn-outline-secondary" htmlFor="documentCC">Documento de Identificação</label>

                            <input type="checkbox" className="btn-check" id="documentCV"
                                onChange={handleChange}
                                checked={fieldsValues.documentCV} />
                            <label className="btn btn-outline-secondary" htmlFor="documentCV">Curriculo</label>

                            <input type="checkbox" className="btn-check" id="documentCH"
                                onChange={handleChange}
                                checked={fieldsValues.documentCH} />
                            <label className="btn btn-outline-secondary" htmlFor="documentCH">Certificado de Habilitações</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}