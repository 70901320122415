import { useContext, useEffect, useState } from 'react';
import Modal from '../shared/Modal';
import { MessagingContext } from '../App';
import Course from '../../models/Course.model';
import { api, getStorageInfo } from '../../services/api';
import { courseCategoriesHash, courseStatusTypesHash } from '../../constants';
import Loader from '../shared/Loader';
import { verifyErrors } from '../../utils/generic';
import CourseStatesCombo from '../combos/CourseStatesCombo';
import CourseCategoryCombo from '../combos/CourseCategoryCombo';

type CourseFormProps = {
    closeHandler: () => void;
    course: Course | undefined;
    courseAdded?: (user: Course) => void;
    courseEdited?: (user: Course) => void;
    editing: boolean;
}

type CourseFormFields = {
    title: string;
    code: string;
    price: number;
    info: string;
    time: number;
    visible: boolean;
    course_category_id: number;
    course_status_id: number;
    priceInputValue?: string;
}

type CourseFormErrors = {
    title: boolean;
    price: boolean;
    time: boolean;
    course_category_id: boolean;
    course_status_id: boolean;
}

const emptyErrors: CourseFormErrors = {
    title: false,
    price: false,
    time: false,
    course_category_id: false,
    course_status_id: false,
}

type CourseCategory = {
    id: number;
    title: string;
}

const courseErrorsDescriptions: any = {
    title: "Titulo",
    code: "Código Único",
    price: "Preço €",
    time: "Hórario Previsto",
    course_category_id: "Categoria de Curso",
    course_status_id: "Estado do Curso",
}


export default function CourseForm(props: CourseFormProps) {
    const messaging = useContext(MessagingContext);
    const [pending, setPending] = useState(false);

    const { course } = props;

    const [fieldsValues, setFieldsValues] = useState<CourseFormFields>({
        title: "",
        code: "",
        info: "",
        price: 0,
        time: 0,
        visible: true,
        course_category_id: 0,
        course_status_id: 0,
    });

    const [courseCategories, setCourseCategories] = useState<CourseCategory[]>([]);

    const [errors, setErrors] = useState<CourseFormErrors>(emptyErrors);

    useEffect(() => {
        //Whevener a Course is available, it means its an edit, and the info should be passed to the fieldsValues
        if (props.course) {
            setFieldsValues({
                title: props.course.title,
                code: props.course.code,
                price: props.course.price,
                priceInputValue: `${props.course.price}`,
                info: props.course.info,
                time: props.course.time,
                visible: props.course.visible,
                course_category_id: props.course.courseSubcategoryId ?? 0,
                course_status_id: props.course.courseStateId ?? 0
            })
        }
    }, [props.course])

    //Creates based on the fieldvalues, an object to pass throught the User constructor.
    function generateCourseObj(id: number) {
        return {
            id: id,
            title: fieldsValues.title,
            code: fieldsValues.code,
            info: fieldsValues.info,
            price: fieldsValues.price,
            visible: fieldsValues.visible,
            time: fieldsValues.time,
            course_category_id: fieldsValues.course_category_id,
            course_category: courseCategoriesHash[fieldsValues.course_category_id]?.name,
            course_status_id: fieldsValues.course_status_id,
            course_status_type: courseStatusTypesHash[fieldsValues.course_status_id]?.name,
        }
    }

    function handleSubmit() {

        const errorFound = verifyErrors(errors as CourseFormErrors);
        if (errorFound) {
            messaging.toast.show("Erro", `Preencha o campo: ${courseErrorsDescriptions[errorFound]}`);
            return;
        }

        setPending(true);

        if (!props.editing) {
            addCourseApi();
        } else {
            editCourseApi();
        }
    }

    function addCourseApi() {

        const dataObj = {
            ...fieldsValues,
            user_id: getStorageInfo().userId,
        }

        api.addCourse(dataObj,
            (response: any) => {
                if (props.courseAdded) {
                    setPending(false);
                    const newCourse = generateCourseObj(parseInt(response));
                    messaging.toast.show("Adicionado", "Novo Utilizador adicionado corretamente");
                    props.courseAdded(new Course(newCourse))
                }
            },
            (err: any) => messaging.toast.show("Erro", err.message));
    }

    function editCourseApi() {
        if (course) {

            const dataObj = {
                id: course.id,
                ...fieldsValues,
                user_id: getStorageInfo().userId,
            }

            api.editCourse(dataObj,
                (response: any) => {
                    if (course && response.status === 200 && props.courseEdited) {
                        setPending(false);
                        const newCourse = generateCourseObj(course.id);
                        console.log(newCourse);
                        messaging.toast.show("Modificado", `O curso ${course.getName()} foi modificado com sucesso!`);
                        props.courseEdited(new Course(newCourse));
                    }
                },
                (err: any) => messaging.toast.show("Erro", err.message));
        }
    }

    useEffect(() => {
        setErrors({
            title: fieldsValues.title === "",
            price: fieldsValues.price === 0,
            time: fieldsValues.time === 0,
            course_category_id: fieldsValues.course_category_id === 0,
            course_status_id: fieldsValues.course_status_id === 0
        });
    }, [fieldsValues]);

    useEffect(() => {
        api.getCourseCategories(
            (data) => {
                setCourseCategories(data)
            },
            (err) => {
                messaging.toast.show("Erro", err.message);
                setCourseCategories([]);
            }
        )
    }, [messaging.toast])

    const body =
        <div>
            <h6>Curso</h6>
            <div style={{ maxHeight: "500px" }}>
                <div className="row">
                    <div className={`col col-lg-${props.editing ? 8 : 12}`}>
                        <div className="form-group">
                            <label>Titulo</label>
                            <input
                                type="text"
                                className="form-control"
                                id="title"
                                value={fieldsValues.title}
                                onChange={event => setFieldsValues({ ...fieldsValues, title: event.target.value })}
                                required />
                        </div>
                    </div>
                    {
                        props.editing ?
                            <div className="col" style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'flex-end'
                            }}>
                                <div style={{ width: '100%' }}>
                                    <input type="checkbox"
                                        onClick={() => setFieldsValues({ ...fieldsValues, visible: !fieldsValues.visible })}
                                        checked={fieldsValues.visible}
                                        className="btn-check"
                                        id="btn-check-outlined" />
                                    <label style={{ width: '100%' }}
                                        className="btn btn-outline-primary"
                                        htmlFor="btn-check-outlined">Visivel</label>

                                </div>
                            </div>
                            : ""
                    }
                </div>
                <div className="row">
                    <div className="col m-8">
                        <div className="form-group">
                            <label htmlFor="description">Categoria do Curso</label>
                            {
                                !courseCategories ?
                                    <Loader />
                                    :
                                    <CourseCategoryCombo
                                        includeOverall={false}
                                        value={fieldsValues.course_category_id}
                                        showError={errors.course_category_id}
                                        selectionChanged={(category: any) => setFieldsValues({ ...fieldsValues, course_category_id: category })}
                                    />
                            }
                        </div>
                    </div>
                    <div className="col m-8">
                        <div className="form-group">
                            <label htmlFor="description">Estado</label>
                            {
                                <CourseStatesCombo
                                    includeOverall={false}
                                    value={fieldsValues.course_status_id}
                                    showError={errors.course_status_id}
                                    selectionChanged={(state: any) => setFieldsValues({ ...fieldsValues, course_status_id: state })}
                                />
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col m-8">
                        <label>Código</label>
                        <input
                            type="text"
                            className="form-control"
                            id="code"
                            value={fieldsValues.code}
                            onChange={event => setFieldsValues({ ...fieldsValues, code: event.target.value })}
                            required />
                        <small className="form-text text-muted">Código único</small>
                    </div>
                    <div className="col m-8">
                        <div className="form-group">
                            <label htmlFor="description">Horas</label>
                            <input
                                type="number"
                                className="form-control"
                                id="time"
                                value={fieldsValues.time}
                                onChange={event => setFieldsValues({ ...fieldsValues, time: parseInt(event.target.value === "" ? "0" : event.target.value) })} />
                        </div>
                    </div>

                    <div className="col m-8">
                        <div className="form-group">
                            <label>Preço</label>
                            <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                id="price"
                                value={fieldsValues.priceInputValue}
                                onChange={event => {
                                    setFieldsValues({
                                        ...fieldsValues,
                                        priceInputValue: event.target.value,
                                        price: parseFloat(event.target.value)
                                    })
                                }} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sml m-8">
                        <div className="form-group">
                            <label htmlFor="description">Informação Adicional</label>
                            <textarea
                                className="form-control"
                                id="info"
                                onChange={event => setFieldsValues({ ...fieldsValues, info: event.target.value })}>
                            </textarea>
                        </div>
                    </div>
                </div>


                <div className="row" style={{ marginTop: "20px" }}>
                    <div className="col text-center">

                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => handleSubmit()}
                            style={{ padding: "5px 40px" }}>
                            {pending ?
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"></span>
                                : <i className="fas fa-plus-square"></i>
                            }
                            <span style={{ margin: "0px 10px" }}>{`${props.editing ? "Modificar" : "Adicionar"} Curso`}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>;

    return (
        <>
            <Modal
                title={`${props.editing ? "Editar" : "Novo"} Curso`}
                body={body}
                closeName="Fechar"
                closeHandler={props.closeHandler}
                bodyMaxHeight={500} />
        </>
    );
}