import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { api } from '../../services/api';
import Modal from '../shared/Modal';
import { MessagingContext } from '../App';
import User from '../../models/User.model';
import OfficeCombo from '../combos/OfficeCombo';
import ActionButton from '../shared/ActionButton';
import { UserTypes } from '../../types/UserTypes';
import UserTypeCombo from '../combos/UserTypeCombo';
import { userTypesHash, noUserType } from '../../constants';
import { verifyErrors } from '../../utils/generic';

type UserFormProps = {
    closeHandler: () => void;
    user: User | undefined;
    userAdded?: (user: User) => void;
    userEdited?: (user: User) => void;
    editing: boolean;
}

type UserFormFields = {
    name: string;
    username: string;
    password: string;
    office_id: number;
    user_type_id: number;
    picture: string;
    active: boolean;
}

type UserFormErrors = {
    name: boolean,
    username: boolean,
    office_id: boolean,
    user_type_id: boolean,
}

const emptyErrors: UserFormErrors = {
    name: false,
    username: false,
    office_id: false,
    user_type_id: false,
}



export default function UserForm(props: UserFormProps) {
    const messaging = useContext(MessagingContext);

    const [fieldsValues, setFieldsValues] = useState<UserFormFields>({
        name: "",
        username: "",
        password: "",
        office_id: 0,
        user_type_id: 0,
        active: true,
        picture: "",
    });
    const [userTypes, setUserTypes] = useState<UserTypes[]>([]);
    const [errors, setErrors] = useState<UserFormErrors>(emptyErrors);
    const [pending, setPending] = useState<boolean>(false);

    useEffect(() => {
        //Whevener a User is available, it means its an edit, and the info should be passed to the fieldsValues
        if (props.user) {
            setFieldsValues({
                name: props.user.name,
                username: props.user.username,
                password: props.user.password,
                office_id: props.user.officeId,
                user_type_id: props.user.userTypeId,
                active: props.user.active,
                picture: props.user.picture,
            })
        }
    }, [props.user])

    //Creates based on the fieldvalues, an object to pass throught the User constructor.
    function generateUserObj(id: number) {
        return {
            id: id,
            name: fieldsValues.name,
            username: fieldsValues.username,
            office_id: fieldsValues.office_id,
            user_type_id: fieldsValues.user_type_id,
            user_type: (userTypesHash[fieldsValues.user_type_id] || noUserType).name,
            picture: fieldsValues.picture,
            active: fieldsValues.active
        }
    }

    function handleSubmit(event: SyntheticEvent) {
        event.preventDefault();

        setPending(true);

        const errorFound = verifyErrors(errors as UserFormErrors);
        if (errorFound) {
            messaging.toast.show("Erro", `Preencha o campo: ${errorFound}`);
            return;
        }

        if (!props.editing) {
            addUserApi();
        } else {
            editUserApi();
        }
    }

    function addUserApi() {
        //If password is left empty, then its set by default to Formabase
        const dataObj = { ...fieldsValues, password: fieldsValues.password || "Formabase" };
        api.addUser(dataObj,
            (response: any) => {
                setPending(false);
                if (props.userAdded) {
                    const newUser = generateUserObj(parseInt(response));
                    messaging.toast.show("Adicionado", "Novo Utilizador adicionado corretamente");
                    props.userAdded(new User(newUser))
                }
            },
            (err: any) => {
                setPending(false);
                messaging.toast.show("Erro", err.message);
            });
    }

    function editUserApi() {
        if (props.user) {
            const dataObj = { ...fieldsValues, id: props.user.id, active: fieldsValues.active ? 1 : 0 };
            api.editUser(dataObj,
                (response: any) => {
                    setPending(false);
                    if (response.status !== 200) {
                        throw response.status;
                    }
                    if (props.userEdited && props.user) {
                        const newUser = generateUserObj(props.user.id);
                        messaging.toast.show("Modificado", "Novo Utilizador editado corretamente");
                        props.userEdited({ ...props.user, ...newUser })
                    }
                },
                (err: any) => {
                    messaging.toast.show("Erro", err.message);
                    setPending(false);
                });
        }
    }

    useEffect(() => {
        setErrors({
            name: fieldsValues.name === "",
            username: fieldsValues.username === "",
            office_id: fieldsValues.office_id === 0,
            user_type_id: fieldsValues.user_type_id === 0,
        });
    }, [fieldsValues]);

    useEffect(() => {
        api.getUserTypes(
            (data) => setUserTypes(data),
            (err) => {
                messaging.toast.show("Erro", err.message);
                setUserTypes([]);
            }
        )
    }, [messaging.toast])

    const body =
        <div>
            <h6>Utilizador</h6>
            <div style={{ maxHeight: "500px" }}>
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col m-2">
                            <div className="form-group">
                                <label>Nome</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    value={fieldsValues.name}
                                    onChange={event => setFieldsValues({ ...fieldsValues, name: event.target.value })}
                                    required />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col m-2">
                            <div className="form-group">
                                <label>Utilizador</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="username"
                                    value={fieldsValues.username}
                                    onChange={event => setFieldsValues({ ...fieldsValues, username: event.target.value })}
                                    required />
                            </div>
                        </div>
                        <div className="col m-2">
                            <div className="form-group">
                                <label>Palavra-Passe</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    value={fieldsValues.password}
                                    onChange={event => setFieldsValues({ ...fieldsValues, password: event.target.value })} />
                                <small className="form-text text-muted">
                                    {"Será 'Formabase' se vazio"}
                                </small>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col m-2">
                            <div className="form-group">
                                <label htmlFor="description">Região</label>
                                <OfficeCombo
                                    showError={errors.office_id}
                                    value={fieldsValues.office_id}
                                    selectionChanged={(newVal: number) => setFieldsValues({ ...fieldsValues, office_id: newVal })}
                                />
                            </div>
                        </div>
                        <div className="col m-2">
                            <div className="form-group">
                                <label htmlFor="description">Tipo de Formação</label>
                                <UserTypeCombo
                                    showError={errors.user_type_id}
                                    value={fieldsValues.user_type_id}
                                    values={userTypes}
                                    selectionChanged={(newVal: number) => setFieldsValues({ ...fieldsValues, user_type_id: newVal })}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col">
                            <div className="form-check form-switch d-flex" style={{ alignItems: "center", paddingLeft: "2%" }}>
                                <input
                                    className="form-check-input"
                                    style={{ fontSize: "1.2em", marginLeft: "0%" }}
                                    type="checkbox"
                                    id="flexSwitchCheckChecked"
                                    checked={fieldsValues.active}
                                    onChange={event => setFieldsValues({ ...fieldsValues, active: event.target.checked })} />
                                <label style={{ marginLeft: "10px" }} className="form-check-label" htmlFor="flexSwitchCheckChecked">Utilizador Ativo</label>
                            </div>

                        </div>
                    </div>
                    <div className="row" style={{ marginTop: "20px" }}>
                        <div className="col text-center">
                            <ActionButton
                                pending={pending}
                                icon="fas fa-plus-square"
                                text={`${props.editing ? "Modificar" : "Adicionar"} Utilizador`} />
                        </div>
                    </div>
                </form>
            </div>
        </div>;

    return (
        <>
            <Modal
                title={`${props.editing ? "Editar" : "Novo"} Utilizador`}
                body={body}
                closeName="Fechar"
                closeHandler={props.closeHandler}
                bodyMaxHeight={500} />
        </>
    );
}
