import { Link } from "react-router-dom";
import Training from "../../interfaces/Training.interface";
import { quantifier } from "../../utils/generic";
import CourseTag from "../tags/CourseTag";
import OfficeTag from "../tags/OfficeTag";
import ExpirationInfo from "./ExpirationInfo";

export default function ExpiringTrainingsTable(props: { values: Training[] }) {
    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Aluno</th>
                    <th scope="col">Curso</th>
                    <th scope="col">Expira em</th>
                    <th scope="col">Extensão</th>
                </tr>
            </thead>
            <tbody>
                {props.values.length > 0 ?
                    props.values.map(row =>
                        <tr key={row.id}>
                            <th scope="row">
                                <Link to={`/alunos/${row.student_id}/formacoes`}>{row.student_name}</Link>
                                <div>
                                    {row.office_id && <OfficeTag office={row.office_id} />}
                                </div>
                            </th>
                            <td><CourseTag name={row.course} /></td>
                            <td style={{ fontSize: "0.95rem" }}>
                                <ExpirationInfo
                                    expirationDate={row.expirationDate}
                                    extension={row.monthExtension} />
                            </td>
                            <td>
                                {row.monthExtension > 0 && quantifier(row.monthExtension, "mês", "meses")}
                            </td>
                        </tr>
                    ) :
                    <tr>
                        <td colSpan={4} style={{ textAlign: "center" }}>
                            Não existem cursos a expirar para os filtros indicados
                        </td>
                    </tr>
                }
            </tbody>
        </table>
    );
}