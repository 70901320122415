import PaymentStatus from './PaymentStatus.model';

export default class Payment {
    public id: number;
    public description: string;
    public createDate: Date;
    public methodId: number;
    public method: string;
    public value: number;
    public expirationDate: Date;
    public statusId: number;
    public statusTypeId: number;
    public status: string;
    public deliveredCheque: boolean;
    public paymentStatus: PaymentStatus[];

    public customerId?: number | undefined;
    public customer?: string | undefined;
    public studentId?: number | undefined;
    public student?: string | undefined;
    public carerId?: number | undefined;
    public carer?: string | undefined;
    public officeId?: number | undefined;
    public office?: string | undefined;

    public observations?: string | undefined;

    constructor(data: any) {
        this.id = data.id;
        this.description = data.description;
        this.createDate = new Date(data.created_date);
        this.methodId = data.payment_method_id;
        this.method = data.payment_method;
        this.value = data.value;
        this.expirationDate = new Date(data.expiration_date);
        this.statusId = data.payment_status_id;
        this.statusTypeId = data.payment_status_type_id;
        this.status = data.payment_status;
        this.deliveredCheque = data.delivered_cheque;

        this.customerId = data.customer_id ?? undefined;
        this.customer = data.customer ?? undefined;
        this.studentId = data.student_id ?? undefined;
        this.student = data.student ?? undefined;
        this.carerId = data.carer_id ?? undefined;
        this.carer = data.carer ?? undefined;
        this.officeId = data.office_id ?? undefined;
        this.office = data.office ?? undefined;


        this.paymentStatus = data.status ? data.status.map((status: any) => new PaymentStatus(status)) : [];

        this.observations = data.status && data.status.length > 0 ? data.status[0].observations : undefined;;

    }
}