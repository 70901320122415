import CourseTag from '../components/tags/CourseTag';
import Product from "../interfaces/Product.interface";
import Course from "./Course.model";
import Discount from "./Discount.model";
import Student from "./Student.model";

export default class Pack implements Product {
    public id: number;
    public title: string;
    public code: string;
    public price: number;
    public time: number;
    public visible: boolean | undefined;
    public discountId: number;
    public discounts: Discount[];
    public courses: Course[];
    public expirationPeriod: number;

    public category: string;
    public status: string;
    public courseSubcategoryId: number | undefined;

    public student: Student;
    public productNumber: number;

    constructor(data: any) {
        this.id = data.id;
        this.title = data.title;
        this.code = data.code;
        this.price = data.price;
        this.time = data.time;
        this.visible = data.visible;
        this.discountId = data.discount_id;
        this.discounts = data.discounts?.map((disc: any) => new Discount(disc));
        this.expirationPeriod = data.expiration_period ?? 6;

        this.courses = data.courses?.map((course: any) => new Course(course));

        this.category = data.course_category ?? "";
        this.status = data.pack_status_type ?? "";
        this.courseSubcategoryId = data.course_category_id ?? 0;

        this.student = data.student ?? undefined;
        this.productNumber = 0;
    }

    clone(): Pack {
        let newPack = new Pack({});

        newPack.id = this.id;
        newPack.title = this.title;
        newPack.code = this.code;
        newPack.price = this.price;
        newPack.time = this.time;
        newPack.visible = this.visible;
        newPack.discountId = this.discountId;
        newPack.discounts = this.discounts;
        newPack.courses = this.courses;
        newPack.student = this.student;
        newPack.productNumber = this.productNumber;

        return newPack;
    }

    getProductPosition(): number {
        return this.productNumber;
    }

    hasStudent(): boolean {
        return this.student !== undefined;
    }

    getStudentName(): string {
        return this.student.name ?? undefined;
    }

    getDiscountValue(discount: Discount | undefined): number {
        if (!discount) {
            return 0;
        }

        return discount.percentage ?
            this.getPrice() * discount.percentage :
            this.getPrice() - discount.value;
    }

    getPriceAfterDiscount(discount: Discount | undefined): number {
        if (!discount) {
            return this.getPrice();
        }
        else {
            return discount.percentage ?
                this.getPrice() - this.getPrice() * discount.percentage :
                this.getPrice() - discount.value;
        }
    }

    getCoursesTagContainer(): JSX.Element {
        return (
            <div style={{ width: "inherit", display: "flex", flexWrap: "wrap" }}>
                {this.courses.map((course: Course) => <CourseTag key={course.getUniqueId()} name={course.getName()} title={course.getName()} />)}
            </div>
        );
    }


    getUniqueId(): string {
        return `pack${this.id}`;
    }

    getName(): string {
        return this.title;
    }

    getTime(): number {
        return this.time;
    }

    getPrice(): number {
        return this.price;
    }

    isVisible() {
        return this.visible === true || this.visible === null;
    }
}