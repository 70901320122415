import React, { CSSProperties } from "react";
import Customer from "../../models/Customer.model";
import Card from "../shared/Card";
import { CustomerDataTable } from "./CustomerDataTable";

type CustomerCardProps = {
    customer: Customer;
    title: string;
    style?: CSSProperties;
    showResponsible?: boolean;
}

export default function CustomerCompactCard(props: CustomerCardProps) {
    const { customer, title, style, showResponsible } = props;

    return (
        <Card
            keyId={customer.id.toString()}
            style={style || {}}
            title={title}
            includeHeader={true}
            headerStyle={{ fontSize: "1.1rem" }}
            body={<CustomerDataTable editMode={false} customer={customer} showResponsible={showResponsible || false} />}
            width={550} />
    );
}