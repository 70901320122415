

type CustomRadioButtonProps = {
    checked: boolean,
    inputId: string,
    displayText: string,
    inputName: string,
    disable: boolean,
    handleOnChange: (value: boolean) => void,
}

export default function CustomRadioButton(props: CustomRadioButtonProps) {

    return (
        <div className="form-check" style={{ marginLeft: "10px" }} >
            <input className="form-check-input" type="radio"
                onChange={(e) => {
                    props.handleOnChange(!props.checked);
                }}
                value=""
                defaultChecked={props.checked}
                id={props.inputId}
                name={props.inputName}
                disabled={props.disable} />
            <label className="form-check-label" htmlFor={props.inputId}>{props.displayText}</label>
        </div>
    );
}