type ConfirmCancelIconsProps = {
    confirmHandler: () => void;
    cancelHandler: () => void;
}

export default function ConfirmCancelIcons(props: ConfirmCancelIconsProps) {
    return (
        <>
            <i
                className="fas fa-check action-icon"
                style={{ margin: "5px 0 0 10px", color: "green" }}
                title="Guardar Alterações"
                onClick={() => props.confirmHandler()}></i>
            <i
                className="fas fa-times action-icon"
                style={{ margin: "5px 0 0 10px", color: "red" }}
                title="Cancelar"
                onClick={() => props.cancelHandler()}></i>
        </>
    )
}