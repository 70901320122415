import { useContext, useEffect, useState } from "react";
import Customer from "../../models/Customer.model";
import { api, NotFoundError } from "../../services/api";
import { MessagingContext } from "../App";
import CustomerCompactCard from "../customer/CustomerCompactCard";
import Loader from "../shared/Loader";

export default function StudentCustomer(props: { studentId: number, setStudentName: (name: string) => void }) {
    const messaging = useContext(MessagingContext);
    const [customer, setCustomer] = useState<Customer | undefined | null>();

    useEffect(() => {
        if (typeof customer === "undefined") {
            api.getStudentCustomer(props.studentId,
                customer => setCustomer(new Customer(customer)),
                err => {
                    if (err instanceof NotFoundError) {
                        setCustomer(null);
                    }
                    else {
                        messaging.modal.showMessage("Erro",
                            "Erro a carregar dados de faturação: " + err.message);
                    }
                });
        }
    }, [customer, messaging, props.studentId]);

    return (
        <div className="d-flex justify-content-center">
            { typeof customer === "undefined" ?
                <Loader /> :
                (customer === null ?
                    <button className="btn btn-primary">Novos dados de faturação</button> :
                    <div>
                        <CustomerCompactCard
                            style={{ marginTop: "15px" }}
                            title="Faturar a"
                            customer={customer} />
                    </div>)
            }
        </div>
    );
}