import { DateRangePicker, Range } from "react-date-range";
import Office from "../../interfaces/Office.interface";
import Course from "../../models/Course.model";
import { toDatePT } from "../../utils/dates";
import OfficeToggle from "../OfficeToggle";
import Modal from "./Modal";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../shared/FilterTable.styles.css';
import MultipleCourseCombo from "../combos/MultipleCourseCombo";

type ListFiltersProps = {
    currentCourse: Course[] | undefined,
    currentOffice: Office,
    calendarState: Range[] | undefined,
    currentDates: Date[] | undefined,
    officeChanged: (newOffice: Office) => void,
    courseAdded: (course: Course) => void;
    courseRemoved: (course: Course) => void;
    datePickerChanged: (item: any) => void,
    calendarCloseHandle: () => void,
    calendarOpenHandle: () => void,
    isCalendarOpen: boolean,
}

export default function ListFilters(props: ListFiltersProps) {
    const { currentDates } = props;

    return (
        <div className="d-flex filterContainer">
            <div style={{ width: "42%" }}>
                <span className="lateralMargin10">Filial:</span>
                <OfficeToggle officeChanged={props.officeChanged} />
            </div>
            <div className="d-flex lateralMargin30" style={{ flexWrap: "wrap" }}>
                <span style={{ marginRight: "5px" }}>Curso:</span>
                {
                    props.currentCourse ?

                        <MultipleCourseCombo
                            value={props.currentCourse}
                            courseAdded={props.courseAdded}
                            courseRemoved={props.courseRemoved}
                            defaultText="Curso para filtrar"
                            width={"270px"}
                            autoFetchCourses={true}
                        />
                        :
                        ""}
            </div>
            <div className="d-flex lateralMargin30">
                {props.isCalendarOpen ?
                    <Modal
                        title={"Intervalo de Datas"}
                        closeHandler={props.calendarCloseHandle}
                        dialogClasses={"medium"}
                        body={<DateRangePicker
                            editableDateInputs={true}
                            onChange={props.datePickerChanged}
                            moveRangeOnFirstSelection={false}
                            ranges={props.calendarState}
                        />}
                    />
                    : ""
                }
                <span style={{ marginRight: "10px" }}>De:</span>
                <input type="text"
                    onClick={props.calendarOpenHandle}
                    value={currentDates ? toDatePT(currentDates[0]) : ""}
                    name="start_date"
                    readOnly
                    id="start_date" />
                <span className="lateralMargin10">Até:</span>
                <input type="text"
                    onClick={props.calendarOpenHandle}
                    value={currentDates ? toDatePT(currentDates[1]) : ""}
                    name="end_date"
                    readOnly
                    id="end_date" />
            </div>
        </div>
    );
}