import { LOCAL_STORAGE_KEY } from '../constants';
import { statusCodes } from '../constants';
import FollowUp from '../interfaces/FollowUp.interface';

const CONTACTS_URI = "/contacts/contacts.php";
const PAYMENT_STATUS_TYPES_URI = "/payment_status_type/payment_status_type.php";
const PAYMENT_METHODS_URI = "/payment_methods/payment_methods.php";
const STUDENTS_URI = "/students/students.php";
const COURSES_URI = "/courses/courses.php";
const COURSES_CATEGORIES_URI = "/course_category/course_category.php";
const TRAININGS_URI = "/trainings/trainings.php";
const CUSTOMERS_URI = "/customers/customers.php";
const SURVEYS_URI = "/surveys/surveys.php";
const ENROLLMENTS_URI = "/enrollments/enrollments.php";
const MASS_ENROLLMENTS_URI = "/enrollments/mass_enrollments.php";
const PAYMENTS_URI = "/payments/payments.php";
const CITIES_URI = "/cities/cities.php";
const CARER_URI = "/carers/carers.php";
const TRAINING_STATUS_URI = "/training_status/training_status.php";
const EDUCATIONS_URI = "/educations/educations.php";
const EDUCATION_AREAS_URI = "/education_areas/education_areas.php";
const IDENTIFICATION_TYPES_URI = "/identification_types/identification_types.php";
const DIVULGATIONS_URI = "/divulgations/divulgations.php";
const OCCUPATIONS_URI = "/occupations/occupations.php";
const COUNTRIES_URI = "/countries/countries.php";
const PACKS_URI = "/packs/packs.php";
const FREE_SESSIONS_URI = "/free_sessions/free_sessions.php";
const USERS_URI = "/users/users.php";
const USER_TYPES_URI = "/users/user_types.php";
const TEACHER_QUALITIES_URI = "/qualities/qualities.php";
const CONTACTS_FOLLOWUP_URI = "/contacts/contacts_followup.php";
const STUDENT_COMBOBOXES_URI = "/students/comboboxes.php";
const CUSTOMER_COMBOBOXES_URI = "/customers/comboboxes.php";
const CARER_COMBOBOXES_URI = "/carers/comboboxes.php";
const ENROLLMENT_COMBOBOXES_URI = "/enrollments/comboboxes.php";
const TRAINING_STATUS_TYPES_URI = "/training_status_types/training_status_types.php";
const LOGIN_URI = "/login_ninjas.php";
const PURCHASES_URI = "/purchases/purchases.php";
const MULTISEARCH_URI = "/multi_search/multi_search.php";
const DISCOUNTS_URI = "/discounts/discounts.php";
const CERTIFICATES_URI = "/certificates/certificates.php";

/**
 * Cria um objeto FormData com todas as propriedades e valores do objeto passado como parametro
 * @param  {object} any Objeto a ser incluido no FormData
 */
function convertObjectToFormData(object: any) {
    let formData = new FormData();
    for (let key in object) {
        formData.append(key, object[key]);
    }
    return formData;
}

export function getStorageInfo() {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || "{}");
}

export class NotFoundError extends Error { }

/**
 * Builds a query string based on the keys and values of an object
 * @param {any} obj The object where the values come from
 * @returns {string} The query string with the "?" or "" if there are no params
 */
function getQueryString(obj: any): string {
    const queryParams = [];
    for (let field of Object.keys(obj)) {
        queryParams.push(field + "=" + obj[field]);
    }
    return queryParams.length > 0 ? "?" + queryParams.join("&") : "";
}

class API {
    apiBaseUrl: string | undefined;
    apiBaseUrlNinjas: string;

    constructor() {
        this.apiBaseUrl = process.env.REACT_APP_API_URL;
        this.apiBaseUrlNinjas = this.apiBaseUrl + "/ninjas";
    }

    /**
     * Performs a generic edit request setting the method as PATH and sending an
     * authentication token in the header
     * @param {object} bodyObject The object to be edited sent in the body as json
     * @param {string} endpoint The endpoint for the resource
     * @param {function} success The success callback function
     * @param {function} fail The fail callback function
     */
    editRequest(bodyObject: object, endpoint: string, success: (data: any) => void, fail: (err: Error) => void) {
        const fetchOptions = {
            method: "PATCH",
            headers: { 'authToken': getStorageInfo().token },
            body: JSON.stringify(bodyObject)
        };

        fetch(this.apiBaseUrlNinjas + endpoint, fetchOptions)
            .then(data => success(data))
            .catch(error => fail(error));
    }

    /**
     * Performs a generic delete request setting the method as DELETE and sending an
     * authentication token in the header
     * @param {string} endpoint The endpoint for the resource
     * @param {function} success The success callback function
     * @param {function} fail The fail callback function
     */
    deleteRequest(id: number, endpoint: string, success: (data: any) => void, fail: (err: Error) => void) {
        const fetchOptions = {
            method: "DELETE",
            headers: { 'authToken': getStorageInfo().token },
        };

        fetch(this.apiBaseUrlNinjas + endpoint + "?id=" + id, fetchOptions)
            .then(data => success(data))
            .catch(error => fail(error));
    }

    /**
     * Performs a generic add request setting the method as POST and sending an
     * authentication token in the header
     * @param {object} bodyObject The object to be created sent in the body as json
     * @param {string} endpoint The endpoint for the resource
     * @param {function} success The success callback function
     * @param {function} fail The fail callback function
     */
    addRequest(bodyObject: object, endpoint: string, success: (data: any) => void, fail: (err: Error) => void) {
        const fetchOptions = {
            method: "POST",
            headers: { 'authToken': getStorageInfo().token },
            body: JSON.stringify(bodyObject)
        };

        fetch(this.apiBaseUrlNinjas + endpoint, fetchOptions)
            .then(resp => {
                if (resp.status >= 200 && resp.status < 300) {
                    return resp.text();
                }
                else {
                    throw new Error(resp.statusText);
                }
            })
            .then(text => success(text))
            .catch(error => fail(error));
    }

    /**
     * Performs a generic add request setting the method as POST and sending an
     * authentication token in the header. The response MUST BE OF TYPE JSON
     * @param {object} bodyObject The object to be created sent in the body as json
     * @param {string} endpoint The endpoint for the resource
     * @param {function} success The success callback function
     * @param {function} fail The fail callback function
     */
    addJSONRequest(bodyObject: object, endpoint: string, success: (data: any) => void, fail: (err: Error) => void) {
        const fetchOptions = {
            method: "POST",
            headers: { 'authToken': getStorageInfo().token },
            body: JSON.stringify(bodyObject)
        };

        fetch(this.apiBaseUrlNinjas + endpoint, fetchOptions)
            .then(resp => resp.json())
            .then(data => success(data))
            .catch(error => fail(error));
    }

    /**
     * Performs a generic get request setting the method as GET and sending an
     * authentication token in the header
     * @param {object} paramsObj The query string params as an object where the key
     * matches the queryString and name and the value matches the queryString value
     * @param {string} endpoint The endpoint for the resource
     * @param {function} success The success callback function
     * @param {function} fail The fail callback function
     */
    getRequest(paramsObj: object, endpoint: string, success: (data: any) => void, fail: (err: Error) => void) {
        const fetchOptions = {
            method: "GET",
            headers: { 'authToken': getStorageInfo().token },
        };

        fetch(this.apiBaseUrlNinjas + endpoint + getQueryString(paramsObj), fetchOptions)
            .then(resp => {
                if (resp.status === statusCodes.NOT_FOUND) {
                    throw new NotFoundError();
                }
                return resp.json();
            })
            .then(data => success(data))
            .catch(error => fail(error));
    }

    login(user: string, pass: string, success: () => void, invalidLogin: () => void, fail: (err: Error) => void) {
        fetch(this.apiBaseUrl + LOGIN_URI, {
            method: 'POST',
            body: convertObjectToFormData({ login: user, password: pass })
        })
            .then(resp => {
                if (resp.status === statusCodes.UNAUTHORIZED) {
                    invalidLogin();
                    throw new Error("Invalid login");
                }
                else {
                    return resp.json();
                }
            })
            .then(data => {
                localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
                success();
            })
            .catch(error => {
                fail(error);
            });
    }

    getContacts(paramsObj: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest(paramsObj, CONTACTS_URI, success, fail);
    }

    getContactFollowUp(contactId: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({ contact_id: contactId }, CONTACTS_FOLLOWUP_URI, success, fail);
    }

    getStudents(paramsObj: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest(paramsObj, STUDENTS_URI, success, fail);
    }

    getMultiSearch(paramsObj: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest(paramsObj, MULTISEARCH_URI, success, fail);
    }

    getStudentInfo(studentId: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({ id: studentId }, STUDENTS_URI, success, fail);
    }

    getCourses(success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({}, COURSES_URI, success, fail);
    }

    addCourse(paramsObj: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.addRequest(paramsObj, COURSES_URI, success, fail);
    }

    addPack(paramsObj: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.addRequest(paramsObj, PACKS_URI, success, fail);
    }

    editCourse(paramsObj: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.editRequest(paramsObj, COURSES_URI, success, fail);
    }
  
    editPack(paramsObj: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.editRequest(paramsObj, PACKS_URI, success, fail);
    }

    deleteCourse(id: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.deleteRequest(id, COURSES_URI, success, fail);
    }

    deletePack(id: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.deleteRequest(id, PACKS_URI, success, fail);
    }

    getCoursesWithFilters(paramsObj: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest(paramsObj, COURSES_URI, success, fail);
    }

    getPacksWithFilters(paramsObj: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest(paramsObj, PACKS_URI, success, fail);
    }

    getStudentTrainings(studentId: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({ student_id: studentId, getStudentTrainings: 4 }, TRAININGS_URI, success, fail);
    }

    getStudentTrainingsFilters(paramsObj: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest(paramsObj, TRAININGS_URI, success, fail);
    }

    getStudentComboboxesInfo(success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({}, STUDENT_COMBOBOXES_URI, success, fail);
    }

    addContact(newContactInfo: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.addRequest(newContactInfo, CONTACTS_URI, success, fail);
    }
    addCarer(newCarerInfo: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.addRequest(newCarerInfo, CARER_URI, success, fail);
    }

    addStudent(newStudentInfo: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.addRequest(newStudentInfo, STUDENTS_URI, success, fail);
    }

    addFollowUp(newFollowUpInfo: FollowUp, success: (data: any) => void, fail: (err: Error) => void) {
        this.addJSONRequest(newFollowUpInfo, CONTACTS_FOLLOWUP_URI, success, fail);
    }

    editFollowUp(newFollowUpInfo: FollowUp, success: (data: any) => void, fail: (err: Error) => void) {
        this.editRequest(newFollowUpInfo, CONTACTS_FOLLOWUP_URI, success, fail);
    }

    editContact(editContactInfo: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.editRequest(editContactInfo, CONTACTS_URI, success, fail);
    }

    editStudent(editStudentInfo: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.editRequest(editStudentInfo, STUDENTS_URI, success, fail);
    }

    editCustomer(editCustomerInfo: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.editRequest(editCustomerInfo, CUSTOMERS_URI, success, fail);
    }

    editCarer(editCarerInfo: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.editRequest(editCarerInfo, CARER_URI, success, fail);
    }

    deleteContact(id: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.deleteRequest(id, CONTACTS_URI, success, fail);
    }

    deleteCarer(id: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.deleteRequest(id, CARER_URI, success, fail);
    }

    deleteFollowUp(id: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.deleteRequest(id, CONTACTS_FOLLOWUP_URI, success, fail);
    }

    deleteStudent(id: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.deleteRequest(id, STUDENTS_URI, success, fail);
    }

    deleteCustomer(id: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.deleteRequest(id, CUSTOMERS_URI, success, fail);
    }

    getCustomers(paramsObj: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest(paramsObj, CUSTOMERS_URI, success, fail);
    }

    getCustomer(id: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({ id: id }, CUSTOMERS_URI, success, fail);
    }

    addCustomer(newCustomerInfo: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.addRequest(newCustomerInfo, CUSTOMERS_URI, success, fail);
    }

    getCustomerComboboxesInfo(success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({}, CUSTOMER_COMBOBOXES_URI, success, fail);
    }

    getEnrollmentComboboxesInfo(success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({}, ENROLLMENT_COMBOBOXES_URI, success, fail);
    }

    getCarerComboboxesInfo(success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({}, CARER_COMBOBOXES_URI, success, fail);
    }

    getExpiringTrainings(paramsObj: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest(paramsObj, TRAININGS_URI, success, fail);
    }

    getExpiringPayments(paramsObj: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest(paramsObj, PAYMENTS_URI, success, fail);
    }

    getStudentCustomer(studentId: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({ student_id: studentId }, CUSTOMERS_URI, success, fail);
    }

    getLastStudentCustomer(studentId: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({ student_id: studentId, last: true }, CUSTOMERS_URI, success, fail);
    }

    getStudentCarer(studentId: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({ student_id: studentId, last: true }, CARER_URI, success, fail);
    }

    getCarer(carerId: any, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({ id: carerId }, CARER_URI, success, fail);
    }

    getCarerCustomers(carerId: any, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({ carer_id: carerId }, CUSTOMERS_URI, success, fail);
    }

    getCarers(paramsObj: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest(paramsObj, CARER_URI, success, fail);
    }

    getStudentSurveys(studentId: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({ student_id: studentId }, SURVEYS_URI, success, fail);
    }

    addEnrollment(newEnrollmentInfo: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.addRequest(newEnrollmentInfo, ENROLLMENTS_URI, success, fail);
    }

    addMassEnrollment(newEnrollmentInfo: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.addJSONRequest(newEnrollmentInfo, MASS_ENROLLMENTS_URI, success, fail);
    }

    getCities(success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({}, CITIES_URI, success, fail);
    }

    getCity(id: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({ id: id }, CITIES_URI, success, fail);
    }

    deleteCity(id: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.deleteRequest(id, CITIES_URI, success, fail);
    }

    addCity(newCityInfo: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.addRequest(newCityInfo, CITIES_URI, success, fail);
    }

    addSurvey(newSurveyInfo: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.addRequest(newSurveyInfo, SURVEYS_URI, success, fail);
    }

    editSurvey(editSurveyInfo: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.editRequest(editSurveyInfo, SURVEYS_URI, success, fail);
    }

    deleteSurvey(id: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.deleteRequest(id, SURVEYS_URI, success, fail);
    }

    editCity(editCityInfo: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.editRequest(editCityInfo, CITIES_URI, success, fail);
    }

    editPayments(editPaymentsInfo: object[], success: (data: any) => void, fail: (err: Error) => void) {
        this.editRequest(editPaymentsInfo, PAYMENTS_URI, success, fail);
    }

    getCitiesWithFilter(paramsObj: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest(paramsObj, CITIES_URI, success, fail);
    }

    getEducations(success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({}, EDUCATIONS_URI, success, fail);
    }

    addEducation(newEducationInfo: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.addRequest(newEducationInfo, EDUCATIONS_URI, success, fail);
    }

    editEducation(editEducationInfo: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.editRequest(editEducationInfo, EDUCATIONS_URI, success, fail);
    }

    deleteEducation(id: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.deleteRequest(id, EDUCATIONS_URI, success, fail);
    }

    getEducationAreas(success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({}, EDUCATION_AREAS_URI, success, fail);
    }

    addEducationArea(newEducationInfo: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.addRequest(newEducationInfo, EDUCATION_AREAS_URI, success, fail);
    }

    editEducationArea(editEducationInfo: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.editRequest(editEducationInfo, EDUCATION_AREAS_URI, success, fail);
    }

    deleteEducationArea(id: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.deleteRequest(id, EDUCATION_AREAS_URI, success, fail);
    }


    getDivulgations(success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({}, DIVULGATIONS_URI, success, fail);
    }

    getIdentificationTypes(success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({}, IDENTIFICATION_TYPES_URI, success, fail);
    };

    getPaymentStatusTypes(success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({}, PAYMENT_STATUS_TYPES_URI, success, fail);
    }

    getPaymentMethods(success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({}, PAYMENT_METHODS_URI, success, fail);
    }

    getPacks(success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({}, PACKS_URI, success, fail);
    };

    getOccupations(success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({}, OCCUPATIONS_URI, success, fail);
    };

    addOccupation(newOccupationInfo: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.addRequest(newOccupationInfo, OCCUPATIONS_URI, success, fail);
    }

    editOccupation(editOccupationInfo: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.editRequest(editOccupationInfo, OCCUPATIONS_URI, success, fail);
    }

    deleteOccupation(id: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.deleteRequest(id, OCCUPATIONS_URI, success, fail);
    }

    getCountries(success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({}, COUNTRIES_URI, success, fail);
    };

    getFreeSessions(paramsObj: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest(paramsObj, FREE_SESSIONS_URI, success, fail);
    };

    addFreeSession(newFreeSessionInfo: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.addRequest(newFreeSessionInfo, FREE_SESSIONS_URI, success, fail);
    }

    editFreeSession(editFreeSessionInfo: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.editRequest(editFreeSessionInfo, FREE_SESSIONS_URI, success, fail);
    }

    getTeachers(success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({ user_type: 1, active: 1 }, USERS_URI, success, fail);
    }

    addUser(paramsObj: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.addRequest(paramsObj, USERS_URI, success, fail);
    }

    editUser(paramsObj: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.editRequest(paramsObj, USERS_URI, success, fail);
    }

    deleteUser(id: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.deleteRequest(id, USERS_URI, success, fail);
    }

    getUsers(paramsObj: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest(paramsObj, USERS_URI, success, fail);
    }

    getUserTypes(success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({}, USER_TYPES_URI, success, fail);
    }

    getCourseCategories(success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({}, COURSES_CATEGORIES_URI, success, fail);
    }

    getTeacherQualities(success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({}, TEACHER_QUALITIES_URI, success, fail);
    }

    deleteFreeSession(id: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.deleteRequest(id, FREE_SESSIONS_URI, success, fail);
    }

    editTraining(editTrainingInfo: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.editRequest(editTrainingInfo, TRAININGS_URI, success, fail);
    }

    getTrainingStatusTypes(success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({}, TRAINING_STATUS_TYPES_URI, success, fail);
    }

    getTrainings(paramsObj: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest(paramsObj, TRAININGS_URI, success, fail);
    }

    getPurchases(paramsObj: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest(paramsObj, PURCHASES_URI, success, fail);
    };

    getCustomerPurchases(customerId: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({ customer_id: customerId }, PURCHASES_URI, success, fail);
    };

    getCarerPurchases(carerId: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest({ carer_id: carerId }, PURCHASES_URI, success, fail);
    };

    deleteTrainingStatus(id: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.deleteRequest(id, TRAINING_STATUS_URI, success, fail);
    }

    editPurchase(editPurchaseInfo: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.editRequest(editPurchaseInfo, PURCHASES_URI, success, fail);
    }

    deletePurchase(id: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.deleteRequest(id, PURCHASES_URI, success, fail);
    }

    getDiscounts(paramsObj: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest(paramsObj, DISCOUNTS_URI, success, fail);
    }

    addDiscount(paramsObj: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.addRequest(paramsObj, DISCOUNTS_URI, success, fail);
    }

    editDiscount(paramsObj: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.editRequest(paramsObj, DISCOUNTS_URI, success, fail);
    }

    deleteDiscount(id: number, success: (data: any) => void, fail: (err: Error) => void) {
        this.deleteRequest(id, DISCOUNTS_URI, success, fail);
    }
    
    getCertificates(paramsObj: object, success: (data: any) => void, fail: (err: Error) => void) {
        this.getRequest(paramsObj, CERTIFICATES_URI, success, fail);
    }


}

export const api = new API();