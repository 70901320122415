import { useContext, useEffect, useState } from 'react';
import Certificate from '../../interfaces/Certificate.interface';
import { api } from '../../services/api';
import { MessagingContext } from '../App';
import CertificateComponent from '../certificate/CertificateComponent';

type StudentCertificatesProps = {
    studentId: number,
    setStudentName: (name: string) => void,
}

export default function StudentCertificates(props: StudentCertificatesProps) {
    const messaging = useContext(MessagingContext);
    const [certificates, setCertificates] = useState<Certificate[]>([]);

    useEffect(() => {
        api.getCertificates({ student_id: props.studentId },
            (data: any) => {
                setCertificates(data);
            },
            (error: any) => {
                console.log(error);
                messaging.toast.show("Erro", "Erro a obter os certificados");
                setCertificates([]);
            })

    }, [messaging.toast, props.studentId]);

    return (
        <div className="d-flex justify-content-center">
            <div style={{ display: "flex", flexWrap: "wrap", marginTop: "10px", justifyContent: "center", width: "100%" }}>
                {certificates && (certificates.length === 0 ?
                    "Este aluno não possui certificados" :
                    certificates.map((certificate: Certificate) =>
                        <CertificateComponent
                            key={certificate.number}
                            certificate={certificate}
                        />
                    )
                )}
            </div>
        </div>

    );
}