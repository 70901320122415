import { CSSProperties } from "react";
import CaretToggle from "./CaretToggle";

interface CardProps {
    title: string | React.ReactElement;
    body: string | React.ReactElement;
    width?: number;
    includeHeader?: boolean;
    style?: CSSProperties;
    headerStyle?: CSSProperties;
    closeable?: boolean;
    hasCaret?: boolean;
    editable?: boolean;
    keyId: string;
    editHandler?: () => void;
    caretHandler?: () => void;
    closeHandler?: () => void;
}

export default function Card(props: CardProps) {
    const cardStyle = props.style || {};

    function handleClose() {
        if (props.closeHandler) {
            props.closeHandler();
        }
    }

    return (
        <div key={props.keyId} className="card" style={{ ...cardStyle, width: props.width || "300px" }}>

            {props.includeHeader &&
                <div className="card-header" style={props.headerStyle || {}}>
                    {props.closeable ?
                        <div>
                            {props.title}
                            <i
                                className="fas fa-times float-end"
                                style={{ cursor: "pointer" }}
                                onClick={handleClose}></i>
                        </div> : props.title}
                    {props.hasCaret ? <CaretToggle visible={props.hasCaret} onClick={props.caretHandler || (() => 1)} /> : ""}
                </div>
            }
            <div className="card-body">
                {!props.includeHeader && <h5 className="card-title">{props.title}</h5>}
                {
                    props.body
                }
            </div>
        </div>
    );
}