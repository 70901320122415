import { useState, useContext } from "react";
import { toDatePT } from "../../../utils/dates";
import Card from "../../shared/Card";
import { FreeSession } from '../../../models/FreeSession.model';
import styles from "../../shared/Card.module.css";
import './freesession.card.styles.css';
import ConfirmCancelIcons from "../../shared/ConfirmCancelIcons";
import CheckOrCrossIcon from "../../shared/CheckOrCrossIcon";
import CourseTag from "../../tags/CourseTag";
import Loader from "../../shared/Loader";
import { Link } from "react-router-dom";
import { MessagingContext } from '../../App';
import FreeSessionFinishForm from '../forms/FreeSessionFinishForm';

type FreeSessionCardProps = {
    value: FreeSession;
    style?: Object;
    deleteFreeSession?: (freeSessionId: number) => void;
    freeSessionChanged?: (freeSession: FreeSession) => void;
}


export default function FreeSessionCard(props: FreeSessionCardProps) {
    const freeSession = props.value;

    const [editMode, setEditMode] = useState(false);
    const [editing, setEditing] = useState(false);

    const messaging = useContext(MessagingContext);
    const [showFinishForm, setShowFinishForm] = useState(false);

    function handleRemoveContact(freeSession: FreeSession) {
        messaging.modal.showFull({
            title: "Remover Sessão Gratuita",
            body: `Tem a certeza que pretende remover a sessão gratuita de ${freeSession.name} ?`,
            closeName: "Cancelar",
            closeHandler: messaging.modal.close,
            actionName: "Eliminar",
            actionHandler: () => {
                messaging.modal.close();
                if (props.deleteFreeSession) {
                    props.deleteFreeSession(freeSession.id);
                }
            },
            actionClassName: "btn-danger",
            showAction: true
        });
    }


    return (
        <>
            {showFinishForm && props.freeSessionChanged && <FreeSessionFinishForm
                freeSessionChanged={props.freeSessionChanged}
                freeSession={freeSession}
                closeHandler={() => setShowFinishForm(false)} />}
            <Card
                keyId={freeSession.id.toString()}
                style={props.style || {}}
                title={
                    <div key={freeSession.id}>Sessão Gratuita - {toDatePT(freeSession.createdDate)}
                        {editMode ?
                            (editing ?
                                <Loader
                                    outerClassName=""
                                    outterStyle={{ display: "inline" }}
                                    style={{ marginLeft: "10px" }}
                                    size="small" /> :
                                <ConfirmCancelIcons
                                    confirmHandler={() => {
                                        setEditing(true)

                                    }}
                                    cancelHandler={() => setEditMode(false)} />
                            ) :
                            <i
                                className="fas fa-pen action-icon"
                                style={{ margin: "5px 0 0 10px" }}
                                title="Editar"
                                onClick={() => setEditMode(true)}></i>
                        }
                        <span
                            className="action-icon"
                            style={{ margin: "5px 0 0 10px" }}
                            onClick={() => handleRemoveContact(freeSession)}>
                            <i className="fas fa-trash" title="Eliminar"></i>
                        </span>
                    </div>
                }
                includeHeader={true}
                headerStyle={{ fontSize: "1.1rem" }}
                body={
                    <>
                        <table className="table table-striped">
                            <tbody>
                                <tr>
                                    <td className={styles.titleField}>Nome</td>
                                    <td className={styles.infoField}>
                                        {freeSession.studentId ?
                                            <Link to={`/alunos/${freeSession.studentId}/sessao-gratuitas`}>{freeSession.student}</Link>
                                            :
                                            freeSession.name
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.titleField}>Username</td>
                                    <td className={styles.infoField}>{freeSession.username}</td>
                                </tr>
                                <tr>
                                    <td className={styles.titleField}>Data da Sessão</td>
                                    <td className={styles.infoField}>{toDatePT(freeSession.sessionDate)}</td>
                                </tr>
                                {freeSession.sessionDate === freeSession.conclusionDate ? <tr>
                                    <td className={styles.titleField}>Data da Conclusão</td>
                                    <td className={styles.infoField}>{toDatePT(freeSession.conclusionDate)}</td>
                                </tr> : null}
                                <tr>
                                    <td className={styles.titleField}>Tipo de Formação</td>
                                    <td className={styles.infoField}>{freeSession.trainingType}</td>
                                </tr>
                                <tr>
                                    <td className={styles.titleField}>Administrador</td>
                                    <td className={styles.infoField}>{freeSession.userStart}</td>
                                </tr>
                                <tr>
                                    <td className={styles.titleField}>Formador</td>
                                    <td className={styles.infoField}>{freeSession.teacher}</td>
                                </tr>
                                {freeSession.conclusion ? <tr>
                                    <td className={styles.titleField}>Conclusão</td>
                                    <td className={styles.infoField}>{freeSession.conclusion}</td>
                                </tr> : null}
                                <tr>
                                    <td className={styles.titleField}>Curso</td>
                                    <td className={styles.infoField}><CourseTag name={freeSession.course} /></td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="d-flex" style={{ justifyContent: 'space-evenly' }}>
                            {(freeSession.interested !== undefined && freeSession.interested !== null) &&
                                <div className="flexSpacedAlignedContainer">
                                    <span>Interessado:</span>
                                    <CheckOrCrossIcon value={freeSession.interested} />
                                </div>}
                            {(freeSession.attended !== undefined && freeSession.attended !== null) &&
                                <div className="flexSpacedAlignedContainer">
                                    <span>Apareceu:</span>
                                    <i className="fas fa-check" style={{ color: "green" }}></i>
                                </div>}

                        </div>
                        <div className="d-flex" style={{ justifyContent: 'space-evenly', marginTop: "20px" }}>

                            {
                                freeSession.conclusion ? null :

                                    <button type="button"
                                        style={{ fontSize: "0.8rem" }}
                                        onClick={() => setShowFinishForm(true)}
                                        className="btn btn-outline-primary">
                                        Finalizar SG
                                    </button>}

                        </div>
                    </>
                }
                width={500} />
        </>
    );
}