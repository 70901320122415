import React, { useState, useRef, useEffect } from "react";
import { DAYS_PER_WEEK, offices } from "../../constants";
import Training from "../../interfaces/Training.interface";
import { api } from "../../services/api";
import { buildTraining } from "../../utils/objects-builders";
import GenericToggle from "../GenericToggle";
import OfficeToggle from "../OfficeToggle";
import Loader from "../shared/Loader";
import PaginationLinks from "../shared/PaginationLinks";
import ExpiringTrainingsTable from "../training/ExpiringTrainingsTable";

const expiringOptions = [
    { name: "3 meses", id: 0, search: { expiring_months: 3 } },
    { name: "2 meses", id: 1, search: { expiring_months: 2 } },
    { name: "1 mês", id: 2, search: { expiring_months: 1 } },
    { name: "3 semanas", id: 3, search: { expiring_days: 3 * DAYS_PER_WEEK } },
    { name: "2 semanas", id: 4, search: { expiring_days: 2 * DAYS_PER_WEEK } },
    { name: "1 semana", id: 5, search: { expiring_days: 1 * DAYS_PER_WEEK } }
];

export default function StudentsExpiringCourses() {
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const [trainings, setTrainings] = useState<Training[] | undefined>();
    const [currentOffice, setCurrentOffice] = useState(offices[0]);
    const [currentExpiringOption, setCurrentExpiringOption] = useState(2);

    const searchRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        const params = { office: currentOffice.value, page: currentPage, };
        Object.assign(params, expiringOptions[currentExpiringOption].search);
        api.getExpiringTrainings(params,
            newTrainings => {
                const sortedTrainings = newTrainings.data.map(buildTraining);
                sortedTrainings.sort((t1: Training, t2: Training) => {
                    if (t1.expirationDate && t2.expirationDate) {
                        const time1: number = t1.expirationDate.getTime();
                        const time2: number = t2.expirationDate.getTime();
                        return time1 - time2;
                    }
                    return 0;
                });

                setTrainings(sortedTrainings);
                setHasMorePages(newTrainings.morePages);
            },
            () => setTrainings([]));

        searchRef.current?.focus();
    }, [currentPage, currentOffice, currentExpiringOption]);

    const paginationLinksJSX = <PaginationLinks
        page={currentPage}
        hasMorePages={hasMorePages}
        pageChanged={newPage => setCurrentPage(newPage)}
        classes="justify-content-end" />;

    return (
        <div className="container">
            <h2 className="text-center m-4">Alunos com cursos a expirar</h2>

            <div style={{ display: "flex" }}>
                <div style={{ margin: "30px 0" }}>
                    Filial: <OfficeToggle officeChanged={newOffice => setCurrentOffice(newOffice)} />
                </div>

                <div style={{ margin: "30px 40px" }}>
                    Expira em: <GenericToggle
                        values={expiringOptions}
                        toggleChanged={newFilter => setCurrentExpiringOption(newFilter.id)}
                        getId={option => option.id.toString()}
                        getOptionContent={option => option.name}
                        defaultOptionIndex={currentExpiringOption}
                    />
                </div>
            </div>

            { trainings ?
                <>
                    {paginationLinksJSX}
                    <ExpiringTrainingsTable values={trainings} />
                    {paginationLinksJSX}
                </> :
                <Loader />
            }
        </div>
    );
}