import { CSSProperties } from "react";

export default function CourseTag(props: { style?: object, name: string, title?: string }) {
    let coursetagStyle: CSSProperties = {
        backgroundColor: "gray",
        padding: "5px",
        borderRadius: "4px",
        color: "white",
        fontSize: "13px",
        fontWeight: "bold",
        margin: "4px",
        whiteSpace: "nowrap"
    };

    if (props.style) {
        Object.assign(coursetagStyle, props.style);
    }

    return (
        <span title={props.title ? props.title : props.name} style={coursetagStyle}>{props.name}</span>
    )
}