import { UserTypes } from "../../types/UserTypes";

interface UserTypeComboProps {
    value: number | undefined;
    values: UserTypes[];
    selectionChanged: (newVal: number) => void;
    showError?: boolean;
}

export default function UserTypeCombo(props: UserTypeComboProps) {
    return (
        <>
            <select
                value={props.value}
                className="form-select"
                onChange={event => props.selectionChanged(parseInt(event.target.value))}>

                <option key={0} value={0}>Escolha uma opção</option>
                {
                    props.values.map((value: UserTypes) =>
                        <option key={value.id} value={value.id}>{value.title}</option>
                    )
                }
            </select>
            {props.showError &&
                <div className="invalid-feedback" style={{ display: "block" }}>
                    Precisa de escolher um tipo de utilizador
                </div>
            }
        </>
    );
}