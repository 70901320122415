import { useState, useEffect, useRef } from 'react';
import { offices } from '../../constants';
import Office from '../../interfaces/Office.interface';
import { api } from '../../services/api';
import Loader from '../shared/Loader';
import PaginationLinks from '../shared/PaginationLinks';
import Purchase from '../../models/Purchase.model';
import PurchasesTable from './PurchasesTable';
import ListFilters from '../shared/ListFilters';
import Course from '../../models/Course.model';
import { toInputDate } from '../../utils/dates';
import AtiveToggle, { AtiveToggleOptions } from '../AtiveToggle';
import ListTotalComponent from '../shared/ListTotalComponent';

export default function PurchasesList() {
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const [purchases, setPurchases] = useState<Purchase[]>();

    const [currentOffice, setCurrentOffice] = useState<Office>(offices[0]);
    const [currentCourses, setCurrentCourses] = useState<Course[]>([]);
    const [currentDates, setCurrentDates] = useState<Date[] | undefined>(undefined);
    const [isCalendarOpen, setCalendarOpen] = useState(false);
    const [purchasesPageTotal, setPurchasesPageTotal] = useState<number>(0);
    const [purchasesTotalValue, setPurchasesTotalValue] = useState<number>(0);
    const [currentActive, setCurrentActive] = useState(0);

    const [calendarState, setCalendarState] = useState([{
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    }]);

    const searchRef = useRef<HTMLInputElement | null>(null);

    function addCourse(course: Course) {
        if (currentCourses) {
            if (!currentCourses.find(c => c.id === course.id)) {
                setCurrentCourses([...currentCourses, course]);
            }
        }
    }

    function removeCourse(course: Course) {
        if (currentCourses) {
            setCurrentCourses(currentCourses.filter(c => c.id !== course.id));
        }
    }


    useEffect(() => {
        let sendParams: any = {
            office: currentOffice.value,
            page: currentPage
        }

        if (currentCourses.length !== 0) {
            sendParams.course = currentCourses.map((c: Course) => c.id).join(",");
        }

        if (currentActive !== 0) {
            sendParams.training_type = currentActive;
        }

        if (currentDates) {
            sendParams.start_date = toInputDate(currentDates[0]);
            if (currentDates[0].getTime() !== currentDates[1].getTime()) {
                sendParams.end_date = toInputDate(currentDates[1]);
            }
        }

        api.getPurchases(sendParams,
            (purchases: any) => {
                const newPurchases = purchases.data.map((data: any) => new Purchase(data));

                setHasMorePages(purchases.morePages);
                setPurchases(newPurchases);
                setPurchasesTotalValue(purchases.total);
                const total = newPurchases.reduce((total: number, curr: Purchase) => total + curr.value, 0);
                setPurchasesPageTotal(total);
            },
            () => setPurchases([])
        );

        searchRef.current?.focus();
    }, [currentPage, currentOffice, currentCourses, currentDates, currentActive]);

    const paginationLinksJSX =
        <PaginationLinks
            page={currentPage}
            pageChanged={newPage => setCurrentPage(newPage)}
            hasMorePages={hasMorePages}
            classes="justify-content-end" />



    const options: AtiveToggleOptions[] = [
        { id: 0, name: "Tudo" },
        { id: 1, name: "Presencial" },
        { id: 2, name: "Online" },
        { id: 3, name: "Bi-Learning" }
    ];

    return (
        <div className="list-container container" style={{ position: "relative" }}>
            <h2 className="text-center m-4">Inscrições</h2>


            <ListFilters
                currentCourse={currentCourses}
                currentOffice={currentOffice}
                calendarState={calendarState}
                currentDates={currentDates}
                officeChanged={newOffice => setCurrentOffice(newOffice)}
                courseAdded={addCourse}
                courseRemoved={removeCourse}
                datePickerChanged={(item: any) => {
                    setCalendarState([item.selection])
                    setCurrentDates([item.selection.startDate, item.selection.endDate])
                }}
                calendarCloseHandle={() => setCalendarOpen(false)}
                calendarOpenHandle={() => setCalendarOpen(true)}
                isCalendarOpen={isCalendarOpen}
            />

            <div>
                <span className="lateralMargin10">Visivel:</span>
                <AtiveToggle activeChanged={setCurrentActive} options={options}
                    activeOption={currentActive} />
            </div>

            {purchases ?
                <>
                    {paginationLinksJSX}
                    <PurchasesTable values={purchases} />
                    {paginationLinksJSX}

                    <ListTotalComponent
                        totalValue={purchasesTotalValue}
                        totalPerPage={purchasesPageTotal}
                        hidePageTotal={true}
                    />
                </> :
                <Loader />
            }
        </div>
    );
}