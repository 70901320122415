import moment from "moment";
import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { CUSTOMER_TYPE_STUDENT_ID, PaymentTypes } from "../../constants";
import City from "../../interfaces/City.interface";
import PaymentMethod from "../../interfaces/PaymentMethod.interface";
import Product from "../../interfaces/Product.interface";
import Course from "../../models/Course.model";
import Discount from "../../models/Discount.model";
import Pack from "../../models/Pack.model";
import { api, getStorageInfo } from "../../services/api";
import { Tax } from "../../types/Tax.type";
import { toInputDate, toISODate } from "../../utils/dates";
import { isEmpty, verifyErrors } from "../../utils/generic";
import { MessagingContext } from "../App";
import MultipleCourseCombo from "../combos/MultipleCourseCombo";
import MultiplePackCombo from "../combos/MultiplePackCombo";
import ContactForTrainingTypeCombo from "../contact/combos/ContactForTrainingTypeCombo";
import CustomerBasicFieldsForm from "../customer/CustomerBasicFieldsForm";
import { CustomerFields, emptyCustomerFields } from "../customer/CustomerForm";
import CustomerSelection from "../customer/CustomerSelection";
import { RedirectionContext } from "../MainRouter";
import CreatePaymentsTable from "../payment/CreatePaymentsTable";
import Card from "../shared/Card";
import GenericCombo from "../shared/GenericCombo";
import ProductsTable from "../enrollment/ProductsTable";

export type ProductsTotals = {
    time: number;
    price: number;
    discountPrice: number;
    total: number;
};

const emptyTotals = {
    time: 0,
    price: 0,
    discountPrice: 0,
    total: 0
};

export type PurchaseErrors = {
    name: boolean;
    address: boolean;
    tax: boolean;
    paymentMethod: boolean;
    paymentType: boolean;
    trainingType: boolean;
};

export type PaymentObject = {
    paymentDate: Date;
    amount: string;
}

const emptyErrors: PurchaseErrors = {
    name: false,
    address: false,
    tax: false,
    paymentMethod: false,
    paymentType: false,
    trainingType: false,
}

const purchaseErrorsDescriptions: any = {
    course: "Curso",
    username: "Nome de utilizador",
    teacher: "Formador",
    office: "Filial",
    trainingType: "Tipo de formação",
}


const availableNumberOfPayments = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export default function NewPurchaseCard(props: { studentId: number, close: () => void }) {
    const emptyFields = {
        ...emptyCustomerFields,
        customer_type: CUSTOMER_TYPE_STUDENT_ID,
        student: props.studentId
    };

    const messaging = useContext(MessagingContext);
    const redirection = useContext(RedirectionContext);
    const [selectedCourses, setSelectedCourses] = useState<Course[]>([]);
    const [courses, setCourses] = useState<Course[] | undefined>();
    const [customerFieldsValues, setCustomerFieldsValues] = useState<CustomerFields>(emptyFields);
    const [cities, setCities] = useState<City[] | undefined>();
    const [totals, setTotals] = useState<ProductsTotals>(emptyTotals);
    const [selectedPaymentType, setSelectedPaymentType] = useState(1);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<number | undefined>();
    const [numberOfPayments, setNumberOfPayments] = useState(1);
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[] | undefined>();
    const [packs, setPacks] = useState<Pack[] | undefined>();
    const [selectedPacks, setSelectedPacks] = useState<Pack[]>([]);
    const [discounts, setDiscounts] = useState<Discount[] | undefined>();
    const [paymentDates, setPaymentDates] = useState<Date[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [productsDiscounts, setProductsDiscounts] = useState<(Discount | undefined)[]>([]);
    const [observations, setObservations] = useState("");
    const [schedule, setSchedule] = useState("");
    const [expirationPeriod, setExpirationPeriod] = useState(6);
    const [taxes, setTaxes] = useState<Tax[] | undefined>();
    const [selectedTaxId, setSelectedTaxId] = useState<number | undefined>();
    const [trainingType, setTrainingType] = useState<number | undefined>();
    const [createdDate, setCreatedDate] = useState<Date>(new Date());
    const [errors, setErrors] = useState(emptyErrors);

    const [applyDiscountLastPayment, setApplyDiscountLastPayment] = useState<Boolean>(false);
    const [paymentsObject, setPaymentsObjects] = useState<PaymentObject[]>([]);

    const totalPayments: number = paymentsObject.reduce((acc: number, curr: PaymentObject) =>
        acc + parseFloat(curr.amount), 0
    );

    useEffect(() => {
        api.getEnrollmentComboboxesInfo(data => {
            setCities(data.cities);
            setCourses(data.courses.map((course: any) => new Course(course)));
            setPaymentMethods(data.payment_methods);
            setPacks(data.packs.map((pack: any) => new Pack(pack)));
            setTaxes(data.taxes);
            setSelectedTaxId(data.taxes[0].id);

            const discounts: Discount[] = data.discounts.map((disc: any) => new Discount(disc));
            setDiscounts(getApplicableDiscounts(discounts));
        }, err => messaging.modal.showMessage(
            "Erro", "Erro a obter cursos, packs, taxas e formas de pagamento: " + err.message));

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        updateTotals();
        // eslint-disable-next-line
    }, [selectedCourses, selectedPacks, productsDiscounts]);

    useEffect(() => {
        generateDatesToNumberOfPayments();
        // eslint-disable-next-line
    }, [numberOfPayments, productsDiscounts, applyDiscountLastPayment]);

    useEffect(() => {
        setErrors({
            name: isEmpty(customerFieldsValues.name),
            address: isEmpty(customerFieldsValues.address),
            tax: !selectedTaxId || selectedTaxId === 0,
            paymentMethod: !selectedPaymentMethod || selectedPaymentMethod === 0,
            paymentType: !selectedPaymentType || selectedPaymentType === 0,
            trainingType: !trainingType || trainingType === 0
        });
    }, [customerFieldsValues, selectedTaxId, selectedPaymentMethod, selectedPaymentType, trainingType]);

    function removeDiscountAtIndex(index: number) {
        const productsDiscountsCopy = [...productsDiscounts];
        productsDiscountsCopy.splice(index, 1);
        setProductsDiscounts(productsDiscountsCopy);
    }

    function generateDatesToNumberOfPayments() {

        const singlePaymentAmount = (applyDiscountLastPayment ? totals.price : totals.total) / numberOfPayments;;
        const lastPaymentWithDiscount = singlePaymentAmount - totals.discountPrice;

        const newArray: PaymentObject[] = [];
        const finalPaymentNumber: number = numberOfPayments;

        let lastDate = new Date();
        for (let i = 0; i < finalPaymentNumber; ++i) {
            const paymentDate: Date = moment(lastDate).add(1, 'months').toDate();
            const amount: number = applyDiscountLastPayment && i === numberOfPayments - 1 ?
                lastPaymentWithDiscount : singlePaymentAmount;

            newArray.push({ paymentDate: paymentDate, amount: amount.toFixed(3) });
            lastDate = paymentDate;
        }

        setPaymentsObjects(newArray);
    }

    function getApplicableDiscounts(discounts: Discount[]): Discount[] {
        const now = new Date();
        return discounts.filter(disc =>
            (disc.startDate === null || disc.startDate <= now) &&
            (disc.endDate === null || disc.endDate >= now)
        );
    }

    function changePaymentDate(newDay: string, pos: number) {
        const newDates = paymentDates.map(d => new Date(d.getTime()));
        newDates[pos] = new Date(newDay);
        setPaymentDates(newDates);
    }


    function changePaymentAmount(newAmount: string, pos: number) {

        const changedPayment: PaymentObject = paymentsObject[pos];
        const newObjects = [...paymentsObject];

        changedPayment.amount = newAmount;
        newObjects[pos] = changedPayment;

        setPaymentsObjects(newObjects);
    }

    function changeProductDiscount(newDiscount: Discount, pos: number, product: Product) {
        const newProductsDiscounts = productsDiscounts.map(
            disc => typeof disc === "undefined" ? undefined : disc.clone());

        newProductsDiscounts[pos] = newDiscount;
        setProductsDiscounts(newProductsDiscounts);
    }

    function updateTotals() {
        console.log("a");
        let newTotals = Object.assign({}, emptyTotals);
        for (let i = 0; i < products.length; i++) {
            const price = products[i].getPrice() || 0
            const discount = productsDiscounts[i];
            newTotals.time += products[i].getTime() || 0;
            newTotals.price += price;
            if (discount) {
                newTotals.discountPrice += parseFloat(discount.getDiscountValueForProduct(products[i]));
            }
        }

        newTotals.total += newTotals.price - newTotals.discountPrice;
        setTotals(newTotals);
    }

    function addCourse(course: Course) {
        if (!selectedCourses.find(c => c.id === course.id)) {
            setSelectedCourses([...selectedCourses, course]);
            setProducts([...products, course]);
            setProductsDiscounts([...productsDiscounts, undefined]);
        }
    }

    function removeCourse(course: Course) {
        setSelectedCourses(selectedCourses.filter(c => c.id !== course.id));
        const productIndex = products.findIndex(p => p.getUniqueId() === course.getUniqueId());
        setProducts(products.filter(p => p.getUniqueId() !== course.getUniqueId()));
        removeDiscountAtIndex(productIndex);
    }

    function addPack(pack: Pack) {
        if (!selectedPacks.find(p => p.id === pack.id)) {
            setSelectedPacks([...selectedPacks, pack]);
            setProducts([...products, pack]);
            const discount = pack.discounts && pack.discounts[0];
            setProductsDiscounts([...productsDiscounts, discount]);
        }
    }

    function removePack(pack: Pack) {
        setSelectedPacks(selectedPacks.filter(p => p.id !== pack.id));
        const packIndex = products.findIndex(p => p.getUniqueId() !== pack.getUniqueId());
        setProducts(products.filter(p => p.getUniqueId() !== pack.getUniqueId()));
        removeDiscountAtIndex(packIndex);
    }

    function handleSubmit(event: SyntheticEvent) {
        event.preventDefault();

        const errorFound = verifyErrors(errors as PurchaseErrors);
        if (errorFound) {
            messaging.toast.show("Erro", `Preencha o campo: ${purchaseErrorsDescriptions[errorFound]}`);
            return;
        }
        
        if (selectedPaymentType === 2 && totalPayments !== totals.total) {
            messaging.toast.show("Erro", `O valor final das prestações não bate certo com o valor final da compra. Confirme os valores!`);
            return;
        }

        const newEnrollmentInfo = {
            customer: {
                ...customerFieldsValues,
                student_id: props.studentId,
                office_id: customerFieldsValues.office,
                customer_type_id: customerFieldsValues.customer_type,
                city_id: customerFieldsValues.city
            },
            purchase: {
                courses: selectedCourses.map((course, pos) => {
                    const discount: Discount | undefined = productsDiscounts[pos];
                    let finalValue = course.getPriceAfterDiscount(discount);
                    if (discount) {
                        finalValue = discount.value;
                    }
                    return Object.assign(course, {
                        discount: productsDiscounts[pos],
                        final_value: finalValue
                    })
                })
                ,
                packs: selectedPacks.map((pack, pos) => Object.assign(
                    { ...pack, pack_id: pack.id }, {
                    discount: productsDiscounts[pos],
                    final_value: pack.getPriceAfterDiscount(productsDiscounts[pos])
                })),
                payments: paymentsObject.map((payment: PaymentObject, pos: number) =>
                ({
                    description:
                        paymentDates.length > 1 ?
                            `${pos + 1}º pagamento de ${paymentDates.length} prestações` :
                            `Pronto Pagamento.`,
                    payment_method_id: selectedPaymentMethod,
                    value: payment.amount,
                    expiration_date: toISODate(payment.paymentDate)
                })
                ),
                tax_id: selectedTaxId,
                observations: observations,
                value: totals.total
            },
            schedule: schedule,
            user_id: getStorageInfo().userId,
            training_type_id: trainingType,
            start_date: toISODate(createdDate),
            forecast_time: totals.time,
            expiration_period: expirationPeriod
        };

        api.addEnrollment(newEnrollmentInfo,
            data => redirection.redirectTo("/alunos/" + props.studentId + "/formacoes"),
            err => messaging.modal.showMessage("Erro", "Erro ao efetuar a inscrição: " + err.message));
    }

    function addDiscountToList(newDiscount: Discount) {
        if (discounts) {
            const newDiscountList = [...discounts, newDiscount];
            setDiscounts(newDiscountList.sort((a: Discount, b: Discount) => a.title.localeCompare(b.title)));
        }
    }

    function editDiscountToList(updatedDiscount: Discount, productPosition: number, product: Product) {
        if (discounts) {
            const newDiscounts = discounts.map((d: Discount) => d.id === updatedDiscount.id ? updatedDiscount : d);
            setDiscounts(newDiscounts.sort((a: Discount, b: Discount) => a.title.localeCompare(b.title)));
            changeProductDiscount(updatedDiscount, productPosition, product);
        }
    }

    return (
        <div className="d-flex justify-content-center">
            <Card
                keyId={props.studentId.toString()}
                title="Nova Inscrição"
                includeHeader={true}
                closeable={true}
                closeHandler={props.close}
                width={900}
                body={
                    <>
                        <div>
                            <h5 className="card-sub-title">Faturação</h5>
                            <CustomerSelection
                                studentId={props.studentId}
                                setCustomerFieldsValues={setCustomerFieldsValues} />
                        </div>
                        <form onSubmit={handleSubmit}>
                            <CustomerBasicFieldsForm
                                fieldsValues={customerFieldsValues}
                                setFieldsValues={setCustomerFieldsValues}
                                cities={cities}
                                showErrors={errors} />

                            <h5 className="card-sub-title" style={{ margin: "40px 0px 10px 0px" }}>Cursos</h5>
                            <div className="d-flex" >
                                <div>
                                    <div>Escolher cursos:</div>
                                    <MultipleCourseCombo
                                        value={selectedCourses}
                                        availableCourses={courses}
                                        autoFetchCourses={false}
                                        courseAdded={addCourse}
                                        courseRemoved={removeCourse}
                                        defaultText="Adicione um curso à inscrição" />
                                </div>
                                <div style={{ marginLeft: "30px" }}>
                                    <div>Escolher packs:</div>
                                    <MultiplePackCombo
                                        value={selectedPacks}
                                        availablePacks={packs}
                                        autoFetchPacks={false}
                                        packAdded={addPack}
                                        packRemoved={removePack}
                                        defaultText="Adicione um pack à inscrição" />
                                </div>
                                <div style={{ marginLeft: "30px" }}>
                                    <div>Taxa:</div>
                                    <GenericCombo
                                        value={selectedTaxId}
                                        values={taxes}
                                        getId={tax => tax.id}
                                        getOptionContent={tax => tax.title}
                                        selectionChanged={taxId => setSelectedTaxId(taxId as number)}
                                        showError={errors.tax} />
                                </div>
                            </div>
                            <div style={{ marginTop: "15px" }}>
                                <ProductsTable
                                    products={products}
                                    productsDiscounts={productsDiscounts}
                                    discounts={discounts}
                                    changeProductDiscount={changeProductDiscount}
                                    newDiscountAdded={addDiscountToList}
                                    newDiscountEdited={editDiscountToList}
                                    totals={totals} />
                            </div>
                            <h5 className="card-sub-title" style={{ margin: "40px 0px 10px 0px" }}>Pagamentos</h5>
                            <div className="row">
                                <div className="col m-2">
                                    <label>Forma de pagamento</label>
                                    <GenericCombo
                                        value={selectedPaymentMethod}
                                        values={paymentMethods}
                                        getId={paymentMethod => paymentMethod.id}
                                        getOptionContent={paymentMethod => paymentMethod.title}
                                        selectionChanged={id => setSelectedPaymentMethod(id as number)}
                                        showError={errors.paymentMethod} />
                                </div>
                                <div className="col m-2">
                                    <label>Tipo de pagamento</label>
                                    <GenericCombo
                                        value={selectedPaymentType}
                                        values={PaymentTypes}
                                        getId={paymentType => paymentType.id}
                                        getOptionContent={paymentType => paymentType.name}
                                        selectionChanged={id => setSelectedPaymentType(id as number)}
                                        showError={errors.paymentType} />
                                </div>
                                {selectedPaymentType === 2 &&
                                    <div className="col m-2">
                                        <div>
                                            <div>
                                                <label>Número de pagamentos</label>
                                                <GenericCombo
                                                    value={numberOfPayments}
                                                    values={availableNumberOfPayments}
                                                    getId={paymentType => paymentType}
                                                    getOptionContent={paymentType => paymentType}
                                                    selectionChanged={num => setNumberOfPayments(num as number)} />
                                            </div>
                                            <div>
                                                {
                                                    productsDiscounts.length > 0 &&
                                                    <>
                                                        <div>
                                                            <label>Desconto</label>
                                                        </div>
                                                        <div>

                                                            <input type="checkbox"
                                                                onClick={() => setApplyDiscountLastPayment(!applyDiscountLastPayment)}
                                                                className="btn-check"
                                                                id="btn-check-outlined" />
                                                            <label style={{ width: '100%' }} className="btn btn-outline-primary" htmlFor="btn-check-outlined">Aplicar na última Prestação</label>


                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            {selectedPaymentType === 2 &&

                                <>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <CreatePaymentsTable
                                                    dateObjects={paymentsObject}
                                                    dateChangedHandler={changePaymentDate}
                                                    amountChangedHandler={changePaymentAmount}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p>Total das Prestações: {totalPayments.toFixed(2)}€</p>
                                        </div>
                                    </div>
                                </>
                            }

                            <h5 className="card-sub-title" style={{ margin: "40px 0px 10px 0px" }}>Outras informações</h5>
                            <div className="row">
                                <div className="col m-2">
                                    <label>Tipo de formação</label>
                                    <ContactForTrainingTypeCombo
                                        value={trainingType}
                                        selectionChanged={newTrainingType => setTrainingType(newTrainingType)}
                                        showError={errors.trainingType} />
                                </div>
                                <div className="col m-2">
                                    <label htmlFor="obs">Data de inscrição:</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={toInputDate(createdDate)}
                                        onChange={event => setCreatedDate(new Date(event.target.value))}
                                        required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col m-2">
                                    <label htmlFor="obs">Horário:</label>
                                    <textarea
                                        className="form-control"
                                        id="obs"
                                        rows={2}
                                        value={schedule}
                                        onChange={event => setSchedule(event.target.value)}></textarea>
                                </div>
                                <div className="col m-2">
                                    <label htmlFor="expiration_period">Periodo de Validade:</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="expiration_period"
                                        value={expirationPeriod}
                                        onChange={event => setExpirationPeriod(parseInt(event.target.value))} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col m-2">
                                    <label htmlFor="obs">Observações:</label>
                                    <textarea
                                        className="form-control"
                                        id="obs"
                                        rows={2}
                                        value={observations}
                                        onChange={event => setObservations(event.target.value)}></textarea>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button
                                    className="btn btn-success mt-10"
                                    type="submit"
                                    style={{ padding: "5px 30px" }}>Inscrever</button>
                            </div>
                        </form>
                    </>
                } />
        </div>
    );
}