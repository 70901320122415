import { useContext } from "react";
import Occupation from "../../interfaces/Occupation";
import { MessagingContext } from "../App";
import styles from "./Occupations.module.css";
import { api } from "../../services/api";


type OccupationTableProp = {
    values: Occupation[];
    occupationRemoved: (id: number) => void;
    occupationEdited: (occupation: Occupation) => void;
}

export default function OccupationsTable(props: OccupationTableProp) {
    const { values, occupationRemoved, occupationEdited } = props;
    const messaging = useContext(MessagingContext);

    function handleRemoveOccupation(occupation: Occupation) {
        messaging.modal.showFull({
            title: "Remover ocupação",
            body: `Tem a certeza que pretende remover a ocupação ${occupation.title} ?`,
            closeName: "Cancelar",
            closeHandler: messaging.modal.close,
            actionName: "Eliminar",
            actionHandler: () => {
                messaging.modal.close();
                deleteOccupation(occupation.id);
            },
            actionClassName: "btn-danger",
            showAction: true
        });
    }

    function deleteOccupation(id: number) {
        api.deleteOccupation(id, (e: any) => {

            if (e.status !== 204) {
                throw new Error(e);
            }

            if (occupationRemoved) {
                occupationRemoved(id);
            }
            messaging.toast.show("Ocupação", "Ocupação removida");
        }, (err) => {
            console.log(err);
            messaging.modal.showMessage("Erro", "Erro a remover ocupação");
        });
    }

    return (
        <div className={styles.container}>
            {
                values.length > 0 ?
                    values.map((row: Occupation) =>
                        <span key={row.id} className={`${styles.tag} badge badge-secondary`}>{row.title}
                            <span className={`${styles.icon} action-icon`}
                                onClick={() => occupationEdited(row)}>
                                <i className="fas fa-pen" title="Editar"></i>
                            </span>
                            <span className={`${styles.icon} action-icon`}
                                onClick={() => handleRemoveOccupation(row)}>
                                <i className="fas fa-trash" title="Eliminar"></i>
                            </span>
                        </span>
                    )
                    :
                    "Não existem ocupações para a pesquisa efetuada"
            }
        </div >
    );
}