import '../../styles/ContactsTable.css';
import { useContext } from 'react';
import { toDatePT } from "../../utils/dates";
import { MessagingContext } from '../App';
import Discount from '../../models/Discount.model';
import CourseTag from '../tags/CourseTag';

type CourseTableProps = {
    values: Discount[],
    deleteDiscount: (id: number) => void;
    editDiscountClick: (id: number) => void;
}
export default function DiscountTable(props: CourseTableProps) {
    const messaging = useContext(MessagingContext);

    function handleRemoveDiscount(discount: Discount) {
        messaging.modal.showFull({
            title: "Eliminar desconto",
            body: `Tem a certeza que pretende remover o desconto ${discount.title} ?`,
            closeName: "Cancelar",
            closeHandler: messaging.modal.close,
            actionName: "Eliminar",
            actionHandler: () => {
                messaging.modal.close();
                props.deleteDiscount(discount.id);
            },
            actionClassName: "btn-danger",
            showAction: true
        });
    }

    return (
        <>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Titulo</th>
                        <th scope="col">Percentagem</th>
                        <th scope="col">Valor</th>
                        <th scope="col">Data de Inicio</th>
                        <th scope="col">Data de Fim</th>
                        <th scope="col">Cursos Associados</th>
                        <th scope="col">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {props.values.length > 0 ?
                        props.values.map((row: Discount) =>
                            <tr key={row.id}>
                                <td>{row.title}</td>
                                <td>{row.percentage * 100}%</td>
                                <td>{row.value}€</td>
                                <td>{row.startDate ? toDatePT(new Date(row.startDate)) : undefined}</td>
                                <td>{row.endDate && toDatePT(new Date(row.endDate))}</td>
                                <td >
                                    <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row" }}>

                                        {
                                            row.courses &&
                                            row.courses.map((course: any) => <CourseTag key={course.id} name={course.title} title={course.title} />)
                                        }
                                        {
                                            row.packs &&
                                            row.packs.map((pack: any) => <CourseTag key={pack.id} style={{ backgroundColor: "#9fc19b" }} name={pack.title} title={pack.title} />)
                                        }
                                    </div>
                                </td>
                                <td style={{ minWidth: "180px" }}>
                                    <>
                                        <div className="d-flex"
                                            style={{
                                                flexDirection: "column",
                                                alignContent: "stretch",
                                                justifyContent: 'space-evenly'
                                            }}>
                                            <button type="button"
                                                style={{ fontSize: "0.8rem", width: "100%", marginTop: "5px" }}
                                                onClick={() => props.editDiscountClick(row.id)}
                                                className="btn btn-outline-primary">
                                                Editar
                                            </button>
                                            <button type="button"
                                                style={{ fontSize: "0.8rem", width: "100%", marginTop: "5px" }}
                                                className="btn btn-outline-danger"
                                                onClick={() => handleRemoveDiscount(row)}>
                                                Remover
                                            </button>
                                        </div>
                                    </>
                                </td>
                            </tr>
                        ) : undefined}
                </tbody>
            </table>
        </>
    );
}