import React, { useState } from 'react';
import Messaging, { emptyMessaging } from '../interfaces/Messaging.interface';
import Printing, { emptyPrinting } from '../interfaces/Printing.interface';
import MainRouter from './MainRouter';
import Modal, { ModalProps } from './shared/Modal';
import Toast, { ToastProps } from './shared/Toast';

export const MessagingContext = React.createContext<Messaging>(emptyMessaging);
export const PrintingContext = React.createContext<Printing>(emptyPrinting);

export default function App() {
	const [toast, setToast] = useState<React.ReactElement | undefined>(undefined);
	const [modal, setModal] = useState<React.ReactElement | undefined>(undefined);
	const [printDocument, setPrintDococument] = useState<React.ReactElement | undefined>(undefined);

	const messaging: Messaging = {
		toast: {
			show: (title: string, body: string) =>
				setToast(<Toast title={title} body={body} removeHandler={() => setToast(undefined)} />),
			showFull: (toastProps: ToastProps) => setToast(<Toast {...toastProps} />),
			close: () => setToast(undefined)
		},
		modal: {
			showMessage: (title: string, body: string) =>
				setModal(<Modal title={title} body={body} closeHandler={() => setModal(undefined)} />),
			showFull: (modalProps: ModalProps) => setModal(<Modal {...modalProps} />),
			close: () => setModal(undefined)
		}
	};

	const printing: Printing = {
		showDocument: (doc: React.ReactElement) => setPrintDococument(doc),
		close: () => setPrintDococument(undefined)
	};



	return (
		<>
			{toast}
			{modal}
			<div>{printDocument}</div>
			{
				printDocument === undefined ?
					<MessagingContext.Provider value={messaging}>
						<PrintingContext.Provider value={printing}>
							<MainRouter />
						</PrintingContext.Provider>
					</MessagingContext.Provider> :
					undefined
			}
		</>
	);
}

