import { useEffect, useState } from "react";
import { api } from "../../services/api";
import CourseCategory from "../../interfaces/CourseCategory.interface";

interface CourseCategoryComboProps {
    value: number | undefined;
    selectionChanged: (newVal: number) => void;
    showError?: boolean;
    includeOverall: boolean;
}

export default function CourseCategoryCombo(props: CourseCategoryComboProps) {

    const { includeOverall, value, selectionChanged } = props;
    
    const [categories, setCategories] = useState<CourseCategory[] | undefined>(undefined);


    useEffect(() => {
        if (!categories) {
            api.getCourseCategories(
                data => {
                    if (includeOverall) {
                        data.unshift({ id: 0, title: "Tudo" })
                    }
                    setCategories(data)
                },
                err => console.log(err)
            );
        }
    }, [categories, includeOverall])


    return (
        <>
            <select
                value={value}
                className="form-select"
                onChange={event => selectionChanged(parseInt(event.target.value))}>
                {categories &&
                    categories.map((option: CourseCategory) =>
                        <option key={option.id} value={option.id}>{option.title}</option>
                    )
                }
            </select>
        </>
    );
}