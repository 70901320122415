import { useState, useEffect, useRef, useContext } from 'react';
import { MILLISECONDS_LIST_WAIT, offices } from '../../constants';
import Office from '../../interfaces/Office.interface';
import { api } from '../../services/api';
import Loader from '../shared/Loader';
import SearchForm from '../shared/SearchForm';
import UserTable from './UserTable';
import User from '../../models/User.model';
import OfficeToggle from '../OfficeToggle';
import AtiveToggle, { AtiveToggleOptions } from '../AtiveToggle';
import UserForm from './UserForm';
import { dealyForXSeconds } from '../../utils/generic';
import { MessagingContext } from '../App';

export default function UserList() {
    const messaging = useContext(MessagingContext)
    const [currentPage, setCurrentPage] = useState(1);
    const [currentSearch, setCurrentSearch] = useState("");
    const [users, setUsers] = useState<User[]>([]);

    const [currentOffice, setCurrentOffice] = useState<Office>(offices[0]);
    const [currentActive, setCurrentActive] = useState(2);

    const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
    const [editingUser, setEditingUser] = useState(false)
    const [newUserModalVisible, setNewUserModalVisible] = useState(false);

    const searchRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        let sendParams: any = {
            search: currentSearch,
            office: currentOffice.value,
            page: currentPage
        }

        if (currentActive !== 2) {
            sendParams.active = currentActive;
        }

        api.getUsers(sendParams,
            (newUsers: any) => {
                setUsers(newUsers.map((data: any) => new User(data)));
            },
            () => setUsers([])
        );

        searchRef.current?.focus();
    }, [currentPage, currentSearch, currentOffice, currentActive]);

    function handleInsertedUser(newUser: User) {
        const newUserList = [newUser, ...users];
        setUsers(newUserList);
        setNewUserModalVisible(false);
    }

    function handleModifiedUser(newUser: User) {
        const newUserList = [...users];

        const findIndex = newUserList.findIndex((user: User) => user.id === newUser.id);
        if (findIndex !== -1) {
            newUserList.splice(findIndex, 1, newUser);
            setUsers(newUserList);
            setNewUserModalVisible(false);
        }
    }

    function handleRemoveUser(userId: number) {
        if (users) {

            api.deleteUser(userId,
                (response) => {
                    if (response.status === 204) {
                        removeUserFromList(userId);
                        messaging.toast.show("Eliminado", "Utilizador eliminado!");
                    }
                },
                err => err
            )
            const userList = [...users];
            setUsers(userList.filter((user: User) => user.id !== userId));
        }
    }

    function removeUserFromList(userId: number) {
        setUsers(users.filter((user: User) => user.id !== userId));
    }

    const options: AtiveToggleOptions[] = [
        { id: 2, name: "Tudo" },
        { id: 1, name: "Ativo" },
        { id: 0, name: "Inativo" },
    ];

    return (
        <div className="list-container container" style={{ position: "relative" }}>

            {
                newUserModalVisible ?
                    <UserForm
                        editing={editingUser}
                        user={selectedUser}
                        userAdded={handleInsertedUser}
                        userEdited={handleModifiedUser}
                        closeHandler={() => setNewUserModalVisible(false)} />
                    : ""
            }

            <button
                className="btn btn-success new-list-button"
                onClick={() => {
                    setSelectedUser(undefined);
                    setEditingUser(false);
                    setNewUserModalVisible(true);
                }}>Novo Utilizador</button>

            <h2 className="text-center m-4">Utilizadores</h2>
            <SearchForm
                label="Pesquisar Utilizadores"
                onChangeHandler={event => {
                    dealyForXSeconds(MILLISECONDS_LIST_WAIT, () => {
                        setCurrentSearch(event.target.value);
                        setCurrentPage(1);
                    });
                }}
                style={{ marginBottom: "20px" }}
                searchRef={searchRef}
                searchHint="Pode pesquisar por nome" />

            <div className="d-flex filterContainer">
                <div style={{ width: "42%" }}>
                    <span className="lateralMargin10">Filial:</span>
                    <OfficeToggle officeChanged={setCurrentOffice} />
                </div>
                <div style={{ width: "42%" }}>
                    <span className="lateralMargin10">Ativo:</span>
                    <AtiveToggle activeChanged={setCurrentActive} options={options}
                        activeOption={currentActive} />
                </div>
            </div>

            {users ?
                <>
                    <UserTable
                        handleEditUser={(user: User) => {
                            setSelectedUser(user);
                            setEditingUser(true);
                            setNewUserModalVisible(true);
                        }}
                        handleDeleteUser={handleRemoveUser}
                        values={users} />
                </> :
                <Loader />
            }
        </div>
    );
}