import { useEffect, useState } from "react";
import Training from "../../models/Training.model";
import { api } from "../../services/api";
import Modal from "../shared/Modal";
import TrainingSelectableTable from "../training/TrainingSelectableTable";

type SurveyTrainingsSelectionModalProps = {
    closeHandler: () => void;
    selectHandler: (trainings: Training[]) => void;
    studentId: number;
}

export default function SurveyTrainingsSelectionModal(props: SurveyTrainingsSelectionModalProps) {


    const [trainings, setTrainings] = useState<Training[]>([]);
    const [chosenTrainings, setChosenTrainings] = useState<Training[]>([]);

    useEffect(() => {
        const searchParams: any = { student_id: props.studentId};

        api.getStudentTrainingsFilters(searchParams,
            trainings => {
                setTrainings(trainings.map((t: Training) => new Training(t)));
            },
            () => setTrainings([]));


    }, [props.studentId]);


    function finishModalData() {

        if (chosenTrainings.length > 0) {
            props.selectHandler(chosenTrainings);
        }
    }




    const body =
        <div>
            <div style={{ backgroundColor: "#efefef", padding: "20px 0" }}>
                <div className="container" style={{ position: "relative" }}>
                    <TrainingSelectableTable values={trainings} selectHandler={(t: Training) => setChosenTrainings([...chosenTrainings, t])} />
                </div>
            </div>
        </div>;

    return (
        <>
            <Modal
                title={"Escolha as formações a associar ao Questionário"}
                body={body}
                closeName="Fechar"
                closeHandler={props.closeHandler}
                showAction={true}
                actionName="Selecionar"
                actionHandler={finishModalData}
                dialogClasses={"medium"}
                style={{}}
                bodyMaxHeight={500} />
        </>
    );
}