import { useState, useEffect, useRef } from 'react';
import City from '../../interfaces/City.interface';
import { api } from '../../services/api';
import CitiesTable from './CitiesTable';
import Loader from '../shared/Loader';
import PaginationLinks from '../shared/PaginationLinks';
import SearchForm from '../shared/SearchForm';
import CityForm from './CityForm';

export default function CitiesList() {
    const [cities, setCities] = useState<City[] | undefined>();

    const searchRef = useRef<HTMLInputElement | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const [currentSearch, setCurrentSearch] = useState("");
    const [showInsertModal, setShowInsertModal] = useState(false);

    const [editing, setEditing] = useState(false);
    const [selectedCity, setSelectedCity] = useState<City | undefined>(undefined);

    useEffect(() => {
        api.getCitiesWithFilter(
            {
                search: currentSearch,
                page: currentPage
            },
            newCities => {
                setCities(newCities.data);
                setHasMorePages(newCities.morePages);
                console.log(newCities);
            },
            () => setCities([])
        );

        searchRef.current?.focus();
    }, [currentPage, currentSearch]);


    function handleCityRemoved(cityId: number) {
        if (cities) {
            setCities(cities.filter(city => city.id !== cityId));
        }
    }

    function sortCities(citiesList: City[]) {
        return citiesList && citiesList.sort((a: City, b: City) => a.title.localeCompare(b.title))
    }

    function addCityToList(newCourse: City) {
        if (cities) {
            const newCitiesList = [...cities, newCourse];
            const sortedCities = sortCities(newCitiesList);
            setCities(sortedCities);
            setShowInsertModal(false);
        }
    }
    function replaceCityInList(updatedCity: City) {
        if (cities) {
            const newCities = cities.map((c: City) => c.id === updatedCity.id ? updatedCity : c);
            const sortedCities = sortCities(newCities);
            setCities(sortedCities);
            setShowInsertModal(false);
            setEditing(false);
            setSelectedCity(undefined);
        }
    }

    const paginationLinksJSX =
        <PaginationLinks
            page={currentPage}
            pageChanged={newPage => setCurrentPage(newPage)}
            hasMorePages={hasMorePages}
            classes="justify-content-end" />

    return (
        <>
            {
                showInsertModal &&
                <CityForm
                    closeHandler={() => setShowInsertModal(false)}
                    city={selectedCity}
                    cityAdded={addCityToList}
                    cityEdited={replaceCityInList}
                    editing={editing}
                />
            }

            <div className="container" style={{ position: "relative" }}>
                <button
                    className="btn btn-success new-list-button"
                    onClick={() => {
                        setSelectedCity(undefined);
                        setShowInsertModal(true);
                    }
                    }>Nova Localidade</button>
                <h2 className="text-center m-4">Localidades</h2>

                <SearchForm
                    label="Pesquisar Localidades"
                    onChangeHandler={event => {
                        setCurrentSearch(event.target.value);
                        setCurrentPage(1);
                    }}
                    style={{ marginBottom: "20px" }}
                    searchRef={searchRef} />

                {cities ?
                    <>
                        {paginationLinksJSX}
                        <CitiesTable
                            values={cities}
                            cityRemoved={handleCityRemoved}
                            cityEdited={(city: City) => {
                                setSelectedCity(city);
                                setEditing(true);
                                setShowInsertModal(true);
                            }}
                        />
                        {paginationLinksJSX}
                    </>
                    :
                    <Loader />
                }
            </div>
        </>
    );
}