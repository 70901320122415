interface PaginationLinksProps {
    pageChanged: (offSet: number) => void;
    page: number;
    classes?: string;
    hasMorePages: boolean;
}

export default function PaginationLinks(props: PaginationLinksProps) {
    const cursorPointer = { cursor: 'pointer' };

    function handlePageChanged(diff: number) {
        props.pageChanged(props.page + diff);
    }

    return (
        <nav>
            <ul className={"pagination " + (props.classes || "")}>
                <li className={`page-item ${props.page === 1 ? "disabled" : ""}`} style={cursorPointer}>
                    <div className="page-link" aria-label="Previous" onClick={() => handlePageChanged(-1)}>
                        <span aria-hidden="true">&laquo;</span>
                    </div>
                </li>
                {props.page > 1 &&
                    <li className="page-item" style={cursorPointer}>
                        <span className="page-link" onClick={() => handlePageChanged(-1)}>{props.page - 1}</span>
                    </li>}
                <li className="page-item active" style={cursorPointer}>
                    <span className="page-link">{props.page}</span>
                </li>

                {props.hasMorePages &&
                    <li className="page-item" style={cursorPointer}>
                        <span className="page-link" onClick={() => handlePageChanged(+1)}>{props.page + 1}</span>
                    </li>
                }
                {props.hasMorePages &&
                    <li className="page-item" style={cursorPointer}>
                        <div className="page-link" aria-label="Next" onClick={() => handlePageChanged(+1)}>
                            <span aria-hidden="true">&raquo;</span>
                        </div>
                    </li>
                }
            </ul>
        </nav>
    );
}