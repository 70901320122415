import { useContext, useEffect, useState } from "react";
import Purchase from "../../models/Purchase.model";
import Carer from "../../models/Carer.model";
import Customer from "../../models/Customer.model";
import { api } from "../../services/api";
import { MessagingContext } from "../App";
import NewMassPurchaseCard from "../purchase/NewMassPurchaseCard";
import CustomerPurchasesGrid from "../customer/CustomerPurchasesGrid";
import Loader from "../shared/Loader";
import CustomerModal from "../customer/CustomerModal";

export default function CarerPurchases(props: { carerId: number, setCarerName: (name: string) => void }) {
    const { carerId, setCarerName } = props;

    const messaging = useContext(MessagingContext);
    const [addingNew, setAddingNew] = useState(false);
    const [loading, setLoading] = useState(false);
    const [purchases, setPurchases] = useState<Purchase[] | undefined>();
    const [carer, setCarer] = useState<Carer>();
    const [selectedCarerCustomer, setSelectedCarerCustomer] = useState<Customer>();
    const [carerCustomers, setCarerCustomers] = useState<Customer[]>([]);



    useEffect(() => {
        api.getCarer(carerId,
            carerFetched => {
                setCarer(new Carer(carerFetched));
                setCarerName(carerFetched.name);
            }, err => messaging.modal.showMessage("Erro", "Erro ao obter o encarregado de educação:" + err.message));

        api.getCarerCustomers(carerId,
            customersFetched => {
                console.log(customersFetched);
                if (customersFetched.length === 1) {
                    setSelectedCarerCustomer(new Customer(customersFetched[0]));
                }
                setCarerCustomers(customersFetched.map((customer: Customer) => new Customer(customer)));
                // setCarerName(carerFetched.name);
            }, err => {
                setSelectedCarerCustomer(undefined);
                setCarerCustomers([]);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carerId, setCarerName]);

    useEffect(() => {
        setLoading(true);
        api.getCarerPurchases(carerId,
            (resp: any) => {
                const purchases = resp.map((val: any) => new Purchase(val));
                purchases.sort((p1: Purchase, p2: Purchase) => p2.startDate.getTime() - p1.startDate.getTime());
                setPurchases(purchases);
                setLoading(false);
            }, err => {
                setLoading(false);
                messaging.modal.showMessage("Erro",
                    "Erro ao carregar inscrições do encarregado de educação: " + err.message)
            });
        //eslint-disable-next-line
    }, [carerId, setCarerName]);

    function removePurchaseHandler(purchaseId: number) {
        if (purchases) {
            const purchasesClone = [...purchases];
            purchasesClone.filter((purchase: Purchase) => purchase.id !== purchaseId);
            setPurchases(purchasesClone);
        }
    }

    return (
        <>
            {addingNew && !selectedCarerCustomer && carerCustomers && carerCustomers.length > 1 &&
                <CustomerModal
                    title="Foram encontrados multiplos 'clientes', escolha qual pretende usar:"
                    closeHandler={() => setAddingNew(false)}
                    selectHandler={(selectedCustomer: Customer) => {
                        setSelectedCarerCustomer(selectedCustomer);
                    }}
                    values={carerCustomers}
                />
            }
            {
                loading ?
                    <Loader /> :
                    <div>
                        <button
                            className="btn btn-success"
                            onClick={() => setAddingNew(true)}
                            style={{ marginBottom: "20px" }}>Nova Inscrição</button>

                        {addingNew && carer &&
                            <NewMassPurchaseCard
                                carer={carer}
                                customer={selectedCarerCustomer}
                                close={() => setAddingNew(false)} />
                        }
                        {!purchases ?
                            <Loader />
                            :
                            purchases.length > 0 ?
                                <CustomerPurchasesGrid
                                    key={carer?.id}
                                    removePurchaseHandler={removePurchaseHandler}
                                    purchases={purchases} />
                                :
                                <div className="container d-flex justify-content-center">
                                    Não existem inscrições para este encarregado de educação
                                </div>
                        }


                    </div>
            }
        </>
    );

}