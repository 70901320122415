import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { FreeSession } from "../../models/FreeSession.model";
import { toDateTimePT } from "../../utils/dates";
import { MessagingContext } from "../App";
import CheckOrCrossIcon from "../shared/CheckOrCrossIcon";
import CourseTag from "../tags/CourseTag";
import Loader from "../shared/Loader";
import OfficeTag from "../tags/OfficeTag";
import FreeSessionFinishForm from "./forms/FreeSessionFinishForm";

type FreeSessionTableProps = {
    values: FreeSession[];
    page?: number;
    freeSessionRemoved?: (id: number) => void;
    freeSessionChanged?: (freeSession: FreeSession) => void;
    selectHandler?: (freeSession: FreeSession) => void;
}

export default function FreeSessionsTable(props: FreeSessionTableProps) {
    const { values, selectHandler } = props;

    const messaging = useContext(MessagingContext);
    const [processingFreeSessionId, setProcessingFreeSessionId] = useState(0);
    const [selectedFreeSession, setSelectedFreeSession] = useState<FreeSession | undefined>();
    const [showFinishForm, setShowFinishForm] = useState(false);

    function handleRemoveFreeSession(freeSession: FreeSession) {
        messaging.modal.showFull({
            title: "Remover sessão gratuita",
            body: `Tem a certeza que pretende remover a sessão gratuita de ${freeSession.name} ?`,
            closeName: "Cancelar",
            closeHandler: messaging.modal.close,
            actionName: "Eliminar",
            actionHandler: () => {
                messaging.modal.close();
                deleteFreeSession(freeSession.id);
            },
            actionClassName: "btn-danger",
            showAction: true
        });
    }

    function deleteFreeSession(id: number) {
        setProcessingFreeSessionId(id);
        if (props.freeSessionRemoved) {
            props.freeSessionRemoved(id);
        }
    }

    function handleFinishFreeSession(freeSession: FreeSession) {
        setSelectedFreeSession(freeSession);
        setShowFinishForm(true);
    }

    return (
        <>
            {showFinishForm && selectedFreeSession && props.freeSessionChanged && <FreeSessionFinishForm
                freeSessionChanged={props.freeSessionChanged}
                freeSession={selectedFreeSession}
                closeHandler={() => setShowFinishForm(false)} />}

            <table className="table table-striped" style={{ width: "95%", margin: "auto" }}>
                <thead>
                    <tr>
                        <th scope="col">Nome</th>
                        <th scope="col">Utilizador</th>
                        <th scope="col">Curso</th>
                        <th scope="col">Data</th>
                        <th scope="col">Formador</th>
                        <th scope="col">Interesse</th>
                        <th scope="col">Concluida</th>
                        <th scope="col">Conclusão</th>
                        <th scope="col">Formação</th>
                        <th scope="col">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {values.length > 0 ?
                        values.map(row =>
                            <tr key={row.id}>
                                <td>
                                    <div>
                                        {row.studentId ?
                                            <Link to={`/alunos/${row.studentId}/sessao-gratuitas`}>{row.student}</Link>
                                            :
                                            row.name
                                        }</div>
                                    <div><OfficeTag office={row.officeId} /></div>
                                </td>
                                <td>{row.username}</td>
                                <td><CourseTag name={row.course} /></td>
                                <td style={{ fontSize: "0.95rem" }}>{toDateTimePT(row.sessionDate)}</td>
                                <td>{row.teacher}</td>
                                <td style={{ textAlign: "center" }}>
                                    {(row.interested !== undefined && row.interested !== null) &&
                                        <CheckOrCrossIcon value={row.interested} />}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                    {row.attended && <i className="fas fa-check" style={{ color: "green" }}></i>}
                                </td>
                                <td>
                                    {row.conclusionDate &&
                                        <div style={{ fontSize: "0.95rem" }}>
                                            {toDateTimePT(row.conclusionDate)}
                                        </div>}
                                    <div>{row.conclusion}</div>
                                </td>
                                <td>{row.trainingType}</td>

                                {selectHandler ?
                                    <td>
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => selectHandler(row)}>Escolher</button>
                                    </td>
                                    :
                                    <td style={{ minWidth: "180px" }}>
                                        {processingFreeSessionId === row.id ?
                                            <Loader style={{ width: "1.75rem", height: "1.75rem", color: "gray" }} /> :
                                            <>
                                                <div className="d-flex"
                                                    style={{
                                                        flexDirection: "column",
                                                        alignContent: "stretch",
                                                        justifyContent: 'space-evenly'
                                                    }}>
                                                    <Link
                                                        className="action-icon"
                                                        to={`/sessoes-gratuitas/${row.id}/editar`}>
                                                        <button type="button"
                                                            style={{ fontSize: "0.8rem", width: "100%", marginTop: "5px" }}
                                                            className="btn btn-outline-primary">
                                                            Editar
                                                        </button>
                                                    </Link>
                                                    <button type="button"
                                                        style={{ fontSize: "0.8rem", width: "100%", marginTop: "5px" }}
                                                        className="btn btn-outline-danger"
                                                        onClick={() => handleRemoveFreeSession(row)}>
                                                        Remover
                                                    </button>

                                                    {
                                                        row.conclusionDate ? "" :

                                                            <button type="button"
                                                                style={{ fontSize: "0.8rem", width: "100%", marginTop: "5px" }}
                                                                onClick={() => handleFinishFreeSession(row)}
                                                                className="btn btn-outline-success">
                                                                Finalizar SG
                                                            </button>}

                                                    {row.studentId ? "" :
                                                        <>
                                                            <Link
                                                                className="action-icon"
                                                                to={`/alunos/novo?sessao-gratuita=${row.id}`}>
                                                                <button type="button"
                                                                    style={{ fontSize: "0.8rem", width: "100%", marginTop: "5px" }}
                                                                    className="btn btn-outline-primary">
                                                                    Inscrever
                                                                </button>
                                                            </Link>
                                                        </>
                                                    }
                                                </div>

                                            </>
                                        }
                                    </td>
                                }
                            </tr>
                        ) :
                        <tr>
                            <td colSpan={9} style={{ textAlign: "center" }}>
                                {"Não existem sessões gratuitas para a pesquisa efetuada"}
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </>
    );
}