import { useState } from 'react';
import { offices } from '../constants';
import Office from "../interfaces/Office.interface";

export default function OfficeToggle(props: { officeChanged: (newOffice: Office) => void }) {
    const [activeOffice, setActiveOffice] = useState(0);

    return (
        <div className="btn-group btn-group-toggle" data-toggle="buttons">
            {offices.map((office, pos) =>
                <label
                    key={pos}
                    className={`btn btn-primary ${pos === activeOffice ? "active" : ""}`}>
                    <input
                        type="radio"
                        name="options"
                        id={office.value}
                        autoComplete="off"
                        onClick={() => {
                            setActiveOffice(pos);
                            props.officeChanged(office);
                        }}
                        style={{ display: "none" }} />{office.name}
                </label>
            )}
        </div>
    );
}