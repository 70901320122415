import React, { useEffect, useState, useContext } from 'react';
import Customer from "../../models/Customer.model";
import { api } from "../../services/api";
import { MessagingContext } from "../App";
import Loader from "../shared/Loader";
import CustomerCard from "./CustomerCard";

export default function CustomerInfo(props: { customerId: number, setCustomerName: (name: string) => void }) {
    const { customerId, setCustomerName } = props;
    const [customer, setCustomer] = useState<Customer>();
    const messaging = useContext(MessagingContext);

    useEffect(() => {
        api.getCustomer(customerId,
            customerFetched => {
                setCustomer(new Customer(customerFetched));
                setCustomerName(customerFetched.name);
            }, err => messaging.modal.showMessage("Erro", "Erro ao obter o cliente:" + err.message));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId, setCustomerName]);

    function handleChange(newCustomer: Customer) {
        if (newCustomer) {
            setCustomer(newCustomer)
        }
    }

    return (
        <div className="d-flex justify-content-center">
            {typeof customer === "undefined" ?
                <Loader /> :

                <div>
                    <CustomerCard
                        style={{ marginTop: "15px" }}
                        customer={customer}
                        handleChange={handleChange} />
                </div>
            }
        </div>
    );
}