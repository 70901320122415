import { useContext, useState } from "react";
import Product from "../../interfaces/Product.interface";
import Course from "../../models/Course.model";
import Student from "../../models/Student.model";
import Discount from "../../models/Discount.model";
import Pack from "../../models/Pack.model";
import { MessagingContext } from "../App";
import GenericCombo from "../shared/GenericCombo";
import { ProductsTotals } from "../purchase/NewPurchaseCard";
import StudentModal from '../student/StudentModal';
import NewStudentCard from '../student/cards/NewStudentModal';
import { formatCurrency } from "../../utils/generic";

type ProductsTableProps = {
    products: Product[];
    productsDiscounts: (Discount | undefined)[];
    discounts: Discount[] | undefined;
    changeProductDiscount: (id: number, pos: number, product: Product) => void;
    handleStudentChange?: (student: Student, selectedProduct: Product) => void;
    totals: ProductsTotals;
};


export default function MassProductsTable(props: ProductsTableProps) {
    const messaging = useContext(MessagingContext);
    const { products, productsDiscounts, discounts, changeProductDiscount, totals, handleStudentChange } = props;

    const [associatingStudent, setAssociatingStudent] = useState(false);
    const [insertingStudent, setInsertingStudent] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(undefined);

    function filterDiscountsToCourse(discounts: Discount[] | undefined, product: Product): Discount[] {
        if (product instanceof Pack) {
            return (product as Pack).discounts;
        }
        else if (product instanceof Course) {
            const course = product as Course;
            if (discounts) {
                return discounts.filter(discount =>
                    discount.courses.map(c => c.id).includes(course.id)
                )
            }
            return [];
        }

        messaging.toast.show("Erro", "Erro a filtrar descontos para o produto");
        return [];
    }

    function studentSelectionHandler(data: Student) {
        if (handleStudentChange && selectedProduct) {
            handleStudentChange(data, selectedProduct);
        }

        setAssociatingStudent(false);
    }

    return (
        <>
            {associatingStudent && selectedProduct &&
                <StudentModal
                    selectHandler={studentSelectionHandler}
                    closeHandler={() => setAssociatingStudent(false)}
                    title={"Associar um Aluno à inscrição"} />

            }
            {insertingStudent &&
                <NewStudentCard
                    title={"Criar novo Formando"}
                    closeHandler={() => setInsertingStudent(false)} />
            }

            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col" style={{ width: "250px" }}>Formando</th>
                        <th scope="col">Curso</th>
                        <th scope="col">Horas</th>
                        <th scope="col">Preço</th>
                        <th scope="col">Desconto</th>
                        <th scope="col">Total</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((product: Product, pos: number) =>
                        <tr id={`course${pos}`} key={`course${pos}`}>
                            <td>
                                {!product.hasStudent() ?
                                    <div className="d-flex" style={{ justifyContent: 'space-evenly' }}>
                                        <button className="btn btn-success"
                                            onClick={(e: any) => {
                                                e.preventDefault();
                                                setAssociatingStudent(true);
                                                setSelectedProduct(product);
                                            }}>
                                            <i
                                                className="fas fa-link"
                                                title="Associar a um formando já existente"
                                                style={{ marginRight: "5px" }}></i>
                                            Associar
                                        </button>
                                        <button className="btn btn-success"
                                            onClick={(e: any) => {
                                                e.preventDefault();
                                                setInsertingStudent(true);
                                                setSelectedProduct(product);
                                            }}>
                                            <i
                                                className="fas fa-plus"
                                                style={{ marginRight: "5px" }}
                                                title="Criar novo formando para inscrição"></i>
                                            Novo
                                        </button>


                                    </div>
                                    :
                                    <span className="bold">{product.getStudentName() ?? ""}</span>

                                }

                            </td>
                            <td>{product.getName()}</td>
                            <td>{product.getTime()}h</td>
                            <td>{product.getPrice()}€</td>
                            <td>
                                {filterDiscountsToCourse(discounts, product).length > 0 &&
                                    <GenericCombo
                                        style={{ width: "auto", marginRight: "10px", display: "inline" }}
                                        value={productsDiscounts[pos]?.id}
                                        values={filterDiscountsToCourse(discounts, product)}
                                        getId={discount => discount.id}
                                        getOptionContent={discount => discount.discount || discount.title}
                                         selectionChanged={discountId =>
                                            changeProductDiscount(discountId as number, pos, product)
                                        } />
                                }   
                                {formatCurrency(product.getDiscountValue(productsDiscounts[pos]))}
                            </td>
                            <td>{formatCurrency(product.getPrice() - product.getDiscountValue(productsDiscounts[pos]))}€</td>
                        </tr>
                    )}
                    <tr key={"total"} style={{ fontWeight: "bold" }}>
                        <td>Total</td>
                        <td>{totals.time}h</td>
                        <td>{totals.price}€</td>
                        <td>{formatCurrency(totals.discountPrice)}</td>
                        <td>{formatCurrency(totals.total)}</td>
                    </tr>
                </tbody>
            </table >
        </>
    );
}