import { useContext, useEffect, useState } from 'react';
import Modal from '../shared/Modal';
import { MessagingContext } from '../App';
import Pack from '../../models/Pack.model';
import { api, getStorageInfo } from '../../services/api';
import GenericCombo from '../shared/GenericCombo';
import { courseCategoriesHash, courseStatusTypesHash } from '../../constants';
import Loader from '../shared/Loader';
import { verifyErrors } from '../../utils/generic';
import MultipleCourseCombo from '../combos/MultipleCourseCombo';
import Course from '../../models/Course.model';

type PackFormProps = {
    closeHandler: () => void;
    pack: Pack | undefined;
    packAdded?: (user: Pack) => void;
    packEdited?: (user: Pack) => void;
    editing: boolean;
}

type PackFormFields = {
    title: string;
    code: string;
    price: number;
    time: number;
    visible: boolean;
    course_category_id: number;
    priceInputValue?: string;
}

type PackFormErrors = {
    title: boolean;
    price: boolean;
    time: boolean;
    course_category_id: boolean;
    courses: boolean;
}

const emptyErrors: PackFormErrors = {
    title: false,
    price: false,
    time: false,
    course_category_id: false,
    courses: false,
}

type CourseCategory = {
    id: number;
    title: string;
}

const packErrorsDescriptions: any = {
    title: "Titulo",
    code: "Código Único",
    price: "Preço €",
    time: "Hórario Previsto",
    course_category_id: "Categoria de Curso",
    courses: "Cursos associados ao pack"
}


export default function PackForm(props: PackFormProps) {
    const messaging = useContext(MessagingContext);
    const [pending, setPending] = useState(false);

    const { pack } = props;

    const [selectedCourses, setSelectedCourses] = useState<Course[]>([]);

    const [fieldsValues, setFieldsValues] = useState<PackFormFields>({
        title: "",
        code: "",
        price: 0,
        time: 0,
        visible: true,
        course_category_id: 0,
    });

    const [courseCategories, setCourseCategories] = useState<CourseCategory[]>([]);

    const [errors, setErrors] = useState<PackFormErrors>(emptyErrors);

    useEffect(() => {
        //Whevener a Course is available, it means its an edit, and the info should be passed to the fieldsValues
        if (pack) {
            setFieldsValues({
                title: pack.title,
                code: pack.code,
                price: pack.price,
                priceInputValue: `${pack.price}`,
                time: pack.time,
                visible: pack.visible ?? true,
                course_category_id: pack.courseSubcategoryId ?? 0,
            });
            setSelectedCourses(pack.courses);
        }
    }, [pack])

    //Creates based on the fieldvalues, an object to pass throught the User constructor.
    function generateCourseObj(id: number) {
        return {
            id: id,
            title: fieldsValues.title,
            code: fieldsValues.code,
            price: fieldsValues.price,
            visible: fieldsValues.visible,
            time: fieldsValues.time,
            courses: selectedCourses,
            course_category_id: fieldsValues.course_category_id,
            course_category: courseCategoriesHash[fieldsValues.course_category_id]?.name,
            pack_status_type: pack ? pack.status : courseStatusTypesHash[1]?.name,
        }
    }

    function handleSubmit() {

        const errorFound = verifyErrors(errors as PackFormErrors);
        if (errorFound) {
            messaging.toast.show("Erro", `Preencha o campo: ${packErrorsDescriptions[errorFound]}`);
            return;
        }

        setPending(true);

        if (!props.editing) {
            addPackApi();
        } else {
            editPackApi();
        }
    }

    function addPackApi() {

        const dataObj = {
            ...fieldsValues,
            courses: selectedCourses.map((c: Course) => c.id).join(","),
            user_id: getStorageInfo().userId,
        }

        api.addPack(dataObj,
            (response: any) => {
                if (props.packAdded) {
                    setPending(false);
                    const newCourse = generateCourseObj(parseInt(response));
                    messaging.toast.show("Adicionado", "Novo Pack adicionado corretamente");
                    props.packAdded(new Pack(newCourse))
                }
            },
            (err: any) => messaging.toast.show("Erro", err.message));
    }

    function handleCourseAdded(course: Course) {
        if (!selectedCourses.find((c: Course) => c.id === course.id)) {
            setSelectedCourses([...selectedCourses, course]);
        }

    }

    function editPackApi() {
        if (pack) {

            const dataObj = {
                id: pack.id,
                ...fieldsValues,
                user_id: getStorageInfo().userId,
                courses: selectedCourses.map((c: Course) => c.id).join(","),
            }

            api.editPack(dataObj,
                (response: any) => {
                    if (pack && response.status === 200 && props.packEdited) {
                        setPending(false);
                        const newCourse = generateCourseObj(pack.id);
                        messaging.toast.show("Modificado", `O pack ${pack.getName()} foi modificado com sucesso!`);
                        props.packEdited(new Pack(newCourse));
                    }
                },
                (err: any) => messaging.toast.show("Erro", err.message));
        }
    }

    useEffect(() => {
        setErrors({
            title: fieldsValues.title === "",
            price: fieldsValues.price === 0,
            time: fieldsValues.time === 0,
            course_category_id: fieldsValues.course_category_id === 0,
            courses: selectedCourses.length === 0
        });
    }, [fieldsValues, selectedCourses]);

    useEffect(() => {

        api.getCourseCategories(
            (data) => {
                setCourseCategories(data)
            },
            (err) => {
                messaging.toast.show("Erro", err.message);
                setCourseCategories([]);
            }
        )
    }, [messaging.toast])

    const body =
        <div>
            <h6>Pack</h6>
            <div style={{ maxHeight: "500px" }}>
                <div className="row">
                    <div className="col m-2">
                        <div className="form-group">
                            <label>Titulo</label>
                            <input
                                type="text"
                                className="form-control"
                                id="title"
                                value={fieldsValues.title}
                                onChange={event => setFieldsValues({ ...fieldsValues, title: event.target.value })}
                                required />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col m-2">
                        <label>Código</label>
                        <input
                            type="text"
                            className="form-control"
                            id="code"
                            value={fieldsValues.code}
                            onChange={event => setFieldsValues({ ...fieldsValues, code: event.target.value })}
                            required />
                        <small className="form-text text-muted">Código de pack único</small>
                    </div>
                    <div className="col m-2">
                        <div className="form-group">
                            <label htmlFor="description">Categoria do pack</label>
                            {
                                !courseCategories ?
                                    <Loader />
                                    :
                                    <GenericCombo
                                        value={fieldsValues.course_category_id}
                                        values={courseCategories}
                                        getId={category => category.id}
                                        showError={errors.course_category_id}
                                        getOptionContent={category => category.title}
                                        selectionChanged={(category: any) => setFieldsValues({ ...fieldsValues, course_category_id: category })}
                                    />
                            }
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col m-2">
                        <div className="form-group">
                            <label htmlFor="description">Horas de Formação</label>
                            <input
                                type="text"
                                className="form-control"
                                id="time"
                                value={fieldsValues.time}
                                onChange={event => setFieldsValues({ ...fieldsValues, time: parseInt(event.target.value === "" ? "0" : event.target.value) })} />
                        </div>
                    </div>
                    <div className="col m-2">
                        <div className="form-group">
                            <label>Preço</label>
                            <input
                                type="number"
                                step="0.01"
                                className="form-control"
                                id="price"
                                value={fieldsValues.priceInputValue}
                                onChange={event => {
                                    setFieldsValues({
                                        ...fieldsValues,
                                        priceInputValue: event.target.value,
                                        price: parseFloat(event.target.value)
                                    })
                                }} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col m-2">
                        <div className="form-group">
                            <label>Cursos associados ao pack </label>
                            <MultipleCourseCombo
                                value={selectedCourses}
                                courseAdded={handleCourseAdded}
                                courseRemoved={(course: Course) => setSelectedCourses(selectedCourses.filter((c: Course) => c.id !== course.id))}
                                defaultText={"Escolha o curso a adicionar ao pacote"}
                                autoFetchCourses={true}
                            />
                        </div>
                    </div>
                </div>
                {
                    props.editing ?
                        <div className="row" style={{ marginTop: "20px" }}>
                            <div className="col">
                                <div className="form-check form-switch d-flex" style={{ alignItems: "center", paddingLeft: "2%" }}>
                                    <input
                                        className="form-check-input"
                                        style={{ fontSize: "1.2em", marginLeft: "0%" }}
                                        type="checkbox"
                                        id="flexSwitchCheckChecked"
                                        checked={fieldsValues.visible}
                                        onChange={event => setFieldsValues({ ...fieldsValues, visible: event.target.checked })} />
                                    <label style={{ marginLeft: "10px" }} className="form-check-label" htmlFor="flexSwitchCheckChecked">Visível</label>
                                </div>

                            </div>
                        </div>
                        : ""
                }

                <div className="row" style={{ marginTop: "20px" }}>
                    <div className="col text-center">

                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => handleSubmit()}
                            style={{ padding: "5px 40px" }}>
                            {pending ?
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"></span>
                                : <i className="fas fa-plus-square"></i>
                            }
                            <span style={{ margin: "0px 10px" }}>{`${props.editing ? "Modificar" : "Adicionar"} Pack`}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>;

    return (
        <>
            <Modal
                title={`${props.editing ? "Editar" : "Novo"} Pack`}
                body={body}
                closeName="Fechar"
                closeHandler={props.closeHandler}
                bodyMaxHeight={500} />
        </>
    );
}