import React, { CSSProperties } from "react";
import IdentificationType from "../../../interfaces/IdentificationType.interface";
import Nationality from "../../../interfaces/Nationality.interface";
import { PORTUGAL_COUNTRY_ID } from "../../../constants";
import GenericCombo from "../../shared/GenericCombo";
import { StudentFields } from "./StudentForm";

type StudentIdentificationFormProps = {
    fieldsValues: StudentFields;
    setFieldsValues: (val: StudentFields) => void;
    nationalities: Nationality[];
    identificationTypes: IdentificationType[];
    style?: CSSProperties
};

export default function StudentIdentificationForm(props: StudentIdentificationFormProps) {
    const { fieldsValues, setFieldsValues, nationalities, identificationTypes, style } = props;

    return (
        <div className="card p-3" style={style}>
            <h4 className="m-2">Identificação</h4>
            <div className="row">
                <div className="col m-2">
                    <div className="form-group">
                        <label>Tipo de identificação</label>
                        <GenericCombo
                            value={fieldsValues.identificationTypeId}
                            values={identificationTypes}
                            getId={identificationType => identificationType.id}
                            getOptionContent={identificationType => identificationType.title}
                            selectionChanged={identificationType =>
                                setFieldsValues({ ...fieldsValues, identificationTypeId: identificationType as number })} />
                    </div>
                </div>
                <div className="col m-2">
                    <div className="form-group">
                        <label htmlFor="postal_code">Número de identificação</label>
                        <input
                            type="text"
                            className="form-control"
                            id="identification_number"
                            value={fieldsValues.identification_number || ""}
                            onChange={event => setFieldsValues({ ...fieldsValues, identification_number: event.target.value })} />
                    </div>
                </div>
                <div className="col m-2">
                    <div className="form-group">
                        <label htmlFor="identification_date">Data de validade</label>
                        <input
                            type="date"
                            className="form-control"
                            id="fiscal_number"
                            value={fieldsValues.identification_date || ""}
                            onChange={event => setFieldsValues({ ...fieldsValues, identification_date: event.target.value })} />
                    </div>
                </div>
                <div className="col m-2">
                    <div className="form-group">
                        <label htmlFor="identification_entity">Entidade</label>
                        <input
                            type="text"
                            className="form-control"
                            id="identification_entity"
                            value={fieldsValues.identification_entity || ""}
                            onChange={event => setFieldsValues({ ...fieldsValues, identification_entity: event.target.value })} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col m-2">
                    <div className="form-group">
                        <label htmlFor="fiscal_number">NIF</label>
                        <input
                            type="text"
                            className="form-control"
                            id="fiscal_number"
                            value={fieldsValues.fiscal_number || ""}
                            onChange={event => setFieldsValues({ ...fieldsValues, fiscal_number: event.target.value })} />
                    </div>
                </div>
                <div className="col m-2">
                    <div className="form-group">
                        <label>Nacionalidade</label>
                        <GenericCombo
                            value={fieldsValues.nationalityId || PORTUGAL_COUNTRY_ID}
                            values={nationalities}
                            getId={nationality => nationality.id}
                            getOptionContent={nationality => nationality.title}
                            selectionChanged={nationality =>
                                setFieldsValues({ ...fieldsValues, nationalityId: nationality as number })} />
                    </div>
                </div>
                <div className="col m-2">
                    <div className="form-group">
                        <label>Naturalidade</label>
                        <input
                            type="text"
                            className="form-control"
                            id="naturality"
                            value={fieldsValues.naturality || ""}
                            onChange={event => setFieldsValues({ ...fieldsValues, naturality: event.target.value })} />
                    </div>
                </div>
            </div>
        </div>
    );
}