import moment from "moment";
import { useState, useEffect, SyntheticEvent, useContext } from "react";
import { useParams, useLocation } from "react-router";
import { officesHash } from "../../../constants";
import Contact from "../../../interfaces/Contact.interface";
import Course from "../../../models/Course.model";
import { FreeSession } from "../../../models/FreeSession.model";
import { api, getStorageInfo } from "../../../services/api";
import { Teacher } from "../../../types/Teacher";
import { toInputDateTime } from "../../../utils/dates";
import { verifyErrors } from "../../../utils/generic";
import { MessagingContext } from "../../App";
import CourseCombo from "../../combos/CourseCombo";
import OfficeCombo from "../../combos/OfficeCombo";
import ContactForTrainingTypeCombo from "../../contact/combos/ContactForTrainingTypeCombo";
import { RedirectionContext, Routes } from "../../MainRouter";
import ActionButton from "../../shared/ActionButton";
import DateTime from "../../shared/DateTime";
import GenericCombo from "../../shared/GenericCombo";
import Loader from "../../shared/Loader";

type FreeSessionFields = {
    name: string;
    date: string;
    teacher: number | undefined;
    office: number | undefined;
    description: string | undefined;
    trainingType: number | undefined;
}

type FreeSessionErrors = {
    course: boolean;
    teacher: boolean;
    office: boolean;
    description: boolean;
    trainingType: boolean;
}


const freeSessionErrorsDescriptions: any = {
    course: "Curso",
    teacher: "Formador",
    office: "Filial",
    description: "Descrição",
    trainingType: "Tipo de formação",
}

const emptyFields: FreeSessionFields = {
    name: "",
    date: toInputDateTime(moment()),
    teacher: undefined,
    office: undefined,
    description: undefined,
    trainingType: undefined
};

export default function FreeSessionForm() {
    const messaging = useContext(MessagingContext);
    const redirection = useContext(RedirectionContext);

    const [fieldsValues, setFieldsValues] = useState(emptyFields);
    const [errors, setErrors] = useState<FreeSessionErrors>({
        course: false,
        teacher: false,
        office: false,
        description: false,
        trainingType: false
    });
    const [pending, setPending] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadedFreeSession, setLoadedFreeSession] = useState<FreeSession | undefined>(undefined);
    const [selectedCourse, setSelectedCourse] = useState<Course>();
    const [teachers, setTeachers] = useState<Teacher[] | undefined>();
    const [contactId, setContactId] = useState(0);
    const [titleInfo, setTitleInfo] = useState("");

    const params: { id: string } = useParams();
    const queryParams = new URLSearchParams(useLocation().search);
    const page = queryParams.get("pagina");

    const freeSessionId = params && params.id ? parseInt(params.id) : undefined;
    const editMode = freeSessionId !== undefined;

    if (editMode && loading === false && typeof loadedFreeSession === "undefined") {
        setLoading(true);

        api.getFreeSessions({ id: freeSessionId }, freeSession => {
            setFieldsValues({
                name: freeSession.name,
                date: toInputDateTime(moment(freeSession.session_date)),
                teacher: freeSession.teacher_id,
                office: freeSession.office_id,
                trainingType: freeSession.training_type_id,
                description: freeSession.description
            });

            setSelectedCourse(new Course({ id: freeSession.course_id }));
            setLoadedFreeSession(freeSession);
            setLoading(false);
        }, err => {
            setLoadedFreeSession(undefined);
            setLoading(false);
            messaging.modal.showMessage("Erro", "Erro no carregamento da sessão gratuita: " + err);
        });
    }


    const location = useLocation();
    useEffect(() => {
        if (location.search.length > 0) {
            const contactIdRegexResult = location.search.match(/contacto=(\d+)/);
            if (contactIdRegexResult && contactIdRegexResult[1]) {
                const id = contactIdRegexResult[1];

                api.getContacts({ id: id }, contactData => {
                    const contact = contactData as Contact;
                    setContactId(contact.id);
                    setFieldsValues(fieldsValues => ({
                        ...fieldsValues,
                        name: contact.name,
                        office: contact.office_id
                    }));
                    setTitleInfo("a partir do contacto de " + contact.name);
                }, err => {
                    messaging.modal.showMessage("Erro",
                        "Erro a obter informação do contacto: " + err.message);
                });
            }
        }
    }, [location, messaging.modal]);


    useEffect(() => {
        api.getTeachers(teachs => setTeachers(teachs), err => setTeachers([]));
    }, []);

    useEffect(() => {
        setErrors({
            course: selectedCourse === undefined,
            teacher: fieldsValues.teacher === undefined,
            office: fieldsValues.office === undefined,
            trainingType: fieldsValues.trainingType === undefined,
            description: fieldsValues.description === undefined
        });
    }, [fieldsValues, selectedCourse]);

    function handleSubmit(event: SyntheticEvent) {
        event.preventDefault();

        const errorFound = verifyErrors(errors as FreeSessionErrors);
        if (errorFound) {
            messaging.toast.show("Erro", `Preencha o campo: ${freeSessionErrorsDescriptions[errorFound]}`);
            return;
        }
        const freeSessionInfo: any = {
            name: fieldsValues.name,
            session_date: fieldsValues.date,
            course_id: selectedCourse?.id,
            teacher_id: fieldsValues.teacher,
            office_id: fieldsValues.office,
            training_type_id: fieldsValues.trainingType,
            description: fieldsValues.description,
            user_start_id: getStorageInfo().userId
        };

        if (contactId > 0) {
            freeSessionInfo.contact_id = contactId;
        }

        setPending(true);
        if (editMode) {
            const editFreeSession = { ...freeSessionInfo, id: freeSessionId };
            api.editFreeSession(editFreeSession, () => {
                setPending(false);
                redirection.redirectTo(page ?
                    (Routes.freeSessions + "?pagina=" + page) :
                    Routes.freeSessions);
            }, err => {
                setPending(false);
                messaging.modal.showMessage("Erro", "Erro ao editar sessão gratuita: " + err.message);
            });
        } else {
            addSchedulerRequest(freeSessionInfo);
        }
    }

    function apiNewFreeSessionRequest(freeSessionInfo: object) {
        api.addFreeSession(freeSessionInfo, (data: any) => {
            setPending(false);

            if (!isNaN(data)) {
                redirection.redirectTo(Routes.freeSessions);
            } else {
                messaging.toast.show("Erro", "Erro ao inserir sessão gratuita: " + data);
            }
        }, err => {
            setPending(false);
            messaging.modal.showMessage("Erro", "Erro ao inserir sessão gratuita: " + err.message);
        });
    }

    function addSchedulerRequest(freeSessionInfo: object) {
        setPending(true);
        messaging.modal.showFull({
            title: "Adicionar",
            body: `Deseja criar a sessão gratuita automaticamente no Programa de Marcações de ${officesHash[fieldsValues.office ?? 0]?.name} ?`,
            closeName: "Não",
            closeHandler: () => {
                messaging.modal.close();
                apiNewFreeSessionRequest(freeSessionInfo);
            },
            actionName: "Sim",
            actionHandler: () => {
                messaging.modal.close();
                apiNewFreeSessionRequest({ ...freeSessionInfo, add_scheduler: true });
            },
            actionClassName: "btn-danger",
            showAction: true
        });
    }

    function handleFieldChange(event: any) {
        const fieldName = event.target.name;
        const fieldValue = event.target.value
        setFieldsValues({ ...fieldsValues, [fieldName]: fieldValue });
    }

    function formJSX() {
        return (
            <>
                <div className="row">
                    <div className="col m-2">
                        <div className="form-group">
                            <label htmlFor="name">Nome</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                value={fieldsValues.name || ""}
                                name="name"
                                onChange={handleFieldChange}
                                required />
                        </div>
                    </div>
                    <div className="col m-2">
                        <div className="form-group">
                            <label >Curso</label>
                            <CourseCombo
                                value={selectedCourse}
                                selectionChanged={course => setSelectedCourse(course)}
                                showError={errors.course}
                                autoFetchCourses={true} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col m-2">
                        <DateTime
                            value={fieldsValues.date}
                            valueChanged={(newDate) => setFieldsValues({ ...fieldsValues, date: newDate })} />
                    </div>
                </div>
                <div className="row">
                    <div className="col m-2">
                        <div className="form-group">
                            <label>Filial</label>
                            <OfficeCombo
                                showError={errors.office}
                                value={fieldsValues.office}
                                selectionChanged={newOffice =>
                                    setFieldsValues({ ...fieldsValues, office: newOffice })} />
                        </div>
                    </div>
                    <div className="col m-2">
                        <div className="form-group">
                            <label>Tipo de formação</label>
                            <ContactForTrainingTypeCombo
                                showError={errors.trainingType}
                                value={fieldsValues.trainingType}
                                selectionChanged={newTrainingType =>
                                    setFieldsValues({ ...fieldsValues, trainingType: newTrainingType })} />
                        </div>
                    </div>
                    <div className="col m-2">
                        <div className="form-group">
                            <label>Formador</label>
                            <GenericCombo
                                value={fieldsValues.teacher}
                                values={teachers}
                                getId={teacher => teacher.id}
                                getOptionContent={teacher => teacher.name}
                                defaultText="Escolha o formador"
                                showError={errors.teacher}
                                selectionChanged={teacherId =>
                                    setFieldsValues({ ...fieldsValues, teacher: teacherId as number })
                                } />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col m-2">
                            <div className="form-group">
                                <label>Descrição</label>
                                <textarea
                                    className="form-control"
                                    id="description"
                                    rows={2}
                                    value={fieldsValues.description || ""}
                                    onChange={event => setFieldsValues({ ...fieldsValues, description: event.target.value })}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row" style={{ marginTop: "30px" }}>
                    <div className="col text-center">
                        <ActionButton
                            pending={pending}
                            icon={`fas ${editMode ? "fa-pen" : "fa-plus-square"}`}
                            text={editMode ? "Editar" : "Criar"} />
                    </div>
                </div>
            </>
        );
    }

    return (
        <div className="container p-4">
            <h2 className="text-center m-4">{editMode ? "Editar" : "Nova"} Sessão Gratuita {titleInfo}</h2>
            <form onSubmit={handleSubmit}>
                <div className="card p-3">
                    {loading ? <Loader /> : formJSX()}
                </div>
            </form>
        </div>
    );
}