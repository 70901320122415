import { CSSProperties, useContext, useEffect, useState } from "react";
import { officesHash } from "../../../constants";
import Divulgation from "../../../interfaces/Divulgation.interface";
import Student from "../../../models/Student.model";
import { api } from "../../../services/api";
import { FieldsEdited } from "../../../types/FieldsEdited";
import { MessagingContext } from "../../App";
import OfficeCombo from "../../combos/OfficeCombo";
import Card from "../../shared/Card";
import styles from "../../shared/Card.module.css";
import ConfirmCancelIcons from "../../shared/ConfirmCancelIcons";
import GenericCombo from "../../shared/GenericCombo";
import Loader from "../../shared/Loader";
import { StudentFormabaseFields } from "../forms/StudentForm";

type StudentFormabaseCardProps = {
    value: Student;
    style?: CSSProperties;
    fieldsEditedCallback: (params: FieldsEdited<StudentFormabaseFields>) => void;
    setStudent: (newStudent: Student) => void;
};

export default function StudentFormabaseCard(props: StudentFormabaseCardProps) {
    const student = props.value;

    const messaging = useContext(MessagingContext);
    const [editMode, setEditMode] = useState(false);
    const [divulgations, setDivulgations] = useState<Divulgation[] | undefined>();
    const [editing, setEditing] = useState(false);
    const [fieldsValues, setFieldsValues] = useState<StudentFormabaseFields>({
        office: student.officeId,
        divulgationId: student.divulgationId,
        username: student.username || "",
        password: student.password || "",
        observations: student.observations || ""
    });
    const [selectedDivulgationName, setSelectedDivulgationName] = useState(student.divulgation);
    const [selectedOfficeName, setSelectedOfficeName] = useState(student.office);

    useEffect(() => {
        if (editMode && !divulgations) {
            api.getDivulgations(divulgations => setDivulgations(divulgations), err => setDivulgations([]));
        }
    }, [editMode, divulgations]);

    function handleConfirm() {
        setEditing(true);
        props.fieldsEditedCallback({
            newValues: fieldsValues,
            success: () => {
                const newStudent = student.clone();
                newStudent.divulgation = selectedDivulgationName;
                newStudent.office = selectedOfficeName;
                props.setStudent(newStudent);
                setEditing(false);
                setEditMode(false);
            },
            fail: err => {
                setEditing(false);
                messaging.modal.showMessage("Erro", "Erro a editar os dados do aluno: " + err.message);
            }
        });
    }

    function handleDivulgationChange(divulgation: number | string | Divulgation) {
        const newDivulgationId = divulgation as number;
        setFieldsValues({ ...fieldsValues, divulgationId: newDivulgationId });
        const newDivulgation = divulgations?.find(divulgation => divulgation.id === newDivulgationId);
        if (newDivulgation) {
            setSelectedDivulgationName(newDivulgation.title);
        }
    }

    function handleOfficeChange(newOffice: number) {
        setFieldsValues({ ...fieldsValues, office: newOffice });
        const newOfficeName = officesHash[newOffice]?.name;
        if (newOfficeName) {
            setSelectedOfficeName(newOfficeName);
        }
    }

    return (
        <Card
            keyId={student.id.toString()}
            style={props.style || {}}
            title={
                <div>Formabase
                    {editMode ?
                        (editing ?
                            <Loader
                                outerClassName=""
                                outterStyle={{ display: "inline" }}
                                style={{ marginLeft: "10px" }}
                                size="small" /> :
                            <ConfirmCancelIcons
                                confirmHandler={handleConfirm}
                                cancelHandler={() => setEditMode(false)} />
                        ) :
                        <i
                            className="fas fa-pen action-icon"
                            style={{ margin: "5px 0 0 10px" }}
                            title="Editar"
                            onClick={() => setEditMode(true)}></i>
                    }
                </div>
            }
            includeHeader={true}
            headerStyle={{ fontSize: "1.1rem" }}
            body={
                <table className="table table-striped" >
                    <tbody>
                        <tr>
                            <td className={styles.titleField}>Filial</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <OfficeCombo
                                        value={fieldsValues.office}
                                        selectionChanged={handleOfficeChange} /> :
                                    student.office}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Divulgação</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <GenericCombo
                                        value={fieldsValues.divulgationId}
                                        values={divulgations}
                                        getId={divulgation => divulgation.id}
                                        getOptionContent={divulgation => divulgation.title}
                                        selectionChanged={handleDivulgationChange} /> :
                                    student.divulgation}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Username</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={fieldsValues.username}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, username: event.target.value }
                                        )} /> :
                                    student.username}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Password</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <input
                                        type="password"
                                        className="form-control"
                                        value={fieldsValues.password}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, password: event.target.value }
                                        )} /> :
                                    student.password}
                            </td>
                        </tr>
                        <tr>
                            <td className={styles.titleField}>Observações</td>
                            <td className={styles.infoField}>
                                {editMode ?
                                    <textarea
                                        className="form-control"
                                        id="obs"
                                        rows={3}
                                        value={fieldsValues.observations || ""}
                                        onChange={event => setFieldsValues(
                                            { ...fieldsValues, observations: event.target.value }
                                        )}></textarea> :
                                    student.observations}
                            </td>
                        </tr>
                    </tbody>
                </table>
            }
            width={350} />
    );
}