import Purchase from "../../models/Purchase.model";
import Loader from "../shared/Loader";
import PurchaseCard from "../purchase/PurchaseCard";

type CustomerPurchasesGridProps = {
    studentId?: number,
    purchases: Purchase[] | undefined;
    removePurchaseHandler: (purchaseId: number) => void
}

export default function CustomerPurchasesGrid(props: CustomerPurchasesGridProps) {


    return (
        props.purchases ?
            <div className="d-flex justify-content-center">
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {props.purchases.map(purchase =>
                        <PurchaseCard
                            studentId={props.studentId ?? undefined}
                            key={purchase.id}
                            style={{ margin: "20px" }}
                            showStudentNames={true}
                            removedData={props.removePurchaseHandler}
                            purchase={purchase} />)}</div>
            </div> :
            <Loader />
    );
}