import { toInputDate } from "../../utils/dates";
import { PaymentObject } from "../purchase/NewPurchaseCard";

type PaymentsTableProps = {
    dateObjects?: PaymentObject[];
    dateChangedHandler: (newDate: string, arrayIndex: number) => void;
    amountChangedHandler: (newAmount: string, arrayIndex: number) => void;
};

export default function CreatePaymentsTable(props: PaymentsTableProps) {
    return (
        <table className="table table-striped">
            <thead>
                <tr>
                    <th scope="col">Data</th>
                    <th scope="col">Valor</th>
                </tr>
            </thead>
            <tbody>
                {props.dateObjects && props.dateObjects.map((date: PaymentObject, pos) =>
                    <tr key={pos}>
                        <td>
                            <input
                                type="date"
                                className="form-control"
                                value={toInputDate(date.paymentDate)}
                                onChange={event => props.dateChangedHandler(event.target.value, pos)} />
                        </td>
                        <td>

                            <input
                                type="number"
                                className="form-control"
                                value={date.amount}
                                onChange={event => props.amountChangedHandler(event.target.value, pos)} />

                        </td>
                    </tr>)}
            </tbody>
        </table>
    );
}