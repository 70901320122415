import { useContext, useEffect, useState } from "react";
import Purchase from "../../models/Purchase.model";
import Customer from "../../models/Customer.model";
import { api } from "../../services/api";
import { MessagingContext } from "../App";
import MassPurchaseCard from "../purchase/NewMassPurchaseCard";
import CustomerPurchasesGrid from "./CustomerPurchasesGrid";
import Loader from "../shared/Loader";

export default function CustomerPurchases(props: { customerId: number, setCustomerName: (name: string) => void }) {
    const { customerId, setCustomerName } = props;

    const messaging = useContext(MessagingContext);
    const [addingNew, setAddingNew] = useState(false);
    const [loading, setLoading] = useState(false);
    const [purchases, setPurchases] = useState<Purchase[] | undefined>();
    const [customer, setCustomer] = useState<Customer>();


    useEffect(() => {
        api.getCustomer(customerId,
            customerFetched => {
                setCustomer(new Customer(customerFetched));
                setCustomerName(customerFetched.name);
            }, err => messaging.modal.showMessage("Erro", "Erro ao obter o cliente:" + err.message));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId, setCustomerName]);

    useEffect(() => {
        setLoading(true);
        api.getCustomerPurchases(customerId,
            (resp: any) => {
                const purchases = resp.map((val: any) => new Purchase(val));
                purchases.sort((p1: Purchase, p2: Purchase) => p2.startDate.getTime() - p1.startDate.getTime());
                setPurchases(purchases);
                setLoading(false);
            }, err => {
                setLoading(false);
                messaging.modal.showMessage("Erro",
                    "Erro ao carregar inscrições do aluno: " + err.message)
            });
        //eslint-disable-next-line
    }, [customerId]);

    function removePurchaseHandler(purchaseId: number) {
        if (purchases) {
            const purchasesFiltered = purchases.filter((purchase: Purchase) => purchase.id !== purchaseId);
            setPurchases(purchasesFiltered);
        }
    }

    return (

        <div>
            <button
                className="btn btn-success"
                onClick={() => setAddingNew(true)}
                style={{ marginBottom: "20px" }}>Nova Inscrição</button>
            {loading ?
                <Loader /> :
                addingNew && customer ?
                    <MassPurchaseCard
                        customer={customer}
                        close={() => setAddingNew(false)} /> :
                    <CustomerPurchasesGrid
                        key={customer?.id}
                        removePurchaseHandler={removePurchaseHandler}
                        purchases={purchases} />
            }
        </div>
    );
}