export default class PaymentStatus {
    public id: number;
    public userId: number;
    public createdDate: Date;
    public observations: string;
    public paymentStatusTypeId: number;
    public paymentId: number;
    public user: string;
    public paymentStatusType: string;

    constructor(data: any) {
        this.id = data.id;
        this.userId = data.user_id;
        this.createdDate = data.created_date;
        this.observations = data.observations;
        this.paymentStatusTypeId = data.payment_status_type_id;
        this.paymentId = data.payment_id;
        this.user = data.user;
        this.paymentStatusType = data.payment_status_type;
    }
}