import React, { useState } from "react";
import Pack from "../../models/Pack.model";
import CourseTag from "../tags/CourseTag";
import PackCombo from "./PackCombo";

interface MultiplePackComboProps {
    value: Pack[];
    packAdded: (Pack: Pack) => void;
    packRemoved: (Pack: Pack) => void;
    defaultText?: string;
    availablePacks: Pack[] | undefined;
    autoFetchPacks?: boolean;
    width?: string;
}

export default function MultiplePackCombo(props: MultiplePackComboProps) {
    const [selectedPack, setSelectedPack] = useState<Pack | undefined>(undefined);

    function packSelected(pack: Pack | undefined) {

        if (pack) {
            props.packAdded(pack);
            setSelectedPack(undefined);
        }
    }

    function removePack(pack: Pack) {
        props.packRemoved(pack);
        setSelectedPack(undefined);
    }

    return (
        <>
            <div className="flex">
                <div>
                    <PackCombo
                        value={selectedPack}
                        availablePacks={props.availablePacks}
                        selectionChanged={newPack => packSelected(newPack)}
                        autoFetchPacks={props.autoFetchPacks}
                        defaultText={props.defaultText} />
                </div>
            </div>
            <div className="flex" style={{ marginTop: "10px", width: `${props.width ?? "100%"} `, flexWrap: "wrap" }}>
                {(props.value || []).map((pack: Pack) =>
                    <div style={{ margin: "5px 1px" }} key={pack.id}>
                        <CourseTag title={pack.hasStudent() ? pack.getStudentName() : pack.title} name={pack.title} />
                        <i
                            className="action-icon fas fa-trash"
                            title={`${pack.hasStudent() ? `Eliminar - ${pack.getStudentName()}` : "Eliminar"}`}
                            onClick={() => removePack(pack)}></i>
                    </div>
                )}
            </div>
        </>
    );
}