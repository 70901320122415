export default class Training {
    public id: number;
    public startDate: Date | undefined;
    public endDate: Date | undefined;
    public totalTime: number;
    public enrollmentId: number;
    public studentId: number;
    public courseId: number;
    public surveyId: number | undefined;
    public monthExtension: number;
    public observationsExtension: string;
    public certificateId: number | undefined;
    public expirationDate: Date;
    public trainingTypeId: number;
    public trainingType: string;
    public createdDate: Date;
    public trainingStatusTypeId: number;
    public trainingStatus: string;
    public trainingStatusObservations: string;
    public user: string;
    public courseName: string;
    public studentName: string;
    public certificateStatus: string;
    public officeId: number;
    public extensionExpirationDate: Date;

    constructor(data: any) {

        this.id = data.id;
        this.startDate = data.start_date ? new Date(data.start_date) : undefined;
        this.endDate = data.end_date ? new Date(data.end_date) : undefined;
        this.totalTime = data.total_time;
        this.enrollmentId = data.enrollment_id;
        this.studentId = data.student_id;
        this.courseId = data.course_id;
        this.surveyId = data.survey_id;
        this.monthExtension = data.month_extension;
        this.observationsExtension = data.observations_extension;
        this.certificateId = data.certificate_id;
        this.expirationDate = new Date(data.expiration_date);
        this.trainingTypeId = data.training_type_id;
        this.trainingType = data.training_type;
        this.createdDate = new Date(data.created_date);
        this.trainingStatusTypeId = data.training_status_type_id;
        this.trainingStatus = data.training_status;
        this.trainingStatusObservations = data.training_status_observations;
        this.user = data.user;
        this.trainingType = data.training_type;
        this.courseName = data.course;
        this.studentName = data.student_name;
        this.certificateStatus = data.certificate_status;
        this.officeId = data.office_id;
        this.extensionExpirationDate = new Date(data.extension_expiration_date);
    }
}