import { contactMethodsHash, noContactMethod, noTrainingType, trainingTypesHash } from "../../constants";
import FollowUp from "../../interfaces/FollowUp.interface";
import { toDateTimePT } from "../../utils/dates";
import CourseTag from "../tags/CourseTag";

interface FollowUpListProps {
    followUp: FollowUp;
    handleFollowUpEdit: (followUp: FollowUp) => void;
    handleFollowUpDelete: (followUp: FollowUp) => void;
}

export default function FollowUpItem(props: FollowUpListProps) {
    const { followUp } = props;
    return (
        <>
            <div className="row" style={{ marginTop: "20px", borderRadius: "5px", backgroundColor: "#efefef", padding: "10px 1px" }}>
                <div className="col col-4">
                    <i
                        className={(contactMethodsHash[followUp.contact_method_id] || noContactMethod).icon}
                        style={{ marginRight: "5px", color: "#607d8b" }}
                        title={(contactMethodsHash[followUp.contact_method_id] || noContactMethod).name}></i>
                    <span>{followUp.description}</span>
                    <div className="contact-created-date">{toDateTimePT(new Date(followUp.created_date || followUp.date))}</div>
                </div>
                <div className="col col-2">
                    <i className="fas fa-user"></i>
                    <span style={{ marginLeft: "10px" }}>
                        {followUp.user}
                    </span>
                </div>
                <div className="col col-2">
                    <i className={`fa ${(trainingTypesHash[followUp.training_type_id] || noTrainingType).icon}`}></i>
                    <span style={{ marginLeft: "10px" }}>
                        {(trainingTypesHash[followUp.training_type_id] || noTrainingType).name}
                    </span>
                </div>
                <div className="col col-2">
                    {followUp.courses && followUp.courses.map((c: any) => <CourseTag name={c.title} title={c.title} />)}
                </div>
                <div className="col col-1" style={{ width: "100px" }}>
                    <i style={{ marginRight: "10px" }} className="action-icon fas fa-pen" title="Editar" onClick={() => props.handleFollowUpEdit(followUp)}></i>
                    <i className="action-icon fas fa-trash" title="Remover" onClick={() => props.handleFollowUpDelete(followUp)}></i>
                </div>
            </div>

        </>
    );
}