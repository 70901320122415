import { api } from '../../services/api';
import React, { useEffect, useState, useContext } from "react";
import { MessagingContext } from '../App';
import Loader from '../shared/Loader';
import Course from '../../models/Course.model';
import CourseForm from '../courses/CourseForm';

type CourseComboProps = {
    value: Course | undefined;
    selectionChanged: (newVal: Course | undefined) => void;
    showError?: boolean;
    office?: number;
    defaultText?: string;
    availableCourses?: Course[] | undefined;
    autoFetchCourses?: boolean;
}

export default function CourseCombo(props: CourseComboProps) {
    const messaging = useContext(MessagingContext);
    const [courses, setCourses] = useState<Course[] | undefined>();
    const [showInsertModal, setShowInsertModal] = useState(false);

    const showCourses = props.autoFetchCourses !== false ? courses : props.availableCourses;

    useEffect(() => {
        if (props.autoFetchCourses !== false) {
            api.getCourses(
                (data: any) => setCourses(data.map((c: any) => new Course(c))),
                err => messaging.modal.showMessage("Erro", "Erro a obter os cursos: " + err));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.office, props.autoFetchCourses]);

    function handleCourseChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const selectIntVal = parseInt(event.target.value);
        if (showCourses) {
            const selectedCourse = showCourses.find(course => course.id === selectIntVal);
            props.selectionChanged(selectedCourse);
        }
    }

    function addCourseToList(newCourse: Course) {
        if (courses) {
            const newCoursesList = [...courses, newCourse];
            setCourses(newCoursesList.sort((a: Course, b: Course) => a.title.localeCompare(b.title)));
            setShowInsertModal(false);
        }
    }

    return (
        <>
            {showCourses ?
                <>
                    {showInsertModal &&
                        <CourseForm
                            closeHandler={() => setShowInsertModal(false)}
                            course={undefined}
                            courseAdded={addCourseToList}
                            editing={false}
                        />
                    }
                    <div className="d-flex" style={{ alignItems: 'baseline' }}>
                        <button type="button"
                            style={{ fontSize: "1rem", marginRight: "5px" }}
                            onClick={() => {
                                setShowInsertModal(true);
                            }}
                            className={`btn btn-outline-success btn-rounded waves-effect`}>
                            <i className="fas fa-plus-square"></i>
                        </button>
                        <div>
                            <select
                                value={typeof props.value === "undefined" ? 0 : props.value.id}
                                className="form-select"
                                onChange={handleCourseChange}>
                                <option value={0}>{props.defaultText || "Escolha o curso"}</option>
                                {
                                    showCourses
                                        .filter(course => course.visible === true)
                                        .map((course: Course, pos: number) =>
                                            <option
                                                key={`${course.id}-${pos}`}
                                                value={course.id}>{course.title}</option>
                                        )}
                            </select>
                            {props.showError &&
                                <div className="invalid-feedback" style={{ display: "block" }}>
                                    Precisa de escolher um curso
                                </div>
                            }
                        </div>
                    </div>
                </>
                :
                <Loader />
            }
        </>
    );
}