import moment from "moment";
import React, { useContext, useState } from "react";
import { noToggle, yesNoToggle, YesNoToggleOption, yesToggle } from "../../../constants";
import { FreeSession } from "../../../models/FreeSession.model";
import { api, getStorageInfo } from "../../../services/api";
import { toInputDateTime } from "../../../utils/dates";
import { MessagingContext } from "../../App";
import DateTime from "../../shared/DateTime";
import Modal from "../../shared/Modal";
import StatelessToggle from "../../StatelessToggle";

type FreeSessionFinishFields = {
    attended?: YesNoToggleOption;
    interested?: YesNoToggleOption;
    conclusion?: string;
    conclusionDate?: string;
}

type FreeSessionFinishProps = {
    freeSession: FreeSession; 
    closeHandler: () => void;
    freeSessionChanged: (freeSession: FreeSession) => void;
}


export default function FreeSessionFinishForm(props: FreeSessionFinishProps) {
    const { freeSession, closeHandler, freeSessionChanged } = props;
    const messaging = useContext(MessagingContext);
    const [fieldsValues, setFieldsValues] = useState<FreeSessionFinishFields>({
        attended: (freeSession.attended === undefined || freeSession.attended === true) ? 
            yesToggle: 
            noToggle,
        interested: (freeSession.interested === undefined|| freeSession.interested === true) ? 
            yesToggle: 
            noToggle,
        conclusion: freeSession.conclusion || "",
        conclusionDate: freeSession.conclusionDate ? 
            toInputDateTime(moment(freeSession.conclusionDate)) : 
            undefined
    });
    const [finishing, setFinishing] = useState(false);

    function handleFinish() {
        const finishObj = {
            ...fieldsValues,
            id: props.freeSession.id,
            attended: fieldsValues.attended ? fieldsValues.attended.val : undefined,
            interested: fieldsValues.interested ? fieldsValues.interested.val : undefined,
            conclusion_date: fieldsValues.conclusionDate,
            user_end_id: getStorageInfo().userId,
        };
        setFinishing(true);

        api.editFreeSession(finishObj, () => {
            setFinishing(false);
            const editedFreeSession = freeSession.clone();
            editedFreeSession.attended = finishObj.attended;
            editedFreeSession.interested = finishObj.interested;
            editedFreeSession.conclusion = finishObj.conclusion;
            editedFreeSession.conclusionDate = finishObj.conclusionDate ? 
                new Date(finishObj.conclusionDate) : 
                undefined;
            freeSessionChanged(editedFreeSession);
            closeHandler();
        }, err => {
            setFinishing(false);
            messaging.modal.showMessage("Erro", "Erro a finalizar sessão gratuita: " + err.message);
        });
    }

    return (
        <Modal
            title={`Finalização da sessão gratuita de ${props.freeSession.name}`}
            body={
                <form>
                    <div className="row">
                        <div className="col m-2">
                            Concluída em
                            <DateTime
                                value={fieldsValues.conclusionDate || ""}
                                valueChanged={(newDate) => setFieldsValues(
                                    { ...fieldsValues, conclusionDate: newDate }
                                )} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col m-2">
                            <div className="form-group">
                                <label htmlFor="name">Interessado</label>
                                <div>
                                    <StatelessToggle
                                        values={yesNoToggle}
                                        value={fieldsValues.interested}
                                        toggleChanged={newVal => setFieldsValues(
                                            {...fieldsValues, interested: newVal}
                                        )}
                                        getId={x => x?.id.toString() || ""}
                                        getOptionContent={x => x?.name || ""} />
                                </div>
                            </div>
                        </div>
                        <div className="col m-2">
                            <div className="form-group">
                                <label htmlFor="name">Frequentou</label>
                                <div>
                                    <StatelessToggle
                                        values={yesNoToggle}
                                        value={fieldsValues.attended}
                                        toggleChanged={newVal => setFieldsValues(
                                            {...fieldsValues, attended: newVal}
                                        )}
                                        getId={x => x?.id.toString() || ""}
                                        getOptionContent={x => x?.name || ""} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col m-2">
                            <div className="form-group">
                                <label htmlFor="name">Conclusão</label>
                                <textarea
                                    className="form-control"
                                    id="obs"
                                    rows={3}
                                    value={fieldsValues.conclusion || ""}
                                    onChange={event => setFieldsValues(
                                        { ...fieldsValues, conclusion: event.target.value }
                                    )}></textarea>
                            </div>
                        </div>
                    </div>
                </form>
            }
            showAction={true}
            actionName="Finalizar"
            actionClassName="btn-success"
            actionHandler={handleFinish}
            closeName="Fechar"
            closeHandler={props.closeHandler}
            bodyMaxHeight={500}
            actionPending={finishing} />
    );
}