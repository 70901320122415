import styles from "./Print.module.css";

export default function PrintInfoTable(props: { data: any }) {


    let result = props.data.map((row: any) =>
        <div className="row">
            {
                row.map((col: string[]) =>
                    <div className={`col ${styles.fieldContainer}`}>
                        <strong>{col[0]}:</strong> {col[1]}
                    </div >
                )
            }
        </div>
    )

    return (


        <div className="container">
            {result}
        </div>
    );
}