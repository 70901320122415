import React, { useContext, useState } from "react";
import { NavLink, Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import { api } from "../../services/api";
import { MessagingContext } from "../App";
import { RedirectionContext } from "../MainRouter";
import Loader from "../shared/Loader";
import CustomerInfo from "./CustomerInfo";
import CustomerPurchases from "./CustomerPurchases";

export default function CustomerPage() {
    const messaging = useContext(MessagingContext);
    const redirection = useContext(RedirectionContext);

    const [deleting, setDeleting] = useState<boolean>(false);
    const [customerName, setCustomerName] = useState<string>("");

    const { path, url } = useRouteMatch();
    const params: { id: string } = useParams();
    const customerId = parseInt(params.id);
    const tabs = [
        { name: "Dados Pessoais", link: url + "/dados-pessoais" },
        { name: "Inscrições", link: url + "/inscricoes" },
    ];

    function handleRemoveCustomer() {
        messaging.modal.showFull({
            title: "Eliminar cliente",
            body: "Tem a certeza que pretende eliminar este cliente ?",
            closeName: "Cancelar",
            closeHandler: messaging.modal.close,
            actionName: "Eliminar",
            actionHandler: () => {
                messaging.modal.close();
                setDeleting(true);
                api.deleteCustomer(customerId, (info: any) => {
                    console.log(info);
                    if (info.status !== 204) {
                        messaging.toast.show("Erro", "O cliente já está associado a uma compra");
                        setDeleting(false);
                    } else {
                        redirection.redirectTo("/clientes");
                    }
                }, err => {
                    messaging.modal.showMessage("Erro", "Erro ao eliminar o cliente: " + err);
                });
            },
            actionClassName: "btn-danger",
            showAction: true
        });
    }

    return (
        <>
            <div>
                <div style={{ position: "relative" }}>
                    <h2 className="text-center m-4">Cliente - {customerName}</h2>
                    <button
                        className="btn btn-danger"
                        disabled={deleting}
                        onClick={handleRemoveCustomer}
                        style={{ position: "absolute", top: "5px", right: "25px" }}>
                        {
                            deleting ?
                                <Loader
                                    outterStyle={{ float: "left", marginRight: "5px" }}
                                    style={{ width: "1.5rem", height: "1.5rem" }} /> :
                                ""
                        }
                        Eliminar Cliente
                    </button>
                </div>
                <ul className="nav nav-tabs">
                    {tabs.map((tab, pos) =>
                        <li
                            key={pos}
                            className="nav-item"
                            style={{ cursor: "pointer" }}>
                            <NavLink
                                to={tab.link}
                                className="nav-link"
                                activeClassName="active">{tab.name}</NavLink>
                        </li>
                    )}
                </ul>

                <div style={{ padding: "15px" }}>
                    <Switch>
                        <Route exact path={[path, path + "/dados-pessoais"]}>
                            <CustomerInfo customerId={customerId} setCustomerName={setCustomerName} />
                        </Route>
                        <Route exact path={`${path}/inscricoes`}>
                            <CustomerPurchases customerId={customerId} setCustomerName={setCustomerName} />
                        </Route>
                    </Switch>
                </div>
            </div>
        </>
    );
}