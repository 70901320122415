import { useContext, useEffect, useState } from "react";
import Contact from "../../interfaces/Contact.interface";
import FollowUp from "../../interfaces/FollowUp.interface";
import { api, NotFoundError } from "../../services/api";
import { toDatePT } from "../../utils/dates";
import { buildContact } from "../../utils/objects-builders";
import { MessagingContext } from "../App";
import FollowUpItem from "../followup/FollowUpItem";
import Loader from "../shared/Loader";

export default function StudentContacts(props: { studentId: number, setStudentName: (name: string) => void }) {
    const messaging = useContext(MessagingContext);
    const [contacts, setContacts] = useState<Contact[] | undefined>();

    const { studentId, setStudentName } = props;

    useEffect(() => {
        api.getContacts({ student_id: studentId }, contactsFetched => {
            setContacts(contactsFetched.map(buildContact));
            if (contactsFetched.length > 0) {
                setStudentName(contactsFetched[0].name);
            }
        }, err => {
            if (err instanceof NotFoundError) {
                setContacts([]);
            }
            else {
                messaging.modal.showMessage("Erro", "Erro a obter contactos do aluno: " + err);
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studentId, setStudentName]);

    function removeFollowUp(id: number) {
        messaging.modal.showFull({
            title: "Remover contacto",
            body: `Tem a certeza que pretende remover o seguimento  ?`,
            closeName: "Cancelar",
            closeHandler: messaging.modal.close,
            actionName: "Eliminar",
            actionHandler: () => {
                messaging.modal.close();
                api.deleteFollowUp(id,
                    () => {
                        messaging.toast.showFull({
                            title: "Seguimento",
                            body: "Seguimento removido",
                            style: { zIndex: 2000 },
                            removeHandler: messaging.toast.close
                        });

                        if (contacts) {
                            const newContacts = contacts.slice();
                            for (let contact of newContacts) {
                                contact.follow_ups = contact.follow_ups?.filter(
                                    followUp => followUp.id === id);
                            }
                            setContacts(newContacts);
                        }
                    },
                    err => messaging.modal.showMessage("Erro", "Erro a remover o seguimento " + err))
            },
            actionClassName: "btn-danger",
            showAction: true,
            style: { zIndex: 1500 }
        });
    }

    return (
        <div>
            {contacts ?
                (contacts.length === 0 ?
                    "Este aluno não possui contactos" :
                    contacts.map(contact =>
                        <div key={contact.id}>
                            <div>{toDatePT(contact.date)}</div>
                            <div>{contact.description}</div>
                            <h6>Seguimentos</h6>
                            {contact.follow_ups && contact.follow_ups.map(followUp =>
                                <FollowUpItem followUp={followUp}
                                    handleFollowUpEdit={
                                        (followUp: FollowUp) => contact
                                    }
                                    handleFollowUpDelete={
                                        (followUp: FollowUp) => removeFollowUp(followUp.id)
                                    }
                                />
                            )}
                        </div>)
                ) :
                <Loader />
            }
        </div>
    );
}