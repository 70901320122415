import { useState, useEffect, useRef, useContext } from 'react';
import { MILLISECONDS_LIST_WAIT } from '../../constants';
import Carer from '../../models/Carer.model';
import { api } from '../../services/api';
import { dealyForXSeconds } from '../../utils/generic';
import { RedirectionContext, Routes } from '../MainRouter';
import Loader from '../shared/Loader';
import PaginationLinks from '../shared/PaginationLinks';
import SearchForm from '../shared/SearchForm';
import ContactsTable from './CarerTable';

export default function ContactsList() {
    const redirection = useContext(RedirectionContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    const [currentSearch, setCurrentSearch] = useState("");
    const [carers, setCarers] = useState<Carer[] | undefined>();

    const searchRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {

        api.getCarers(
            {
                search: currentSearch,
                page: currentPage
            },
            newCarers => {
                setHasMorePages(newCarers.morePages);
                setCarers(newCarers.data.map((carer: Carer) => new Carer(carer)));
            },
            () => setCarers([])
        );

        searchRef.current?.focus();
    }, [currentPage, currentSearch]);

    const paginationLinksJSX =
        <PaginationLinks
            page={currentPage}
            pageChanged={newPage => setCurrentPage(newPage)}
            hasMorePages={hasMorePages}
            classes="justify-content-end" />

    function handleContactRemoved(carerId: number) {
        if (carers) {
            setCarers(carers.filter(carer => carer.id !== carerId));
        }
    }

    return (
        <div className="container" style={{ position: "relative" }}>
            <button
                className="btn btn-success new-list-button"
                onClick={() => redirection.redirectTo(Routes.newCarer)}>Novo Encarregado de Educação</button>
            <h2 className="text-center m-4">Encarregados de Educação</h2>
            <SearchForm
                label="Pesquisar por encarregado de educação"
                onChangeHandler={event => {
                    dealyForXSeconds(MILLISECONDS_LIST_WAIT, () => {
                        setCurrentSearch(event.target.value);
                        setCurrentPage(1);
                    });
                }}
                style={{ marginBottom: "20px" }}
                searchRef={searchRef}
                searchHint="Pode pesquisar por nome, email ou telefone" />
            {carers ?
                <>
                    {paginationLinksJSX}
                    <ContactsTable values={carers} carerRemoved={handleContactRemoved} />
                    {paginationLinksJSX}
                </> :
                <Loader />
            }
        </div>
    );
}