import { useState } from 'react';

export default function GenericToggle<T>(props: {
    values: T[],
    toggleChanged: (newVal: T) => void,
    getId: (option: T) => string,
    getOptionContent: (option: T) => number | string | React.ReactElement;
    defaultOptionIndex?: number
}) {
    const [activeOption, setActiveOption] = useState(props.defaultOptionIndex || 0);

    return (
        <div className="btn-group btn-group-toggle" data-toggle="buttons">
            {props.values.map((val, pos) =>
                <label
                    key={pos}
                    className={`btn btn-primary ${pos === activeOption ? "active" : ""}`}>
                    <input
                        type="radio"
                        name="options"
                        id={props.getId(val)}
                        autoComplete="off"
                        onClick={() => {
                            setActiveOption(pos);
                            props.toggleChanged(val);
                        }}
                        style={{ display: "none" }} /> {props.getOptionContent(val)}
                </label>
            )}
        </div>
    );
}